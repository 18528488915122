import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LTIApiCalls } from "../../apis/LTIApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import "../../components/modals/modal.css";
import NormalPagination from "../../components/pagination/NormalPagination";
import useHostname from "../../hooks/useHostname";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as UNGRADED_ICON } from "../../images/grade-question-ungraded-icon.svg";
import { ReactComponent as CORRECT_ICON } from "../../images/grade-question-correct-icon.svg";
import { ReactComponent as INCORRECT_ICON } from "../../images/grade-question-incorrect-icon.svg";

import { useLoading } from "../../utils/LoadingContext";
import {
	ABSENT_STATUS,
	ABSENT_TEXT, ACTION_HEADER, ACTION_KEY,
	ATMPT_LABEL_2,
	ATTEMPT_REGEX,
	ATTENDANCE_STATUS_COLUMN_ID,
	ATTENDANCE_STATUS_COLUMN_NAME,
	CHECKBOX_COLUMN_ID,
	DATE_COLUMN_ID,
	DATE_COLUMN_NAME,
	DEFAULT_ROWS_PER_PAGE,
	FIXED_ANSWER,
	GRADE_BOOK_ATTENDANCE_NO_RESULTS_FOUND_EXPLAIN,
	GRADE_TEXT,
	NO_RESPONSE,
	NO_RESULTS_FOUND,
	PRESENT_STATUS,
	PRESENT_TEXT,
	SERIAL_NO_COLUMN_ID,
	SERIAL_NO_COLUMN_NAME,
	STD_LABEL,
	STUDENT_COLUMN_ID,
	STUDENT_COLUMN_NAME, SYNC_GRADEBOOK_TEXT,
	TEXTBOX,
	UNGRADED,
	UNGRADED_Cap,

} from "../../utils/constants";
import {getLocationGBDisplay, isValidScore, notifyError, notifySuccess} from "../../utils/helpers";
import { ENTER_VALID_SCORES, GRADEBOOK_SYNC_SUCCESS, SELECT_AT_LEAST_ONE_STUDENT } from "../../utils/toast-message-constants";
import styles from "./GradeBook.module.css";
import {YuJaKebabMenu} from "../../components/standardization/YuJaKebabMenu";
import GradeAttendanceModal from "./GradeAttendanceModal";
import YuJaButton from "../../components/standardization/YuJaButton";

function IndeterminateCheckbox({
	indeterminate,
	className = '',
	...rest
}) {
	const ref = useRef();

	useEffect(() => {
	  if (typeof indeterminate === 'boolean') {
		ref.current.indeterminate = !rest.checked && indeterminate;
	  }
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' indeterminate-checkbox'}
			{...rest}
			aria-label="checkbox"
		/>
	)
}

export default function AttendanceGradeBookTabs({
	responses = [],
	setResponses=undefined,
	// questions = [],
	pollKey=undefined,
	pollCode=undefined,
	// pollTitle=undefined,
	pollType=undefined,
	// lmsAttempt="",
	syncMode=0,
	setSyncMode=undefined,
	showCheckbox=true,
	geofenceEnable=false,
	// autoSyncData={},
	// setAutoSyncData=undefined,
	dataLoading=false,
	// setDataLoading,
	globalFilterObj=undefined,
	// pageIdx=0,
	// geofence=undefined,
	attendanceFilter=null,
	syncLmsEnable=false
}) {
	// const history = useHistory();
	const hostResource = useHostname(window.location.hostname);
	const {institutionId = ""} = hostResource;
	const [customScoresMap, setCustomScoresMap] = useState({});
	const [syncCountMap, setSyncCountMap] = useState(false);
	const { syncScore } = ResponseApiCalls();
	const { gradebookSync } = LTIApiCalls();
	const { setLoading } = useLoading();
	const [globalFilter, setGlobalFilter] = useState({});
	const [selectedRow, setSelectedRow] = useState(null);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: DEFAULT_ROWS_PER_PAGE
	});
	const [gradeAttendanceOpen, setGradeAttendanceOpen] = useState(false);
	const [gradeAttendanceUserId, setGradeAttendanceUserId] = useState("");


    const ATTENDANCE_GRADE_BOOK_COLUMNS = [
		{
			header: SERIAL_NO_COLUMN_NAME,
			accessorKey: SERIAL_NO_COLUMN_ID,
			enableSorting: false,
			id: SERIAL_NO_COLUMN_ID,
			size: 80
		},
		{
			header: STUDENT_COLUMN_NAME,
			accessorFn: row => `${row.firstName}${row.lastName ? ' ' + row.lastName : ''}`,
			enableSorting: true,
			sortingFn: 'alphanumeric',
			id: STUDENT_COLUMN_ID,
			size: 180
		},

		{
			header: DATE_COLUMN_NAME,
			accessorKey: DATE_COLUMN_ID,
			enableSorting: true,
			sortingFn: (rowA, rowB, _) => {
				const dateAParts = rowA.original.dateTitle.split("-");
				const dateBParts = rowB.original.dateTitle.split("-");
				// console.log(new Date(+dateAParts[2], dateAParts[1] - 1, +dateAParts[0]), new Date(+dateBParts[2], dateBParts[1] - 1, +dateBParts[0]));
				return new Date(+dateAParts[2], dateAParts[1] - 1, +dateAParts[0]) - new Date(+dateBParts[2], dateBParts[1] - 1, +dateBParts[0]);
			},
			id: DATE_COLUMN_ID,
			size: 120
		},
		{
			header: ATTENDANCE_STATUS_COLUMN_NAME,
			accessorKey: ATTENDANCE_STATUS_COLUMN_ID,
			enableSorting: false,
			id: ATTENDANCE_STATUS_COLUMN_ID,
			size: 120
		},
		{
			header: ACTION_HEADER,
			accessorKey: ACTION_KEY,
			size: 80,
			id: ACTION_KEY,
		}
	];

	const columns = useMemo(() => {
        // if (!!geofence && JSON.stringify(geofence) !== "") {
        // 	ATTENDANCE_GRADE_BOOK_COLUMNS.splice(2, 0, {
        // 		header: GEOFENCE_COLUMN_NAME,
        // 		accessorKey: GEOFENCE_COLUMN_NAME,
        // 		id: GEOFENCE_COLUMN_NAME,
        // 		size: 150
        // 	});
        // }
        return ATTENDANCE_GRADE_BOOK_COLUMNS;
	}, [ATTENDANCE_GRADE_BOOK_COLUMNS]); // pollType, geofence

	const filterCustomize = (r, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return true;
        }
		
        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
		const filteredStatus = globalFilterValue.status ? globalFilterValue.status : "";
		// console.log(filteredKeyword, globalFilterValue);
		if (!filteredKeyword && !filteredStatus) {
			return true;
		}

		const status = r.getValue(ATTENDANCE_STATUS_COLUMN_ID);
		if (filteredStatus && filteredStatus !== 'all' && status !== filteredStatus) {
			return false;
		}
		
		const participantName = r.getValue(STUDENT_COLUMN_ID)
		if (participantName && participantName.toLowerCase().indexOf(filteredKeyword) > -1) {
			return true;
		}

		return false;
    };

	const {
		getHeaderGroups,
		getRowModel,
		setPageIndex,
		getPageOptions,
		getCanPreviousPage,
		previousPage,
		getCanNextPage,
		nextPage,
		getPageCount,
		getState,
		getSelectedRowModel,
		getIsAllRowsSelected,
		getIsSomeRowsSelected,
		getToggleAllRowsSelectedHandler,
		resetRowSelection,
		resetSorting,
		resetPagination,
		setPageSize
	} = useReactTable({
		columns: columns,
		data: responses,
		// maxLeafRowFilterDepth: 0,
		// paginateExpandedRows: false,
		autoResetPageIndex: true,
		autoResetSelectedRows: true,
		autoResetSelectedCell: true,
		autoResetSelectedColumn: true,
		enableSortingRemoval: false,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		// getExpandedRowModel: getExpandedRowModel(),
		// getSubRows: (row) => {
		// 	return row.subRows;
		// },
		globalFilterFn: filterCustomize,
		onGlobalFilterChanged: setGlobalFilter,
		onPaginationChange: setPagination,
		state: {
			globalFilter: globalFilter,
			pagination
		},
	});

	useEffect(() => {
		// console.log(responses);
		setPageSize(DEFAULT_ROWS_PER_PAGE);
		let tempScoresMap = {};
		let tempSyncCountMap = {};
		responses.map(r => {
			tempSyncCountMap[r.pollKey + "#" + r.uniqueCode] = tempSyncCountMap[r.pollKey + "#" + r.uniqueCode] || {};
			tempSyncCountMap[r.pollKey + "#" + r.uniqueCode][r.userId] = r.syncCount;
			tempScoresMap[r.pollKey + "#" + r.uniqueCode] = tempScoresMap[r.pollKey + "#" + r.uniqueCode] || {};
			tempScoresMap[r.pollKey + "#" + r.uniqueCode][r.userId] = r.attendance === PRESENT_STATUS ? "100.00" : "0.00";
		});

		setCustomScoresMap(tempScoresMap);
		setSyncCountMap(tempSyncCountMap);
	}, [responses]);

	// useEffect(() => {
	// 	if (!!autoSyncData && !!autoSyncData.userId) {
	// 		console.log("Auto Syncing...", pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
	// 		autoSync(pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
	// 		setAutoSyncData({});
	// 	} else if (!!autoSyncData && !!autoSyncData.userIds) {
	// 		for (const id of autoSyncData.userIds) {
	// 			autoSync(pollCode, id , responses[responses.findIndex(r => r.userId === id)].attempts[0].score);
	// 		}
	// 		setAutoSyncData({});
	// 	}
	// }, [autoSyncData]);

	useEffect(() => {
		if (dataLoading) {
			resetSorting();
			resetPagination();
			resetRowSelection();
		}
	}, [dataLoading]);

	useEffect(() => {
		const syncWrapper = async () => {
			if (syncMode === 0) { // no need to sync
				return;
			}
			setLoading(true);
			if (syncMode === 1) { // student sync
				const selectedResponses = getSelectedRowModel().flatRows.filter(row => row.depth === 0).map(row => row.original);
				// console.log(selectedResponses);
				if (selectedResponses.length === 0) {
					notifyError(SELECT_AT_LEAST_ONE_STUDENT);
				} else {
					const res = await saveSelectedStudents(selectedResponses);
					if (res) {
						notifySuccess(GRADEBOOK_SYNC_SUCCESS);
					} else {
						notifyError(ENTER_VALID_SCORES);
					}
				}
			// } else if (syncMode === 2) { // quiz sync
			// 	let students = customScoresMap;
			// 	if (students.length === 0) {
			// 		notifyError(NO_STUDENTS_FOUND);
			// 	} else {
			// 		const res = await saveAllStudents(students);
			// 		if (res) {
			// 			notifySuccess(GRADEBOOK_SYNC_SUCCESS);
			// 		} else {
			// 			notifyError(ENTER_VALID_SCORES);
			// 		}
			// 	}
			}
			setSyncMode(0);
			setLoading(false);
		};

		syncWrapper();
    }, [syncMode]);

	const autoSync = (pollKey, pollCode, userId, score) => {
		console.log(pollCode, userId, score, syncCountMap, syncCountMap[pollKey + "#" + pollCode][userId]);
		if (syncLmsEnable) {
			gradebookSync(
				pollCode,
				userId,
				score,
				// () => syncScore(pollKey, pollCode, userId, true)
			);
		}
	};

	const syncStudent = async (pollKey, pollCode, userId, score) => {
		if (!pollCode) {
			console.log("Invalid pollCode");
			return false;
		}
		if (!userId) {
			console.log("Invalid userId");
			return false;
		}
		if (!isValidScore(score)) {
			console.log("Invalid score");
			return false;
		}
		if (!syncLmsEnable) {
			console.log("SyncLms is disabled");
			return false;
		}
		const res = await gradebookSync(
			pollCode,
			userId,
			score,
			() => {
				syncScore(pollKey, pollCode, userId, true, true);
				setResponses(responses.map(r => {
					if (r.userId === userId && r.pollKey === pollKey) {
						r.syncCount = r.syncCount + 1;
					}
					return r;
				}));
				// console.log("incrementing syncCountMap", pollKey + "#" + pollCode, userId, syncCountMap[pollKey + "#" + pollCode][userId] + 1);
				// setSyncCountMap({ ...syncCountMap, [pollKey + "#" + pollCode]: { ...syncCountMap[pollKey + "#" + pollCode], [userId]: syncCountMap[pollKey + "#" + pollCode][userId] + 1 } });
			}
		);
		return res;
	};

	async function saveSelectedStudents (selectedResponses) {
		if (selectedResponses.length === 0) { // base case
			return true;
		}
		const selectedResponse = selectedResponses.pop();
		// console.log(selectedResponse);
		const { pollKey, uniqueCode, userId } = selectedResponse;
		// console.log(pollKey, uniqueCode, userId);
		// console.log(customScoresMap[selectedResponse.pollKey + "#" + selectedResponse.uniqueCode][selectedResponse.userId]);
		if (!isValidScore(customScoresMap[pollKey + "#" + uniqueCode][userId])) { // invalid score
			console.log("Invalid score", customScoresMap[pollKey + "#" + uniqueCode][userId], "for", pollKey + "#" + uniqueCode, userId);
			// return false;
		} else {
			const res = await syncStudent(pollKey, uniqueCode, userId, customScoresMap[pollKey + "#" + uniqueCode][userId]);
			if (res) { 
				console.log("Synced", customScoresMap[pollKey + "#" + uniqueCode][userId], "for", pollKey + "#" + uniqueCode, userId);
			} else {
				console.log("Error in Gradebook Sync for", pollKey + "#" + uniqueCode, userId);
			}
		}
		return await saveSelectedStudents(selectedResponses);
	};
	
	// const saveAllStudents = async (responses) => {
	// 	// console.log(students);
	// 	for (let pollKeyCode in responses) {
	// 		for (let student in responses[pollKeyCode]) {
	// 			if (!isValidScore(responses[pollKeyCode][student])) {
	// 				console.log("Invalid score", responses[pollKeyCode][student], "for", pollKeyCode, student);
	// 				continue;
	// 				// return false;
	// 			}
	// 			const pollKey = pollKeyCode.substring(0, pollKeyCode.indexOf("#"));
	// 			const pollCode = pollKeyCode.substring(pollKeyCode.indexOf("#") + 1);
	// 			const res = await syncStudent(pollKey, pollCode, student, responses[pollKeyCode][student][0]);
	// 			if (res) {
	// 				console.log("Synced", responses[pollKeyCode][student], "for", pollKeyCode, student);
	// 			} else {
	// 				console.log("Error in Gradebook Sync for", pollKeyCode, student);
	// 			}
	// 		}
	// 	}
	// 	return true;
	// };

	useEffect(() => {
		setGlobalFilter(globalFilterObj);
		setPageIndex(0);
		if (selectedRow) {
			selectedRow.getToggleExpandedHandler()();
			setSelectedRow(null);
		}
	}, [globalFilterObj, responses]);


	const onClickViewAll = (row) => {
		const userId = row.original.userId;
		setGradeAttendanceUserId(userId);
		setGradeAttendanceOpen(true);
	}


	return (
		<>
			{gradeAttendanceOpen &&
				<GradeAttendanceModal
					institutionId={institutionId}
					pollKey={pollKey}
					pollCode={pollCode}
					userId={gradeAttendanceUserId}
					isShow={gradeAttendanceOpen}
					setIsShow={setGradeAttendanceOpen}
					responses={responses}
					setResponses={setResponses}
					geofenceEnable={geofenceEnable}
				/>
			}

			{!!getSelectedRowModel().flatRows.length &&
				<span style={{width: "100%", display: "flex", justifyContent: "end"}}>
					<YuJaButton
						style={{fontSize: 15, width: 150}}
						onClick={() => {
							if (setSyncMode) {
								setSyncMode(1);
							}
						}}
					>
					  {SYNC_GRADEBOOK_TEXT}
					</YuJaButton>
				</span>
			}

			<div
				className={`
					${styles.tableContainer}
					${showCheckbox ?
						styles.gradeBookTableContainerAttendanceWCheckbox :
						styles.gradeBookTableContainerAttendanceWoCheckbox
					}
				`}
			>
				<div className={styles.tableWrapper}>
					{dataLoading && (
						<div className={styles.skeletonTableContainer}>
							<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
								<div className={styles.skeletonHeaderContainer}>
									<Skeleton borderRadius={10} height={20} inline={true} width="2vw" containerClassName={styles.skeletonBodyCellWrapper} />
									<Skeleton borderRadius={10} height={20} inline={true} width="6vw" containerClassName={styles.skeletonBodyCellWrapper} />
									<Skeleton borderRadius={10} height={20} inline={true} width="3vw" containerClassName={styles.skeletonBodyCellWrapper} />
									<Skeleton borderRadius={10} height={20} inline={true} width="3vw" containerClassName={styles.skeletonBodyCellWrapper} />
									<Skeleton borderRadius={10} height={20} inline={true} width="4vw" containerClassName={styles.skeletonBodyCellWrapper} />
									<Skeleton borderRadius={10} height={20} inline={true} width="2vw" containerClassName={styles.skeletonBodyCellWrapper} />
									<Skeleton borderRadius={10} height={20} inline={true} width="3vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
								</div>
								<div className={styles.skeletonBodyContainer}>
									{Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
										return (<div className={styles.skeletonBodyRow}>
											<Skeleton borderRadius={10} height={20} inline={true} width={`${6 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
											<Skeleton borderRadius={10} height={20} inline={true} width={`${3 + (Math.random()*7)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
											<Skeleton borderRadius={10} height={20} inline={true} width={`${idx % 2 === 0 ? 3.5 : 3}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
											<Skeleton borderRadius={10} height={20} inline={true} width={`${1 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
											<Skeleton borderRadius={10} height={20} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
											<Skeleton borderRadius={10} height={20} inline={true} width={`${1 + (Math.random())}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
											<Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
										</div>)
									})}
								</div>
							</SkeletonTheme>
						</div>
					)}

					{!dataLoading &&
						<Table >
							<thead>
							{getHeaderGroups().map(headerGroup => (
								<tr
									// {...headerGroup.getHeaderGroupProps()}
									key={headerGroup.id}
								>
									{headerGroup.headers.map(column => {
										// console.log(column);
										if (column.id ===  SERIAL_NO_COLUMN_ID) {
											return (
												<th {...{
													// ...column.getHeaderProps(),
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													<span style={{display: "flex", alignItems: "center", gap: 5}}>
														<span
															style={{width: 30, display: "flex", alignItems: "center", justifyContent: "center", flexShrink: 0}}
														>
															{showCheckbox &&
																<IndeterminateCheckbox
																	className="form-check-input"
																	checked={getIsAllRowsSelected()}
																	indeterminate={getIsSomeRowsSelected()}
																	onChange={getToggleAllRowsSelectedHandler()}
																	style={{width: 16, height: 16, marginTop: 0}}
																/>
															}
														</span>
														{flexRender(column.column.columnDef.header, column.getContext())}
													</span>
												</th>
										)
										} else if (column.id === CHECKBOX_COLUMN_ID) {
											// console.log(column.column.columnDef.header, column.getContext());
											return (
												<th {...{
													// ...column.getHeaderProps(),
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													{flexRender(column.column.columnDef.header, column.getContext())}
												</th>
											);
										}  else if (column.id === ACTION_KEY) {
											return (
												<th {...{
													// ...column.getHeaderProps(),
													key: column.id,
													style: {width: column.getSize()}
												}}>
													<span style={{width: "100%", display: "flex", justifyContent: "end"}}>
														<span
															style={{
																width: "80%",
																display: "flex",
																alignItems: "center",
																justifyContent: "center"
															}}
														>
														{ACTION_HEADER}
														</span>
													</span>
												</th>
											);
										}  else {
											// console.log(column.column.columnDef.header, column.getContext());
											return (
												<th tabIndex={0} {...{
													// ...column.getHeaderProps(),
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													{flexRender(column.column.columnDef.header, column.getContext())}
													{column.column.getCanSort() ? (
														column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
																	<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} /> :
																	<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={column.column.getToggleSortingHandler()} />
															) :
															<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} />
													) : null}
												</th>
											);
										}
									})}
								</tr>
							))}
							</thead>
							<tbody>
								{getRowModel().rows?.length === 0 &&
									<tr>
										<td colSpan={columns.length}>
											<div style={{
												padding: 30,
												justifyContent: "center",
												display: "flex",
												marginTop: "20px",
												flexDirection: "column",
												alignItems: "center",
												gap: "5px"
											}}>
												<NoResultIllustration/>
												<div style={{
													fontSize: 20,
													marginTop: 25,
													fontWeight: 600,
													color: "#000"
												}}> {NO_RESULTS_FOUND} </div>
												<div style={{
													fontSize: 15,
													marginTop: 10,
													fontWeight: 400,
													color: "#000"
												}}> {GRADE_BOOK_ATTENDANCE_NO_RESULTS_FOUND_EXPLAIN} </div>

											</div>
										</td>
									</tr>
								}
								{getRowModel().rows?.length > 0 &&
									(getRowModel().rows.map((row, i) => {
										let subRowData;
										let selectedOption;
										let locationData;
										let locationDisplay;
										let locationAccuracy;
										if (row.depth === 0) {
											subRowData = null;
											selectedOption = null;
											locationData = null;
										} else {
											subRowData = row.original;
											selectedOption = subRowData.selectedOption && subRowData.selectedOption !== `"${FIXED_ANSWER.UNANSWERED}"` ?
												JSON.parse(row.original.selectedOption)[0] :
												NO_RESPONSE;
											locationData = subRowData.locationData ? JSON.parse(subRowData.locationData) : null;
											if (locationData) {
												locationDisplay = getLocationGBDisplay(locationData.message, locationData.accuracy);
												locationAccuracy = locationData.accuracy ? `${parseFloat(locationData.accuracy).toFixed(2)} m` : null;
											}
										}
										return (<>
											<tr key={row.id}>
												{row.depth === 0 && row.getVisibleCells().map(cell => {
													if (cell.column.id === SERIAL_NO_COLUMN_ID) {
														return (
															<td
																tabIndex={0}
																role={TEXTBOX}
																{...{
																	key: cell.id,
																	style: {
																		width: cell.column.getSize(),
																		backgroundClip: "padding-box",
																		height: "fit-content"
																	}
																}}
															>
															<span style={{display: "flex", alignItems: "center", gap: 5}}>
																<span style={{
																	width: 30,
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center",
																	flexShrink: 0
																}}>
																	{showCheckbox &&
																		<IndeterminateCheckbox
																			className="form-check-input"
																			checked={row.getIsSelected()}
																			// disabled={!row.getCanSelect()}
																			indeterminate={row.getIsSomeSelected()}
																			onChange={row.getToggleSelectedHandler()}
																			style={{width: 16, height: 16, marginTop: 0}}
																		/>
																	}
																</span>
																{row.index + 1}
															</span>
															</td>
														);
													} else if (cell.column.id === ATTENDANCE_STATUS_COLUMN_ID) {
														// console.log(cell.row.original.pollKey, cell.row.original.userId);
														return <td
															{...{
																key: cell.id,
																style: {
																	width: cell.column.getSize(),
																	backgroundClip: "padding-box"
																}
															}}
															className={styles.gradeBookAttendanceStatus}
														>
															<div className={styles.attendanceStatusContainer}>
																{cell.getValue() !== PRESENT_STATUS && cell.getValue() !== ABSENT_STATUS &&
																	<div
																		tabIndex={0}
																		role={TEXTBOX}
																		aria-label={UNGRADED}
																		className={styles.generalBadge}
																		style={{backgroundColor: "#D3DDF2"}}
																	>
																		<UNGRADED_ICON/>{UNGRADED_Cap}
																	</div>
																}

																{cell.getValue() === PRESENT_STATUS &&
																	<div
																		tabIndex={0}
																		role={TEXTBOX}
																		aria-label={PRESENT_STATUS}
																		className={styles.generalBadge}
																		style={{backgroundColor: "#DBE7E1"}}
																	>
																		<CORRECT_ICON/>{PRESENT_TEXT}
																	</div>
																}

																{cell.getValue() === ABSENT_STATUS &&
																	<div
																		tabIndex={0}
																		role={TEXTBOX}
																		aria-label={ABSENT_STATUS}
																		className={styles.generalBadge}
																		style={{backgroundColor: "#F8DCDB"}}
																	>
																		<INCORRECT_ICON/>{ABSENT_TEXT}
																	</div>
																}
															</div>
														</td>
													} if (cell.column.id === CHECKBOX_COLUMN_ID) {
														return (
															<td {...{ key: cell.id, style: { width: cell.column.getSize(), backgroundClip: "padding-box" } }}>
																{flexRender(cell.column.columnDef.cell, cell.getContext())}
															</td>
														);
													} else if (cell.column.id === STUDENT_COLUMN_ID) {
														// console.log(cell.column.columnDef.cell, cell.getValue());
														return (
															<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
																<div
																	aria-label={cell.getValue().includes("Attempt") ? (cell.getValue() + ATMPT_LABEL_2) : (STD_LABEL + cell.getValue())} className={ATTEMPT_REGEX.test(cell.getValue()) ? styles.subRow : styles.mainRow} style={{display: "flex", justifyContent: "left"}}
																>
																	<div tabIndex={0} role={TEXTBOX} style={{width: "fit-content"}}>{flexRender(cell.column.columnDef.cell, cell.getContext())} </div>
																</div>
															</td>
														);
													} else if (cell.column.id === ACTION_KEY) {
														return (
															<td
																align={"right"}
																className={styles.actionTd}
																{...{key: cell.id,
																	style: {
																		width: cell.column.getSize(),
																		zIndex: getRowModel().rows.length - i,
																	}
																}}
															>
																<span
																	style={{
																		backgroundColor: "#F8F8F8",
																		width: "80%",
																		height: 60,
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center"
																	}}
																>
																	<YuJaKebabMenu>
																		<YuJaKebabMenu.Menu>
																			<YuJaKebabMenu.Item onClick={() => onClickViewAll(row)}>
																				{GRADE_TEXT}
																			</YuJaKebabMenu.Item>
																		</YuJaKebabMenu.Menu>
																	</YuJaKebabMenu>
																</span>

															</td>
														);
													}  else {
														// console.log(cell);
														return (
															<td
																tabIndex={0}
																{...{key: cell.id,
																	style: {
																		width: cell.column.getSize(),
																		backgroundClip: "padding-box",
																		whiteSpace: "nowrap",
																		overflow: "hidden",
																		textOverflow: "ellipse"
																	}
																}}
															>
																{cell.getValue()}
															</td>
														);
													}
												})}
											</tr>
										</>);
									}))
								}
							</tbody>
						</Table>
					}

				</div>

				{!dataLoading && getPageCount() > 0 &&
					<NormalPagination
						pageCount={getPageCount()}
						pageIndex={getState().pagination.pageIndex}
						pageOptions={getPageOptions()}
						canPreviousPage={getCanPreviousPage()}
						canNextPage={getCanNextPage()}
						previousPage={previousPage}
						nextPage={nextPage}
						gotoPage={setPageIndex}
						paddingTop={"0px"}
					/>
				}
			</div>
		</>
	)
}