import React, { useEffect, useState } from "react";
// import { Pagination } from "react-bootstrap";
import { ReactComponent as First } from "../../images/First.svg";
import { ReactComponent as DisabledFirst } from "../../images/disabled_first.svg";
import { ReactComponent as Last } from "../../images/Last.svg";
import { ReactComponent as DisabledLast } from "../../images/disabled_last.svg";
import { ReactComponent as Next } from "../../images/Next.svg";
import { ReactComponent as DisabledNext } from "../../images/disabled_next.svg";
import { ReactComponent as Prev } from "../../images/Prev.svg";
import { ReactComponent as DisabledPrev } from "../../images/disabled_prev.svg";
import { BUTTON, CURRENT_PAGE, DEFAULT_PAGINATION_SIZE, FIRST_PAGE, LAST_PAGE, NEXT_PAGE, PREVIOUS_PAGE } from "../../utils/constants";
import "./Pagination.module.css";
import styles from "./Pagination.module.css";

export default function NormalPagination({pageIndex, pageOptions, pageCount, canPreviousPage, canNextPage, previousPage, nextPage, gotoPage, paddingTop="10px"}) {
    const paginationSize = DEFAULT_PAGINATION_SIZE;
    const getOpts = () => {
        // console.log(pageCount, paginationSize, pageIndex, pageOptions);

        //less than pagination size
        if (pageCount <= paginationSize) {
            return pageOptions;
        }

        //general
        if (pageIndex - paginationSize / 2 >= 0 && pageIndex + paginationSize / 2  <= pageCount) {
            return pageOptions.slice(pageIndex - paginationSize / 2, pageIndex + paginationSize / 2);
        }

        //the left side pages is not enough to display
        if (pageIndex - paginationSize / 2 < 0) {
            return pageOptions.slice(0, paginationSize);
        }

        //the right side pages is not enough to display
        if (pageIndex + paginationSize / 2  > pageCount) {
            return pageOptions.slice(pageCount - paginationSize, pageCount);
        }
        return [];
    }

    const [displayedOpts, setDisplayedOpts] = useState(() => {
        return getOpts();
    });




    useEffect(() => {
        setDisplayedOpts(getOpts());
    }, [pageIndex, pageCount]);

    return (
        <div className={styles.paginationContainer} style={{paddingTop: paddingTop}}>
            <div
                className={canPreviousPage ? styles.paginationItem : styles.paginationItemDisabled}
                onClick={() => {
                    if (canPreviousPage) gotoPage(0)
                }}
                tabIndex={0}
                aria-label={FIRST_PAGE}
                role={BUTTON}
            >
                {canPreviousPage ? <First style={{height: 12, width:12}} /> : <DisabledFirst style={{height: 12, width:12}}/>}
            </div>
            <div
                className={canPreviousPage ? styles.paginationItem : styles.paginationItemDisabled}
                onClick={() => {
                    if (canPreviousPage) previousPage()
                }}
                tabIndex={0}
                aria-label={PREVIOUS_PAGE}
                role={BUTTON}
            >
               {canPreviousPage ?  <Prev style={{height: 20, width:20}}/> : <DisabledPrev style={{height: 20, width:20}}/>}
            </div>
            {displayedOpts.map((idx, i) => {
                return (
                    <div
                        key={i}
                        className={pageIndex === idx ? styles.paginationItemActive : styles.paginationItem}
                        onClick={() => gotoPage(idx)}
                        tabIndex={0}
                        aria-label={CURRENT_PAGE+(idx+1)}
                        role={BUTTON}
                    >
                        {idx + 1}
                    </div>
                )
            })}
            <div
                className={canNextPage ? styles.paginationItem : styles.paginationItemDisabled}
                onClick={() => {
                    if (canNextPage) nextPage()
                }}
                tabIndex={0}
                aria-label={NEXT_PAGE}
                role={BUTTON}
            >
                {canNextPage ? <Next style={{height: 20, width:20}}/> : <DisabledNext style={{height: 20, width:20}}/>}
            </div>
            <div
                className={canNextPage ? styles.paginationItem : styles.paginationItemDisabled}
                onClick={() => {
                    if (canNextPage) gotoPage(pageCount-1)
                }}
                tabIndex={0}
                aria-label={LAST_PAGE}
                role={BUTTON}
            >
                {canNextPage ? <Last style={{height: 12, width:12}}/> : <DisabledLast style={{height: 12, width:12}}/>}
            </div>
        </div>
        // Old :
        // <Pagination>
        //     <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
        //     <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
        //     {displayedOpts.map((idx, i) => {
        //         return (
        //             <Pagination.Item
        //                 key={i}
        //                 active={pageIndex === idx}
        //                 onClick={() => gotoPage(idx)}>
        //                 {idx + 1}
        //             </Pagination.Item>
        //         );
        //     })}
        //     <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        //     <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
        // </Pagination>
    );
}