import classes from "./PollReady.module.css"
import {ReactComponent as DELETE } from "./images/delete.svg";
import {
    FIRST_WC_PLACEHOLDER,
    FIXED_ANSWER,
    OTHER_WC_PLACEHOLDER,
    QUESTION_TYPES,
    WC_ANSWER_LENGTH
} from "../../utils/constants";
import React, {useEffect,  useState} from "react";
import {checkBlankAnswered, checkUnansweredNew} from "../../utils/helpers";

export default function WordCloudEntries({
                                             currEntries,
                                             shownEntries,
                                             setShownEntries,
                                             currentSelectedOption,
                                             setCurrentSelectedOption,
                                             isAnsweredState=false
}) {
    const BAR_START_WIDTH = 50;
    const DELETE_BAR_WIDTH = 130;
    const [startX, setStartX] = useState(0);


    const [isTouchAble, setIsTouchAble] = useState(false);

    useEffect(() => {
        setIsTouchAble(window.navigator.maxTouchPoints > 0);
    }, [window.navigator.maxTouchPoints]);


    const onChange = (e, index) => {
        const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : {...currentSelectedOption}
        copy[index] = e.target.value;
        if (index + 1  === Object.keys(copy).length && Object.keys(copy).length < currEntries && e.target.value.length) {
            copy[index + 1] = "";
        }

        setCurrentSelectedOption(copy);

    }

    const handleDelete = (i) => {
        if (isAnsweredState) {
            return;
        }

        let copy = { ...currentSelectedOption };
        delete copy[i];
        if (!Object.entries(copy).length) {
            copy[0] = "";
        } else {
            let copyValues = Object.values(copy);
            copy = {}
            for (let j = 0; j < copyValues.length; j++) {
                copy[j] = copyValues[j];
            }
        }
        setCurrentSelectedOption(copy);

    };



    const onPointerMove = (e) => {
        if (!isTouchAble || !!isAnsweredState) {
            return;
        }

        if (document.activeElement === e.currentTarget.querySelector("input")) {
            return;
        }

        let newX = e.changedTouches[0].clientX;
        if (startX - newX >= 0) {
            let length = startX - newX;
            length = length > 130 ? 130 : length;

            let manageBar = e.currentTarget.querySelector(`.${classes.WCOptionManagerBarTouchAble}`);

            if (!manageBar) {
                console.log(`manage bar is null`);
                console.log(e);
                return;
            }
            manageBar.style.width = `${BAR_START_WIDTH + length}px`;
            manageBar.style.transition = undefined;
        }
    }

    const onPointerDown = (e) => {
        if (!isTouchAble || !!isAnsweredState) {
            return;
        }

        if (document.activeElement === e.currentTarget.querySelector("input")) {
            return;
        }

        setStartX(e.targetTouches[0].clientX);
    }

    const onPointerUp = (e) => {
        if (!isTouchAble || !!isAnsweredState) {
            return;
        }

        let currentTarget = e.currentTarget;

        if (document.activeElement === currentTarget.querySelector("input")) {
            return;
        }


        let manageBar = currentTarget.querySelector(`.${classes.WCOptionManagerBarTouchAble}`);

        let newX = e.changedTouches[0].clientX;
        if (startX - newX >= DELETE_BAR_WIDTH) {
            manageBar.style.width = `${BAR_START_WIDTH}px`;
            let index = currentTarget.getAttribute("data-wc-option-index");
            handleDelete(parseInt(index));
            return;
        }

        manageBar.style.transition = "0.1s ease";
        manageBar.style.width = `${BAR_START_WIDTH}px`;
    }

    const showDelete = (idx) => {
        let deleteIcon = document.getElementById(`delete-icon-${idx}`);
        if (!!deleteIcon) {
            deleteIcon.style.display = "block";
        }
    }

    const hideDelete = (idx) => {
        let deleteIcon = document.getElementById(`delete-icon-${idx}`);
        if (!!deleteIcon) {
            deleteIcon.style.display = "none";
        }
    }

    const onMouseEnter = (idx) => {
        if (!!isTouchAble) {
            return;
        }

        showDelete(idx);
    }

    const onMouseLeave = (e, idx) => {
        if (!!isTouchAble) {
            return;
        }


        if (e.currentTarget.contains(document.activeElement)) {
            return;
        }

        hideDelete(idx);
    }

    const onFocus = (idx) => {
        if (!!isTouchAble) {
            return;
        }

        showDelete(idx);
    }

    const onBlur = (e, idx) => {
        if (!!isTouchAble) {
            return;
        }

        console.log(`document.activeElement: ${document.activeElement}`)

        if (e.currentTarget.parentNode.contains(document.activeElement)) {
            return;
        }

        hideDelete(idx);

    }

    const getEntries = () => {
        if (!currentSelectedOption || currentSelectedOption === FIXED_ANSWER.UNANSWERED || Object.keys(currentSelectedOption).length === 0) {
            return currEntries;
        }

        if (Object.keys(currentSelectedOption).length === 0) {
            return currEntries
        }

        return Object.keys(currentSelectedOption).length;
    }



    return (
        <>
            {Array(getEntries()).fill(null).map((item, i) => (
                <div
                    key={i}
                    data-wc-option-index={i}
                    className={`${classes.WCOptionContainer} ${!!isAnsweredState ? classes.notAllowed : ""}`}
                    // onTouchStart={onPointerDown}
                    // onTouchEnd={onPointerUp}
                    // onTouchMove={onPointerMove}
                    // onMouseEnter={() => onMouseEnter(i)}
                    // onMouseLeave={(e) => onMouseLeave(e, i)}
                    // onFocus={() => onFocus(i)}
                    // onBlur={(e) => onBlur(e, i)}
                >
                    <input
                        disabled={!!isAnsweredState}
                        maxLength={WC_ANSWER_LENGTH}
                        className={classes.WCOptionInputBox}
                        placeholder={i === 0 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                        value={checkBlankAnswered(currentSelectedOption) ? "" : currentSelectedOption[i]}
                        onChange={(e) => onChange(e, i)}
                        // onFocus={() => onFocus(i)}
                        // onBlur={(e) => onBlur(e, i)}
                    />

                    <div className={classes.WCOptionManagerBar}>
                        <span className={classes.WCOptionCharacterRemain}>
                            {WC_ANSWER_LENGTH -
                                ((!!currentSelectedOption && checkBlankAnswered(currentSelectedOption) && !!currentSelectedOption[i])
                                    ? currentSelectedOption[i].length : 0)
                            }
                        </span>
                        <DELETE
                            tabIndex={0}
                            id={`delete-icon-${i}`}
                            onClick={() => handleDelete(i)}
                            style={{flexShrink: 0, cursor: "pointer"}}
                        />
                    </div>

                    {/*{isTouchAble &&*/}
                    {/*    <div className={classes.WCOptionManagerBarTouchAble}>*/}
                    {/*        <span className={classes.WCOptionCharacterRemain}>*/}
                    {/*            {WC_ANSWER_LENGTH - (currentSelectedOption[i] ? currentSelectedOption[i].length : 0)}*/}
                    {/*        </span>*/}
                    {/*        <span style={{width: 20}}/>*/}
                    {/*        <span className={classes.WCOptionDeleteBar}>*/}
                    {/*            <DELETE/>*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*{!isTouchAble &&*/}
                    {/*    <div className={classes.WCOptionManagerBar}>*/}
                    {/*        <span className={classes.WCOptionCharacterRemain}>*/}
                    {/*            {WC_ANSWER_LENGTH - (currentSelectedOption[i] ? currentSelectedOption[i].length : 0)}*/}
                    {/*        </span>*/}
                    {/*        <DELETE*/}
                    {/*            tabIndex={0}*/}
                    {/*            id={`delete-icon-${i}`}*/}
                    {/*            onClick={() => handleDelete(i)}*/}
                    {/*            style={{flexShrink: 0, cursor: "pointer"}}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}

                </div>
            ))}

        </>
    );
}