import React, {useEffect, useState, Fragment} from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { ReactComponent as NoResultIllustration } from "../../images/notGradedAttendance.svg";

import {
    BUTTON,
    FIXED_ANSWER,
    NO_RESPONSES_TEXT, OE_NO_RESPONSE_EXPLAIN, OPEN_ENDED_DISLIKE_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_TIME_SORT,
    QUESTION_TYPES,
    THUMBS_DOWN, THUMBS_UP, YUJA_DROPDOWN_SIZE
} from "../../utils/constants";

import classes from "./OpenEndedResult.module.css";
import classResultClasses from "./ClassResult.module.css";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";

export default function OpenEndedResultFromClassResult({pollKey, uniqueCode, question, isExpanded}) {
    const { getClassResult, getOpenEndedReaction} = ResponseApiCalls();
    const [questionData, setQuestionData ] =  useState([]);
    const [openEndedData, setOpenEndedData] =  useState([]);
    const [currentReaction, setCurrentReaction] = useState([]);
    const [loading, setLoading] = useState(false);
    const [originalOpenEnded, setOriginalOpenEnded] = useState(null);
    const [sortOption, setSortOption] = useState(OPEN_ENDED_TIME_SORT);
    const [containerHeight, setContainerHeight] = useState(0);
    const [commentCount, setCommentCount] = useState(0);

    async function getQuestionsData() {
        const results = await getClassResult(pollKey, uniqueCode, question, null,  'all');
        if (results && results.openEnded && Object.keys(results.openEnded).length >= 1 && results.openEnded) {
            setOriginalOpenEnded(results.openEnded);
        }

        setQuestionData(results.responses);
    }

    useEffect( () => {
        if (!question || question.questionType !== QUESTION_TYPES.OE.name) {
            return;
        }

        setLoading(true);
        getReaction().then(() => {
            getQuestionsData().then(() => {
                handleSort();
            });
        }).finally(() => {
            setLoading(false);
        });



        const interval = setInterval(() => {
            getQuestionsData();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [question]);


    useEffect(() => {
        handleSort();
    }, [sortOption, originalOpenEnded])

    const handleSort = () => {
        if (sortOption === OPEN_ENDED_TIME_SORT && originalOpenEnded) {
            setOpenEndedData(Object.entries(originalOpenEnded));
        } else if (sortOption === OPEN_ENDED_LIKE_SORT && originalOpenEnded && Object.keys(originalOpenEnded).length > 1) {
            let entries = Object.entries(originalOpenEnded);
            entries.sort(([, valueA], [, valueB]) => {
                return valueB["1"] - valueA["1"];
            });
            setOpenEndedData(entries);
        } else if (sortOption === OPEN_ENDED_DISLIKE_SORT && originalOpenEnded  && Object.keys(originalOpenEnded).length > 1) {
            let entries = Object.entries(originalOpenEnded);
            entries.sort(([, valueA], [, valueB]) => {
                return valueB["2"] - valueA["2"];
            });
            setOpenEndedData(entries);
            console.log(entries)
        }
    }


    async function getReaction() {
        const result = await getOpenEndedReaction(pollKey, uniqueCode, question.serialNo);
        if (result && result.reactions) {
            setCurrentReaction(result.reactions);
        }
    }





    useEffect(() => {

        let height = 0;

        let commentLength = 0;
        commentLength = !!openEndedData
            ? openEndedData.filter(entry => questionData && questionData[entry[0]] && questionData[entry[0]] !== FIXED_ANSWER.UNANSWERED).length
            : 0;


        if (!!loading) {
            height = 300;
        } else if (!!commentLength) {
            height = commentLength * 1000 + 35;
        } else {
            height = 300;
        }

        setContainerHeight(height);
        setCommentCount(commentLength);
    }, [loading, openEndedData, questionData]);




  return (
      <div className={classResultClasses.chartContainer} style={{maxHeight: !isExpanded ? 0 : containerHeight}}>
          <div className={classResultClasses.chartContainerBody}>
              {!!loading &&
                  <div className={classes.body}>
                      <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                          <Skeleton height={96} borderRadius={8}/>
                          <Skeleton height={96} borderRadius={8}/>
                          <Skeleton height={96} borderRadius={8}/>
                      </SkeletonTheme>
                  </div>
              }
              {!loading &&
                  <>
                      <YuJaDropdown
                          size={YUJA_DROPDOWN_SIZE.SMALL}
                          data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                          getOptionLabel={item => item}
                          getOptionValue={item => item}
                          onChange={option => setSortOption(option)}
                          value={sortOption}
                          containerStyle={{width: 130, alignSelf: "end"}}
                      />
                      <div className={classes.body} style={{padding: 0}}>
                          {!!commentCount &&
                              openEndedData?.map(([key, value], index) => {
                                  if (questionData && questionData[key] && questionData[key] !== FIXED_ANSWER.UNANSWERED) {
                                      let isThumbUp = currentReaction && currentReaction[key] && currentReaction[key][0] === "1";
                                      let isThumbDown = currentReaction && currentReaction[key] && currentReaction[key][0] === "2";
                                      return (
                                          <div
                                              key={index}
                                              className={classes.responseCard}
                                              style={{
                                                  borderRadius: 4,
                                                  backgroundColor: "#F6F6F6E6",
                                                  border: "1px solid #E6E6E6"
                                              }}
                                          >
                                              <span tabIndex={isExpanded ? 0 : -1}>{`${questionData[key]["0"]}`}</span>
                                              <span className={classes.likeAndDislikeContainer} style={{backgroundColor: "#E6E6E6"}}>
                                                  <span
                                                      className={`${classes.thumbUpIcon} ${isThumbUp ? classes.active : ""}`}
                                                      tabIndex={isExpanded ? 0 : -1}
                                                      aria-label={THUMBS_UP}
                                                      role={BUTTON}
                                                  />
                                                 <span style={{
                                                     fontSize: 15,
                                                     fontWeight: 500,
                                                     color: "#4D4D4D"
                                                 }}> {value[1]} </span>
                                                 <span style={{flex: 1}}/>
                                                 <span
                                                     className={`${classes.thumbDownIcon} ${isThumbDown ? classes.active : ""}`}
                                                     tabIndex={isExpanded ? 0 : -1}
                                                     aria-label={THUMBS_DOWN}
                                                     role={BUTTON}
                                                 />
                                                 <div style={{
                                                     fontSize: 15,
                                                     fontWeight: 500,
                                                     color: "#4D4D4D"
                                                 }}> {value[2]} </div>
                                             </span>
                                          </div>
                                      );
                                  } else {
                                      return <Fragment key={index}></Fragment>;
                                  }
                              })
                          }

                          {!commentCount &&
                              <>
                                  <NoResultIllustration width={130} height={71} style={{alignSelf: "center"}}/>
                                  <span style={{fontSize: 16, lineHeight: "19px", fontWeight: 600, alignSelf: "center"}}>
                                        {NO_RESPONSES_TEXT}
                                  </span>
                                  <span style={{fontSize: 14, lineHeight: "16px", fontWeight: 400, alignSelf: "center"}}>
                                      {OE_NO_RESPONSE_EXPLAIN}
                                  </span>
                              </>
                          }
                      </div>
                  </>
              }
          </div>
      </div>
  )
}