import React, { useEffect, useState } from "react";
import useMobileAccess from "../../hooks/useMobileAccess";
import {
    ASCII_CODE_A,
    ASCII_CODE_SMALL_A, CORRECT,
    FIXED_ANSWER,
    OPTION_LABEL,
    QUESTION_TYPES,
    TEXTBOX
} from "../../utils/constants";
import { checkUnanswered } from "../../utils/helpers";
import { ReactComponent as CORRECT_ICON } from "../../images/grade-question-correct-icon.svg";
import "./styles.css";
import classes from "./ClassResult.module.css";
import ImageViewer from "./ImageViewer";

export default function ClassResultChart({ correctAnswers, chartData, questionType, showAnswerOnViewer=false, queResponse, question={}, isLoading=false, isExpanded}) {
    // const curWindow = useWindowDimensions();
    const [data, setData] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [correctAnswerIndexArr, setCorrectAnswerIndexArr] = useState([]);
    const isMobile = useMobileAccess(480);
    const {optionsImageMap={}, weightage, serialNo} = question;
    const gradeEnabled = !!weightage;

    useEffect(() => {
        let optionArrData = [];
        if (!chartData || !Object.entries(chartData).length) {
            if (questionType === QUESTION_TYPES.MCSS.name) {//{"a":"Margin-buying","b":"Overproduction ","c":"Expansion of credit buying","d":"The Dust Bowl "}
                optionArrData = Object.keys(question.optionsMap).sort().map(i => ({index: i.toUpperCase(), value: 0}));
            } else if (questionType === QUESTION_TYPES.CI.name) {//[{"text":"ab","x":0.147369,"y":0.068148,"width":0.310526,"height":0.16},{"text":"b","x":0.511404,"y":0.470617,"width":0.411404,"height":0.206913},{"text":"c","x":0.116667,"y":0.801482,"width":0.297368,"height":0.162468}]

            } else if (questionType === QUESTION_TYPES.FITB.name
                || questionType === QUESTION_TYPES.MH.name) {
                    optionArrData.push({ index: "Correct", value: 0});
                    optionArrData.push({ index: "Incorrect", value: 0});
                }
            else if (questionType === QUESTION_TYPES.TF.name
            ) {//graded poll TF or FITB or MH
                optionArrData.push({ index: "True", value: 0});
                optionArrData.push({ index: "False", value: 0});
            }
            setData(optionArrData);
            return;
        }


        const selectedIdxCopy = []
        let tempTotalData = 0;
        const correctAnswerIndexArrCopy = [];
        chartData.forEach((result, index) => {
            if (questionType === QUESTION_TYPES.MCSS.name) {
                optionArrData.push({ index: result.ans.split(". ")[0], value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
            } else if (questionType === QUESTION_TYPES.CI.name) {
                if (result.ans === FIXED_ANSWER.OTHER) {
                    optionArrData.push({ value: result.people, answer: FIXED_ANSWER.OTHER});
                } else {
                    optionArrData.push({ index: result.ans.split(". ")[0].toUpperCase(), value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
                }
            }else if (questionType === QUESTION_TYPES.TF.name) {
                optionArrData.push({ index: result.ans, value: result.people, answer: result.ans});
            } else {
                optionArrData.push({ index: result.ans, value: result.people, answer: result.ans});
            }

            if (!!correctAnswers && !!Object.entries(correctAnswers).length) {
                if ((questionType === QUESTION_TYPES.MCSS.name && correctAnswers.includes(optionArrData[index]?.index?.toLowerCase()))
                    || (questionType === QUESTION_TYPES.CI.name && correctAnswers.includes(result.name))
                    || (questionType === QUESTION_TYPES.TF.name && result.name.toString().includes(correctAnswers))
                    || (questionType === QUESTION_TYPES.SA.name && result.ans === "Correct")
                    || (questionType === QUESTION_TYPES.MH.name && result.ans === "Correct")
                    || (questionType === QUESTION_TYPES.FITB.name && result.ans === "Correct")
                ) {
                    correctAnswerIndexArrCopy.push(index);
                }
            }
            tempTotalData += result.people
            if (!checkUnanswered(gradeEnabled, questionType, queResponse?.selectedOption)) {
                if (questionType === QUESTION_TYPES.MCSS.name && !gradeEnabled && queResponse.selectedOption.includes(optionArrData[index]?.index?.toLowerCase())) {
                    selectedIdxCopy.push(index);
                }else if (questionType === QUESTION_TYPES.MCSS.name && !!gradeEnabled && queResponse.selectedOption === optionArrData[index]?.index?.toLowerCase()) {
                    selectedIdxCopy.push(index);
                }else if ( questionType === QUESTION_TYPES.TF.name && queResponse.selectedOption?.toLowerCase() === optionArrData[index]?.index?.toLowerCase()) {
                    selectedIdxCopy.push(index);
                }else if (questionType === QUESTION_TYPES.FITB.name) {
                    if ((!!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'correct') || (!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'incorrect') ) {
                        selectedIdxCopy.push(index);
                    }
                } else if (questionType === QUESTION_TYPES.MH.name) {
                    if ((!!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'correct') || (!queResponse.isCorrect && optionArrData[index]?.index?.toLowerCase() === 'incorrect') ) {
                        selectedIdxCopy.push(index);
                    }
                } else if (questionType === QUESTION_TYPES.CI.name) {
                    const selectedOptIdx = isNaN(queResponse.selectedOption.selected) ? FIXED_ANSWER.OTHER : String.fromCharCode(ASCII_CODE_A + parseInt(queResponse.selectedOption.selected));
                    if (optionArrData[index]?.index?.toUpperCase() === selectedOptIdx.toUpperCase()) {
                        selectedIdxCopy.push(index);
                    }
                }
            }

        })

        setData(optionArrData);
        setTotalData(tempTotalData);
        setSelectedIdx(selectedIdxCopy);
        setCorrectAnswerIndexArr(correctAnswerIndexArrCopy);
    }, [chartData]);

    const setBar = () => {
        data.forEach((entry, index) => {
            let bar = document.getElementById(`que-${serialNo}-count-bar-${index}`);
            if (!!bar) {
                bar.style.width = `${!!totalData ? (entry.value / totalData) * 100 : 0}%`;
            }
        });
    }

    const resetBar = () => {
        data.forEach((entry, index) => {
            let bar = document.getElementById(`que-${serialNo}-count-bar-${index}`);
            if (!!bar) {
                bar.style.width = "0";
            }
        });
    }

    useEffect(() => {
        if (isExpanded) {
            setBar();
        } else {
            resetBar();
        }
    }, [isExpanded]);

    return (
        <>
            {!!data && !isLoading &&
                <div
                    className={classes.chartContainer}
                    style={{maxHeight: !isExpanded ? 0 : !!Object.entries(chartData).length ? Object.entries(chartData).length * 1000 : 300}}
                >
                    <div className={classes.chartContainerBody}>
                        {data.map((entry, index) => {
                            const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + index);
                            return (
                                <div key={index} className={classes.countBarContainer}>
                                    <div id={`que-${serialNo}-count-bar-${index}`} className={classes.countBar}/>

                                    {questionType !== QUESTION_TYPES.TF.name &&
                                        <div
                                            tabIndex={isExpanded ? 0 : -1}
                                            role={TEXTBOX}
                                            aria-label={OPTION_LABEL + entry.index}
                                            className={classes.optionIndex}
                                            style={{zIndex: 1}}
                                        >{entry.index}</div>
                                    }

                                    {!!optionsImageMap[optIdx]?.imageURL &&
                                        <ImageViewer
                                            size={38}
                                            showEnlargeByDefault={true}
                                            imageURL={optionsImageMap[optIdx]?.imageURL}
                                        />
                                    }

                                    {!!entry.answer &&
                                        <span
                                            id={`optionText${index}`}
                                            tabIndex={isExpanded ? 0 : -1}
                                            role={TEXTBOX}
                                            style={{zIndex: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}
                                        }>
                                            {entry.answer}
                                        </span>
                                    }

                                    <span style={{flex: 1}}/>

                                    {gradeEnabled && !!showAnswerOnViewer && correctAnswerIndexArr.includes(index) &&
                                        <span className={classes.correctBadge} role={TEXTBOX} tabIndex={isExpanded ? 0 : -1} aria-label={CORRECT}
                                              style={{zIndex: 1}}><CORRECT_ICON/></span>
                                    }

                                    <span className={classes.countBadge} role={TEXTBOX} tabIndex={isExpanded ? 0 : -1}
                                          style={{zIndex: 1}}>{entry.value}</span>
                                </div>
                            );

                        })}
                    </div>
                </div>
            }
        </>
    );
}
