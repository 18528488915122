import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as CaretDown } from "../../images/caret_down.svg";
import { ReactComponent as CaretUp } from "../../images/caret_up.svg";
import { ReactComponent as DetailsIcon } from "../../images/details.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as NotificationIcon } from "../../images/notification.svg";
import { ReactComponent as ReleaseOptionsIcon } from "../../images/release_options.svg";
import {
    ACTIVATE_TIME,
    DETAILS_TEXT,
    EMAIL_NOTIFICAITON_LABEL_PREFIX,
    EMAIL_NOTIFICAITON_LABEL_SUFFIX,
    EMAIL_NOTIFICAITON_SUBTITLE,
    EMAIL_NOTIFICAITON_TEXT,
    EMAIL_NOTIFICATION_OPTIONS,
    MODAL,
    NOTIFICATION_DROPDOWN_PLACEHOLDER,
    POLL_SHARE_MODE,
    POLL_TIME_LIMIT_DESCRIPTION,
    POLL_TIME_LIMIT_MODAL_CANCEL,
    POLL_TIME_LIMIT_MODAL_CONFIRM,
    RELEASE_NOW_LABEL,
    RELEASE_NOW_OPTION,
    RELEASE_NOW_SUBTITLE,
    RELEASE_OPTIONS_TEXT,
    SCHEDULE_MODAL_TITLE,
    SCHEDULE_POLL_LABEL,
    SCHEDULE_POLL_OPTION,
    SCHEDULE_RELEASE_SUBTITLE,
    TEXTBOX,
    USER_SETTINGS
} from "../../utils/constants";
import { smoothScrollToBottom } from "../../utils/helpers";
import DatePickerComponentNew from "../datePickerComponent";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaDropdown } from "../standardization/YuJaDropdown";
import { YuJaRadioBox } from "../standardization/YuJaRadiobox";
import "./modal.css";

export default function ScheduleModal({ show, setShow, disabled, pollTimeLimit, inCourse, userSettings, handleSubmit }) {
    const [shareMode, setShareMode] = useState(POLL_SHARE_MODE.SHARE_ALL);
    const [timeLimit, setTimeLimit] = useState(0);
    const [pollTimeLimitH, setPollTimeLimitH] = useState("");
    const [pollTimeLimitM, setPollTimeLimitM] = useState("");
    const [_, setPollTimeLimitS] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [notificationTime, setNotificationTime] = useState(-1);
    const [selectedNotificationTime, setSelectedNotificationTime] = useState(null);
    const [reminderOptions, setReminderOptions] = useState(EMAIL_NOTIFICATION_OPTIONS);

    // const handlePollTimeChangeH = (e) => {
    //     setPollTimeLimitH(e.target.value);
    // }

    // const handlePollTimeChangeM = (e) => {
    //     setPollTimeLimitM(e.target.value);
    // }
    const handlePollTimeChangeM = (e) => {
        let mins = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0, 2);
        setPollTimeLimitM(mins);

    };

    const handlePollTimeonBlurM = (e) => {
        let mins = pollTimeLimitM;
        const isValidNumber = /^\d+$/.test(pollTimeLimitM);
        if (isValidNumber && !isNaN(parseInt(pollTimeLimitM, 10))) {
            let unroundedMins = (mins < 10 ? "0" + mins.toString() : mins.toString());
            let hours = Math.floor(parseInt(mins, 10) / 60);
            mins = parseInt(mins, 10) % 60;
            if (hours + parseInt(pollTimeLimitH, 10) > 24) {
                unroundedMins = (mins < 10 ? "0" + mins.toString() : mins.toString());
            }
            hours = hours + parseInt(pollTimeLimitH, 10) > 24 ? 24 : hours + parseInt(pollTimeLimitH, 10);

            if (mins === "") {
                mins = "00";
            } else if (mins.length === 1) {
                mins = "0" + mins;
            }
            setPollTimeLimitM((mins < 10 ? "0" : "") + mins);
            setPollTimeLimitH((hours < 10 ? "0" : "") + hours);
            if (parseInt(pollTimeLimitM, 10) === 0) {
                setPollTimeLimitM("00");
            }
        }
        else {
            setPollTimeLimitM("00");
        }
    }

    const handlePollTimeChangeH = (e) => {
        let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0, 2);
        setPollTimeLimitH(hours);
    };

    const handlePollTimeonBlurH = (e) => {
        let hours = pollTimeLimitH;
        const isValidNumber = /^\d+$/.test(pollTimeLimitH);
        if (isValidNumber && !isNaN(parseInt(pollTimeLimitH, 10)) && parseInt(pollTimeLimitH, 10) <= 24) {

            if (hours === "") {
                hours = "00";
            } else if (hours.length === 1) {
                hours = "0" + hours;
            }
            setPollTimeLimitH(hours);
            if (parseInt(pollTimeLimitH, 10) === 0) {
                setPollTimeLimitH("00");
            }
        }
        else {
            setPollTimeLimitH("00");
        }
    }

    useEffect(() => {
        if (!show) {
            setShareMode(POLL_SHARE_MODE.SHARE_ALL);
            // setStartDate("");
            // setEndDate("");
            // setPollTimeLimitH("");
            // setPollTimeLimitM("");
            // setPollTimeLimitS("");
            // setNotificationTime(-1);
        }
    }, [show]);

    useEffect(() => {
        if (!userSettings || !show) {
            setNotificationTime(-1);
            return;
        }
        const duration = pollTimeLimit ? pollTimeLimit : userSettings[USER_SETTINGS.POLL_DURATION];
        setPollTimeLimitH(String(Math.floor(duration / 3600)).padStart(2, '0'));
        setPollTimeLimitM(String(Math.floor((duration % 3600) / 60)).padStart(2, '0'));
        setPollTimeLimitS(String(duration % 60).padStart(2, '0'));
    }, [pollTimeLimit, userSettings, show]);

    useEffect(() => {
        console.log((pollTimeLimitH * 3600) + (pollTimeLimitM * 60));
        setTimeLimit((pollTimeLimitH * 3600) + (pollTimeLimitM * 60));
    }, [pollTimeLimitH, pollTimeLimitM]);

    useEffect(() => {
        if (notificationTime !== selectedNotificationTime) return;
        // console.log(notificationTime);
        
        smoothScrollToBottom(document.querySelector(".details-content"));
    }, [notificationTime]);

    useEffect(() => {
        const timeToStart = startDate - new Date();
        const filteredReminderOptions = EMAIL_NOTIFICATION_OPTIONS.filter(option => ((option.value * 1000) <= timeToStart));
        setReminderOptions(filteredReminderOptions);
        if (!filteredReminderOptions.map(option => option.value).includes(notificationTime)) {
          setNotificationTime(-1);
        }
    }, [startDate]);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="schedule-modal-dialog"
        >
            <Modal.Header className="schedule-modal-header">
                <ModalClose onClick={() => setShow(false)} className="schedule-modal-close" />
                <Modal.Title
                    className="schedule-modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0}
                    aria-label={SCHEDULE_MODAL_TITLE + MODAL}
                    role={TEXTBOX}
                >
                    {SCHEDULE_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="schedule-modal-body">
                <div className="release-options-card">
                    <div className="release-options-title-container">
                        <ReleaseOptionsIcon className="release-options-title-icon" />
                        <div className="release-options-title-text">
                            {RELEASE_OPTIONS_TEXT}
                        </div>
                    </div>
                    <div className="release-options-content">
                        <div className="release-options-column">
                            <YuJaRadioBox
                                size={20}
                                checked={shareMode === POLL_SHARE_MODE.SHARE_ALL}
                                onClick={() => setShareMode(POLL_SHARE_MODE.SHARE_ALL)}
                                label={RELEASE_NOW_OPTION}
                                style={{ cursor: "pointer" }}
                                labelStyle={{ fontSize: 13, fontWeight: 700, alignSelf: "flex-start", lineHeight: "13px", cursor: "pointer" }}
                            />
                            <div className="release-options-subtext">
                                {RELEASE_NOW_SUBTITLE}
                            </div>
                        </div>

                        <div className="release-options-column">
                            <YuJaRadioBox
                                size={20}
                                checked={shareMode === POLL_SHARE_MODE.SCHEDULE}
                                onClick={() => setShareMode(POLL_SHARE_MODE.SCHEDULE)}
                                label={SCHEDULE_POLL_OPTION}
                                style={{ cursor: "pointer" }}
                                labelStyle={{ fontSize: 13, fontWeight: 700, alignSelf: "flex-start", lineHeight: "13px", cursor: "pointer" }}
                            />
                            <div className="release-options-subtext">
                                {SCHEDULE_RELEASE_SUBTITLE}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="details-card">
                    <div className="details-title-container">
                        <DetailsIcon className="details-title-icon" />
                        <div className="details-title-text">
                            {DETAILS_TEXT}
                        </div>
                    </div>
                    <div className="details-content" style={shareMode === POLL_SHARE_MODE.SCHEDULE ? { paddingRight: 5, height: 230, overflow: "auto" } : {}}>
                        <div tabIndex={0} role={TEXTBOX} className="details-label">
                            {shareMode === POLL_SHARE_MODE.SHARE_ALL ? RELEASE_NOW_LABEL : SCHEDULE_POLL_LABEL}
                        </div>

                        {shareMode === POLL_SHARE_MODE.SCHEDULE &&
                            <DatePickerComponentNew
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                noPadding={true}
                            />
                        }

                        <div style={{ width: "100%", display: "flex", padding: shareMode === POLL_SHARE_MODE.SCHEDULE ? "0 0 15px 0" : 0, gap: 10 }}>
                            <div tabIndex={0} role={TEXTBOX} aria-label={ACTIVATE_TIME} style={{ color: "#000",  fontSize: "13.5px", fontWeight: "400", display: "flex", height: 26, alignSelf: "flex-end" }}>
                                {POLL_TIME_LIMIT_DESCRIPTION}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: 116 }}>
                                <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                                    <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}> Hours </div>
                                    <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}> Minutes </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", border: "0.61px solid #D0D0D0", borderRadius: "3.11px" }}>
                                    <input
                                        aria-label={pollTimeLimitH + " hours"}
                                        role={TEXTBOX}
                                        className="time-input"
                                        type="text"
                                        maxLength={2}
                                        value={pollTimeLimitH}
                                        placeholder="HH"
                                        onChange={handlePollTimeChangeH}
                                        onBlur={handlePollTimeonBlurH}
                                        style={{ color: "#565656", fontSize: "13.5px", width: "55px", height: "26px", borderColor: "transparent" }}
                                    />
                                    <div style={{ alignItems: "center", justifyContent: "center", display: "flex", fontSize: "13.5px", height: "26px" }}> : </div>
                                    <input
                                        aria-label={pollTimeLimitM + " minutes"}
                                        role={TEXTBOX}
                                        className="time-input"
                                        type="text"
                                        maxLength={2}
                                        value={pollTimeLimitM}
                                        placeholder="MM"
                                        onChange={handlePollTimeChangeM}
                                        onBlur={handlePollTimeonBlurM}
                                        style={{ color: "#565656", fontSize: "13.5px", width: "55px", height: "26px", border: "none" }}
                                    />
                                </div>
                            </div>
                        </div>

                        {shareMode === POLL_SHARE_MODE.SCHEDULE &&
                            <div className="notification-container">
                                <div className="notification-title-container">
                                    <NotificationIcon className="notification-title-icon" />
                                    <div className="notification-title-text">
                                        {EMAIL_NOTIFICAITON_TEXT}
                                    </div>
                                    {notificationTime === -1 ?
                                        <CaretDown
                                            tabIndex={0}
                                            className="caret-icon"
                                            onClick={() => setNotificationTime(selectedNotificationTime)}
                                        /> :
                                        <CaretUp
                                            tabIndex={0}
                                            className="caret-icon"
                                            onClick={() => setNotificationTime(-1)}
                                        />
                                    }
                                </div>
                                {notificationTime !== -1 &&
                                    <div className="notification-content">
                                        <div className="notification-subtext">
                                            {EMAIL_NOTIFICAITON_SUBTITLE}
                                        </div>
                                        <div className="notification-label-container">
                                            <div className="notification-label-text">
                                                {EMAIL_NOTIFICAITON_LABEL_PREFIX}
                                            </div>
                                            <YuJaDropdown
                                                data={reminderOptions}
                                                value={notificationTime}
                                                disabled={!inCourse || reminderOptions.length === 0}
                                                onChange={option => {
                                                    setSelectedNotificationTime(option.value);
                                                    setNotificationTime(option.value);
                                                }}
                                                // onOpen={() => smoothScrollToBottom(document.querySelector(".details-content"))}
                                                placeholder={NOTIFICATION_DROPDOWN_PLACEHOLDER}
                                                isNotificationDropdown={true}
                                            />
                                            <div className="notification-label-text">
                                                {EMAIL_NOTIFICAITON_LABEL_SUFFIX}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>                
                </div>
            </Modal.Body>
            <Modal.Footer className="schedule-modal-footer">
                <YuJaButton
                    disabled={disabled}
                    type="secondary"
                    onClick={() => { setShow(false); }}
                    style={{ margin: 0, borderRadius: 4.21, fontSize: 14 }}
                >
                    {POLL_TIME_LIMIT_MODAL_CANCEL}
                </YuJaButton>
                <YuJaButton
                    disabled={disabled}
                    onClick={async () => { await handleSubmit({ shareMode, timeLimit, startDate, endDate, notificationTime }); }}
                    style={{ margin: 0, borderRadius: 4.21, fontSize: 14 }}
                >
                    {POLL_TIME_LIMIT_MODAL_CONFIRM}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}