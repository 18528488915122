import React, {useEffect} from "react";
import {useCountdown} from "../../hooks/useCountdown";
import {GET_READY_PAGE_EXPLAIN, GET_READY_PAGE_TITLE, TEXTBOX} from "../../utils/constants";
import {newPageLog} from "../../utils/helpers";
import "./styles.css";
import styles from "./styles.module.css";
import PollTitleContainer from "./PollTitleContainer";
import IllustrationWrapper from "./IllustrationWrapper";

export default function GetReady({question, handleGetReadyComplete, pollTitle, websocketState}) {
    const {questionStartTime} = question;
    const {getReturnValues, resetCountdown} = useCountdown(new Date(questionStartTime).getTime());
    const [days, hours, minutes, seconds, milliSeconds] = getReturnValues;

    useEffect(() => {
        newPageLog("GetReady.js");
    }, []);

    const renderCounter = () => {
        const secondsRemain = days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60 + seconds;

        if (secondsRemain < 1) {
            return (
                <div className={`${styles.getReadyCountDownContainer} ${styles.small}`}>
                    <span style={{fontSize: 15, lineHeight: "18px", fontWeight: "700"}}>Get Ready</span>
                    <span>GO</span>
                </div>
            );
        } else {
            return (
                <div className={`${styles.getReadyCountDownContainer} ${secondsRemain >= 10 ? styles.small : ""}`}>
                    <span className={styles.countDownBlink}>{secondsRemain}</span>
                </div>
            );
        }

    }

    useEffect(() => {
        resetCountdown(questionStartTime);
    }, [questionStartTime]);

    useEffect(() => {
        if (days + hours + minutes + seconds + milliSeconds <= 0) {
            handleGetReadyComplete();
        }
    }, [seconds]);


    return (
        <div className={styles.generalContainer} style={{height: "unset", flex: 1}}>
            <PollTitleContainer pollTitle={pollTitle}/>
            <span style={{flex: 1}}/>
            <IllustrationWrapper>
                {renderCounter()}
            </IllustrationWrapper>

            <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomTitle}>
                {GET_READY_PAGE_TITLE}
            </span>
            <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomDesc}>
                {GET_READY_PAGE_EXPLAIN}
            </span>
            <span style={{flex: 2}}/>
        </div>
    );
}
