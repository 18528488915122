import {useEffect, useState} from "react";
import {SCREEN_TYPE} from "../utils/constants";

const useWindowDimensions = () => {
    const getType = (width) => {
        let type = "";
        if (width < 768) {
            type = SCREEN_TYPE.MOBILE;
        } else if (width <= 1024) {
            type = SCREEN_TYPE.TABLET;
        } else {
            type = SCREEN_TYPE.DESKTOP;
        }
        return type;
    }

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        type: getType(window.innerWidth),
        isTouchAble: window.navigator.maxTouchPoints > 0
    });

    useEffect(() => {
        let type = getType(window.innerWidth);
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
            type,
            isTouchAble: window.navigator.maxTouchPoints > 0
        });
    }, [window.navigator.maxTouchPoints]);


    useEffect(() => {
        const handleResize = () => {
            let type = getType(window.innerWidth);
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
                type,
                isTouchAble: window.navigator.maxTouchPoints > 0
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    return dimensions;
};

export default useWindowDimensions;