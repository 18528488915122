import classes from "./styles.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {SCREEN_TYPE} from "../../utils/constants";

export default function IllustrationWrapper({children}) {
    const {type: windowType} = useWindowDimensions();

    const styles = {
        first: {
            position: 'absolute',
            top: -16,
            left: -16,
            width: 16,
            height: 16,
            borderRadius: "50%",
            background: '#B3D2D9',
        },
        second: {
            position: 'absolute',
            top: -3,
            left: 205,
            width: 25,
            height: 25,
            borderRadius: "50%",
            background: '#B3D2D9',
        },
        thirdDesktop: {
            position: 'absolute',
            top: -16,
            left: -184,
            width: 6,
            height: 6,
            borderRadius: "50%",
            background: '#ADB5E5',
        },
        thirdMobile: {
            position: 'absolute',
            top: 105,
            left: -42,
            width: 6,
            height: 6,
            borderRadius: "50%",
            background: '#ADB5E5',
        },
        fourthDesktop: {
            position: 'absolute',
            top: 113,
            left: "calc(100% + 144px)",
            width: 6,
            height: 6,
            borderRadius: "50%",
            background: '#ADB5E5',
        },
        fourthMobile: {
            position: 'absolute',
            top: 188,
            left: "calc(100% + 44px)",
            width: 6,
            height: 6,
            borderRadius: "50%",
            background: '#ADB5E5',
        },
        fifth: {
            position: 'absolute',
            top: 201,
            left: -58,
            width: 15,
            height: 15,
            borderRadius: "50%",
            background: '#ADB5E5',
        },
        sixth: {
            position: 'absolute',
            top: "calc(100% + 9px)",
            left: "calc(100% - 45px)",
            width: 13,
            height: 13,
            borderRadius: "50%",
            background: '#ADB5E5',
        },
        seventhDesktop: {
            position: 'absolute',
            top: "calc(100% + 64px)",
            left: "calc(100% + 159px)",
            width: 6,
            height: 6,
            borderRadius: "50%",
            background: '#FFE5EB',
            boxShadow: "0px 0px 2px 0px #00000040"

        },
        eighthDesktop: {
            position: 'absolute',
            top: "calc(100% + 124px)",
            left: -94,
            width: 6,
            height: 6,
            borderRadius: "50%",
            background: '#ADB5E5',
            boxShadow: "0px 0px 2px 0px #00000040"
        },
        ninthDesktop: {
            position: 'absolute',
            top: "calc(100% + 200px)",
            left: 144,
            width: 6,
            height: 6,
            borderRadius: "50%",
            background: '#B3D2D9',
            boxShadow: "0px 0px 2px 0px #00000040"
        },
    }
    return (
        <div className={classes.waitingIllustrationWrapper}>
            {children}
            <div style={styles.first}/>
            <div style={styles.second}/>
            <div style={windowType === SCREEN_TYPE.MOBILE ? styles.thirdMobile : styles.thirdDesktop}/>
            <div style={windowType === SCREEN_TYPE.MOBILE ? styles.fourthMobile : styles.fourthDesktop}/>
            <div style={styles.fifth}/>
            <div style={styles.sixth}/>
            {windowType !== SCREEN_TYPE.MOBILE &&
                <div style={styles.seventhDesktop}/>
            }
            {windowType !== SCREEN_TYPE.MOBILE &&
                <div style={styles.eighthDesktop}/>
            }

            {/*{windowType !== SCREEN_TYPE.MOBILE &&*/}
            {/*    <div style={styles.ninthDesktop}/>*/}
            {/*}*/}
            {/*<div style={styles.seventh}/>*/}
            {/*<div style={styles.eighth}/>*/}
        </div>
    );
}