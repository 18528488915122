import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "react-native-web";
import { ReactComponent as Prohibit } from "../../images/Prohibit.svg";
import styles from "./YuJaTooltip.module.css";
import {findFirstOverflowParent} from "../../utils/helpers";


export const YuJaTooltip = ({text="", placement="top", children, tooltipStyle={}, visibleInTouchableDevice=false, prohibit=false, ...props}) => {
    const toolTrigger = useRef();
    const tooltipRef = useRef();
    const tooltipTriangleRef = useRef();
    const windowDimensions = useWindowDimensions();
    const placementVarRef = useRef(placement);
    const [isTouchAble, setIsTouchAble] = useState(window.navigator.maxTouchPoints > 0);
    const [isOpen, setIsOpen] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);


    // useEffect(() => {
    //     //adjust position only when tooltip is visible
    //     if (maskVisible) {
    //         resetToolTipPosition();
    //         resetTrianglePosition();
    //     } else {
    //         tooltipRef.current.style.top = '0px';
    //         tooltipRef.current.style.left = '0px';
    //         tooltipTriangleRef.current.style.top = '0px';
    //         tooltipTriangleRef.current.style.left = '0px';
    //     }
    // }, [windowDimensions, toolTrigger?.current?.getBoundingClientRect()]);

    useEffect(() => {
        setIsTouchAble(window.navigator.maxTouchPoints > 0);
    }, [window.navigator.maxTouchPoints]);

    const resetToolTipPosition = () => {
        //only left and top can be used as anchor, all those variables are just for the current window, it doesn't count the scroll offset
        const {width: windowWidth, height: windowHeight} = windowDimensions;
        const {width, height, top, left} = toolTrigger.current.getBoundingClientRect();
        const {width: toolTipWidth, height: tooltipHeight} = tooltipRef.current.getBoundingClientRect();

        tooltipRef.current.style.top = null;
        tooltipRef.current.style.bottom = null;
        tooltipRef.current.style.left = null;
        tooltipRef.current.style.right = null;
        let adjustedTop = 0;
        let adjustedLeft = 0;
        placementVarRef.current = placement;
        if (placement === "right") {
            adjustedTop = top - (tooltipHeight / 2 - height / 2);
            adjustedLeft = left + width + 10;
            if (adjustedLeft + toolTipWidth > windowWidth) {//not enough place at right
                adjustedLeft = left - toolTipWidth - 10;
                placementVarRef.current = "left";
            }
        } else if (placement === "left") {
            adjustedTop = top - (tooltipHeight / 2 - height / 2);
            adjustedLeft = left - toolTipWidth - 10;
            if (adjustedLeft < 0) {//not enough place at left
                placementVarRef.current = "right";
                adjustedLeft = left + width + 10;
            }
        } else if (placement === "top") {//not enough place at top
            adjustedTop = top - tooltipHeight - 10;
            adjustedLeft = left - (toolTipWidth / 2 - width / 2);
            if (adjustedTop < 0) {
                placementVarRef.current = "bottom";
                adjustedTop = top + height + 10;
            }
        } else if (placement === "bottom") {//not enough place at bottom
            adjustedTop = top + height + 10;
            adjustedLeft = left - (toolTipWidth / 2 - width / 2);
            if (adjustedTop + tooltipHeight > windowHeight) {
                placementVarRef.current = "top";
                adjustedTop = top - tooltipHeight - 10;
            }
        }
        if (adjustedLeft < 0) {
            adjustedLeft = 10;
        } else if (adjustedLeft + toolTipWidth > windowWidth) {
            adjustedLeft = windowWidth - toolTipWidth - 10;
        } else if (adjustedTop < 0) {
            adjustedTop = 10;
        } else if (adjustedTop + tooltipHeight > windowHeight) {
            adjustedTop = windowHeight - tooltipHeight - 10;
        }

        tooltipRef.current.style.transform = `translate(${Math.round(adjustedLeft + window.scrollX)}px, ${Math.round(adjustedTop + window.scrollY)}px)`;
    }

    const onMouseEnter = (e, callbackF) => {
        if (callbackF) {
            callbackF(e);
        }
        setIsOpen(true);

    }

    const onMouseLeave = (e, callbackF) => {
       if (callbackF) {
           callbackF(e);
       }
       setIsOpen(false);
    }

    const resetTrianglePosition = () => {
        //only left and top can be used as anchor, all those variables are just for the current window, it doesn't count the scroll offset
        const {width: windowWidth, height: windowHeight} = windowDimensions;
        const {width, height, top, left } = toolTrigger.current.getBoundingClientRect();
        let adjustedTop = 0;
        let adjustedLeft = 0;
        if ( placementVarRef.current === "right") {
            adjustedLeft = left + width;
            adjustedTop = top + height / 2;
        } else if (placementVarRef.current === "left") {
            adjustedLeft = left - 10;
            adjustedTop = top + height / 2;
        } else if (placementVarRef.current === "top") {//not enough place at top
            adjustedLeft = left + width / 2;
            adjustedTop = top - 10;
        } else if (placementVarRef.current === "bottom") {//not enough place at bottom
            adjustedLeft = left + width / 2;
            adjustedTop = top + height;
        }


        if (adjustedLeft < 0) {
            adjustedLeft = 10;
        } else if (adjustedLeft > windowWidth) {
            adjustedLeft = windowWidth- 10;
        } else if (adjustedTop < 0) {
            adjustedTop = 10;
        } else if (adjustedTop > windowHeight) {
            adjustedTop = windowHeight - 10;
        }

        tooltipTriangleRef.current.style.transform = `translate(${Math.round(adjustedLeft + window.scrollX)}px, ${Math.round(adjustedTop + window.scrollY)}px)`;
    }


    function isVisible (parent, child) {
        let {top: childTop, height: childHeight} = child.getBoundingClientRect();
        let {top: parentTop, height: parentHeight} = parent.getBoundingClientRect();

        return !(childTop + childHeight < parentTop || childTop > parentTop + parentHeight);

    }


    const setPosition = () => {
        if(!tooltipTriangleRef.current || !tooltipRef.current) {
            return;
        }

        resetToolTipPosition();
        resetTrianglePosition();


        let parent = findFirstOverflowParent(toolTrigger.current);
        if (!isVisible(parent, toolTrigger.current)) {
            setTooltipVisible(false);
        } else {
            setTooltipVisible(true);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('scroll', setPosition, true);
            setPosition();
        } else {
            setTooltipVisible(false);
        }

        return () => {
            document.removeEventListener('scroll', setPosition)
        };
    }, [isOpen, windowDimensions]);


    return (
        <>
            {/*case 1: child is just plain text*/}
            {!children.type &&
                <div
                    className={styles.tooltipTrigger}
                    ref={toolTrigger}
                    onMouseLeave={isTouchAble ? () => {} : (e) => onMouseEnter(e)}
                    onMouseEnter={isTouchAble ? () => {} : (e) => onMouseLeave(e)}
                    onClick={isTouchAble ? (e) => onMouseEnter(e): () => {}}
                    tabIndex={0}
                    onFocus={(e) => onMouseEnter(e)}
                    onBlur={(e) => onMouseLeave(e)}
                >
                    {children}
                </div>
            }

            {/*case 2: complicated children*/}
            {!!children.type &&
                React.cloneElement(
                    children,
                    {
                        ...children.props,
                        ref: toolTrigger,
                        onMouseEnter:  isTouchAble ? () => {} : (e) => onMouseEnter(e, children.props.onMouseEnter),
                        onMouseLeave: isTouchAble ? () => {} : (e) => onMouseLeave(e, children.props.onMouseLeave),
                        onClick: isTouchAble ? (e) => onMouseEnter(e, children.props.onClick): children.props.onClick,
                        tabIndex: 0,
                        onFocus: (e) => onMouseEnter(e, children.props.onMouseEnter),
                        onBlur:  (e) => onMouseLeave(e, children.props.onMouseLeave)
                    }
                )
            }

            {isOpen &&
                <>
                    <div
                        ref={tooltipRef}
                        className={`${styles.tooltip}`}
                        style={{...tooltipStyle, visibility: tooltipVisible ? "visible" : "hidden"}}
                        onMouseLeave={(e) => onMouseLeave(e)}
                    >
                        {prohibit ? <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Prohibit style={{width: "25px", height: "25px", marginRight: "5px"}}/>
                                {text}
                            </div> :
                            text
                        }
                    </div>
                    <div ref={tooltipTriangleRef} className={`${styles[placementVarRef.current]}`}/>
                </>
            }


            {/*{!isTouchAble &&*/}
            {/*    <TooltipWrapper>*/}
            {/*        <div*/}
            {/*            ref={tooltipRef}*/}
            {/*            className={`${styles.tooltip}`}*/}
            {/*            style={tooltipStyle}*/}
            {/*            onMouseLeave={(e) => onMouseLeave(e)}*/}
            {/*        >*/}
            {/*            {prohibit ? <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>*/}
            {/*                    <Prohibit style={{width: "25px", height: "25px", marginRight: "5px"}}/>*/}
            {/*                    {text}*/}
            {/*                </div> :*/}
            {/*                text*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div ref={tooltipTriangleRef} className={`${styles[placementVar]}`}/>*/}
            {/*    </TooltipWrapper>*/}
            {/*}*/}

            {/*{isTouchAble &&*/}
            {/*    <>*/}
            {/*        <TooltipWrapper>*/}
            {/*            <div*/}
            {/*                ref={tooltipRef}*/}
            {/*                className={`${styles.tooltip} ${visibleInTouchableDevice ? styles.visibleInTouchableDevice : ""}`}*/}
            {/*                style={tooltipStyle}*/}
            {/*            >*/}
            {/*                {prohibit ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>*/}
            {/*                    <Prohibit style={{ width: "25px", height: "25px", marginRight: "5px" }} />*/}
            {/*                    {text}*/}
            {/*                </div> :*/}
            {/*                    text*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*            <div ref={tooltipTriangleRef} className={`${styles[placementVar]}  ${visibleInTouchableDevice ? styles.visibleInTouchableDevice : ""}`}/>*/}
            {/*        </TooltipWrapper>*/}
            {/*        {maskVisible &&*/}
            {/*            <div style={{position: "fixed", height: '100vh', width: '100vw', top: 0, left: 0, zIndex: 1000}} onTouchStart={onMouseLeave}/>*/}
            {/*        }*/}

            {/*    </>*/}
            {/*}*/}
        </>

    )
}

YuJaTooltip.propTypes = {
    text: PropTypes.string,
    placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
}