import {ReactComponent as FULL_SCREEN} from "../../images/full-screen-icon.svg";

import React, {useEffect, useState} from "react";
import {YuJaImageViewer} from "../../components/standardization/YuJaImageViewer";
import classes from "./PollReady.module.css";
import {DEFAULT_IMAGE_ALT} from "../../utils/constants";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";


export default function ImageViewer({imageURL, alt, size=270, styles={}, showEnlargeByDefault=false}) {
    const [showManageImageIcon, setShowManageImageIcon] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [isTouchAble, setIsTouchAble] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSmall, setIsSmall] = useState(false);


    useEffect(() => {
        setIsTouchAble(window.navigator.maxTouchPoints > 0);

    }, [window.navigator.maxTouchPoints]);

    useEffect(() => {
        const img = new Image();
        img.src = imageURL;
        img.onload = () => {
            if (img.width <= 100 && img.height <= 100) {
                setIsSmall(true);
            } else {
                setIsSmall(false);
            }
        };

        return () => {
            setIsLoaded(false);
        }
    }, [imageURL]);


    const handleView = (e) => {
        e.stopPropagation();
       setShowViewModal(true);
    };

    const handleClose = () => {
        setShowViewModal(false);
        onMouseLeave();
    };

    const onMouseEnter = () => {
        setShowManageImageIcon(true);
    };

    const onMouseLeave = () => {
        setShowManageImageIcon(false);
    };

    const onClickImage = (e) => {
        handleView(e);
    }

    const onLoad = (e) => {
        setIsLoaded(true);
    }

    if (!isLoaded) {
       return (
           <>
               <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                   <Skeleton borderRadius={3} height={size} width={size}/>
               </SkeletonTheme>

               <img
                   onLoad={onLoad}
                   alt={DEFAULT_IMAGE_ALT}
                   src={imageURL}
                   style={{width: 100, height: 100, display: "none"}}
                   aria-label={alt ? alt : DEFAULT_IMAGE_ALT}
               />
           </>
       );
    }


    return (
        <>
            <YuJaImageViewer showViewer={showViewModal} onHandleClose={handleClose} imageURL={imageURL}/>
            <div
                className={classes.imageContainer}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{
                    position: "relative",
                    width: size ? size : undefined,
                    height: size ? size : undefined,
                    cursor: "pointer",
                    display: isLoaded ? undefined : "none",
                    ...styles
                }}
            >
                <img
                    onClick={onClickImage}
                    tabIndex={0}
                    alt={DEFAULT_IMAGE_ALT}
                    src={imageURL}
                    style={{maxWidth: "100%", maxHeight: "100%", cursor: "pointer"}}
                    aria-label={alt ? alt : DEFAULT_IMAGE_ALT}
                />

                {(!!isTouchAble || !!showEnlargeByDefault || showManageImageIcon || !!isSmall) &&
                    <>
                        <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                left: 0,
                                top: 0,
                                background: "#000000",
                                opacity: 0.5,
                                borderRadius: 3,
                            }}
                        />
                        <div
                            onClick={handleView}
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                left: 0,
                                top: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <FULL_SCREEN
                                style={{width: Math.min(size * 0.6, 60), height: Math.min(size * 0.6, 60)}}
                            />
                        </div>
                    </>

                }

            </div>
        </>

    );
}