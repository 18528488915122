import {ReactComponent as CloseIcon} from "../../images/full-screen-close.svg";
import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaImageViewer.module.css"
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ReactDOM from "react-dom";

export const YuJaImageViewer = ({imageURL="", imageAlt="", onHandleClose, showViewer, }) => {
    const {width, height} = useWindowDimensions();
    const isNarrow = width < 600;
    const isLandScape = width > height;

    const timeOutRef = useRef(0);
    const [showTopBar, setShowTopBar] = useState(false);

    useEffect(() => {
        const handleGlobalKeyUp= (event) => {
            if (event.key === 'Escape') {
                onHandleClose();
            }

        };

        if (showViewer) {
            setShowTopBar(true);
            timeOutRef.current = setTimeout(() => {
                setShowTopBar(false);
            }, 3000);
            document.addEventListener('keyup', handleGlobalKeyUp);
        }

        return () => {
            clearTimeout(timeOutRef.current);
            document.removeEventListener('keyup', handleGlobalKeyUp)
        }
    }, [showViewer]);

    const onMove = () => {
        clearTimeout(timeOutRef.current);
        setShowTopBar(true);
        timeOutRef.current = setTimeout(() => {
            setShowTopBar(false);
        }, 3000);
    }

    return (
        <ImageViewerWrapper>
            {showViewer &&
                <div
                    onClick={e => e.stopPropagation()}
                    className={`${styles.container} ${isLandScape ? styles.landscapeView : styles.profileView}`}
                    onMouseMove={onMove}
                >
                    <div className={styles.mask} onClick={onHandleClose}/>

                        <img
                            tabIndex={0}
                            alt={imageAlt}
                            src={`${imageURL}`}
                            style={{maxWidth: "100%", maxHeight: "100%", background: "#FFF"}}
                        />
                    <div className={styles.topBar} style={{opacity: showTopBar ? 1 : 0}}>
                        <div style={{flex: 1}}/>
                        <CloseIcon style={{width: isNarrow ? 20 : 40, height: isNarrow ? 20 : 40, cursor: "pointer"}} onClick={onHandleClose}/>
                    </div>

                </div>
            }

        </ImageViewerWrapper>
    );
}


const ImageViewerWrapper = ({children}) => {
    const [wrapper, ] = useState(() => {
        const div = document.createElement("div");
        div.id = `YuJa_Image_Viewer_${Date.now().toString()}`;
        return div;

    });
    useEffect(() => {
        const rootEle = document.getElementById('root');
        rootEle.appendChild(wrapper);

        return () => {
            if (wrapper) {
                wrapper.remove();
            }
        }
    }, []);



    return ReactDOM.createPortal(children, wrapper);

}