import React, {useEffect, useState} from "react";
import { useWindowDimensions } from "react-native-web";
import useMobileAccess from "../../hooks/useMobileAccess";
import {
    TEXTBOX, WAIT_QUESTION_EXPLAIN,
    WAITING_ROOM_ACTIVITY_TEXT,
    WAITING_ROOM_WAIT_POLL_START_DESC,
} from "../../utils/constants";
import { newPageLog } from "../../utils/helpers";
import "./styles.css";
import PollTitleContainer from "./PollTitleContainer";
import styles from "./styles.module.css";
import waitingIllustration from "./images/wating-illustration.gif";
import IllustrationWrapper from "./IllustrationWrapper";
import { ReactComponent as QUESTION_ICON } from "./images/question-icon.svg";

export default function WaitingRoom({ bodyText, bodyShareCount, titleText, afterQ, loadingDelay }) {

  const windowDimensions = useWindowDimensions();

  const getImageSize = () => {
    let {width, height} = windowDimensions;
    let adjustedWidth = Math.min(width * 0.3, 300);
    let adjustedHeight = Math.min(height * 0.3, 300);

    return Math.min(adjustedWidth, adjustedHeight);
  }

  const isNarrow = useMobileAccess(1200);
  const [imageSize, setImageSize] = useState(() => getImageSize())
  useEffect(() => {
    newPageLog("WaitingRoom.js");
    if (isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
  }, []);

  useEffect(() => {
    setImageSize(getImageSize());
  }, [windowDimensions]);

  return (
      <div className={styles.generalContainer} style={{height: "unset", flex: 1}}>
          <PollTitleContainer pollTitle={loadingDelay ? null : titleText}/>
          <span style={{flex: 1}}/>
          <div className={styles.waitingIllustrationContainer}>
              <IllustrationWrapper>
                  <img src={waitingIllustration} alt="waiting" style={{height: 200}}/>
              </IllustrationWrapper>
          </div>

          {bodyShareCount &&
              <div className={styles.questionSharedBadge}>
                  <QUESTION_ICON/>
                  <span>Questions - {bodyShareCount}</span>
              </div>
          }
          {!loadingDelay && !!titleText &&
              <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomTitle}>
                  {bodyText}
              </span>
          }

          {!!afterQ &&
              <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomDesc}>{WAIT_QUESTION_EXPLAIN}</span>
          }

          {!afterQ &&
              <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomDesc}>
                {(loadingDelay || !titleText) ? WAITING_ROOM_ACTIVITY_TEXT : WAITING_ROOM_WAIT_POLL_START_DESC}
            </span>
          }
          <span style={{flex: 2}}/>
      </div>
  );
}
