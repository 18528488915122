import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useRef, useState} from "react";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as ICIcon } from "../../images/clickable_grey.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_grey.svg";
import { ReactComponent as MHIcon } from "../../images/matching_grey.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_grey.svg";
import { ReactComponent as SAIcon } from "../../images/sa_grey.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEndedGrey.svg";
import { ReactComponent as TFIcon } from "../../images/tf_grey.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_grey.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIconGrey.svg";
import { ReactComponent as ARROW_UP } from "./images/arrow-up.svg";
import { ReactComponent as ARROW_DOWN } from "./images/arrow-down.svg";
import { ReactComponent as EXPAND_ICON } from "./images/expand-icon.svg";
import { ReactComponent as BACK_ICON } from "./images/left-arrow-purple.svg";

import { YuJaGlobalState, useLoading } from "../../utils/LoadingContext";
import {
    BLANK_REGEX,
    BLANK_REGEX_NEW,
    POINTS_TEXT,
    QUESTION,
    QUESTION_TYPES,
    SA_PLACE_HOLDER, SCREEN_TYPE,
    UNANSWERED,
    WORD_CLOUD_DATA
} from "../../utils/constants";
import {InitializeQuestionResultCount, hasWC,  newPageLog} from "../../utils/helpers";
import { questionCodeToName } from "../../utils/questionUtils";
import "./styles.css";
import parse from "html-react-parser";
import classes from "./ClassResult.module.css";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import ImageViewer from "./ImageViewer";
import RenderYourResponse from "./RenderYourResponse";
import RenderCorrectAnswer from "./RenderCorrectAnswer";
import RenderAllResponse from "./RenderAllResponse";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import reactStringReplace from "react-string-replace";
import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";

export default function ClassResultAllMerged({ currentPoll, websocketState,  pollInfo, setCountdownContent, setShowClassResult}) {
    const {width: windowWidth, type: windowType} = useWindowDimensions();
    const { questions, rawQueResponses = [], timeLimit } = currentPoll;
    const { pollKey, uniqueCode, pollTitle, showAnswerOnViewer} = pollInfo;
    const { getAllClassResult} = ResponseApiCalls();
    const { setLoading } = useLoading();
    const [questionsData, setQuestionsData] = useState([]);
    const [setWordCloudData,] = useLocalStorageNew(WORD_CLOUD_DATA, {});
    const { isOnline } = useContext(YuJaGlobalState);
    const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);
    // const [participantCount, setParticipantCount] = useState("-");
    // const [responsesCount, setResponsesCount] = useState("-");
    // const [completionRate, setCompletionRate] = useState("-");
    const [questionData, setQuestionData] = useState(() => {
        return {
            ...questions[0],
            questionType: questions[0].questionType,
            queTitle: questions[0].queTitle,
            questionResults: InitializeQuestionResultCount(questions[0]),
            rawResults: {},
            serialNo: questions[0].serialNo,
            imageURL: questions[0].imageURL,
            image: questions[0].image,
            suffix: questions[0].suffix,
            directLinkEvp: questions[0].directLinkEvp,
            blankRegex: questions[0].hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX
        };
    });
    const [dataLoading, setDataLoading] = useState(false);
    // const [, getSession] = useLocalStorageNew("session", {});
    const [questionListOpen, setQuestionListOpen] = useState(false);
    const [pollPanelOpen, setPollPanelOpen] = useState(false);
    const pollPanelRef = useRef();
    const questionListContainerRef = useRef();

    useEffect(() => {
        newPageLog("ClassResultAllMerged.js");

        setDataLoading(true);
        getQuestionsData().finally(() => {
            setDataLoading(false);
        });

    }, []);

    useEffect(() => {
        if (hasWC(questions)) setWordCloudData({});
    }, [questions]);

    function removeUnanswered(result) {
        let newResult = [];
        if (result == null) {
            return newResult;
        }
        result.forEach((r) => {
            if (r.ans !== UNANSWERED) {
                newResult.push({ ...r });
            }
        });
        return newResult;
    }

    async function getQuestionsData() {
        setLoading(true);
        const results = await getAllClassResult(pollKey, uniqueCode, questions);
        let tempQuestionsData = [];
        for (const result of results) {
            let serialNo = result.serialNo;
            const que = questions.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
            let questionType = que.questionType;
            let queTitle = que.queTitle;
            let MCSSelements = [];
            if (questionType === QUESTION_TYPES.MCSS.name) {
                for (let key in que.optionsMap) {
                    MCSSelements.push(que.optionsMap[key]);
                }
            }
            let MCSSnum = MCSSelements.length;
            let questionImageURL;
            let isVideo = false;
            if (que.directLinkEvp) {
                questionImageURL = que.directLinkEvp;
                isVideo = true;
            } else {
                questionImageURL = que.imageURL;
                isVideo = false;
            }

            let questionData = {
                ...que,
                questionType: questionType,
                queTitle: queTitle,
                gradeEnabled: !!que.weightage,
                questionResults: result.questionResults,
                serialNo: serialNo,
                MCSSnum: MCSSnum,
                questionImageURL: questionImageURL,
                isVideo: isVideo,
                rawResponse: result.rawResponse
            };
            tempQuestionsData.push(questionData);
        }

        tempQuestionsData.forEach(item => {
            item.unansweredCount = getUnanswered(item.questionResults);
        });

        tempQuestionsData.forEach(item => {
            item.questionResults = removeUnanswered(item.questionResults);
        });

        if (JSON.stringify(questionsData) !== JSON.stringify(tempQuestionsData)) {
            setQuestionsData(tempQuestionsData);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (!isOnline || websocketState !== WebSocket.OPEN) {
            return;
        }
        let interval = 0;
        if (!questionData.weightage) {
            interval = setInterval(getQuestionsData, 5000);
        }

        return () => interval && clearInterval(interval);
    }, [isOnline, websocketState, questionData]);

    const getUnanswered = (result) => {
        if (result == null) {
            return 0;
        }

        let unansweredCount = 0;
        result.forEach((r) => {
            if (r.ans === UNANSWERED) {
                unansweredCount = r.people;
            }
        });
        return unansweredCount;
    }

    useEffect(() => {
        if (!dataLoading && questionsData && questionsData.length !== 0) {
            let serialNo = selectedQuestion.serialNo;
            const queData = questionsData.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
            setQuestionData(queData);
        }
    }, [questionsData, selectedQuestion, dataLoading]);



    useEffect(() => {
        const handleGlobalClick = (event) => {
            if (questionListOpen && !!questionListContainerRef.current && !!questionListContainerRef.current
                && !questionListContainerRef.current.contains(event.target)
            ) {
                setQuestionListOpen(false);
            }
        };

        if (questionListOpen) {
            document.addEventListener('click', handleGlobalClick, true);
        }
        return () => {
            document.removeEventListener('click', handleGlobalClick)
        };
    }, [questionListOpen]);

    useEffect(() => {
        const handleGlobalClick = (event) => {
            if (pollPanelOpen && !!pollPanelRef.current && !!pollPanelRef.current
                && !pollPanelRef.current.contains(event.target)
            ) {
                setPollPanelOpen(false);
            }
        };

        if (pollPanelOpen) {
            document.addEventListener('click', handleGlobalClick, true);
        }
        return () => {
            document.removeEventListener('click', handleGlobalClick)
        };
    }, [pollPanelOpen]);

    const updateTopNavContent = () => {
        if (!setCountdownContent) {
            return;
        }
        if (windowType === SCREEN_TYPE.MOBILE) {
            setCountdownContent(
                <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: 5, padding: "0 20px"}}>
                    <span className={classes.navBarTitle}>{pollTitle}</span>
                    {!pollPanelOpen &&
                        <ARROW_DOWN style={{cursor: "pointer", flexShrink: 0}} onClick={() => setPollPanelOpen(true)}/>
                    }

                    {!!pollPanelOpen &&
                        <ARROW_UP style={{cursor: "pointer", flexShrink: 0}}/>
                    }

                    {!!pollPanelOpen &&
                        <div ref={pollPanelRef} className={classes.pollPanelContainer}>
                            <span className={classes.cardTitle}>{pollTitle}</span>
                            <div style={{display: "flex", gap: 5}}>
                               <span className={classes.badge} style={{backgroundColor: "#F8DCDB"}}>
                                 {questions.length} Questions
                               </span>
                                <span className={classes.badge} style={{backgroundColor: "#D3DDF2"}}>
                                  {questions.filter(q => !!q.weightage).reduce(function (result, q) {
                                      return result + q.weightage;
                                  }, 0)} Points
                               </span>
                                {!!timeLimit &&
                                    <span className={classes.badge} style={{backgroundColor: "#F9E4F5"}}>
                                        {timeLimit / 60} Mins
                                   </span>
                                }
                            </div>
                        </div>
                    }
                </div>
            );
        } else {
            setCountdownContent(null);
        }
    }


    useEffect(() => {
        updateTopNavContent();
    }, [setCountdownContent, pollTitle, pollPanelOpen, windowType]);


    const renderTitle = () => {
        if (!!dataLoading) {
            return (
                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                    <Skeleton borderRadius={4} height={30} width={"min(80vw, 500px)"}/>
                </SkeletonTheme>
            );
        }

        if (questionData.questionType !== QUESTION_TYPES.FITB.name) {
            return questionData?.queTitle === undefined ? "" : parse(questionData?.queTitle);
        }

        let blankNameArr = Object.keys(questionData.correctAnswers).map(str => `\\(${str}\\)`);
        const regex = new RegExp(`(${blankNameArr.join("|")})`, "g");

        return (
            <span style={{marginRight: 5, lineHeight: "32px"}}>
               {reactStringReplace(questionData?.queTitle, regex, (blank, i) => {

                   const match = !!blank ? blank.match(/\(([^)]+)\)/) : null;
                   if (!match) {
                       return "";
                   }
                   const blankName = match[1];

                   return (
                       <input
                           key={i}
                           id={blankName}
                           className={classes.FITBInputBox}
                           disabled={true}
                           placeholder={SA_PLACE_HOLDER}
                           type={"text"}
                           style={{backgroundColor: "#FFF"}}
                       />
                   );
               })}
           </span>
        );
    }

    return (
        <div className={`${classes.parentContainer} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}>
            {windowType !== SCREEN_TYPE.MOBILE &&
                <div className={classes.pollTitleContainer} style={{gap: 20}}>
                    <span
                        tabIndex={0}
                        style={{width: 70, cursor: "pointer", flexShrink: 0}}
                        onClick={() => setShowClassResult(false)}
                    >
                        <BACK_ICON/> Back
                    </span>
                    <div style={{flex: 1}}/>
                    <span className={classes.pollTitleContainerTitle} tabIndex={0}>
                        {!!pollTitle &&
                            <span>{pollTitle}</span>
                        }
                        {!pollTitle &&
                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                                <Skeleton width={windowWidth * 0.7} height={40} borderRadius={6}/>
                            </SkeletonTheme>
                        }
                    </span>
                    <div style={{flex: 1}}/>
                    <span style={{width: 70}}/>
                </div>
            }


            <div className={`${classes.card} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}>
                <div
                    ref={questionListContainerRef}
                    className={`${classes.questionListContainer} 
                    ${windowType === SCREEN_TYPE.TABLET ? classes.tablet : ""} 
                    ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}
                    ${questionListOpen ? classes.opened : ""}`}
                    style={{width: windowType === SCREEN_TYPE.MOBILE ? (questionListOpen ? 88 : 0) : undefined}}
                >
                    {!!questions && (questions.map((que, index) => (
                        <div
                            key={index}
                            tabIndex={0}
                            className={`${classes.questionListItem} 
                            ${selectedQuestion.serialNo === que.serialNo ? classes.active : ""}
                            ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}
                            onClick={() => {
                                setDataLoading(true)
                                getQuestionsData().finally(() => {
                                    setDataLoading(false);
                                });
                                setSelectedQuestion(que);
                                setQuestionListOpen(false);
                            }}
                        >
                            <YuJaTooltip
                                placement={windowType === SCREEN_TYPE.MOBILE ? "left" : "right"}
                                text={QUESTION_TYPES[que.questionType].description}
                            >
                                <span className={classes.questionListQuestionTypeCircle}>
                                {que.questionType === QUESTION_TYPES.MCSS.name &&
                                    <MCSSIcon alt="MCSS Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.SA.name &&
                                        <SAIcon alt="SA Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.OE.name &&
                                        <OEIcon alt="QA Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.TF.name &&
                                        <TFIcon alt="TF Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.FITB.name &&
                                        <FITBIcon alt="FITB Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.CI.name &&
                                        <ICIcon alt="CI Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.WC.name &&
                                        <WCIcon alt="WC Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.MH.name &&
                                        <MHIcon alt="MH Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.RK.name &&
                                        <RKIcon alt="RK Icon" style={{width: 18, height: 18}}/>}
                            </span>
                            </YuJaTooltip>


                            <span className={classes.questionListItemText}>
                                {windowType === SCREEN_TYPE.DESKTOP ? `Question ${que.serialNo}` : `Q${que.serialNo}`}
                            </span>
                        </div>

                    )))}
                </div>

                <div className={classes.mainContainer}>
                    <div className={classes.mainContainerRow}>
                        <span tabIndex={0} style={{fontSize: 18, fontWeight: "500", lineHeight: "22px"}}>
                            {QUESTION} {selectedQuestion?.serialNo}
                        </span>
                        {!!selectedQuestion?.weightage &&
                            <span
                                tabIndex={0}
                                className={classes.badge}
                                style={{width: "fit-content", padding: "0 10px", backgroundColor: "#E4E3F6"}}
                            >
                                {selectedQuestion.weightage} {POINTS_TEXT}
                            </span>
                        }

                        <span style={{flex: 1}}/>

                        {windowType === SCREEN_TYPE.MOBILE &&
                            <span
                                tabIndex={0}
                                className={classes.expandIcon}
                                style={{marginLeft: 5}}
                                onClick={() => setQuestionListOpen(true)}
                            >
                              <EXPAND_ICON/>
                            </span>
                        }
                    </div>
                    <div className={classes.mainContainerRow}>
                      <span tabIndex={0} style={{color: "#6B6B6B"}}>
                        {questionCodeToName(selectedQuestion?.questionType)}
                      </span>
                    </div>

                    <div
                        tabIndex={0}
                        className={classes.mainContainerRow}
                        style={{
                            flexDirection: windowType === SCREEN_TYPE.MOBILE ? "column" : undefined,
                            alignItems: "start"
                        }}
                    >
                        {renderTitle()}
                        <span style={{flex: 1}}/>

                        {!!selectedQuestion?.imageURL && !selectedQuestion?.directLinkEvp &&
                            <ImageViewer
                                size={150}
                                alt={selectedQuestion?.imageAlt}
                                imageURL={selectedQuestion.imageURL}
                                styles={{alignSelf: windowType === SCREEN_TYPE.MOBILE ? "center" : undefined}}
                            />
                        }
                        {!!selectedQuestion?.imageURL && !!selectedQuestion?.directLinkEvp &&
                            <iframe allowFullScreen style={{width: 270, height: 270, alignSelf: "center"}}
                                    src={selectedQuestion.imageURL}/>
                        }
                    </div>

                    <RenderYourResponse
                        curUserResponses={rawQueResponses}
                        showAnswerOnViewer={showAnswerOnViewer}
                        questionData={questionData}
                        dataLoading={dataLoading}
                    />
                    <RenderCorrectAnswer
                        questionData={questionData}
                        curUserResponses={rawQueResponses}
                        showAnswerOnViewer={showAnswerOnViewer}
                        dataLoading={dataLoading}
                    />

                    <RenderAllResponse
                        pollKey={pollKey}
                        uniqueCode={uniqueCode}
                        questionData={questionData}
                        curUserResponses={rawQueResponses}
                        pollResults={questionsData}
                        dataLoading={dataLoading}
                        showAnswerOnViewer={showAnswerOnViewer}
                    />

                </div>
            </div>
        </div>
    );
}
                        // <YuJaButton
                        //     style={{position: "fixed", right: 0,
                        //         transform: "translate(50%, 50%) rotate(-90deg)",
                        //         transformOrigin: "bottom",
                        //         top: "calc(80% - 90px)"}}
                        //     type={"secondary"}
                        //     onClick={() => setFeedBackModalShow(true)}
                        // >
                        //     {FEEDBACK_BUTTON_TEXT}
                        // </YuJaButton>
