import React, { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "react-native-web";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import {TEXTBOX, TRY_ANOTHER_SEARCH_TEXT, UNGRADED, YUJA_DROPDOWN_SIZE} from "../../utils/constants";
import styles from "./YuJaDropdown.module.css";
import { YuJaTooltip } from "./YuJaTooltip";
import {findFirstOverflowParent} from "../../utils/helpers";
import {ReactComponent as SearchIcon} from "../../images/search_bar_icon.svg";
import PropTypes from "prop-types";

export const YuJaDropdown = ({
                                 data=[], onChange, onOpen=()=>{}, ariaLabel, isInvalid=false, errorMsg="",
                                 name, id, arrowSize=null, value, placeholder, getOptionLabel, getOptionValue, searchPlaceholder="Search...",
                                 containerStyle={}, style={}, textStyle={}, listStyle={}, itemStyle={},
                                 searchAble=false, size=YUJA_DROPDOWN_SIZE.MEDIUM,
                                 isNotificationDropdown=false, isAttendanceDropdown= false, isEditNotificationDropdown=false,
                                 ...props
}) => {
    const parentContainerRef = useRef();

    const windowDimensions = useWindowDimensions();
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(props?.defaultOpen || false);
    const smallMenuHeight = 100;
    const bigMenuHeight = 200;
    const [menuHeight, setMenuHeight] = useState(bigMenuHeight);
    const [menuVisible, setMenuVisible] = useState(false);
    const [searchKeywords, setSearchKeywords] = useState("");
    const actualValInputRef = useRef();

    let sizeClassName;
    if (size === YUJA_DROPDOWN_SIZE.SMALL) {
        sizeClassName = styles.small;
    } else if (size === YUJA_DROPDOWN_SIZE.MEDIUM) {
        sizeClassName = styles.medium;
    } else {
        sizeClassName = styles.large;
    }

    const open = () => {
        if (props.disabled) {
            return;
        }
        setIsOpen(!isOpen);
        if (props?.setOpen) {
            props.setOpen(!isOpen);
        }
    }

    const onClick = (item) => {
        setIsOpen(false);
        if (props.disabled) {
            return;
        }

        if (props?.setOpen) {
            props.setOpen(false);
        }
        const input = actualValInputRef.current;
        if (input) {
            input.value = item.value;
        }
        if (onChange) {
            onChange(item);
        }
    }

    const getLabel =(item) => {
        if (!item) {
            return "";
        }
        if (getOptionLabel) {
            return getOptionLabel(item);
        }

        return item.label;
    }

    const getValue =(item) => {
        if (!item) {
            return "";
        }
        if (getOptionValue) {
            return getOptionValue(item);
        }

        return item.value;
    }

    useEffect(() => {
        if (props?.defaultOpen) {
            setIsOpen(true);
            if (props?.setOpen) {
                props.setOpen(true);
            }
        }
    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        for (const node of menuRef.current?.children) {
            const textNode = node?.children?.length ? node.children[0] : null;
            if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
                textNode.title = textNode.innerText;
            }
        }
        onOpen();
    }, [isOpen]);


    const keywordsFilter = (item) => {
        if (!searchAble || !searchKeywords || searchKeywords.trim().length === 0) {
            return true;
        }
        let label = getLabel(item).toLowerCase();

        return label.includes(searchKeywords.trim().toLowerCase());
    }



    function isVisible (parent, child) {
        let {top: childTop, height: childHeight} = child.getBoundingClientRect();
        let {top: parentTop, height: parentHeight} = parent.getBoundingClientRect();

        return !(childTop + childHeight < parentTop || childTop > parentTop + parentHeight);

    }

    const setPosition = () => {
        if (!dropdownRef.current || !menuRef.current) {
            return;
        }

        let parent = findFirstOverflowParent(dropdownRef.current);
        if (!isVisible(parent, dropdownRef.current)) {
            setMenuVisible(false);
        } else {
            setMenuVisible(true);
        }

        if (!dropdownRef.current || !menuRef.current) {
            return;
        }


        const {left, width, height, top, } = dropdownRef.current?.getBoundingClientRect();
        menuRef.current.style.width = `${width}px`;

        if (window.innerHeight - top - height < smallMenuHeight) {
            menuRef.current.style.transform =  `translate(${Math.round(left)}px, ${Math.round(window.innerHeight - smallMenuHeight) - 1}px)`;
            setMenuHeight(smallMenuHeight);
        } else {
            menuRef.current.style.transform =  `translate(${Math.round(left)}px, ${Math.round(top + height) - 1}px)`;
            let h = window.innerHeight - top - height;
            h = h > bigMenuHeight ? bigMenuHeight : h;
            setMenuHeight(h);
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('scroll', setPosition, true);
            setPosition();
        }

        return () => {
            document.removeEventListener('scroll', setPosition)
        };
    }, [isOpen, windowDimensions]);


    useEffect(() => {
        const handleKeyDownDropdown = (event) => {
            if (event.key === 'Escape') {
                setIsOpen(false);
                return;
            }

            if (event.key !== "Tab") {
                return;
            }
            const container = parentContainerRef.current;
            if (!container) {
                return;
            }

            const focusableElements = container.querySelectorAll('[tabindex]:not([tabindex="-1"])');

            if (!parentContainerRef.current.contains(event.target)) {
                event.preventDefault();
                focusableElements[0].focus();
                return;
            }

            if (event.shiftKey) {
                for (let i = 0; i < focusableElements.length; i++) {
                    if (focusableElements[i] !== event.target) {
                        continue;
                    }
                    if (i === 0) {
                        event.preventDefault();
                        focusableElements[focusableElements.length - 1].focus();
                        return;
                    } else {
                        event.preventDefault();
                        focusableElements[i - 1].focus();
                        return;
                    }
                }
            } else {
                for (let i = 0; i < focusableElements.length; i++) {
                    if (focusableElements[i] !== event.target) {
                        continue;
                    }
                    console.log(`index is: ${i}`)
                    if (i === focusableElements.length - 1) {
                        event.preventDefault();
                        focusableElements[0].focus();
                        return;
                    } else {
                        event.preventDefault();
                        focusableElements[i + 1].focus();
                        return;
                    }
                }
            }

        };

        if (!!isOpen) {
            document.addEventListener("keydown", handleKeyDownDropdown);
        }


        return () => {
            document.removeEventListener("keydown", handleKeyDownDropdown);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && !!dropdownRef.current && !!menuRef.current
                && !dropdownRef.current.contains(event.target) && !menuRef.current.contains(event.target)
            ) {
                setIsOpen(false);
                if (props?.setOpen) {
                    props.setOpen(false);
                }
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleGlobalClick, true);
            // addClickEventHandler(handleGlobalClick);
        } else {
            setMenuVisible(false);
            setSearchKeywords("");
        }

        return () => {
            document.removeEventListener('click', handleGlobalClick)
        };
    }, [isOpen]);

    return (
        <div ref={parentContainerRef} className={styles.container} style={containerStyle}>
            <div
                ref={dropdownRef}
                className={`${styles.presenter} ${sizeClassName} ${isOpen ? styles.opened : ""} ${isInvalid ? styles.invalid : ""} ${props.disabled ? styles.disabled : ""}`}
                onClick={open}
                style={{...style}}
                tabIndex={0}
                aria-label={ariaLabel}
                // onKeyDown={(e) => handleTabDown(e)}
                role={TEXTBOX}
                id={id}
            >
                <span className={styles.presenterText} style={{...textStyle}}>
                    {isAttendanceDropdown && value === UNGRADED
                        ? value
                        : value ? getLabel(data.find(item => getValue(item) === value)) : placeholder
                    }
                </span>
                {!isOpen && <ArrowDown style={{width: 24, height: 24, cursor: 'pointer', flexShrink: 0, }} /> }
                {isOpen && <ArrowUp style={{width: 24, height: 24, cursor: 'pointer', flexShrink: 0, }} />}
                {isInvalid && errorMsg &&
                    <div className={styles.iconContainer}>
                        <YuJaTooltip placement={"right"} text={errorMsg} tooltipStyle={{maxWidth: 300}}>
                            <div className={styles.invalidIcon}/>
                        </YuJaTooltip>
                    </div>
                }
            </div>
            <input ref={actualValInputRef} defaultValue={value} name={name} style={{display: "none"}} id={value} aria-label="dropdown"/>
            {isOpen &&
                <div
                    ref={menuRef}
                    className={styles.menuListContainer}
                    style={{maxHeight: menuHeight, visibility: menuVisible ? "visible" : "hidden"}}
                >
                    {searchAble &&
                        <div tabIndex={0} className={`${styles.searchBar} ${sizeClassName}`}>
                            <SearchIcon style={{flexShrink: 0}}/>
                            <input
                                value={searchKeywords}
                                onChange={e => setSearchKeywords(e.target.value)}
                                placeholder={searchPlaceholder}
                                style={{flex: 1, minWidth: 0}}
                            />
                        </div>
                    }

                    <div className={styles.menuList} style={{...listStyle}}>
                        {data.filter(keywordsFilter).map((item, idx) =>
                            <div
                                key={idx}
                                className={`${styles.menuItem} ${sizeClassName} ${getValue(item) === value ? styles.selected : ""}`}
                                style={{...itemStyle}}
                                onClick={() => onClick(item)}
                                tabIndex={0}
                                role={TEXTBOX}
                            >
                                <span className={styles.menuItemText}>{getLabel(item)}</span>
                            </div>
                        )}
                        {!data.filter(keywordsFilter).length &&
                            <div
                                className={`${styles.menuItem} ${sizeClassName}`}
                                style={{...itemStyle}}
                                tabIndex={0}
                                role={TEXTBOX}
                            >
                                <span className={styles.menuItemText}>{TRY_ANOTHER_SEARCH_TEXT}</span>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>

    );
}
YuJaDropdown.propTypes = {
    size: PropTypes.oneOf([YUJA_DROPDOWN_SIZE.SMALL, YUJA_DROPDOWN_SIZE.MEDIUM, YUJA_DROPDOWN_SIZE.LARGE]),
}