import classes from "./ClassResult.module.css";
import {CORRECT, SCREEN_TYPE, TEXTBOX} from "../../utils/constants";
import React, {useEffect, useState} from "react";
import { ReactComponent as CORRECT_ICON } from "../../images/grade-question-correct-icon.svg";
import { ReactComponent as ARROW_UP } from "./images/arrow-up.svg";
import { ReactComponent as ARROW_DOWN } from "./images/arrow-down.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function PremiseChart({premise, premiseIdx, data, shouldShowCorrect, correctAnswers}) {
    const {type: windowType} = useWindowDimensions();
    const [isExpanded, setIsExpanded] = useState(false);

    const setBar = () => {
        let total = Object.values(data).reduce((result, item) => result + item, 0);
        Object.keys(data).forEach((option, index) => {
            let barWithPer = !total ? "0" : `${(data[option] / total) * 100}%`;
            let countBar = document.getElementById(`premise-${premiseIdx}-option-count-bar-${index}`);
            if (!!countBar) {
                countBar.style.width = barWithPer;
            }
        });
    }

    const resetBar = () => {
        Object.keys(data).forEach((option, index) => {
            let countBar = document.getElementById(`premise-${premiseIdx}-option-count-bar-${index}`);
            if (!!countBar) {
                countBar.style.width = "0";
            }
        });
    }


    useEffect(() => {
        if (isExpanded) {
            setBar();
        } else {
            resetBar();
        }
    }, [isExpanded, data]);

    return (
        <div className={classes.premiseChartContainer} style={{maxHeight: isExpanded ? 300 : 55}}>
            <span
                tabIndex={0}
                className={classes.premiseChartContainerHeader}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <span style={{
                    flex: 1,
                    minWidth: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}>{premise}</span>
                <span style={{flex: 1}}/>
                {isExpanded && <ARROW_UP/>}
                {!isExpanded && <ARROW_DOWN/>}
            </span>
            <div className={classes.premiseChartContainerBody} style={{paddingRight: 3}}>
                {Object.keys(data).map((option, i) =>
                    <div key={i} className={classes.countBarContainer}>
                        <div id={`premise-${premiseIdx}-option-count-bar-${i}`} className={classes.countBar}/>
                        <span id={`premise-option-text-${i}`} tabIndex={isExpanded ? 0 : -1} role={TEXTBOX} style={{zIndex: 1}}>{option}</span>
                        <span style={{flex: 1}}/>

                        {!!shouldShowCorrect && correctAnswers[premiseIdx][1] === option &&
                            <span className={classes.correctBadge} role={TEXTBOX} tabIndex={isExpanded ? 0 : -1}
                                  aria-label={CORRECT}
                                  style={{zIndex: 1}}><CORRECT_ICON/></span>
                        }

                        <span
                            className={classes.countBadge}
                            role={TEXTBOX}
                            tabIndex={isExpanded ? 0 : -1}
                            style={{zIndex: 1}}
                        >{data[option]}</span>
                    </div>
                )}
            </div>
        </div>
    );
}