import ActiveCreatePoll from "../images/links/active_create_group_icon.svg";
import ActiveCreateMergedPoll from "../images/links/active_create_polls_icon.svg";
import ActiveCreateAttendance from "../images/links/active_attendance_icon.svg";
import ActiveGradeBook from "../images/links/active_gradebook_icon.svg";
import ActivePollGradeBookIcon from "../images/links/active_poll_gradebook_icon.svg";
import ActiveAttendanceGradeBookIcon from "../images/links/active_attendance_gradebook_icon.svg";
import ActiveHome from "../images/links/active_home_icon.svg";
import ActiveIntegration from "../images/links/active_integration_icon.svg";
import ActiveManageCourses from "../images/links/active_manage_courses_icon.svg";
import ActiveManagePolls from "../images/links/active_manage_polls_icon.svg";
import ActiveManageUsers from "../images/links/active_manage_users_icon.svg";
import ActiveSettings from "../images/links/active_settings_icon.svg";
import CreatePoll from "../images/links/create_group_icon.svg";
import CreateMergedPoll from "../images/links/create_poll_icon.svg";
import CreateAttendanceIcon from "../images/links/create_attendance.svg";
import GradeBook from "../images/links/gradebook_icon.svg";
import PollGradeBookIcon from "../images/links/poll_gradebook_icon.svg";
import AttendanceGradeBookIcon from "../images/links/attendance_gradebook.svg";
import GradientManageCourses from "../images/links/gradient_manage_courses_icon.svg";
import GradientManagePolls from "../images/links/gradient_manage_polls_icon.svg";
import GradientSettings from "../images/links/gradient_settings_icon.svg";
import Home from "../images/links/home_icon.svg";
import Integration from "../images/links/integration_icon.svg";
import ManageCourses from "../images/links/manage_courses_icon.svg";
import ActiveQuestionBanks from "../images/links/questionbank_active_icon.svg";
import ManageQuestionBanks from "../images/links/questionbank_icon.svg";
import ManagePolls from "../images/links/manage_polls_icon.svg";
import ManageUsers from "../images/links/manage_users_icon.svg";
import Settings from "../images/links/settings_icon.svg";
import Recycle from "../images/recycle.svg";
import ActiveRecycle from "../images/recycle_active.svg";
import Report from "../images/reportIcon.svg";
import ActiveReport from "../images/reportIcon-active.svg";
import Login from "../images/login.svg";

import CORRECT_ICON from "../images/gradebook-correct-icon.svg";
import INCORRECT_ICON from "../images/gradebook-incorrect-icon.svg";
import NON_GRADABLE_ICON from "../images/gradebook-non-gradable-icon.svg";
import UN_GRADED_ICON from "../images/gradebook-ungraded-icon.svg";
import UNANSWERED_ICON from "../images/gradebook-unanswered-icon.svg";
import NOT_POSTED_ICON from "../images/gradebook-not-posted-icon.svg";



export const COLLAPSED_SIDE_BAR_WIDTH = 60;
export const EXPANDED_SIDE_BAR_WIDTH = 240;
export const RESPONSIVE_SIDE_BAR_POINT = 900;
export const RELEASE_PAGE_RIGHT_PANEL_OVERLAY_POINT = 1100;

export const REQUIRED_FIELD_ERROR = "this field is required";
export const INVALID_POLL_CODE_ERROR = "Invalid activity code";
export const CANNOT_ACCESS_POLL_ERROR = "User cannot access the activity.";
export const INVALID_QUESTION_TYPE = "Invalid question type";
export const UNAUTHORIZED_ERROR = "Sorry, Unauthorized, please Login first!";
export const SESSION_MISSED_ERROR = "Sorry, Please login first!";
export const SESSION_EXPIRED_ERROR = "Sorry, session is expired, Please re-login!";
export const PERMISSION_VALIDATE_ERROR = "Sorry, You don't have permission to access this page!";
export const NO_ACCESS_TO_POLL_ERROR = "You don't have access to this activity!";
export const BLANK_REGEX = /\([[a-zA-Z0-9!?@#=$&:*<>"';^_%\\\-.+,\/\ ]+\)/g; // 1 or more characters without limit
export const BLANK_REGEX_NEW = /\([[a-zA-Z0-9!?@#=$&:*<>"';^_%\\\-.+,\/\ ]{1,25}\)/g; // 25 characters limit
export const BLANK_REGEX_WRAPPED = /(\([[a-zA-Z0-9!?@#=$&:*<>"';^_%\\\-.+,\/\ ]+\))/g; // 1 or more characters without limit
export const BLANK_REGEX_WRAPPED_NEW = /(\([[a-zA-Z0-9!?@#=$&:*<>"';^_%\\\-.+,\/\ ]{1,25}\))/g; // 25 characters limit
export const GRADE_BOOK_QUESTION_COLUMN_REGEX = /^Q\d*$/;
export const ATTEMPT_REGEX = /^Attempt #\d+$/;
export const SCORE_REGEX = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/g;
export const QR_URL_REGEX = /^(?:https?:\/\/)?(?:[a-zA-Z-]+\.engage\.yuja\.com\/qr\/)([0-9]+)$/;

export const POLL_CODE_LENGTH = 6;
export const ANONYMOUS_USERNAME_LENGTH = 6;
export const USERNAME_LENGTH = 4;

export const DEFAULT_TOTAL_QUESTIONS = 5;
export const DEFAULT_POINTS = 10;
export const DEFAULT_TIME_LIMIT = 15;
export const DEFAULT_GET_READY_TIME_LIMIT = 10;
export const DEFAULT_CLASS_RESULT_TIME_LIMIT = 15;
export const DEFAULT_POLL_BREAK_TIME_LIMIT = 5;
export const DEFAULT_POLL_TIME_LIMIT = 1800;
export const DEFAULT_ATTENDANCE_MANUAL_END = true;
export const DEFAULT_ATTENDANCE_TIME_LIMIT = 3600;
export const DEFAULT_ATTENDANCE_SAVED_LOCATIONS = "[]";
export const DEFAULT_GET_READY_ALL_TIME_LIMIT = 10;
export const DEFAULT_POLL_REQUIRED_NAME = false;
export const DEFAULT_POLL_SHOW_CORRECT_ANS = false;
export const DEFAULT_POLL_MAX_ATTEMPTS = -1;
export const DEFAULT_ENTRIES_PER_PARTICIPANT = 1;
export const DEFAULT_SHOWN_ENTRIES = 3;
export const UNTIMED_POLL = -1;
export const UNTIMED_POLL_TIME_LIMIT = 24*60*60;
export const GET_READY = 11;
export const WAIT_LENGTH = 20;
export const TOTAL_LENGTH = WAIT_LENGTH + GET_READY + 3;

export const POLL_TITLE_LENGTH = 100;
export const POLL_TITLE_DROPDOWN_LENGTH = 50;
export const QUESTION_LENGTH = 1000;
export const FITB_QUESTION_LENGTH = 1000; 
export const FITB_BLANK_LENGTH = 25; 
export const MC_ANSWER_LENGTH = 100;
export const SA_ANSWER_LENGTH = 100;
export const FITB_ANSWER_LENGTH = 100;
export const FITB_BLANK_LIMIT = 2;
export const MAX_MH_RESPONSE_DISPLAY = 3;
export const WC_ANSWER_LENGTH = 25; // Word Cloud Response Character Limit
export const WC_DISPLAY_LIMIT = 1; // Show View Answer if more than one entry
export const WC_MAXIMUM_ENTRIES = 10;
export const FIRST_WC_PLACEHOLDER = "Enter a word";
export const OTHER_WC_PLACEHOLDER = "Enter another word";
export const CHARACTERS_REMAINING = " characters remaining";

export const YUJA_TITLE = "YuJa Engage";
export const YUJA_OPTIMIZE_TEXT = "Optimize Your Experience";
export const YUJA_DOWNLOAD_TEXT = "For the best experience, please download our app.";

export const MANAGE_POLLS_PAGE_POLL_TITLE_LENGTH = 30;

export const QR_SCANNER_WIDTH = 300;
export const QR_SCANNER_HEIGHT = 300;

export const ANY = "ANY";
export const PORTRAIT = "PORTRAIT";
export const LANDSCAPE = "LANDSCAPE";

export const ROLES = {
    ADMINISTRATOR: { value: "ADMINISTRATOR", display: "Admin" },
    IT_MANAGER: { value: "IT_MANAGER", display: "IT Manager" },
    CREATOR: { value: "CREATOR", display: "Creator" },
    VIEWER: { value: "VIEWER", display: "Viewer" },
    ANONYMOUS: { value: "ANONYMOUS", display: "Anonymous" }
}

export const CREATION_ROLES = {
    IT_MANAGER: { value: "IT_MANAGER", display: "IT Manager" },
    CREATOR: { value: "CREATOR", display: "Creator" },
    VIEWER: { value: "VIEWER", display: "Viewer" },
}

export const ERROR_CODES = {
    USER_ALREDY_EXISTS: "Y009",
    INVALID_CODE: "Y008",
    EXPIRED_SESSION: "Y026",
    INVALID_SESSION: "Y027",
    INVALID_POLL_CODE: "Y029",
    EXPIRED_POLL_CODE: "Y032"
};

export const AUTHORIZATION_TYPE = "Bearer";

export const FROM_HEADERS = { from: "APPLICATION" };

export const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    AUTHORIZE_DENY: 403,
}

export const HOME_PATH = "/home";
export const COURSE_PATH = "/poll/course/";
export const POLL_PATH = "/poll/user";
export const POLL_CHOOSER_PATH = "/poll/choose/";
export const JOIN_PARTICIPANT_PATH = "/qr";
export const LOGIN_PATH = "/";
export const SESSION_WILL_EXPIRED_DURATION = 30 * 60 * 1000;
export const COOKIE_WILL_EXPIRED_DURATION = 24 * 60 * 60 * 1000;
export const SESSION_CHECK_INTERVAL = 60 * 1000;

export const SURVEY_ALL_PAGE_STATE = {
    QUESTION: 'QUESTION',
    RESULT: 'RESULT',
    RESULT_ALL: 'RESULT_ALL',
    NOT_READY: 'NOT_READY'
};
export const ATTENDANCE_PAGE_STATE = {
    LMS_GEOFENCE: 'LMS_GEOFENCE', // LMS view for geofenced attendance
    WAITING: 'WAITING', // waiting for attendance
    CHECK_IN: 'CHECK_IN', // location check in
    QUESTION: 'QUESTION', // attendance question
    THANK_YOU: 'THANK_YOU', // attendance submitted
    // THANK_YOU_CLOSED: 'THANK_YOU_CLOSED', // attendance closed with response
    CLOSED: 'CLOSED', // attendance closed
};

export const SURVEY_ALL_CURRENT_QUESTION_NO_KEY = 'SURVEY_ALL_CURRENT_QUESTION_NO_KEY';

export const QUESTION_TYPES = {
    MCSS: { name: 'MCSS', description: 'Multiple Choice', detail: 'Ask a question and let participants choose from a list of answers.' },
    SA: { name: 'SA', description: 'Short Answer', detail: 'Ask a question and let participants type their response.' },
    TF: { name: 'TF', description: 'True or False', detail: 'Ask a question and let participants choose between true or false.' },
    FITB: { name: 'FITB', description: 'Fill in the Blank', detail: 'Ask a question and let participants fill in the blanks.' },
    CI: { name: 'CI', description: 'Clickable Image', detail: 'Upload an image and ask a question. Participants click anywhere on the image to respond.' },
    WC: { name: 'WC', description: 'Word Cloud', detail: 'Visualize audience responses as a dynamic word cloud.' }, // Survey only
    MH: { name: 'MH', description: 'Matching', detail: 'Ask a question and let participants match premises to responses.' }, // Graded Poll only
    RK: { name: 'RK', description: 'Ranking', detail: 'Ask a question and let participants rank the given options in order of preference.' }, // Survey only
    OE: { name: 'OE', description: 'Open Discussion', detail: 'Ask a question and let participants type their response and upvote/downvote other responses.' },
};
export const SAMPLE_QUESTIONS = [
    { "Question Type": "Multiple Choice", "Question Title": "What is your favourite fruit", "Options": "Apple , Banana , Orange , Grapes", "Answer": "Apple", "Blank Pairs": "" },
    { "Question Type": "Short Answer", "Question Title": "This is a short answer question", "Options": ``, "Answer": "", "Blank Pairs": "" },
    { "Question Type": "True or False", "Question Title": "Is this True", "Options": "True, False", "Answer": "True", "Blank Pairs": "" },
    { "Question Type": "Clickable Image", "Question Title": "Where is the cat in the image?", "Options": "", "Answer": "", "Blank Pairs": "" },
    { "Question Type": "Matching", "Question Title": "Match the capital with the country", "Options": "", "Answer": "{France:Paris , Italy:Rome , Toronto}", "Blank Pairs": "" },
    { "Question Type": "Fill in the Blank", "Question Title": "This is a fill in the (Blank 1) question with multiple (Blank 2)", "Options": "", "Answer": "", "Blank Pairs": "{Blank 1: Answer1 | Answer2, Blank 2: Answer3 | Answer 4}" },
    { "Question Type": "Word Cloud", "Question Title": "What core values do you believe are essential for success?", "Options": "" },
    { "Question Type": "Ranking", "Question Title": "Rank the following historical events in order of their significance in shaping the modern world.", "Options": "The Industrial Revolution, The signing of the Magna Carta, The discovery of the New World by Columbus, The invention of the internet" },
    { "Question Type": "Open Discussion", "Question Title": "In what ways do you believe emerging technologies could help in our everyday lives?", "Options": "" }
];

export const imageSuffixArr = ['.png', '.jfif', '.pjpeg', '.jpg', '.jpeg', '.pjp'];
export const videoSuffixArr = ['.mp4'];
export const fileSuffixArr = ['.pdf', '.doc', '.docx', '.txt', '.png', '.jfif', '.pjpeg', '.jpg', '.jpeg', '.pjp', '.mp4'];

export const MCSSoptLower = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const MCSSoptUpper = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const TFoptions = ['True', 'False'];

// Accessible Colors with White Background:
export const WORD_CLOUD_COLORS = ['#004A7F', '#006B3F', '#7F0000', '#4C005C', '#BF4600', '#333333', '#006E99', '#D63838', '#8A2BE2', '#737373', '#008080', '#CF00CF', '#000080', '#808000', '#B85B39', '#800000', '#4B0082', '#7F6C5B'];
export const RANKING_COLORS = ['#CE8240', '#50A551', '#499EC5', '#9F85E0', '#758CF0', '#EE63A4', '#C18867', '#EE63A4'];

export const PAGE_TYPE = {
    COMPATIBLE: "0",
    VIEWER: "1",
    OTHER: "2"
};

export const NETWORK_ISSUE = "NETWORK IS NOT WORKING";
export const NETWORK_STATUS_TYPE = {
    ONLINE: "Online",
    OFFLINE: "Offline"
};

export const NETWORK_EFFECTIVE_TYPE = {
    LOW: "2g",
    MID: '3g',
    NORMAL: '4g'
};

export const NETWORK_DOWN_TOOLTIP_MSG = "The internet connection has been disconnected. Please check your connection and select the Refresh button.";
export const WEBSOCKET_CONNECTION_DOWN_TOOLTIP_MSG = "A communication error has occurred. Please select the Refresh button to try again.";
export const NETWORK_LOW_TOOLTIP_MSG = "Weak internet connection detected. Please check your connection and select the Refresh button to avoid missing any questions.";

export const POLL_STATE = {
    SHARED: "SHARED",
    SHARED_ALL: "SHARED_ALL",
    STOPPED: "STOPPED",
    STOPPED_ALL: "STOPPED_ALL",
    LOCKED: "LOCKED",
    PAUSED: "PAUSED",
    RESUMED: "RESUMED",
    SCHEDULED: "SCHEDULED",
    STOPPED_SCHEDULED: "STOPPED_SCHEDULED",
    CLOSED: "CLOSED"
};

export const QUIZ_STATE = {
    LOADING: "LOADING",
    WAITING: "WAITING",
    PENDING: "PENDING",
    GET_READY: "GET_READY",
    GET_READY_ALL: "GET_READY_ALL",
    POLL_TIME: "POLL_TIME",
    POLL_TIME_ALL: "POLL_TIME_ALL",
    LOCKED: "LOCKED",
    PAUSED: "PAUSED",
    RESUMED: "RESUMED",
    USER_COMPLETE_POLL: "USER_COMPLETE_POLL",
    USER_COMPLETE_SURVEY: "USER_COMPLETE_SURVEY",
    SURVEY_ALL_END: "SURVEY_ALL_END",
    CLASS_RESULT: "CLASS_RESULT",
    SA_RESULT: "SA_RESULT",
    CLASS_RESULT_ALL: "CLASS_RESULT_ALL",
    CLASS_RESULT_ALL_SURVEY: "CLASS_RESULT_ALL_SURVEY",
    EXPIRED: "EXPIRED",
    CLOSED: "CLOSED",
    ATTENDANCE_PAGE: "ATTENDANCE_PAGE"
};

export const POLL_TYPE = {
    GRADED_POLL: "GRADED_POLL",
    SURVEY: "SURVEY",
    ATTENDANCE: "ATTENDANCE",
    MERGED_POLL: "MERGED_POLL",
};

export const SUB_QUESTION_TYPE = {
    PREVIOUS: "PREVIOUS",
    NEXT: "NEXT",
    UNANSWERED: "UNANSWERED"
}

export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;
export const INTEGRATION_TYPES = {
    ADFS: "ADFS",
    SHIBBOLETH: "SHIBBOLETH",
    DCAM: "DCAM",
    GOOGLE: "GOOGLE",
    AZURE: "AZURE",
    CANVAS: "CANVAS",
    BLACKBOARD: "BLACKBOARD",
    MOODLE: "MOODLE",
    D2L: "D2L",
    LTI: "LTI1.3",
    LMS_API: "LMS_API"
};
export const IDENTITY_PROVIDER_TYPES = {
    SAML: "SAML",
    LMS: "LMS",
    YUJA: "YuJa"
};

export const LOG_TYPES = {
    INFO: "Info: ",
    WARNING: "Warning: ",
    ERROR: "ERROR: "
};

export const LOG_API_FILES = {
    RESPONSE: "ResponseApiCalls.js",
    PUBLIC: "PublicApiCall.js",
    POLL: "PollApiCalls.js",
    USER: "UserApiCalls.js",
    LTI: "LTIApiCalls.js",
    INST: "InstitutionApiCalls.js",
    COURSE: "CourseApiCalls.js",
    AUTHENTICATION: "AuthenticationApiCalls.js"
};

export const VIEWER_USER_TYPE = {
    MANUAL_GUEST: "MANUAL_GUEST",
    AUTO_GUEST: "AUTO_GUEST",
    LOGGED_IN: "LOGGED_IN",
    LMS_CANVAS: "LMS_CANVAS",
    LMS_BLACKBOARD: "LMS_BLACKBOARD",
    LMS_MOODLE: "LMS_MOODLE",
    LMS_D2L: "LMS_D2L"
};

export const CI_COORDINATE_SIZE = {
    width: 24,
    height: 24
};

export const TOAST_TYPE = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    INFO: "INFO",
    WARNING: "WARNING",
}

export const LMS_GRADEBOOK_SETTINGS_TITLE = "LMS Gradebook Settings";
export const LMS_GRADEBOOK_SETTINGS_SUBTITLE = "Send Poll Attempt to LMS Gradebook";
export const LMS_GRADEBOOK_SETTINGS_LABEL = "LMS Gradebook";
export const LMS_GRADEBOOK_SETTINGS_DESCRIPTION = "will be sent to the LMS gradebook";
export const POLL_ATTEMPT_SETTINGS_TEXT = "Poll Attempts";
export const SURVEY_ATTEMPT_SETTINGS_TEXT = "Survey Attempts";
export const NO_SELECTION_TEXT = "Please Select one";

export const PLATFORM_SETTINGS = {
    DISABLE_USER: "DisableUser",
    ANONYMOUS_JOIN: "AnonymousJoin",
    LMS_QUIZ_ATTEMPTS: "LMSQuizAttempts",
    YUJA_LOGIN: "YujaLogin",
    YUJA_NAME: "YujaName",
    PWD_RESET: "PasswordResetEnable",
    SSONAME_ADFS: "SSOName_ADFS",
    SSONAME_SHI: "SSOName_SHI",
    SSONAME_DCAM: "SSOName_DCAM",
    SSONAME_GOOGLE: "SSOName_GOOGLE",
    SSONAME_AZURE: "SSOName_AZURE",
    DEFAULT: "default",
}

export const DEFAULT_LOGIN_METHODS = {
    YUJA_LOGIN: "1",
    SSONAME_ADFS: "2",
    SSONAME_SHI: "3",
    SSONAME_DCAM: "4",
    SSONAME_GOOGLE: "5",
    SSONAME_AZURE: "6",
}

export const LOGIN_METHODS = {
    YUJA: "YuJa Credentials",
    ADFS: "SSO - ADFS",
    SHIBBOLETH: "SSO - SHIBBOLETH",
    DCAM: "SSO - DCAM",
    GOOGLE: "SSO- GOOGLE",
    AZURE: "SSO - AZURE",
}

export const USER_SETTINGS = {
    TOAST_MESSAGE_DURATION: "ToastMessageDuration",
    QUESTION_POINTS: "questionPoints",
    QUESTION_DURATION: "questionDuration",
    QUESTION_COUNTDOWN: "questionCountdown",
    QUESTION_ENTRIES: "questionEntries",
    QUESTION_CLASS_RESULT_DURATION: "questionClassResultDuration",
    POLL_DURATION: "pollDuration",
    POLL_COUNTDOWN: "pollCountdown",
    POLL_REQUIRED_NAME: "pollRequirementName",
    POLL_SHOW_CORRECT_ANS: "pollShowCorrectAns",
    POLL_LMS_SYNC: "pollLmsSync",
    POLL_RECORD_ATTEMPTS: "pollRecordAttempts",
    POLL_MAX_ATTEMPTS: "pollMaxAttempts",
    SURVEY_MAX_ATTEMPTS: "surveyMaxAttempts",
    SURVEY_RECORD_ATTEMPTS: "surveyRecordAttempts", 
    POLL_BREAK_DURATION: "pollBreakDuration",
    PPT_AUTO_SHARE: "pptAutoShare",
    ATTENDANCE_MANUAL_END: "attendanceManualEnd",
    ATTENDANCE_DURATION: "attendanceDuration",
    ATTENDANCE_PPT_AUTO_SHARE: "attendancePptAutoShare",
    ATTENDANCE_SAVED_LOCATIONS: "attendanceSavedLocations"
};

export const QUESTION_DURATION_OPTIONS = [
    {value: 10, label: "10 Seconds"},
    {value: 15, label: "15 Seconds"},
    {value: 30, label: "30 Seconds"},
    {value: 45, label: "45 Seconds"},
    {value: 60, label: "1 Minute"},
    {value: 300, label: "5 Minutes"}
];

export const QUESTION_POINTS_OPTIONS = [
    {value: 1, label: "1 Point"},
    {value: 5, label: "5 Points"},
    {value: 10, label: "10 Points"},
    {value: 15, label: "15 Points"},
    {value: 20, label: "20 Points"},
    {value: 50, label: "50 Points"}
];

export const QUESTION_COUNTDOWN_OPTIONS = [
    {value: 10, label: "10 Seconds"},
    {value: 15, label: "15 Seconds"},
    {value: 30, label: "30 Seconds"}
];

export const AUDIENCE_RESTRICTION = {
    UNRESTRICTED: "UNRESTRICTED", // no restriction - everyone can join
    NAME_REQUIRED: "NAME_REQUIRED", // guests need to provide name
    LOGGED_IN: "LOGGED_IN", // logged in users only
    REGISTERED: "REGISTERED" // logged in users with course access
};
export const DEFAULT_AUDIENCE_RESTRICTION = AUDIENCE_RESTRICTION.UNRESTRICTED;

export const CONFIGURE_GRADEBOOK_OPTIONS_TEXT = "Configure Gradebook Options";
export const RECORD_ATTEMPT_TITLE = "Record Poll Attempts";
export const RECORD_ATTEMPT_TITLE_SURVEY = "Record Survey Attempts";
export const POLL_LENGTH_SETTING_TEXT = "Time Limit of Poll";
export const ATTENDANCE_LENGTH_SETTING_TEXT = "Close Attendance Options";
export const ATTENDANCE_LENGTH_SETTING_DESC = "Choose to close the attendance manually or set a time limit to close it automatically.";
export const POLL_LENGTH_SETTING_DESCRIPTION = " to complete the poll.";
export const ATTENDANCE_LENGTH_SETTING_DESCRIPTION = " to complete the attendance.";
export const PPT_AUTO_SHARE_TITLE = "Auto-Share During Presentations";
export const PPT_AUTO_SHARE_DESCRIPTION = "Automatically release the poll to participants when the slide is presented.";
export const PPT_AUTO_SHARE_ATTENDANCE_DESCRIPTION = "Automatically open the attendance to participants when the slide is presented. ";
export const PPT_AUTO_SHARE_CHECKBOX_TEXT = "Automatically release";
export const PPT_AUTO_SHARE_CHECKBOX_TEXT_ATTENDANCE = "Automatically open";
export const SAVED_LOCATIONS_TITLE = "Saved Geofenced Locations";
export const GEOFENCED_LOCATIONS_TITLE = "Geofenced Locations";
export const CONFIGURE_TEXT = "Configure";
export const ATTENDANCE_SAVED_LOCATION_DESCRIPTION = "View and add geofenced locations for attendance tracking.";
export const SAVED_LOCATIONS = "Geofenced Locations";
export const LMS_ATTEMPT_TITLE = "LMS Gradebook Sync";
export const MAX_ATTEMPTS_TITLE = "Maximum Attempts";
export const CORRECT_ANSWER_TITLE = "Correct Answer";
export const AUDIENCE_RESTRICTION_TITLE = "Participant Restrictions";
export const UNLIMITED_MAX_ATTEMPTS_TEXT = "Allow unlimited attempts";
export const UNLIMITED_MAX_ATTEMPTS_SETTING_TEXT = "Allow participants to have unlimited attempts.";
export const CUSTOM_MAX_ATTEMPTS_TEXT = "Maximum attempts allowed (if limited)";
export const MODAL_CANCEL_TEXT = "Cancel";
export const MODAL_CLOSE_TEXT = "Close";
export const MODAL_EDIT_TEXT = "Edit";
export const MODAL_GO_BACK_TEXT = "Go Back";
export const MODAL_SAVE_TEXT = "Save";
export const MODAL_NEXT_TEXT = "Next";
export const MODAL_IMPORT_TEXT = "Import";
export const MODAL_SEND_TEXT = "Send";
export const MODAL_DELETE_TEXT = "Delete";
export const MODAL_CONFIRM_TEXT = "Confirm"; 
export const REATTEMPT_MODAL_TITLE = "Reattempt Poll";
export const REATTEMPT_MODAL_SURVEY_TITLE = "Reattempt Survey";
export const REATTEMPT_MODAL_BODY = "Are you sure you want to reattempt the poll?";
export const REATTEMPT_MODAL_SURVEY_BODY = "Are you sure you want to reattempt the survey?";
export const YES_TEXT = "Yes";
export const RESTORE_TEXT = "Restore";
export const NO_TEXT = "No";
export const USERNAME = "Username";
export const USERNAME_SUBTITLE = "Please fill username.";
export const PASSWORD = "Password";
export const PASSWORD_SUBTITLE = "Please fill password.";
export const INVALID_PASSWORD_MSG = "Please ensure your password meets the following requirements:\n";
export const PASSWORD_LENGTH = "Between 12-30 characters in length";
export const PASSWORD_CAPITAL = "Contains a capital letter";
export const PASSWORD_NUMBER = "Contains a number";
export const PASSWORD_SPECIAL = "Contains a special character";
export const CONFIRM_PASSWORD = "Confirm Password";
export const CONFIRM_PASSWORD_SUBTITLE = "Please comfirm password.";
export const PASSWORD_NOT_MATCH = "Please type the same password in both text boxes.";
export const ROLE = "Role";
export const ROLE_SUBTITLE = "Please select one option.";
export const FIRST_NAME = "First Name";
export const FIRST_NAME_SUBTITLE = "Please fill first name.";
export const MIDDLE_NAME = "Middle Name";
export const MIDDLE_NAME_SUBTITLE = "Please fill middle name.";
export const LAST_NAME = "Last Name";
export const LAST_NAME_SUBTITLE = "Please fill last name.";
export const EMAIL = "Email";
export const EMAIL_SUBTITLE = "Please fill email.";
export const PHONE = "Phone";
export const PHONE_SUBTITLE = "Please fill phone.";
export const JOIN_POLL = "Join";
export const SCAN_QR = "Scan QR Code";
export const CLEAR = "Clear";
export const APPLY = "Apply";


export const USER_COMPLETE_POLL_TITLE = "You're all Set!";
export const NEW_USER_COMPLETE_POLL_TITLE = "You’re All Done! Results Will Be Available Once the Poll Closes.";
export const USER_COMPLETE_POLL_SUBTITLE = "Results will be available after the poll closes.";
export const USER_COMPLETE_POLL_REATTEMPT_SUBTITLE = "Results will be available after the poll closes. Click Reattempt if you would like to retake the poll.";
export const USER_COMPLETE_SURVEY_TITLE = "Thank You for Completing the Survey";

export const UNLIMITED_ATTEMPTS_TEXT = "If you would like to retake the poll, click Reattempt.";
export const UNLIMITED_ATTEMPTS_SURVEY_TEXT = "Click Retry to reattempt the survey.";
export const MAX_ATTEMPTS_REACHED = "You have reached the maximum number of attempts for this poll.";
export const MAX_ATTEMPTS_REACHED_SURVEY = "You have reached the maximum number of attempts for this survey.";
export const REATTEMPT_OUT_OF = "out of";
export const REATTEMPT_REMAINING_TEXT = "attempts have been used. You can re-attempt the poll by clicking Retry.";
export const RETRY_TEXT = "Reattempt";
export const RESULT_FOR = " Result For ";

export const SUBMIT_TEXT = "Submit";
export const POLL_TEXT = "Poll";
export const SURVEY_TEXT = "Survey";

export const HAS_JOINED_POLL = "Has just joined the poll";
export const HAS_JOINED_ATTENDANCE = "Has just joined the attendance";

export const RECORD_ATTEMPT = {
    ALL: {value: 'all', label: 'All attempts'},
    RECENT: {value: 'recent', label: 'Most recent attempt'},
}

export const LMS_GRADEBOOK_SYNC = { // for platform setting
    FIRST: {value: 'first', label: 'First Attempt'},
    RECENT: {value: 'recent', label: 'Most Recent Attempt'},
    HIGHEST: {value: 'highest', label: 'Attempt with Highest Score'}
}

export const SYNC_ATTEMPT = { // other cases
    FIRST: {value: 'first', label: 'Send first attempt to LMS'},
    RECENT: {value: 'recent', label: 'Send most recent attempt to LMS'},
    HIGHEST: {value: 'highest', label: 'Send highest-score attempt to LMS'}
}

export const DEFAULT_POLL_LMS_SYNC = LMS_GRADEBOOK_SYNC.FIRST.value;
export const DEFAULT_POLL_RECORD_ATTEMPTS = RECORD_ATTEMPT.ALL.value;

// Support Center:
export const YUJA_HELP = "YuJa Help";
export const YUJA_HELP_TEXT = "We're here to help! For product questions and assistance, please select your preferred support option.";
export const TOLL_FREE_PHONE = "Toll-Free Phone";
export const SEND_EMAIL = "Send Email";
export const SEND_MESSAGE = "Send Message";
export const PRODUCT_DOCS = "Product Docs";
export const YUJA_SUPPORT = "YuJa Support";
export const YUJA_SUPPORT_TEXT = "YuJa offers live product support to all users. Please select the phone number closest to you.";
export const US_CANADA_TEXT = "US & Canada";
export const US_CANADA_PHONE = "+1 (888) 257 2278";
export const UK_TEXT = "United Kingdom";
export const UK_PHONE = "+44 (800) 368 8712";
export const NED_TEXT = "Netherlands";
export const NED_PHONE = "+31 (800) 280 0355";
export const INT_TEXT = "International";
export const INT_PHONE = "+1 (408) 457 1036";
export const EMAIL_SUPPORT_BODY = "Our Support Team aims to provide you with a response within 24 hours.";
export const EMAIL_SUPPORT_PREFIX = "To reach YuJa Support via email, please contact";
export const SUPPORT_EMAIL_ID = "support@yuja.com";
export const MESSAGE_SUPPORT_BODY = "Our Support Team aims to provide you with a response within 24 hours.";
export const NAME_TEXT = "Name";
export const EMAIL_TEXT = "Email";
export const SUBJECT_TEXT = "Subject";
export const MESSAGE_TEXT = "Message";

// Gradebook:
export const GRADE_BOOK_POLL_NO_RESULTS_FOUND_EXPLAIN = "Try adjusting your search criteria. If no results appear, the poll may not be released or responded to yet.";
export const GRADE_BOOK_ATTENDANCE_NO_RESULTS_FOUND_EXPLAIN = "Try adjusting your search criteria. If no results appear, the poll may not be released or responded to yet.";
export const COURSE_FILTER_LABEL = "Course";
export const COURSE_PLACEHOLDER = "Select a course";
export const SEARCH_BY_COURSE_TEXT = "Search by course";
export const PUBLISHED_POLL_FILTER_LABEL = "Published Poll:";
export const SELECT_TEXT = "Select";
export const SEARCH_A_POLL_TEXT = "Select a poll";
export const SEARCH_BY_POLL_TEXT = "Search by poll";
export const SEARCH_BY_PARTICIPANT_TEXT = "Search by participant";
export const GRADEBOOK_POLL_TITLE_DESCRIPTION = "Select a poll to view the results of completed attempts."
export const GRADEBOOK_ATTENDANCE_TITLE_DESCRIPTION = "Select a Attendance to view the results of completed attempts."
export const ATTENDANCE_FILTER_LABEL = "Attendance";
export const ATTENDANCE_PLACEHOLDER = "Select an attendance";
export const SEARCH_BY_ATTENDANCE_TEXT = "Search by attendance";
export const EXPORT_GRADEBOOK_TEXT = "Export Grade Book";
export const DOWNLOAD_RESULTS_TEXT = "Download Results (.csv)";
export const LMS_EXPORT_PREFIX = "Download for ";
export const LMS_EXPORT_SUFFIX = " (.csv)";
export const MOODLE_GRADEBOOK_TEXT = "Moodle Gradebook";
// export const BLACKBOARD_GRADEBOOK_TEXT = "Blackboard Gradebook";
export const CANVAS_GRADEBOOK_TEXT = "Canvas Gradebook";
export const BLACKBOARD_GRADEBOOK_TEXT = "Blackboard Gradebook";
export const STUDENT_HEADER = "Student";
export const IP_ADDRESS_HEADER = "IP Address";
export const RESPONDED_BY_HEADER = "Responded By";
export const PLATFORM_HEADER = "Platform";
export const ID_HEADER = "ID";
export const USER_ID_HEADER = "User ID";
export const SIS_USER_ID_HEADER = "SIS User ID";
export const SIS_LOGIN_ID_HEADER = "SIS Login ID";
export const SECTION_HEADER = "Section";
export const POLL_TITLE_PREFIX = "External Tool: ";
export const OVERALL_RESULT_HEADER = "Overall Result";
export const SYNC_GRADEBOOK_TEXT = "Sync Gradebook";
export const STUDENT_SYNC = "On-Demand LMS Student Gradebook Sync";
export const QUIZ_SYNC = "On-Demand LMS Quiz Gradebook Sync";
export const HEADER = 'Header'; // for react-table v7
// export const HEADER_NEW = 'header'; // for react-table v8
export const CELL = 'Cell'; // for react-table v7
// export const CELL_NEW = 'cell'; // for react-table v8
export const IP_ADDRESS_COLUMN_NAME = 'Ip Address';
export const CHECKBOX_COLUMN_ID = 'selection';
export const OPERATION_COLUMN_ID = 'operation';
export const SERIAL_NO_COLUMN_NAME = 'No.';
export const SERIAL_NO_COLUMN_ID = 'serialNo';
export const DATE_COLUMN_NAME = 'Date';
export const DATE_COLUMN_ID = 'dateTitle';
export const STUDENT_COLUMN_NAME = 'Participant';
export const STUDENT_COLUMN_ID = 'student';
export const STUDENT_COLUMN_INDEX = 0;
export const RESPONSE_COLUMN_NAME = 'Response';
export const RESPONSE_COLUMN_ID = 'response';
export const GEOFENCE_COLUMN_NAME = 'Geofence';
export const GEOFENCE_COLUMN_ID = 'inGeofence';
export const GEOFENCE_COLUMN_INDEX = 2;
export const SCORE_COLUMN_NAME = 'Percentage';
export const SCORE_COLUMN_ID = 'score';
export const REAL_SCORE_COLUMN_NAME = 'Score';
export const REAL_SCORE_COLUMN_ID = 'RealScore';
export const ATTENDANCE_TITLE_COLUMN_NAME = 'Title';
export const ATTENDANCE_TITLE_COLUMN_ID = 'attendanceTitle';
export const ATTENDANCE_STATUS_COLUMN_NAME = 'Status';
export const ATTENDANCE_STATUS_COLUMN_ID = 'attendance';
export const ATTENDANCE_DETAILS_COLUMN_NAME = 'Details';
export const ATTENDANCE_DETAILS_COLUMN_ID = 'details';
export const USER_ID_COLUMN_NAME = 'User ID';
export const USER_ID_COLUMN_ID = 'userId';
export const USER_ID_COLUMN_INDEX = 3;
export const CORRECT = 'correct';
export const INCORRECT = 'incorrect';
export const ALL_STATUS_OPTION = { value: 'all', label: 'All' };
export const PRESENT_STATUS = 'present';
export const ABSENT_STATUS = 'absent';
export const PRESENT_TEXT = 'Present';
export const ABSENT_TEXT = 'Absent';
export const NOT_GRADED = 'Not Graded'
export const NO_RESPONSE = 'No Response';
export const ATTEMPT = 'Attempt';
export const ATTEMPT_NO_PREFIX = ATTEMPT + ' #';
export const IS_CORRECT_PREFIX = 'isCorrect';
export const IS_GRADED_PREFIX = 'isGraded';
export const ATTEMPTS = 'attempts';
export const RESPONSES = 'responses';
export const DATE_HEADER = "Date";
export const UPDATED_DATE_PK = "updatedDatePK";
export const DELETION_TIME = "deletionTime";
export const DELETION_TIME_HEADER = "Deletion Date";
export const POLL_TITLE_HEADER = "Poll Title";
export const TITLE_HEADER = "Title";
export const POLL_TITLE = "pollTitle";
export const POLL_TYPE_HEADER = "Type";
export const POLL_TYPE_ACCESSOR = "pollType";
export const POLL_SHARE_MODE_ACCESSOR = "pollShareMode";
export const QUESTION_HEADER = "Questions";
export const TOTAL_QUESTIONS = "totalQuestions";
export const ACTIVATION_STATUS_HEADER = "Activation Status";
export const FEEDBACK_HEADER = "Feedback";
export const POLL_SORT_KEY = "pollSortKey";
export const FEEDBACK_KEY = "feedbackCount";
export const ACTION_HEADER = "Actions";
export const ACTION_KEY = "action";
export const POLL_KEY = "pollKey";
export const FEEDBACK_TITLE_HEADER = "Feedback";
export const FEEDBACK_STUDENT_HEADER = "Student Name";
export const QUESTION_BANK_TITLE_HEADER = "Question Bank Name";
export const QUESTION_BANK_ACTION_HEADER = "Action";
export const FEEDBACK_SATISFACTION_HEADER = "Satisfaction";
export const FEEDBACK_CATEGORY_HEADER = "Category";
export const FEEDBACK_DATE_HEADER = "Date";
export const FEEDBACK_FILE_HEADER = "Attachment";
export const FEEDBACK_DETAIL_HEADER = "Details";
export const GRADE_QUESTION_MODAL_TITLE = "Question Grading";
export const STUDENT_RESPONSE_MODAL_TITLE = "Question Response";
export const VIEW_GRADE_RESPONSE_MODAL_TITLE = "Question Responses";
export const QUESTION_TEXT = "Question";
export const GRADE_CORRECTION_TEXT = "Grade Correction";
export const ANSWER_TEXT = "Answer";
export const GRADE_TEXT = "Grade";
export const VIEW_RESPONSE_TOOLTIP_TEXT = "Click to view response";
export const GRADE_RESPONSE_TOOLTIP_TEXT = "Click to view and grade response";
export const GRADE_ATTENDANCE_TOOLTIP_TEXT = "Click to grade attendance.";
export const NOTE_PREFIX = "Note:";
// export const GRADE_MODAL_NOTE_TEXT = "Grades will automatically save when adjusted.";
export const VIEW_MODAL_DESCRIPTION = "View the responses to questions participants have provided.";
export const GRADE_MODAL_DESCRIPTION = "Select a participant and a question to adjust grades. All changes will save automatically.";
export const VIEW_GRADE_RESPONSE_MODAL_DESCRIPTION = "View or grade the responses to questions participants have provided.";
export const VIEW_ALL_TEXT = "View All";
export const GRADE_ATTENDANCE_MODAL_DESCRIPTION = "Choose a participant to grade whether they were present for the attendance based on their location.";
export const NON_COURSE_OPTION_TEXT = "Unpublished to Course";

export const VIEW_TAKEN_POLLS_TITLE = "Activities";
export const YOUR_ANSWER_TITLE = "Your Answer";
export const PLEASE_SELECT_POLL = "Please select an activity";
export const YOUR_ANSWER_LABEL = "Your Answer:";

export const RESULT_CONTAINER_KEY_PREFIX = "resultContainer";
export const WORD_CLOUD_ID = "wordCloud";
export const WORD_CLOUD_DATA = "wordCloudData";
export const EMPTY_WORD_CLOUD_TEXT = "No Responses Yet";
export const NO_RESPONSES_TEXT = "No Responses Yet";
export const QUESTION_IMAGE_ID = "questionImage";

export const UNLIMITED_ATTEMPTS = -1;
export const DEFAULT_TOAST_MESSAGE_DURATION = 5;
export const DEFAULT_PAGINATION_SIZE = 5; // Page Numbers shown in the bottom of the table
export const DEFAULT_ROWS_PER_PAGE = 6; // Rows per page
export const DEFAULT_ATTENDANCE_ROWS_PER_PAGE = 10; // Rows per page
export const PPT_DEFAULT_ROWS_PER_PAGE = 8;
export const POLL_TITLE_CHARACTER_LIMIT = 50;
export const QUESTION_TITLE_CHARACTER_LIMIT = 1000;
export const COURSE_CHARACTER_LIMIT = 50;
export const DEFAULT_PPT_AUTO_SHARE = true;
export const CHOOSE_A_POLL_GRADEBOOK = "Select a Poll";
export const CHOOSE_A_ATTENDANCE_GRADEBOOK = "Select an Attendance to View the Gradebook";
export const GRADEBOOK_DEFAULT_MESSAGE = "Choose a poll that has been released and review each participant's results.";
export const GRADEBOOK_ATTENDANCE_DEFAULT_MESSAGE = "Choose an attendance that has been released and review each participant's results.";
export const ATTENDANCE_GRADEBOOK_DEFAULT_MESSAGE = "Choose an attendance to review results.";
export const STUDENTS_ATTEMPT_LABEL =  "Participant’s Attempt";
export const GRADE_QUESTION_TEXT = "Grade Question";
export const GRADE_QUESTION_EXPLAIN_TEXT = "You can grade whether the question is correct or incorrect.";
export const UNABLE_GRADE_QUESTION_TEXT = "Unable to Grade Question";
export const UNABLE_GRADE_QUESTION_EXPLAIN_TEXT = "The question was not released to participants.";
export const NON_GRADABLE_QUESTION_TEXT = "Non-Gradable Question";
export const NON_GRADABLE_QUESTION_EXPLAIN_TEXT = "This question does not have any points associated with it.";
export const GRADE_BOOK_RESPONSE_STATUS = {
    CORRECT: {
        key: "CORRECT",
        description: "Correct",
        icon: CORRECT_ICON,
        order: 1
    },
    INCORRECT: {
        key: "INCORRECT",
        description: "Incorrect",
        icon: INCORRECT_ICON,
        order: 2
    },
    NON_GRADABLE: {
        key: "NON_GRADABLE",
        description: "Non-gradable",
        icon: NON_GRADABLE_ICON,
        order: 3
    },
    UN_GRADED: {
        key: "UN_GRADED",
        description: "Ungraded",
        icon: UN_GRADED_ICON,
        order: 4
    },
    UNANSWERED: {
        key: "UNANSWERED",
        description: "Unanswered",
        icon: UNANSWERED_ICON,
        order: 5
    },
    NOT_POSTED: {
        key: "NOT_POSTED",
        description: "Question not shared",
        icon: NOT_POSTED_ICON,
        order: 6
    },
    UNDEFINED: {
        key: "UNDEFINED",
        description: "Undefined response",
        icon: "",
        order: 7
    }

};

export const FIXED_ANSWER = {
    UNANSWERED: "unanswered",
    OTHER: "other"
}
export const UNANSWERED = "Unanswered";
export const ANSWERED = "Answered";
export const IN_PROGRESS = "In progress";
export const NON_GRADED = "Non-Gradable";
export const UNGRADED = "ungraded";
export const UNGRADED_Cap = "Ungraded";

export const POLL_SHARE_MODE = {
    SHARE_ALL: "shareAll",
    SHARE_EACH: "shareEach",
    SCHEDULE: "schedule",
    UNKNOWN: "unknown"
}

export const POLL_STATUS_ENUM = {
    SHARE_ALL_GRADED_POLL: "Released Entire Poll", //shareAll and poll type equal to Graded poll
    SHARE_ALL_SURVEY: "Released Entire Survey", //shareAll and poll type equal to Survey
    SHARE_EACH: "Released by Question", //share each
    SCHEDULE: "Scheduled Release", //poll type equal to schedule
    READY_TO_RELEASE: "Ready to Release", //poll type equal to unknown
    UNFINISHED: "Unfinished Draft", // sort key is draft
}

export const USER_CREATION_FIELDS = {
    USERNAME: "userName", 
    FIRST_NAME: "firstName", 
    MIDDLE_NAME: "middleName", 
    LAST_NAME: "lastName",
}

export const ASCII_CODE_A = 65;
export const ASCII_CODE_SMALL_A = 97;

export const MENU_LIST = {
    LOGIN: {
        name: "Login",
        path: "/",
        icon: Login,
        activeIcon: Login,
        gradientIcon: Login,
        roles: [ROLES.ANONYMOUS.value,]
    },
    HOME: {
        name: "Home",
        path: "/home",
        icon: Home,
        activeIcon: ActiveHome,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value, ROLES.VIEWER.value]
    },
    CREATE: {
        name: "Create",
        path: "/create",
        altPath: "/edit",
        icon: CreatePoll,
        activeIcon: ActiveCreatePoll,
        subStates: {
            [POLL_TYPE.MERGED_POLL]: {
                name: "Poll",
                icon: CreateMergedPoll,
                activeIcon: ActiveCreateMergedPoll
            },
            [POLL_TYPE.ATTENDANCE]: {
                name: "Attendance",
                icon: CreateAttendanceIcon,
                activeIcon: ActiveCreateAttendance
            }
        },
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    // JOIN_POLL: {
    //     name: "Join Poll",
    //     path: "",
    //     icon: JoinPoll,
    //     activeIcon: ActiveJoinPoll,
    //     gradientIcon: GradientJoinPoll,
    //     roles: [ROLES.ANONYMOUS.value, ROLES.VIEWER.value]
    // },
    ACTIVITIES: {
        name: "Activities",
        path: "/poll/user",
        parentPage: "/poll",
        icon: ManagePolls,
        activeIcon: ActiveManagePolls,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    ACTIVITIES_STUDENT: {
        name: "Activities",
        path: "/student/polls",
        icon: ManagePolls,
        activeIcon: ActiveManagePolls,
        subStates: {
            "Graded Poll": {
                name: "Poll",
                icon: PollGradeBookIcon,
                activeIcon: ActivePollGradeBookIcon
            },
            [POLL_TYPE.ATTENDANCE]: {
                name: "Attendance",
                icon: AttendanceGradeBookIcon,
                activeIcon: ActiveAttendanceGradeBookIcon
            }
        },
        roles: [ROLES.VIEWER.value]
    },
    GRADEBOOK: {
        name: "Grade Book",
        path: "/gradebook",
        icon: GradeBook,
        activeIcon: ActiveGradeBook,
        subStates: {
            "Graded Poll": {
                name: "Poll",
                icon: PollGradeBookIcon,
                activeIcon: ActivePollGradeBookIcon
            },
            [POLL_TYPE.ATTENDANCE]: {
                name: "Attendance",
                icon: AttendanceGradeBookIcon,
                activeIcon: ActiveAttendanceGradeBookIcon
            }
        },
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },

    REPORT: {
        name: "Report",
        path: "/report/user",
        parentPage: "/report",
        icon: Report,
        activeIcon: ActiveReport,
        roles: [ROLES.CREATOR.value]
    },

    REPORT_INST: {
        name: "Report",
        path: "/report/inst",
        parentPage: "/report",
        icon: Report,
        activeIcon: ActiveReport,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value]
    },

    RECYCLEBIN: {
        name: "Recycle Bin",
        path: "/recyclebin",
        icon: Recycle,
        activeIcon: ActiveRecycle,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    USERS: {
        name: "Users",
        path: "/users",
        icon: ManageUsers,
        activeIcon: ActiveManageUsers,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value]
    },
    QUESTION_BANKS: {
        name: "Question Banks",
        path: "/QuestionBank",
        icon: ManageQuestionBanks,
        activeIcon: ActiveQuestionBanks,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    COURSES: {
        name: "Courses",
        path: "/course/user",
        icon: ManageCourses,
        activeIcon: ActiveManageCourses,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    COURSES_STUDENT: {
        name: "Courses",
        path: "/student/courses",
        icon: ManageCourses,
        activeIcon: ActiveManageCourses,
        gradientIcon: GradientManageCourses,
        roles: [ROLES.VIEWER.value]
    },
    INTEGRATIONS: {
        name: "Integrations",
        path: "/admin/integration",
        icon: Integration,
        activeIcon: ActiveIntegration,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    SETTINGS: {
        name: "Settings",
        path: "/admin/platformsetting",
        icon: Settings,
        activeIcon: ActiveSettings,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    SETTINGS_STUDENT: {
        name: "Settings",
        path: "/student/settings",
        icon: Settings,
        activeIcon: ActiveSettings,
        gradientIcon: GradientSettings,
        roles: [ROLES.VIEWER.value]
    },
};

export const PPT_MENU_LIST = {
    CREATE: {
        name: "Create",
        path: "/create",
        icon: CreatePoll,
        activeIcon: ActiveCreatePoll,
        roles: [ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    ACTIVITIES: {
        name: "Activities",
        path: "/polls",
        icon: ManagePolls,
        activeIcon: ActiveManagePolls,
        roles: [ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
};

export const VIEWER_WEBSOCKET_MESSAGE = {
    SHARE: "share",
    SHARE_ALL: "shareAll",
    PAUSE: "pause",
    RESUME: "resume",
    STOP: "stop",
    STOP_ALL: "stopAll",
    STOP_SCHEDULED: "stopScheduled",
    LOCK: "lock",
    UPDATE_SCHEDULE_TIME: "updateScheduleTime",
    UPDATE_POLL_TIME: "updatePollTime",
    RESET: "reset"
}

// CreateQuizSidebar:
export const QUESTION_DASHBOARD = "Question Dashboard";
export const SWITCH_POLL_MODAL_TITLE = "Activity Not Saved";
export const LOSE_CHANGE_MESSAGE = "The existing questions will not be kept. Do you wish to proceed?";
export const SWITCH_POLL_MESSAGE = "Your activity has not been saved. Do you wish to proceed?";
export const SWITCH_POLL_MODAL_TEXT = "Yes";

export const QUESTION_PROFILE_MESSAGE = "Are you sure you want to permanently delete the Question Bank named ";

export const QUESTION_PROFILE_TITLE = "Delete Question Bank";

export const GRADED_POLL = "Graded Poll";
export const SURVEY = "Survey";

export const CREATE_POLL_PATH = "/create";
export const EDIT_POLL_PATH = "/edit";
export const EDIT_QUESTION_BANK_PATH = "/questions/edit";
export const JOIN_POLL_PATH = "/join";
export const GRADEBOOK_PATH = "/gradebook";
export const TAKEN_ACTIVITIES_PATH = "/student/polls";

export const YUJA_TOKEN_EXPIRED_ALERT_TITLE = "Session Expired";
export const YUJA_TOKEN_EXPIRED_ALERT_CONTENT = "Your session has expired. Please click OK to log in or refresh the page if you're within a LMS.";
export const YUJA_TOKEN_EXPIRED_ALERT_OK_BUTTON = "OK";

export const CONFIRM_UNANSWERED_SUBMIT_TITLE = "Submit Unanswered Question";
export const CONFIRM_UNANSWERED_SUBMIT_CONTENT = "You have not answered this question. Are you sure you want to submit?";


// Question Banks
export const CREATE_QUESTION_PROFILE_MODAL_TITLE = "Save as Question Bank";
export const CREATE_QUESTION_PROFILE_MODAL_TEXT = "Enter a name to save existing questions as a new Question Bank.";
export const CREATE_QUESTION_PROFILE_MODAL_INPUT = "Question Bank Name";
export const SELECT_ALL_TEXT = "Select All";
export const CLEAR_ALL_TEXT = "Clear All";
export const QUESTION_PROFILE_MODAL_TEXT = "Import";
export const QUESTION_PROFILE_MODAL_TITLE = "Question Bank";
export const QUESTION_PROFILE_MODAL_MESSAGE = "Choose a Question Bank from the list below to add questions to your poll."
export const QUESTION_PROFILE_MODAL_SUB_TITLE = "View Question Banks";
export const QUESTION_PROFILE_MODAL_SEARCH_BAR_PLACEHOLDER = "Search by question bank name";
export const GO_BACK_TEXT = "Go Back";
export const IMPORT_BANK_TEXT = "Import from Bank";
export const QUESTION_PROFILE_ACTION_ONE = "Edit";
export const QUESTION_PROFILE_ACTION_TWO = "Delete";
export const QUESTION_BANK_ACTION_SHARE = "Share";
export const QUESTION_PROFILE_IMPORT_MESSAGE = "Select questions from the list below and Import them.";
export const QUESTION_PROFILE_IMPORT_EDIT = "Edit Bank";
export const QUESTION_BANK_TITLE_PLACEHOLDER = "Question Bank Title";
export const EDIT_QUESTION_BANK = "Edit Question Bank";
export const QUESTION_BANK_NAME_HEADER = "Question Bank Name";
export const QUESTIONS_HEADER = "Questions";

export const ADD_QUESTION_TEXT = "Add Question";

export const CREATE_QUESTION_DETAIL_SIDEBAR_TITLE = "Question Details";

export const POLL_QUESTION_TITLE = "Poll Questions";
export const NO_QUESTION_ADDED = "No Questions added yet"


// CreateQuizDetailSidebar:
export const ENTRIES_TITLE = "Entries per Participant";
export const ENTRIES_SUBTITLE = "Enter maximum entries per participant.";
export const CUSTOM_TEXT = "Custom";
export const ENTRY_TEXT = "Entry";
export const ENTRIES_TEXT = "Entries";
export const CUSTOM_ENTRIES_FOOTER = "Max 10";

// Question options:
export const QUESTION_DEFAULT_OPTIONS = {
    MCSS: '{"a":"","b":"","c":"","d":""}',
};
export const OPTION_DELETE_ALT_TEXT = "delete icon";
export const OPTION_DELETE_TITLE = "delete icon";
export const OPTION_UPLOAD_TITLE = "upload icon";
export const DRAG_TITLE = "Drag to Reorder";

// Matching
export const PREMISE = "Premise";
export const RESPONSE = "Student Keyword Response";

//Tab Participant options
export const TAB_PARTICIPANTS_CORRECT = "Correct";
export const TAB_PARTICIPANTS_INCORRECT = "Incorrect";
export const TAB_PARTICIPANTS_PRESENT = "Present";
export const TAB_PARTICIPANTS_ABSENT = "Absent";
export const TAB_PARTICIPANTS_NO_RESPONSE = "No Response";
export const TAB_PARTICIPANTS_UNANSWERED = "Unanswered";

// Student Gradebook
export const VIEW_TEXT = "View";
export const VIEW_ANSWER = "View Answer";

// Courses:
export const COURSES = "Courses";
export const CREATE_COURSE = "Create Course";
export const COURSE_SEARCH_PLACEHOLDER = "Search by Course Name or Course Code";
export const NO_RESULTS_FOUND = "No Results Found";
export const NO_RESULTS_FOUND_EXPLAIN = "Please check your filter settings and try again.";
export const COURSE_NAME = "Course Name";
export const COURSE_CODE = "Course Code";
export const ENROLLED_MEMBERS = "Enrolled Members";
export const VIEW_COURSE = "View Course";
export const EDIT_TEXT = "Edit";
export const NO_COURSES_TITLE = "No Courses Available";
export const NO_COURSES_DESC = "You have not been enrolled in a course.";

// Polls:
export const POLLS = "Activities";
export const CREATE_POLL = "Create Poll";
export const NO_POLLS_TEXT = "There are no activities to display.";
export const CREATE_POLL_TEXT = "Press the button below to create an activity.";
export const OVERRIDE_IMAGE_TEXT = "The activity only allows one image or video per question. Do you wish to replace the image or video currently associated with this question?";
export const OVERRIDE_IMAGE_TITLE = "Replace Image or Video"; 
export const OVERRIDE_IMAGE_BUTTON = "Continue";
export const TOTAL_POLLS = "Total Activities";
export const TOTAL_GRADED = "Total Polls";
export const TOTAL_SURVEYS = "Total Surveys";
export const TOTAL_ATTENDANCES = "Total Attendances";
export const DUPLICATE_TEXT = "Duplicate";
export const SCHEDULE_TEXT = "Schedule";
export const POLL_STATUS_HEADER = "Status";
export const UNFINISHED = "Unfinished Draft";
export const UNFINISHED_CHOOSER = "Draft";
export const READY_TO_SHARE = "Ready to Release";
export const SHARED_BY_QUESTION = "Released by Question";
export const SHARED_ENTIRELY = "Released Entire Activity";
export const SHARED_ENTIRE_POLL = "Released Entire Poll";
export const SHARED_ENTIRE_SURVEY = "Released Entire Survey";
export const SCHEDULED = "Scheduled Release";

// Poll Search Form:
export const START_DATE = "Start Date";
export const END_DATE = "End Date";

// Taken Polls:
export const NUMBER = "Number";
export const TYPE = "Type";
export const ATTENDANCE_PRESENT_RESULT = "You have been marked as present.";
export const ATTENDANCE_ABSENT_RESULT = "You have been marked as absent.";
export const ATTENDANCE_NOT_GRADED_TITLE = "Attendance Not Graded";
export const ATTENDANCE_NOT_GRADED_RESULT = "Your attendance has not been graded yet. Please check back later.";

// Users:
export const USERS = "Users";
export const TOTAL_USERS = "Total Users";
export const TOTAL_IT_MANAGERS = "Total IT Managers";
export const TOTAL_INSTRUCTORS = "Total Creators";
export const TOTAL_STUDENTS = "Total Participants";
export const CREATE_USER = "Create User";
export const DATE_CREATED = "Date Created";
export const LAST_ACCESSED = "Last Accessed";
export const OPERATION = "Operation";
export const UPDATE_USER = "Update User";
export const SEARCH_USER = "Search by Username, First Name, Last Name, Full Name or Email";

// Platform Setting
export const PLATFORM_SETTING_SAVE_ERROR_MSG = "Can not set YuJa Login as default credentials when YuJa Login method is disabled!";
export const LOGIN_SETTINGS_ERROR_MSG = "In order to disable the YuJa Engage credential as a stand-alone option, you need to first set up an authentication option with your campus Single Sign On. For more information on how to configure an SSO authentication with Engage, please go to support.yuja.com.";
export const LMS_AUTHENTICATION_LABEL = "Require users to authenticate through LMS before accessing poll.";
export const YUJA_DISABLE_LABEL = "Disable YuJa Login Credentials";
export const PWD_EXPIRY_LABEL ="Require a password reset for all users after 12 months.";
export const TOAST_MESSAGE_SETTINGS = "Toast Message Settings";
export const ATTENDANCE_DETAILS_SETTINGS = "Attendance Details Settings";
export const ATTENDANCE_SETTINGS = "Attendance Settings";
export const ATTENDANCE_SETTINGS_CARD_SUB_TITLE = "Allow creators on your platform to choose from pre-set geofenced locations when creating an attendance.";
export const ATTENDANCE_SETTINGS_GEO_LOCATION_SUB_TITLE = "Add and edit geofenced locations for attendance tracking.";
export const PLATFORM_SETTING_SAVED_LOCATIONS_MODAL_DESCRIPTION = "You can add up to five geofenced locations to your platform.";
export const SURVEY_DETAILS_SETTING = "Survey Details Settings";
export const QUESTION_DETAILS_SETTINGS = "Question Details Settings";
export const POLL_DETAILS_SETTINGS = "Poll Details Settings";
export const USER_TOAST_MESSAGE_TOOLTIP = "How long the toast message should display until it disappears.\n" +
"If this option is set to manual dismissed, the toast will not disappear until the user closes it manually.";
export const USER_TOAST_MESSAGE_LABEL = "Customize the time for viewing toast messages.";
export const UPLOAD_IMAGE_ERROR = "Please upload an image with one of the following extensions: PNG, JFIF, PJPEG, JPG, JPEG, or PJP.";
export const UPLOAD_IMAGE_TEXT = "Upload Image";
export const UPLOAD_MEDIA_TEXT = "Upload Media";
export const DRAG_DROP_IMAGE_TEXT = "Drag and Drop Image";
export const CHOOSE_MEDIA_TEXT = "Choose Media";
export const CHOOSE_FILE_TEXT = "Choose File";
export const UPLOAD_IMAGE_DESCRIPTION = "Upload an image to mark regions on it as options. You can then select one or multiple regions as the correct answer.";
export const SAVE_CHANGES_TEXT = "Save Changes";
export const USER_ATTENDANCE_MESSAGE_LABEL = "Choose the default settings that will be applied to attendances.";
export const USER_QUESTION_MESSAGE_LABEL = "Choose the default settings that will be applied to each question.";
export const USER_POLL_MESSAGE_LABEL = "Choose the default settings for when Polls are released.";
export const QUESTION_DURATION_LABEL = "Time Limit";
export const ATTENDANCE_DURATION_LABEL = "Time Limit";
export const QUESTION_POINT_LABEL = "Points";
export const COUNTDOWN_TIMER_LABEL = "Countdown Timer"; 
export const MAXIMUM_ENTRIES_LABEL  = "Maximum Entries Per Participant";
export const CLASS_RESULT_DURATION_LABEL  = "Class Result Duration";
export const PARTICIPANTS_LABEL = "Participants";
export const HOURS = "Hours";
export const MINUTES = "Minutes";
export const SECONDS = "Seconds";
export const CLASS_RESULT_DURATION_DESCRIPTION_SUFFIX = "to view class result after each question.";
export const POLL_BREAK_DURATION_LABEL  = "Poll End Countdown";
export const POLL_BREAK_DURATION_SUFFIX = " to complete their poll after it is stopped manually.";
export const POLL_BREAK_DURATION_ATTENTION_FORMAT  = "Your {pollType} will automatically be submitted in {time}.";
export const SAVE_ALL_CHANGES_TEXT  = "Save All Changes";




// Keynote presentation question page
export const PRESENTATION_QUESTION_PAGE_TITLE = "YuJa Engage";
export const PRESENTATION_QUESTION_POLL_UNAVAILABLE_TITLE = "POLL UNAVAILABLE";
export const PRESENTATION_QUESTION_POLL_UNAVAILABLE_TEXT = "Please try logging in as the poll owner.";
export const PRESENTATION_QUESTION_INTERNAL_ERROR_TITLE = "INTERNAL ERROR";
export const PRESENTATION_QUESTION_INTERNAL_ERROR_TEXT = "Please contact YuJa Help Center.";


//course API 

export const INVALID_COURSE_CODE = "Invalid courseCode";
export const INVALID_COURSE_NAME = "Invalid courseName";

export const serialNo_TEXT = 'serialNo'; 
export const POLL_TITLE_TEXT = 'pollTitle';
export const BANK_TITLE_TEXT = "ProfileTitle";
export const UNIQUECODE_TEXT = "uniqueCode";
export const SUBMITED_QUESTION_NO_TEXT = "submittedQueNo"; 

export const DELETE_CONFIRMATION = "Are you sure you want to delete it?";
export const DELETE_COURSE_CONFIRMATION = "Are you sure you want to delete this course?";
export const DELETE_USER_CONFIRMATION = "Are you sure you want to delete the user and everything associated with this user?";
export const DELETE_MULTIPLE_USER_CONFIRMATION = "Are you sure you want to delete them?";

// schedule poll 
export const ATTENTION_TEXT = "Attention: ";
export const SCHEDULE_POLL_TIME_MESSAGE = "Set the length of time for the Poll (HH:MM:SS) :";
export const SCHEDULE_POLL_EDIT_MODAL_TITLE = "Update Release Schedule"; 
export const SCHEDULE_POLL_EDIT_MODAL_MESSAGE = "Update the scheduled poll's Start Date, End Date, and the length of time participants will have to complete the poll once they join. ";
export const SCHEDULE_POLL_OPTION = "Schedule Poll"; 
export const SCHEDULE_POLL_SHARE_MODE = "schedule"; 
export const SCHEDULE_POLL_OPEN_MESSAGE = "The Poll Will Open on ";
export const SCHEDULE_START_DATE_PLACEHOLDER = "Start Date"; 
export const SCHEDULE_END_DATE_PLACEHOLDER = "End Date"; 
export const SCHEDULE_ADD_START_DATE = "Start Time"; 
export const SCHEDULE_ADD_END_DATE = "End Time"; 
export const SCHEDULE_POLL_LABEL = "To schedule a poll, choose the dates when it will be available and specify the length of time participants will have to complete the poll once they join.";
export const SCHEDULE_FROM_TEXT = "From"; 
export const SCHEDULE_TO_TEXT = "To"; 
export const SCHEDULE_TIME_AM = "AM"; 
export const SCHEDULE_TIME_PM = "PM";
export const SCHEDULE_POLL_EXPIRED_TITLE_START = "The scheduled poll was available from";
export const SCHEDULE_POLL_EXPIRED_TITLE_MID = "to";
export const SCHEDULE_POLL_EXPIRED_TITLE_END = "and has now closed.";
export const SCHEDULE_POLL_LIVE_TITLE = "The scheduled poll is now live to participants. It will close on";
export const SCHEDULE_POLL_FUTURE_TITLE_PREFIX = "The Poll will automatically be shared with students on";
export const UPDATE_RELEASE_SCHEDULE_PREFIX = "You can modify the Poll by clicking ";
export const UPDATE_RELEASE_SCHEDULE = "Update Release Schedule.";
export const SURVEY_END_TITLE = "The survey will end on ";
export const ATTENDANCE_END_TITLE = "The attendance will end on ";

// Ended Poll:
export const REATTEMPT_CLOSED_POLL = "The poll is no longer available for you to join.";
export const REATTEMPT_CLOSED_SURVEY = "If you would like to re-attempt the survey, please reach out to your instructor directly.";
export const CLOSED_GRADED_POLL = "Poll Has Ended";
export const CLOSED_SURVEY = "The survey is now closed.";
export const SUPPORT_PREFIX = "For technical questions, please reach out to";
export const YUJA_ENGAGE_SUPPORT = "YuJa Engage Support";
export const DO_NEED_ASSISTANCE_TEXT = "Do You Need Assistance";
export const PLEASE_CONTACT_SUPPORT__PREFIX = "Please contact ";
export const PLEASE_CONTACT_SUPPORT__SUFFIX = " if you are experiencing any technical issues.";


//create poll page
export const SHOW_CORRECT_ANSWER_CHECKBOX_LABEL = "Show correct answers to participants";
export const FILE_SIZE_TOO_LARGE = "Your file size is too big. Please upload a file size of 50MB or smaller.";
export const UNLIMITED_TIME_INPUT_DESCRIPTION = "Enter “0” to give participants unlimited time.";
export const UNGRADED_POINT_INPUT_DESCRIPTION = "For ungraded polls, enter a Points value of 0.";
export const MH_POINTS_DESCRIPTION = "Enter the number of points this question is worth. Points cannot be 0.";
export const RK_POINTS_DESCRIPTION = "Ranking questions cannot be graded.";
export const WC_POINTS_DESCRIPTION = "Word Cloud questions cannot be graded.";
export const OE_POINTS_DESCRIPTION = "Open Discussion questions cannot be graded.";
export const REPLACE_TEXT = "Replace";
export const IMAGE_ALT_TEXT = "Alt Text";
export const CORRECT_ANY_DESCRIPTION = "Full points for any correct answer";


//Poll Filtering 
export const FILTER_POLLS = "Filter Activities";
export const ACTIVATED_STATUS = "Activated";
export const ALL_MODE = "All Mode";
export const GRADED_MODE = "Graded Poll";
export const GRADED_SHORT = "Graded";
export const MERGED_MODE = "Poll";
export const ATTENDANCE = "Attendance";
export const COURSE_STATUS = "Course"; 
export const DRAFT_STATUS = "Draft"; 
export const ALL_STATUS = "All Status"; 

//Category Filtering 

export const ALL_CATEGORY = "All Categories";
export const ALL_SATISFACTION = "All Satisfaction";

// User Filtering:
export const FILTER_USERS = "Filter Users";
export const ALL_ROLES = "All Roles";
export const ALL_LINKAGE_TYPES = "All Linkage Types";
export const NO_LINKAGE = "No Linkage";
export const LTI = "LTI";
export const LTI_SAML = "LTI SAML";
export const SAML = "SAML";
export const SAML_LTI = "SAML LTI";
export const LINKAGE_TYPES = [NO_LINKAGE, LTI, SAML, SAML_LTI];

export const SEARCH_TEXT = "Search";
export const GET_RESULT_TEXT = "Get Results";

export const WAITING_ROOM_ACTIVITY_TEXT = " Fetching your activity...";
export const WAITING_ROOM_POLL_TEXT = " Waiting for the creator to start the poll.";
export const WAITING_ROOM_SURVEY_TEXT = " Waiting for the creator to start the survey.";
export const WAITING_ROOM_ATTENDANCE_TEXT = " Waiting for the creator to start the attendance.";
export const ATTENDANCE_LOCATION_ACCURACY_WARNING = "This attendance has geofencing enabled. To accurately determine your location, we recommend using a mobile device.";
export const PRESENT_ATTENDANCE_TEXT = "You have been marked as present.";
export const ABSENT_ATTENDANCE_TEXT = "You have been marked absent for not joining the attendance.";
export const WAITING_ROOM_WAIT_POLL_START_TITLE = "Waiting on the Poll to Start";
export const WAITING_ROOM_WAIT_POLL_START_DESC = "The creator will start the poll shortly.";

// Disable anonymous join 
export const DISABLE_ANONYMOUS_SIDE_BAR = "Require participants to enter their names when joining"; 
export const DISABLE_ANONYMOUS_PLATFORM = "Require participants' names when joining"; 
export const INSTITUTION_DISABLE_ANONYMOUS_WARNING = "Please log in to Engage to join"; 
export const POLL_DISABLE_ANONYMOUS_WARNING = "Please enter your name to join."; 
export const POLL_DISABLE_ANONYMOUS_INFO = "The settings have been updated. Please enter your name to join."; 
export const POLL_DISABLE_GUEST_WARNING = "Please log in to Engage to join."; 
export const POLL_DISABLE_GUEST_INFO = "The settings have been updated. Please log in to Engage to join."; 
export const ANONYMOUS_JOIN_SETTING_TEXT = "anonymousJoin"; 
export const ANONYMOUS_JOIN_MODAL_TITLE = "Join Poll";
export const ANONYMOUS_JOIN_MODAL_SUBTITLE = "To join the Activity please enter a name."; 
export const ANONYMOUS_JOIN_MODAL_BUTTON = "Enter Poll";

// LMS authenticate 

export const AUTHENTICATE_LMS_SETTING = "Require users to authenticate through the LMS before accessing Polls";
export const POLL_CODE_PREFIX = "PUB#UC#"


export const EDIT_DATE_ACTION_TEXT = "Edit Open Dates";

// Integration Page
export const INTEGRATION_TYPE_PLACEHOLDER = "Choose Integration Type";
export const LMS_TYPE_PLACEHOLDER = "Choose LMS";
export const ADFS_SSO_URL = "ADFS SSO URL";
export const NAME_FORMAT = "Name ID Format";
export const REMOTE_LOGOUT = "Remote Logout URL";
export const ADFS_CERTIFICATE = "ADFS Signing Certificate Fingerprint";
export const LOGOUT_URL = "Logout URL";
export const GIVEN_NAME = "Given Name Attribute";
export const FAMILY_NAME = "Family Name Attribute";
export const EMAIL_ATTR = "Email Attribute";
export const ROLE_ATTR = "Role Attribute";
export const ROLE_MAPPING = "Role Mapping";
export const IT_MANAGER_MAPPING = "IT Manager Mapping";
export const INSTR_MAPPING = "Instructor Mapping";
export const LINKAGE_ATTR = "Linkage Attribute";
export const LINKAGE_TYPE = "Linkage Type";
export const CROSS_INTEGRATION = "Cross Integration With LTI";
export const AZURE_SSO_URL = "Azure SSO URL";
export const AZURE_CERTIFICATE = "Azure Signing Certificate Fingerprint";
export const DCAM_CERTIFICATE = "DCAM Signing Certificate Fingerprint";
export const DCAM_SSO_URL = "DCAM SSO URL";
export const USER_PROVISION = "User Provisioning";
export const INSTRUCTOR = "Instructor";
export const GENERAL_SSO = "General SSO Details";
export const GOOGLE_SSO_URL = "Google SSO URL";
export const GOOGLE_CERTIFICATE = "Google Signing Certificate Fingerprint";
export const SHIBBOLETH_SSO_URL = "Shibboleth SSO URL";
export const SHIBBOLETH_CERTIFICATE = "Shibboleth Signing Certificate Fingerprint";
export const API_CONFIG = "API Configuration";
export const API_ACCESS = "API Access";
export const ALLOW_API_ACCESS = "Allow API Access";
export const SYNC_SETTINGS = "Synchronization Settings";
export const LINKAGE_SETTINGS = "Linkage Settings";
export const ISS = "Enter ISS";
export const CLIENT_ID = "Enter Client Id";
export const OIDC = "Enter OIDC Endpoint";
export const JWKS = "Enter JWKS Endpoint";
export const OAUTH = "Enter OAuth2 Token Endpoint";
export const DEPLOYMENT = "Enter Deployment Id";
export const USER_CONFIG = "User Configuration";
export const AUTO_PROVISION_USER = "Provision User Accounts";
export const AUTO_PROVISION_USER_DESC = "Creates a user account in Engage when entering the LTI tool, if one does not exist.";
export const AUTO_SYNC_USER = "Sync Data on User Login";
export const AUTO_SYNC_USER_DESC = "Updates the existing user data in Engage when entering the LTI tool.";
export const AUTO_PROVISION_COURSE = "Provision Courses";
export const AUTO_PROVISION_COURSE_DESC = "Creates a course in Engage when entering the LTI tool, if it does not exist.";
export const AUTO_SYNC_COURSE = "Sync Course Data";
export const AUTO_SYNC_COURSE_DESC = "Updates the existing course data in Engage when entering the LTI tool.";
export const COURSE_CONFIG = "Course Configuration";
export const FALL_BACK_TO_EMAIL = "Fallback to email address to cross-match users when no Linkage Attribute matches";
export const FALL_BACK_TO_EMAIL_SAML = "Use Email as Backup Field";
export const FALL_BACK_TO_EMAIL_SAML_DESC = "Fallback to email address to cross-match users when no Linkage Attribute matches.";

export const INTEGRATION_TYPES_LOWERCASE = {
    ADFS: "Adfs",
    SHIBBOLETH: "Shibboleth",
    DCAM: "Dcam",
    GOOGLE: "Google",
    AZURE: "Azure",
    CANVAS: "Canvas",
    BLACKBOARD: "Blackboard",
    MOODLE: "Moodle",
    D2L: "D2L",
};

export const INTEGRATION_TYPES_DETAILS = {
    ADFS: "SSO - ADFS [SAML]",
    SHIBBOLETH: "SSO - Shibboleth [SAML]",
    DCAM: "SSO - DCAM [SAML]",
    GOOGLE: "SSO - Google Apps",
    AZURE: "SSO - Azure",
    LTI: "LMS - LTI 1.3",
    LMS_API: "LMS - API"
};

export const blackboardRoles = [{display: "Student", value: "studentRole"}, {display: "Instructor", value: "instructorRole"}, 
    {display: "Grader", value: "graderRole"}, {display: "Course Builder", value: "courseBuilderRole"}, 
    {display: "Teaching Assistant", value: "teachingAssistantRole"}, {display: "Guest", value: "guestRole"}, 
    {display: "Administrator", value: "adminRole"}, {display: "Other", value: "otherRole"}  
];

export const canvasRoles = [{display: "Administrator", value: "adminRole"}, {display: "Teacher", value: "teacherRole"}, 
    {display: "Teaching Assistant", value: "teachingAssistantRole"}, {display: "Designer", value: "designerRole"}, 
    {display: "Observer", value: "observerRole"}, {display: "Student", value: "studentRole"}
];

export const moodleRoles = [{display: "Student/Course Creator", value: "studentRole"}, {display: "Teacher/Non-editing Teacher/Manager", value: "instructorRole"},  
    {display: "Administrator", value: "adminRole"}
];

export const d2lRoles = [{display: "Student/Course Creator", value: "studentRole"}, {display: "Instructor", value: "instructorRole"},  
    {display: "Administrator", value: "adminRole"}
];

export const LTI_USER_MAPPING = {
    STUDENT: "Student",
    INSTRUCTOR: "Instructor",
    GRADER: "Grader",
    COURSE_BUILDER: "Course Builder",
    TEACHER: "Teacher",
    TEACHING_ASSISTANT: "Teaching Assistant",
    GUEST: "Guest",
    OTHER: "Other",
    ADMIN: "Administrator",
    DESIGNER: "Designer",
    OBSERVER: "Observer",
    STUDENT_COURSE: "Student/Course Creator",
    TEACHER_MANAGER: "Teacher/Non-editing Teacher/Manager"
};

// Share Poll Modal:
export const SHARE_POLL_BODY = "Please choose the members you want to share this poll with.";
export const SHARE_SURVEY_BODY = "Please choose the members you want to share this survey with.";
export const SHARE_QUESTION_BANK_BODY = "Please choose the members you want to share this Question Bank with.";
export const SHARE_TEXT = "Share";

// Poll Time Limit Modal:
export const POLL_TIME_LIMIT_MODAL_TITLE = "Poll Time Limit";
export const SURVEY_TIME_LIMIT_MODAL_TITLE = "Release Survey";
export const ATTENDANCE_TIME_LIMIT_MODAL_TITLE = "Open Attendance";
export const SURVEY_TIMED_DESCRIPTION = "Choose to end the survey manually or set an end date for it to close automatically.";
export const ATTENDANCE_TIMED_DESCRIPTION = "Choose to end the attendance manually or set an end date for it to close automatically.";
export const POLL_TIME_LIMIT_MODAL_SUBTITLE = "Enter the total amount of time participants will have to complete the poll.";
export const POLL_TIME_LIMIT_MODAL_BODY = "Please state the time in the following format: HH:MM:SS";
export const POLL_TIME_LIMIT_MODAL_CANCEL = "Cancel";
export const POLL_TIME_LIMIT_MODAL_CONFIRM = "Release Poll";
export const SURVEY_TIME_LIMIT_MODAL_CONFIRM = "Release Survey";
export const ATTENDANCE_TIME_LIMIT_MODAL_CONFIRM = "Open Attendance";
export const SURVEY_OPTION_TIME_TEXT = "Manually stop the survey";
export const ATTENDANCE_OPTION_TIME_TEXT = "Manually close the attendance";

// Activity Options Modal:
export const POLL_OPTIONS_MODAL_TITLE = "Poll Options";
export const SURVEY_OPTIONS_MODAL_TITLE = "Survey Options";
export const ATTENDANCE_OPTIONS_MODAL_TITLE = "Attendance Options";
export const GRADED_OPTIONS_MODAL_DESCRIPTION = "Edit the option for participants to join the poll and specify how their responses will be recorded.";
export const SURVEY_OPTIONS_MODAL_DESCRIPTION = "Edit the option for participants to join the survey.";
export const ATTENDANCE_OPTIONS_MODAL_DESCRIPTION = "Edit the option for participants to join the attendance.";
export const SHOW_CORRECT_ANSWER = "Show Correct Answer";
export const SHOW_CORRECT_ANSWER_CUSTOM_TEXT = "Show correct answers to participants";
export const AUDIENCE_RESTRICTION_EVERYONE = "Allow all participants to join";
export const AUDIENCE_RESTRICTION_NAME = "Allow only participants with names to join";
export const REQUIRED_NAME_CUSTOM_TEXT = "Require participants' names when joining ";
export const AUDIENCE_RESTRICTION_LOGGED_IN = "Allow only logged-in participants to join";
export const AUDIENCE_RESTRICTION_COURSE = "Allow only course members to join";
// export const POLL_OPTIONS_LIMIT_MODAL_CANCEL = "Cancel";
// export const POLL_OPTIONS_LIMIT_MODAL_CONFIRM = "Save";
export const POLL_OPTIONS_MODAL_SAVE_TOOLTIP_TEXT = "Save changes.";
export const POLL_OPTIONS_MODAL_DISABLED_SAVE_TOOLTIP_TEXT = "Changes cannot be made after releasing the activity.";

// Schedule Modal:
export const SCHEDULE_MODAL_TITLE = "Release Poll";
export const RELEASE_OPTIONS_TEXT = "Release Options";
export const RELEASE_NOW_SUBTITLE = "Participants will be able to join the poll right away.";
export const SCHEDULE_RELEASE_SUBTITLE = "Specify a date and time to release the poll.";
export const DETAILS_TEXT = "Details";
export const POLL_TIME_LIMIT_DESCRIPTION ="The time participants will have to complete the poll: ";
export const EMAIL_NOTIFICAITON_TEXT = "Email Notification";
export const EMAIL_NOTIFICAITON_SUBTITLE = "To notify participants, please select a course by clicking Poll Options on the Release page in the Engage web app and allow a grace period of at least 15 minutes before the start of the poll.";
export const EMAIL_NOTIFICAITON_LABEL_PREFIX = "Participants will be notified";
export const EMAIL_NOTIFICAITON_LABEL_SUFFIX = "prior to the release of the poll.";
export const RELEASE_NOW_OPTION = "Release Now";
export const RELEASE_NOW_LABEL = "Enter the total amount of time participants will have to complete the poll.";
export const NOTIFICATION_DROPDOWN_PLACEHOLDER = "Time";
// export const REMINDER_TIME_OFFSET = (15 * 60 * 1000);
export const EMAIL_NOTIFICATION_OPTIONS = [
    // { value: 0, label: "never" },
    { value: 900, label: "15 mins" },
    { value: 1800, label: "30 mins" },
    { value: 3600, label: "1 hour" },
    { value: 7200, label: "2 hours" },
    { value: 86400, label: "1 day" }
];

// Delete Modal:
export const DELETE_POLL = "Delete Poll";
export const DELETE_SURVEY = "Delete Survey";
export const DELETE_ACTIVITY = "Delete Activity";
export const DELETE_ACTIVITIES = "Delete Activities";
export const RESTORE_ACTIVITY = "Restore Activity";
export const RESTORE_ACTIVITIES = "Restore Activities";
export const DELETE_POLL_MODAL_BODY = "Are you sure you want to delete the activity? Doing so will remove responses recorded in the gradebook.";
export const PERMANENT_DELETE_POLL_MODAL_BODY = "Are you sure you want to permanently delete the selected activity?";
export const PERMANENT_DELETE_POLL_SELECTED_MODAL_BODY = "Are you sure you want to permanently delete the selected activities?";
export const RESTORE_POLL_SELECTED_MODAL_BODY = "Are you sure you want to restore the selected activities?";
export const DELETE_USER = "Delete User";
export const DELETE_USER_MODAL_BODY = "Are you sure you want to delete it?";
export const DELETE_USER_DATA_MODAL_BODY = "Are you sure you want to delete the user and everything associated with this user?";
export const DELETE_COURSE = "Delete Course";
export const DELETE_COURSE_MODAL_BODY = "Are you sure you want to delete this course?";

export const RESTORE_POLL_MODAL_BODY = "Are you sure you want to restore the selected activity?";
// QR Landing Page
export const WELCOME_YUJA_ENGAGE = "Welcome to YuJa Engage";
export const PLEASE_CHOOSE_OPTIONS = "Please choose one of the following options.";
export const POLL_IN_COURSE_LOGIN_AND_JOIN = "The Poll requires you to join using your credentials.";

// Share Poll Page
export const OPEN = "Open ";
export const CLOSE = "Close ";
export const STOP = "Stop ";
export const QUESTION = "Question ";
export const ENTIRELY = "Entirely";
export const ANALYTICS = "Analytics";
export const RESPONSES_HEADER = "Responses";
export const RESPONSES_SUBTITLE_PREFIX = "See breakdown of ";
export const EDIT_QUESTION = "Edit Question";
export const QUESTION_BUILDER = "Question Builder";
export const QUESTION_BUILDER_CAPTION = "Start adding question to the live poll.";
export const DISABLED_QUESTION_BUILDER_CAPTION = "Cannot add questions after poll release.";
export const DISABLED_ATTENDANCE_BUILDER_CAPTION = "The question cannot be edited after the attendance is released.";
export const QUESTION_BUILDER_BUTTON = "Add Question Now";
export const QUESTION_TYPE = "Question Type";
export const OPTIONS = "Options";
export const BLANKS = "Blanks";
export const CORRECT_ANSWER = "Correct Answer";
export const TYPE_CORRECT_ANSWER = "Type Correct Answer in Edit Question Tab";
export const IMAGE = "Upload Image";
export const MEDIA = "Upload Media";
export const ENTER_QUESTION_TITLE = "Enter Question Title";
export const QUESTION_TITLE_PLACEHOLDER = "Enter your Question Title in the Question Builder.";
export const OPTION_PLACEHOLDER_PREFIX = "Type option ";
export const OPTION_PLACEHOLDER_SUFFIX = " in the Question Builder.";
export const MAX = "Max ";
export const MCSS_ADD_OPTION_TEXT = "Add Option";
export const ATTENDANCE_MAP_TEXT = "Click anywhere on the map to adjust the center location of the geofence.";
export const CI_ADD_OPTION_TEXT = "Click anywhere on the image to mark that region as an option.";
export const MH_ADD_OPTION_TEXT = "Provide a premise and a matching response. You can also add distractors, which do not have a matching premise.";
export const QUESTION_BUILDER_SCROLL_DOWN_BUTTON = "View More";
export const QUESTION_BUILDER_SCROLL_UP_BUTTON = "Back to Top";
export const QUESTION_BUILDER_DISCARD_BUTTON = "Discard Changes";
export const QUESTION_BUILDER_DELETE_BUTTON = "Delete";
export const QUESTION_BUILDER_ADD_QUESTION_BUTTON = "Add Question to Live Poll";
export const QUESTION_BUILDER_UPDATE_QUESTION_BUTTON = "Update Question";
export const QUESTION_BUILDER_ADD_TOOLTIP = "Add question to the live poll.";
export const GO_BACK = "View all questions";
export const SELECT_QUESTION_ARIA = "view question ";
export const QUESTION_NAVIGATION_TEXT = "View Question";
export const CUSTOMIZE_POINTS_TEXT = "Points";
export const CUSTOMIZE_POINTS_TOOLTIP_TEXT = "Specify how many points the question is worth.";
export const CUSTOMIZE_POINTS_DISABLED_TOOLTIP_TEXT = "Points cannot be changed after releasing the poll.";
export const CUSTOMIZE_TIME_LIMIT_TEXT = "Timer";
export const CUSTOMIZE_TIME_LIMIT_TOOLTIP_TEXT = "Set a response timer for participants. ";
export const CUSTOMIZE_TIME_LIMIT_DISABLED_TOOLTIP_TEXT = "The Timer cannot be changed after releasing the poll.";
export const CUSTOMIZE_ENTRIES_TEXT = "Entries per Participant";
export const CUSTOMIZE_ENTRIES_TOOLTIP_TEXT = "Specify the number of responses each participant can give.";
export const CUSTOMIZE_ENTRIES_DISABLED_TOOLTIP_TEXT = "The number of responses cannot be changed after releasing the poll.";
export const SHOW_CORRECT_ANSWER_TOOLTIP_TEXT = "View correct answer";
export const HIDE_CORRECT_ANSWER_TOOLTIP_TEXT = "Hide correct answer";
export const CONFIGURE_POLL_OPTIONS_TEXT = "Poll Options";
export const CONFIGURE_SURVEY_OPTIONS_TEXT = "Survey Options";
export const CONFIGURE_ATTENDANCE_OPTIONS_TEXT = "Attendance Options";
export const BROWSER_TYPES = {
    Chrome: "Chrome",
    Firefox: "Firefox",
    Edge: "Edge",
    Safari: "Safari",
    MobileApp: "Mobile App",
    Other: "Other"
};
export const PLATFORM_TYPES = {
    Apple: "Apple",
    iPhone: "iOS",
    Mac: "Mac OS",
    iPad: "iPad OS",
    Windows: "Windows",
    Android: "Android"
};
export const AVG_ENGAGEMENT_TITLE = "Average Engagement";
export const ENGAGEMENT_TITLE = "Engagement";
export const COMPLETION_RATE_TITLE = "Completion Rate";
export const VIEW_POLL_CODE_TEXT = "To join directly, either scan the QR code or copy the link and paste it into your browser.";
export const VIEW_POLL_CODE_MINIMIZED_TEXT = "To join directly, either scan the QR code or copy the link and paste it into your browser.";
export const SHOW_OR_HIDE_CORRECT_ANSWER_TOGGLE_LABEL = "Show Correct Answer";
// export const ASSOCIATE_COURSE_TEXT = "Associate Poll with Course";
// export const ASSOCIATE_COURSE_BODY_TEXT = "To associate the poll with a course, select the course from the list below.";
// export const ASSOCIATE_COURSE_BUTTON_TEXT = "Select Course";
export const RELEASE_POLL_SURVEY_ERROR = "You cannot release this activity. Please duplicate and release it."; // disable release page for Graded Poll and Survey
export const RELEASE_POLL_SURVEY_PPT_ERROR = "The activity cannot be released. Please duplicate the activity and release it."; // disable ppt release page for Graded Poll and Survey
export const UNSUPPORTED_ACTIVITY = "Unsupported Activity";
export const UNSUPPORTED_ACTIVITY_RELEASE_DESC = "This activity is no longer available. Please duplicate and re-embed the activity.";
export const UNSUPPORTED_ACTIVITY_JOIN_DESC = "This activity is no longer available.";
export const RIGHT_PANEL_TABS = {
    ANALYTICS: {name: "ANALYTICS", desc: "Analytics"},
    QUESTION_BUILDER: {name: "QUESTION_BUILDER", desc: "Question Builder"},
    SETTINGS: {name: "SETTINGS", desc: "Activity Settings"}
};
export const RESPONSE_TITLE = "Response";
export const NO_RESPONSE_FOUND_TEXT = "No Responses Found";
export const PARTICIPANTS_TITLE = "Participants";
export const NO_PARTICIPANTS_FOUND_TEXT = "No Participants Found";
export const SAVE_SETTINGS_TEXT = "Save Settings";
export const ACTIVITY_FEED_TITLE = "Waiting for Participants to Join";
export const WAITING_PARTICIPANTS_JOIN_TEXT = "Waiting for Participants to Join";
export const HOW_TO_JOIN_ACTIVITY_TITLE = "How to Join the Activity";
export const Q_TEXT = "Q";
export const GEOFENCE_ENABLED_TEXT = "Geofence Enabled";
export const GEOFENCE_DISABLED_TEXT = "Geofence Disabled";

//test

// Release Question Modal
export const RELEASE_QUESTION_MODAL_TITLE = "Release Question";
export const RELEASE_QUESTION_MODAL_BODY_TEXT = "You have some unsaved changes. Would you like to save those changes and release the question, or continue editing the question?";
export const RELEASE_QUESTION_CANCEL = "Continue Editing";
export const RELEASE_QUESTION_CONFIRM = "Save and Release";

// Release Poll Modal
export const UNSAVED_CHANGES_TEXT = "Unsaved Changes";
export const UNSAVED_CHANGES_CONTENT_TEXT = "Changes you have made will not be saved. Are you sure you want to continue?";
export const RELEASE_POLL_MODAL_BODY_TEXT = " You have some unsaved changes. Would you like to save those changes and release the poll or continue editing the question?";
export const RELEASE_POLL_CANCEL = "Continue Editing";
export const RELEASE_POLL_CONFIRM = "Save and Release";

// Delete/Discard Question Modal
export const DELETE_MODAL_TITLE = "Delete Question";
// export const DISCARD_MODAL_TITLE = "Discard Changes";
export const DELETE_MODAL_BODY_TEXT = "Are you sure you want to delete this question?";
export const DISCARD_MODAL_BODY_TEXT = "Are you sure you want to discard all changes to the question?";
export const DISCARD_CHANGES_MODAL_BODY_TEXT = "Are you sure you want to discard all changes?";
export const DELETE_TEXT = "Delete";
export const DISCARD_CHANGES_TEXT = "Discard Changes";

// Add/Update Question Modal
export const ADD_QUESTION_MODAL_TITLE = "Add Question";
// export const UPDATE_QUESTION_MODAL_TITLE = "Update Question";
export const ADD_QUESTION_MODAL_BODY_TEXT = "Are you sure you want to add this question to the poll?";
export const UPDATE_QUESTION_MODAL_BODY_TEXT = "Are you sure you want to update the question?";
export const CANCEL_UPDATE_TEXT = "Cancel Update";
export const UPDATE_QUESTION_TEXT = "Update Question";

//Join poll modal
export const JOIN_POLL_MODAL_TITLE = "Join";
export const JOIN_POLL_MODAL_POLL_CODE_LABEL = "Activity Code";
export const JOIN_POLL_MODAL_NAME_REQUIRED_LABEL = "Name";
export const JOIN_POLL_MODAL_NAME_OPTIONAL_LABEL = "Name (optional)";
export const JOIN_POLL_MODAL_ENTER_POLL = "Enter Activity";
export const JOIN_POLL_MODAL_SCAN_QR = "Scan QR";
export const JOIN_POLL_MODAL_CANCEL = "Cancel";


export const SEND_REQUEST_TITLE = "Sending Request";

// Navbar
export const NAVBAR_ENGAGE = "Engage";
export const SURVEY_NAVBAR = " Survey";
export const POLL_NAVBAR = " Poll";
export const ATTENDANCE_NAVBAR = " Attendance";
export const RELEASE = "Release ";
export const RESULTS = "Results";
export const JOIN_SURVEY = "Join Survey";
export const JOIN_ATTENDANCE = "Join Attendance";

export const OPEN_IN_NEW_TAB = "Open in New Tab";

export const POLL_TITLE_PLACEHOLDER = "Poll Title"; 
export const SURVEY_TITLE_PLACEHOLDER = "Survey Title"; 
export const ATTENDANCE_TITLE_PLACEHOLDER = "Attendance Title"; 
export const ATTENDANCE_DEFAULT_TITLE = "Attendance - date";
export const DATE_TO_REPLACE = "date";
export const ATTENDANCE_DEFAULT_QUESTION_TITLE = "What is the word of the day?";
export const UPDATE_CLOSE_BUTTON_TEXT = "Update & Close";
export const SAVE_CLOSE_BUTTON_TEXT = "Save & Close"; 

export const FITB_QUESTION_TITLE = "Type your question here and provide blanks by selecting Add Blank. Then, give each blank an answer.";
export const FITB_EMPTY_TITLE_ERROR = "The title cannot be empty.";
export const FITB_EMPTY_BLANK_ERROR = "The question must contain at least 1 blank, eg. (Blank 1).";
export const FITB_LONG_BLANK_ERROR = "The name of the blank cannot exceed 25 characters.";
export const CI_QUESTION_TITLE = "Click anywhere on the image to mark that region as an option.";
export const PREVIEW_TEXT = "Preview";
export const ADD_BLANK_TEXT = "Add Blank";
export const ADD_MATCHING_PAIR_TEXT = "Add Matching Pair"; 
export const ADD_DISTRACTORS_TEXT = "Add Distractors"; 

//get ready page
export const GET_READY_PAGE_TITLE = "Get Ready!";
export const GET_READY_PAGE_EXPLAIN = "A question is about to be released.";
export const GET_READY_PAGE_TIME_LIMIT_1ST_PART = "You will have";
export const GET_READY_PAGE_TIME_LIMIT_2ND_PART = "to answer all questions!";
export const GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION = "Get ready! The question will appear in";
export const GET_READY_ALL_PAGE_EXPLAIN = "Questions are about to be released.";

// local storage
export const SESSION = "session";
export const MISSED_RESPONSE_MAP = "missedResponseMap";
export const GUEST_SESSION = "guestSession";
export const TIME_OFFSET = "timeOffset";
export const COURSE_CONTENT = "courseContent";
export const QUESTIONS = "questions";
export const CREATE_TIME = "createTime";
export const SAVE_EXIT = "saveExit";
export const RECENT_QUESTIONS = "recentQuestions";
export const SHOW_ANSWER = "showAnswer";
export const POLL_KEY_TEXT = "PollKey";
export const RESP_DATA = "respData";
export const SA_RESULT = "SAResult";
export const FITB_RESULT = "FITBResult";
export const CURR_INDEX = "Curr_index";
export const IS_SAVING = "isSaving";
export const RESPONSE_CORRECT = "responseCorrect";

// external integration
export const TOPHAT = "Top Hat";
export const MENTIMETER = "Mentimeter";
export const POLL_EVERYWHERE = "Poll Everywhere";
export const INTEGRATED_PRODUCTS = [
    {label: MENTIMETER, value: MENTIMETER}, 
    {label: TOPHAT, value: TOPHAT}, 
    {label: POLL_EVERYWHERE, value: POLL_EVERYWHERE}
];
export const INTEG_SUBTITLE = "You can migrate existing data from third-party products to use within your Polls and Surveys. To get started, simply select a product from the drop-down menu below and follow the steps.";
export const MENTIMETER_EXTENSION_ID = "jbjniknpaofjfnghbocbhcppplchkkhp";
export const TOPHAT_EXTENSION_ID = "bgdaaeabdabncnacphdgiafkhmjfodio";
export const POLL_EVERYWHERE_EXTENSION_ID = "hddappnkhbmbfnpekaejnneejhohdokh";
export const LOGOUT_CHROME_EXT_MESSAGE = "removeChromeExtSessionInfo";
export const GET_CHROME_EXT_MESSAGE = "getChromeSessionInfo";
export const EXT_CONNECTION_ERROR = "An error occurred while connecting to the extension, please make sure the extension is installed!";
export const INSTALL_STEP = "Install the Engage Browser Extension";
export const INSTALL_SUBTITLE = "Please open Chrome and download the Engage Browser Extension.";
export const INSTALL_BTN = "Install Extension";
export const COMPLETED_STEP = "Step 1: Installed extension successfully.";
export const CONNECT_STEP = "Connect to the Engage Browser Extension";
export const CONNECT_SUBTITLE = "Once you've installed the extension, please click the “Connect” button.";
export const CONNECT_BTN = "Connect";
export const COMPLETED_CONNECT = "Step 2: Connected to the Engage browser extension successfully.";
export const ACCESS = "Access ";
export const MIGRATE_QUES = " to Migrate Questions";
export const TOPHAT_STEPS = ["Step 1: Select My Courses from the Main Menu.", "Step 2: Select the Engage Browser Extension.", "Step 3: Click the Migrate button to begin migrating questions.", "Step 4: Log in to Engage and access the Poll tab to review your migrated Polls."];
export const MENTIMETER_STEPS = ["Step 1: Select My Presentations from the left-side menu.", "Step 2: Select the Engage Browser Extension.", "Step 3: Click the Migrate button to begin migrating questions.", "Step 4: Log in to Engage and access the Poll tab to review your migrated Polls."];
export const PE_STEPS = ["Step 1: Select My Activities from the Activities menu.", "Step 2: Select the Engage Browser Extension.", "Step 3: Click the Migrate button to begin migrating questions.", "Step 4: Log in to Engage and access the Poll tab to review your migrated Polls."];
export const TOPHAT_URL = "https://chrome.google.com/webstore/detail/yuja-engage-for-poll-migr/bgdaaeabdabncnacphdgiafkhmjfodio";
export const PE_URL = "https://chrome.google.com/webstore/detail/yuja-engage-for-activity/hddappnkhbmbfnpekaejnneejhohdokh";
export const MENTIMETER_URL = "https://chrome.google.com/webstore/detail/yuja-engage-for-question/jbjniknpaofjfnghbocbhcppplchkkhp";
export const TOPHAT_SUPPORT_URL = "https://support.yuja.com/hc/en-us/articles/18985103413015";
export const MENTIMETER_SUPPORT_URL = "https://support.yuja.com/hc/en-us/articles/18982550088215";
export const PE_SUPPORT_URL = "https://support.yuja.com/hc/en-us/articles/18984955562775";

// Activity Chooser
export const ACTIVITY_CHOOSER = "Activity Chooser";
export const POLL_OPTION = "Graded Poll";
export const SURVEY_OPTION = "Ungraded Survey";
export const ATTENDANCE_OPTION = "Attendance Check";
export const NO_ACTIVITIES_FOUND = "You have not created any activities.";
export const INSERT_HEADER = "Insert";
export const ACTION_ID = "action";

// Poll chooser confirm
export const POLL_CHOOSER_CONFIRM_TITLE = "Insert Activity";
export const POLL_CHOOSER_CONFIRM_CANCEL = "Cancel";
export const POLL_CHOOSER_CONFIRM_CONTENT = "Select an activity to insert. When inserting it through the Activity Chooser, the activity will only be available through the LMS.";
export const POLL_CHOOSER_CONFIRM_CONTENT_BB = "When using the Activity Chooser, participants need to join through the LMS for grade book sync. To allow for grade book sync outside of the LMS, update the Participant Restrictions to allow only course members to join.";
export const POLL_CHOOSER_CONFIRM_DONT_SHOW_AGAIN = "Do not show this again";
export const POLL_CHOOSER_CONFIRM_OK = "I Understand";

// LMS API Integration
export const START_SYNC = "Start Synchronization";
export const COURSE_ID = "Course ID";
export const ENTER_COURSE_ID = "Enter Course ID";
export const DOMAIN = "Domain";
export const INST_BASE_URL = "Enter the institution's base url";
export const LMS_API_LINK = "LMS API Link";
export const APP_ID = "App ID";
export const APP_ID_PLACEHOLDER = "App ID used for D2L API Access";
export const APP_KEY = "App Key";
export const APP_SECRET = "App Secret";
export const APP_SECRET_PLACEHOLDER = "App Secret used for API Access";
export const CLIENT_ID_LABEL = "Client ID";
export const CLIENT_ID_PLACEHOLDER = "Enter the Client ID";
export const APP_KEY_PLACEHOLDER = "App key used for D2L API Access";
export const API_ENDPOINT_INST = "The API endpoint for the institution";
export const ACCESS_TOKEN = "Access Token";
export const MOODLE_CANVAS_ACCESS_TOKEN = "Access Token";
export const D2L_ACCESS_TOKEN = "App Key";
export const BLACK_BOARD_ACCESS_TOKEN = "App Secret";

export const API_ACCESS_TOKEN = "The institution's API access token";
export const MOODLE_CARD_DESC = "Moodle API Courses and users can be linked based on various fields. Select the fields which you want to be used to link Moodle Courses and Users provisioned using the Moodle API.";
export const CANVAS_CARD_DESC = "Canvas API Courses and users can be linked based on various fields. Select the fields which you want to be used to link Canvas Courses and Users provisioned using the Canvas API.";
export const D2L_CARD_DESC = "D2L API Courses and users can be linked based on various fields. Select the fields which you want to be used to link D2L Courses and Users provisioned using the D2L API.";
export const BB_CARD_DESC = "Blackboard API Courses and users can be linked based on various fields. Select the fields which you want to be used to link Blackboard Courses and Users provisioned using the Blackboard API.";
export const MOODLE_COURSES = "Moodle Courses:";
export const CANVAS_COURSES = "Canvas Courses:";
export const D2L_COURSES = "D2L Courses:";
export const BB_COURSES = "Blackboard Courses:";
export const PRIMARY_LINKAGE_ATTR = "Primary Linkage Attribute:";
export const MOODLE_USERS = "Moodle Users:";
export const CANVAS_USERS = "Canvas Users:";
export const D2L_USERS = "D2L Users:";
export const BB_USERS = "Blackboard Users:";
export const FALL_BACK_TO_EMAIL_LMS = "Use Email as Backup Field";
export const FALL_BACK_TO_EMAIL_LMS_MOODLE = "Use user email as backup field if no Moodle User ID is found (Recommended)";
export const FALL_BACK_TO_EMAIL_LMS_CANVAS = "Use user email as backup field if no Canvas User ID is found (Recommended)";
export const FALL_BACK_TO_EMAIL_LMS_D2L = "Use user email as backup field if no D2L User ID is found (Recommended)";
export const FALL_BACK_TO_EMAIL_LMS_BB = "Use user email as backup field if no Blackboard User ID is found (Recommended)";
export const FALL_BACK_TO_COURSE_CODE_LMS_MOODLE = "Use Course Code as backup field if no Moodle Course ID is found (Recommended)";
export const FALL_BACK_TO_COURSE_CODE_LMS_CANVAS = "Use Course Code as backup field if no Canvas Course ID is found (Recommended)";
export const FALL_BACK_TO_COURSE_CODE_LMS_D2L = "Use Course Code as backup field if no D2L Course ID is found (Recommended)";
export const FALL_BACK_TO_COURSE_CODE_LMS_BB = "Use Course Code as backup field if no Blackboard Course ID is found (Recommended)";
export const API_TOKEN_OVERWRITE_MODAL_TITLE_CANVAS_MOODLE = "Override Access Token";
export const API_TOKEN_OVERWRITE_MODAL_TITLE_D2L = "Override App Key";
export const API_TOKEN_OVERWRITE_MODAL_TITLE_BLACKBOARD = "Override App Secret";
export const API_TOKEN_OVERWRITE_MODAL_DESC_CANVAS_MOODLE = "Please enter the new access token to override the existing one.";
export const API_TOKEN_OVERWRITE_MODAL_DESC_D2L = "Please enter the new app key to override the existing one.";
export const API_TOKEN_OVERWRITE_MODAL_DESC_BLACKBOARD = "Please enter the new app secret to override the existing one.";




//Take Embedded poll guide
export const EMBEDDED_POLL_PAGE_IDENTIFIER = "Join Poll Guide";
export const EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE = "Can not Access the activity. Viewer has to click to the Activity link of LMS to take the activity.";
export const EMBEDDED_POLL_TITLE = "How to Access the Activity";
export const EMBEDDED_POLL_GUIDE_TITLE = "Join Activity Steps";
export const EMBEDDED_POLL_GUIDE_DESCRIPTION = "Simply follow the steps below to join the activity.";
export const EMBEDDED_POLL_GUIDE_STEP_1_PREFIX = "1. Log in to your";
export const EMBEDDED_POLL_GUIDE_STEP_1_SUFFIX = "LMS.";
export const EMBEDDED_POLL_GUIDE_STEP_2 = "2. Navigate to the course that contains the Activity.";
export const EMBEDDED_POLL_GUIDE_STEP_3 = "3. Locate and select the Activity to access it. If you're unable to locate the Activity, your instructor can provide you with information regarding the name and location of the Activity.";

export const UNAVAILABLE_TEXT = "N/A";
export const PARTICIPANT_TEXT ="Participants";
export const RESPONSE_TEXT = "Responses";
export const WRONG_ANSWER_TEXT = "Wrong Answers"; 
export const COMPLETION_RATE_TEXT = "Response Rate";
export const CORRECT_ANSWER_TEXT = "Correct Answers"; 
export const CORRECT_ANSWER_PLACEHOLDER = "Correct Answer"; 

// accessibility roles narrator
export const BUTTON = "button";
export const TOGGLE = "toggle";
export const TEXTBOX = "paragraph";
export const NONE = "none";
export const IMG = "img";
export const ACTIVE_POLLS_LABEL = "Join Poll ";
export const ACTIVE_POLLS_LABEL_2 = "with poll code ";
export const ACTIVITY_CODE = "Activity Code";
export const POLL_CODE_LABEL = "Text box ";
export const POLL_CODE_2_LABEL = " for the poll code";
export const POLL_TITLE_LABEL = "Poll Title ";
export const PERCENTAGE_LABEL = "Percentage ";
export const VIEW_RESULTS_LABEL = "View Results for Poll ";
export const TOOLTIP = "Tooltip";
export const COURSE_LABEL = "Course Name ";
export const COURSE_CODE_LABEL = "Course Code ";
export const COURSE_NAME_LABEL = "Course Name ";
export const CLOSE_MODAL = "Close Modal";
export const PREVIOUS_PAGE = "Go To Previous Page";
export const NEXT_PAGE = "Go To Next Page";
export const FIRST_PAGE = "Go To First Page";
export const LAST_PAGE = "Go To Last Page";
export const CURRENT_PAGE = "Go To Page ";
export const COLLAPSE_SIDEBAR = "Collapse Right Panel";
export const EXPAND_SIDEBAR = "Expand Right Panel";
export const LOGOUT_LABEL = "Logout";
export const HOME_ICON = "Return to Engage homepage";
export const NUMBER_LABEL = "Number Q";
export const QUESTION_LABEL = "Question";
export const TYPE_LABEL = "Type";
export const STATUS_LABEL = "Status";
export const YOUR_ANS_LABEL = "Your Answer";
export const FITB_BLANK = "______";
export const FITB_NARRATOR = "blank";
export const DIVISOR_LABEL = " out of ";
export const GET_READY_LABEL = "Poll will start in ";
export const SECONDS_LABEL = " seconds";
export const TOTAL_SHARED_LABEL = "Total Shared ";
export const QUESTION_TITLE = "Question Title ";
export const THUMBS_UP = "Rate question with a thumbs up";
export const THUMBS_DOWN = "Rate question with a thumbs down";
export const QUESTIONS_LABEL = " questions";
export const HOUR_LABEL = " hours ";
export const MIN_LABEL = " minutes ";
export const SEC_LABEL = " seconds ";
export const TIME_REMAINING = "Time remaining ";
export const OPTION_LABEL = "Option ";
export const NEXT_QUES = "Go to next question ";
export const PREV_QUES = "Go to previous question ";
export const CUSTOM_TIME_LIMIT = "Type Custom Time Limit";
export const CUSTOM_POINTS = "Type Custom Points";
export const CUSTOM_COUNTDOWN = "Type Custom Countdown";
export const CUSTOM_ENTRIES = "Type Custom Entries";
export const VIEW_COURSE_LABEL = "Search by username, first name, or last name";

// accessibility creator narrator
export const MODAL = " modal";
export const CLOSE_GRADE_MODAL = "Close Configure Gradebook Options Modal";
export const SUPPORT_CENTER_BTN = "Close YuJa Help modal";
export const SUPPORT_CENTER = 'YuJa Help modal';
export const COPY_POLL = "Copy Join Poll Link";
export const ACTIVATE_BUTTON = "Activate the poll: ";
export const RELEASE_BUTTON = "Release the poll: ";
export const VIEW_RESULTS = "View results for poll: ";
export const VIEW_RESULTS_TEXT = "View Results";
export const TOTAL_QUESTIONS_LABEL = "Total Questions for ";
export const ACTIONS_LABEL = "More actions for Poll Title: ";
export const VIEW_GRADEBOOK = "View gradebook for poll title: ";
export const YUJA_HELP_MODAL = "YuJa Help modal";
export const QUES_LABEL_1 = "Question ";
export const QUES_LABEL_2 = " Duplicate question button";
export const QUES_LABEL_3 = " Delete question button";
export const DUPLICATE_BTN = "Duplicate question ";
export const DELETE_BTN = "Delete question ";
export const QUESTION_BANK_MODAL = "Question Bank Modal";
export const QUESTION_BANK_PAGE_TEXT = "Question Banks";
export const SERACH_BOX_QUES_BANK = "Search box. Search question banks by typing their names";
export const SEARCH_BTN = "Search questions";
export const QUES_BANK_NAME = "Question bank name: ";
export const TOTAL_QUES_BANK = "Total Questions for ";
export const EDIT_QUES_BANK = "Edit question bank: ";
export const DELETE_QUES_BANK = "Delete question bank: ";
export const SHARE_QUES_BANK = "Share Question Bank";
export const QUES_BANK_CLOSE = "Cancel Question Bank modal";
export const QUES_BANK_CHECKBOX = "Check box for ";
export const QUES_BANK_SAVE = "Check box. Save as question bank ";
export const RADIAL_LABEL = " radial button ";
export const ATTEMPTS_BOX = "Check box. Allow unlimited attempsts ";
export const CREATE_POLL_LABEL = "Text box. Enter the poll title here";
export const CREATE_ATTENDANCE_LABEL = "Text box. Enter the attendance title here";
export const CREATE_QUES_TYPE = " drop-down menu for more question types";
export const CREATE_QUES_LABEL = "Text box. Enter the question title";
export const ALT_TEXT_LABEL = "Text box. Enter image alternative description";
export const ALT_TEXT_PLACEHOLDER = "Describe the image";
export const DEFAULT_IMAGE_ALT = "Question Image";
export const CLICKABLE_IMAGE_ALT = "Uploaded Clickable Image";
export const UPLOAD_MEDIA = "Upload media button";
export const OPT_LABEL = "Option ";
export const CREATE_OPT_RADIAL = "radial button option ";
export const TEXT_BOX = "Text box. Option ";
export const DELETE_OPT_BTN = "Delete option ";
export const RADIAL_BTN_TRUE = "Radial button true ";
export const RADIAL_BTN_FALSE = "Radial button false ";
export const FITB_INPUT = "Text box. Type a question and click Add Blank for the word you wish to not show. Add the answer to your blank below";
export const FITB_ADD = "Add more options for ";
export const FITB_DELETE = "Delete ";
export const PREMISE_SELECTED = "Text box. Premise ";
export const RESPONSE_LABEL = "Text box. Response ";
export const DELETE_BTN_MH = "Delete Premise ";
export const DELETE_BTN_MH_2 = " and response ";
export const SEARCH_POLL = "Search text box. Search by Activity Title";
export const SEARCH_GRADEBOOK = "Search text box. Search by participants and title";
export const START_DATE_TEXT = "Start Date";
export const END_DATE_TEXT = "End Date";
export const START_DATE_LABEL = "Select Start Date";
export const END_DATE_LABEL = "Select End Date";
export const ALL_MODE_LABEL = " drop-down menu for modes";
export const ALL_CATEGORY_LABEL = " drop-down menu for category";
export const ALL_SATISFACTION_LABEL = " drop-down menu for satisfaction";
export const ALL_STATUS_LABEL = " drop-down menu for status";
export const ALL_COURSE_LABEL = " drop-down menu for courses";
export const ALL_ATTENDANCES_LABEL = " drop-down menu for attendances";
export const DATE_CREATED_LABEL = "Select Date Created";
export const LAST_ACCESSED_LABEL = "Select Last Accessed";
export const ALL_ROLE_LABEL = " drop-down menu for role";
export const ALL_LINKAGE_TYPE_LABEL = " drop-down menu for linkage type";
export const ACTIVATE_TIME = "Set the length of time for the Poll in hours, minutes, and seconds.";
export const CANCEL_ACTIVATE = "Cancel Active poll";
export const DATE = "Date ";
export const TITLE = "Title ";
export const MODE = "Mode ";
export const ORDER = " order";
export const QUES_LABEL = "Questions for ";
export const ACT_STATUS = "Activation status for ";
export const POLL_STATUS = "Actvitiy status ";
export const ACT_ITEMS = "Action items for ";
export const ACT_MODAL = "Activate Poll Modal";
export const SHARE_MODAL = "Share Poll modal";
export const SHARE_MEMBERS = "Select members drop-down menu";
export const SHARE_MODAL_CLOSE = "Close Share Poll modal button";
export const DELETE_MODAL = "Delete Poll Modal";
export const GRADEBOOK_COURSE_DROPDOWN = "Select course drop-down menu";
export const GRADEBOOK_DROPDOWN = "Select published Poll drop-down menu";
export const ATTENDANCE_GRADEBOOK_DROPDOWN = "Select attendance drop-down menu";
export const EXPORT_GRADEBOOK_DROPDOWN = "Export gradebook drop-down menu";
export const SYNC_GRADEBOOK_DROPDOWN = "Select sync gradebook drop-down menu";
export const SHOW_ATMPS = "Show Attempts carrot icon ";
export const HIDE_ATMPS = "Hide attempts carrot icon ";
export const VIEW_QUES = "View ";
export const STD_LABEL = "Student ";
export const SCORE_LABEL = "Score ";
export const ATMPT_LABEL = "Attempt ";
export const ATMPT_LABEL_2 = " for student";
export const USERS_SEARCH = "Search text box. Search by username, First Name, Last name or Email";
export const CREATE_USER_MODAL = "Create user modal";
export const CANCEL_USER = "Cancel Create User.";
export const TEXT_BOX_MODAL = "Text box enter ";
export const USER_ROLE = "Select role drop-down menu.";
export const ROLE_LABEL = "Role ";
export const OPERATION_LABEL = "Operation more action menu.";
export const SEARCH_BOX_COURSE = "Search text box. Search by course name or course code";
export const EDIT_BTN = "Edit. Course Update ";
export const DELETE_CRSE = "Edit. Delete course ";
export const SELECT_LABEL = "Dropdown. Select ";
export const SEARCH_BOX = "Search text box. Search by First Name or Last name.";
export const INTEG_TEXT_BOX = " text box";
export const ATMPT_DROPDOWN = "Attempt drop-down menu. ";
export const TOAST_DROPDOWN = "Toast message drop-down menu. ";
export const SETT_LABEL = " text box. Enter how you’d like the option to appear";
export const RADIAL_BTN = " radial button. ";
export const CREATE_POLL_CARROT = "Expand the Time Limit panel";
export const CREATE_POLL_CARROT_2 = "Collapse the Time Limit panel";
export const CREATE_POLL_CARROT_3 = "Expand the Points panel";
export const CREATE_POLL_CARROT_4 = "Collapse the Points panel";
export const CREATE_POLL_CARROT_5 = "Expand the Countdown panel";
export const CREATE_POLL_CARROT_6 = "Collapse the Countdown panel";
export const TOGGLE_LABEL = "Toggle button ";
export const ALT_TEXT_TOOL_TIP = "To ensure that people with visual impairments can access your content, please provide a description of the image that will be read by screen readers.";

// websocket roles
export const WEBSOCKET_STUDENT = "STUDENT";
export const WEBSOCKET_INSTRUCTOR = "INSTRUCTOR";
export const WEBSOCKET_PPT = "PPT";
export const PPT_WEBSOCKET_END_TYPE = "webview";

export const MOBILE_APP_STORE_URL = "https://apps.apple.com/ca/app/yuja-engage/id6446036970";
export const MOBILE_PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.vaarananyuja.yujaengagemobile";

//websocket action
export const WEBSOCKET_MESSAGE_REMOVE_CONNECTIONS_ACTION = "removeConnections";
export const WEBSOCKET_MESSAGE_POST_HEARTBEAT_ACTION = "postHeartbeat";
export const WEBSOCKET_MESSAGE_REQUEST_GEO_LOCATION_ACTION = "requestGEOLocation";
export const WEBSOCKET_POST_HEARTBEAT_INTERVAL = 20000;
export const WEBSOCKET_CHECK_HEARTBEAT_INTERVAL = 5000;
export const WEBSOCKET_MAX_TOLERANCE_HEARTBEAT_GAP = 60000;


export const LOGIN_PAGE_FOOTER_TEXT = "Powered by YuJa"; 
export const LOGIN_BUTTON_TEXT = "Login & Join"; 
export const JOIN_POLL_DESCRIPTION = "To join the activity, please enter your name and activity code.";
export const JOIN_USING_CODE_QR_TITLE = "Join Using Code or QR";
export const JOIN_USING_CODE_TITLE = "Join Using Code";
export const JOIN_ANONYMOUS_TEXT = "Join Anonymously";
export const QR_LANDING_PAGE_TEXT = "Transforms any course into a more active learning experience for students and allows instructors to gain actionable metrics.";
export const JOIN_ANONYMOUS_MESSAGE = "You can enter your name below to join the activity.";
export const SCAN_QR_TITLE = "Scan to Join Activity";

export const SEARCH_BOX_PLACEHOLDER = "Search by title";
export const GRADEBOOK_SEARCH_BOX_PLACEHOLDER = "Search for participants or attendance";
export const GRADEBOOK_SEARCH_BOX_PLACEHOLDER_BY_NAME = "Search by participant name";
export const YUJA_SUPPORT_LINK = "https://www.yuja.com/support/";

export const SURVEY_RESULT_TEXT = "Survey Result For ";
export const POLL_RESULT_TEXT = "Poll Result For ";
export const RELEASE_QUESTION_COUNT_TEXT = "Questions Released:";
export const WAIT_QUESTION_TEXT = "Waiting for Next Question";
export const WAIT_QUESTION_EXPLAIN = "The next question will be released shortly.";
export const WAIT_ATTENDANCE_TEXT = "Waiting for the Attendance to Start.";
export const TAKEN_ATTENDANCE_TEXT = "The Attendance Has Closed";
export const WAIT_POLL_TEXT = "Waiting for the Poll to Start.";
export const VIDEO_ERROR_CI = "Videos are not supported for Clickable Image questions";

export const YUJA_ENGAGE_CAN_ACCESS_D2L_API = "YuJa Engage now has access to the D2L API";
export const YOU_MAY_NOW_CLOSE_THIS_TAB = "You may now close this tab.";

//PPT web view page
export const PPT_ROOT_PATH = "/PPT";
export const PRESENTATION_PATH = "/presentation";
export const PPT_WEBVIEW_POLL_TABLE_GUIDE_LINE = "Select an activity to insert into your presentation. Please note that polls with the Scheduled Release or Released Entirely statuses will not be available.";
export const PPT_WEBVIEW_REFRESH_BUTTON = "Refresh";
export const PPT_WEBVIEW_ADD_POLL_TO_SLIDES_BUTTON = "Insert Activity";
export const PPT_WEBVIEW_ADDING_SLIDES_DESCRIPTION = "The activity will soon be available.";
export const PPT_WEBVIEW_ADDING_SLIDES_ERROR1 = "You have not selected a Poll. Please select a Poll to add to your presentation.";
export const PPT_WEBVIEW_ADDING_SLIDES_ERROR2 = "Could not insert poll. Please try again or contact YuJa Help Center.";
export const GO_BACK_POLLS_LINK = "Go back to Activities";
export const PPT_PLUGIN_INSTALL_ZIP_FILE_NAME = "Engage PowerPoint Plugin.zip";
export const PPT_INSTALL_DESCRIPTION = "Spark exciting conversations directly from your PowerPoint slide deck.";
export const PPT_INSTALL_STEP1_TITLE = "Download the PowerPoint add-in";
export const PPT_INSTALL_STEP1_BUTTON_TEXT = "Download for Windows";
export const PPT_INSTALL_STEP1_SUB_1 = "Please download the Engage PowerPoint .zip file for Windows.";
export const PPT_INSTALL_STEP2_TITLE = "Ensure you have uninstalled any previous versions of the Engage add-in";
export const PPT_INSTALL_STEP2_SUB_1 = "Step 1. Open your PowerPoint application.";
export const PPT_INSTALL_STEP2_SUB_2 = "Step 2. Go to File -> Options -> Add-ins and click \"Go\" to view all the COM add-ins installed.";
export const PPT_INSTALL_STEP2_SUB_3 = "Step 3. Select the Engage add-in, click \"Remove\" to delete it, and then close PowerPoint.";
export const PPT_INSTALL_STEP3_TITLE = "Ensure your system has removed the Engage add-in";
export const PPT_INSTALL_STEP3_SUB_1 = "Step 1. Open the Window's Control Panel.";
export const PPT_INSTALL_STEP3_SUB_2 = "Step 2. Select \"Uninstall a program\" and search for \"Engage\".";
export const PPT_INSTALL_STEP3_SUB_3 = "Step 3. If the Engage PowerPoint add-in appears, right-click the application to uninstall it.";
export const PPT_INSTALL_STEP4_TITLE = "Install the Engage PowerPoint Add-in";
export const PPT_INSTALL_STEP4_SUB_1 = "Step 1. Locate the Engage PowerPoint .zip file and right-click to extract the content.";
export const PPT_INSTALL_STEP4_SUB_2 = "Step 2. Open the extracted content and launch the \"setup.exe\" file.";
export const PPT_INSTALL_STEP4_SUB_3 = "Step 3. Once the installation is complete, you can open PowerPoint and locate the Engage add-in within the PowerPoint Ribbon.";


// Recently Modified Polls:
export const RECENT_POLLS = "Recent Activities";
export const RECENT_POLLS_SUBTITLE = "To release a recently modified activity, select the 'Release' button";
export const NO_RECENT_POLLS = "No recent modified activities found";

export const VIEW_FEEDBACK_TEXT = "View Feedback";
export const FEEDBACK_TEXT = "Would you like to provide feedback?";
export const FEEDBACK_BUTTON_TEXT = "Feedback";
export const FEEDBACK_MODAL_TITLE = "Provide Feedback";
export const FEEDBACK_MODAL_SUB_TITLE = "Please provide your feedback.";
export const FEEDBACK_MODAL_SUB_TITLE_SUFFIX = "poll.";
export const FEEDBACK_TITLE_PLACEHOLDER = "Write your summary here";
export const FEEDBACK_TEXT_PLACEHOLDER = "Please give as much detail as you can about your idea or suggestion. We'll reach out to you.";
export const FEEDBACK_MODAL_SATISFACTION = "How satisfied are you with this ";
export const FEEDBACK_MODAL_SATISFACTION_SUFFIX = "poll?";
export const SATISFACTION_POOR_TEXT = "Poor";
export const SATISFACTION_LABEL = "Satisfaction Option";
export const SATISFACTION_SATISFIED_TEXT = "Satisfied";
export const SATISFACTION_GOOD_TEXT = "Good";
export const SATISFAcTION_EXCELLENT_TEXT = "Excellent";
export const FEEDBACK_MODAL_CATEGORY = "Please select the feedback category.";
export const FEEDBACK_CATEGORY_SUGGESTION = "Suggestion";
export const FEEDBACK_CATEGORY_COMMENT = "Comment";
export const FEEDBACK_CATEGORY_OTHER = "Other";
export const FEEDBACK_TITLE_TEXT = "Provide us with a summary of your feedback.";
export const FEEDBACK_THOUGHT_TEXT = "Would you like to share any additional thoughts?";
export const FEEDBACK_CATEGORY = {
    Suggestion: "Suggestion",
    Comment: "Comment",
    Other: "Other"
};
export const FEEDBACK_THANK_TEXT = "Thank You!";
export const FEEDBACK_DETAIL_TEXT = "Details about Feedback";
export const FEEDBACK_APPRECIATION_TEXT = "We appreciate your feedback.";
export const FEEDBACK_APPRECIATION_EDITING_TEXT = "We have updated your feedback.";
export const FEEDBACK_TABLE_POLL_TEXT = "Review feedback from students and consider their overall satisfaction to help improve future engagement";
export const FEEDBACK_TABLE_SURVEY_TEXT = "Review feedback from students and consider their overall satisfaction to help improve future engagement";

export const TIME_LIMIT_CHECKBOX_LABEL = "Enable Time Limit";
export const TIME_LIMIT_DESCRIPTION  = "Enter the amount of time participants will have to complete each question";
export const TIME_LIMIT_DESCRIPTION_SUFFIX = "(MM:SS)";

export const POINTS_CHECKBOX_LABEL = "Enable Points";
export const POINTS_DESCRIPTION = "Each question will be worth";
export const PARTICIPANT_TIME_INTRO = "Participants will have";
export const PARTICIPANT_TIME_INTRO_ATTEDNANCE = "The attendance will automatically close after";
export const COUNTDOWN_TIMER_DESCRIPTION = "seconds to get ready for each question.";
export const MAXIMUM_ENTRIES_DESCRIPTION = "Word Cloud questions will allow participants to respond with";
export const COUNTDOWN_TIMER_POLL_DESCRIPTION = "seconds to get ready for the poll.";

// Login Page:
export const BACK_TO_LOGIN_TEXT = "Back to login page.";
export const BACK_TO_LOGIN_ARIA_LABEL = "Link. Back to login page.";
export const FORGOT_ARIA_LABEL = "Link. Forgot username or password";
export const FORGOT_TEXT = "Forgot Username or Password?";
export const JOIN_AS_PARTICIPANT_TEXT = "JOIN AS A PARTICIPANT";
export const REMEMBER_ME_ARIA_LABEL = "Checkbox. Remember me";
export const CHECKED = "checked";
export const UNCHECKED = "unchecked";
export const FORGOT_PWD_TITLE = "Forgot your username or password?";
export const FORGOT_PWD_DESC = "Enter your registered email to receive password reset instructions.";
export const FORGOT_PWD_EMAIL_INPUT_BOX_LABEL = "Email";
export const FORGOT_PWD_EMAIL_INPUT_BOX_PLACEHOLDER = "Enter your email";
export const FORGOT_PWD_INVALID_EMAIL_MSG = "Please enter a valid email address.";
export const FORGOT_PWD_EMAIL_HAS_SENT = "Email has been sent.";
export const FORGOT_PWD_EMAIL_HAS_SENT_DESC = "Please check your inbox and click in the received link to reset a password.";

export const EXPAND_WINDOW_LABEL = "expand window arrow";
export const MINIMIZE_WINDOW_LABEL = "minimize window arrow";

export const ROSTER_SYNC_MODAL_TITLE = "Roster Sync";
export const ROSTER_SYNC_MODAL_MSG = "You are about to sync your LMS roster with Engage. Doing so will update users and reassign roles. Are you sure you want to continue?"
export const ROSTER_SYNC_MODAL_OK_TEXT = "Sync Roster";
export const ROSTER_SYNC_MODAL_CHECKBOX_TEXT = "Yes, I would like to continue.";
export const USER_FINISH_SURVEY_MESSAGE_REATTEMPT = "Click View Responses to see how others responded, or click Retry to reattempt the survey.";
export const USER_FINISH_SURVEY_MESSAGE_NORMAL = "To view other participants' responses, please click on the button below.";
export const ENDED_SURVEY_TEXT = "The Survey Has Closed";
export const ENDED_POLL_TEXT = "Poll Has Ended";
export const YOU_ARE_ALL_DONE_TEXT = "You're All Done";
export const SEE_RESPONSES_BUTTON_TEXT = "View Responses";
export const USER_COMPLETE_MERGED_REATTEMPT_RESPONSES = "Click View Responses to see how others responded, or click Retry to reattempt the poll.";
export const USER_COMPLETE_MERGED_RESPONSES = "You can close this page or view your responses along with those from other participants.";
export const USER_COMPLETE_MERGED_REATTEMPT = "Click Retry to reattempt the poll";

export const EXPORT_GRADEBOOK_WARNING_TITLE = "Important: Gradebook Sync Will Be Disabled";
export const EXPORT_GRADEBOOK_WARNING_TEXT = "You are about to export a CSV file containing the results for this poll. Uploading the result to your LMS gradebook will result in the following:";
export const EXPORT_GRADEBOOK_FIRST_WARNING = "1) Engage will no longer send grade results for this poll to the gradebook. ";
export const EXPORT_GRADEBOOK_SECOND_WARNING = "2) If the poll was embedded in the LMS, it will appear twice in the gradebook. ";
export const EXPORT_GRADEBOOK_WARNING_SUBTEXT = "Please note that participants who completed an embedded poll in their LMS will have their results duplicated.";

// Sidebar
// export const CREATE_POLL = "Create Poll";
export const CREATE_SURVEY = "Survey";
export const CREATE_ATTENDANCE = "Attendance";
export const GRADEBOOK = "Grade Book";
// export const POLL_SURVEY_GRADEBOOK = "Polls and Surveys";
export const MERGED_POLL_GRADEBOOK = "Poll";
export const ATTENDANCE_GRADEBOOK = "Attendance Gradebook";
export const COPY_IMAGE_PREFIX = "Copy of";
export const IMAGE_UPLOADING_PLACEHOLDER = "IMAGE_UPLOADING_PLACEHOLDER";

// Attendance:
export const GEOFENCE_CHECKBOX_LABEL = "Create a geofence for participants.";
export const LMS_SYNC_CHECKBOX_LABEL = "Sync results to LMS.";
export const DEFAULT_GEOFENCE_LAT = 0;
export const DEFAULT_GEOFENCE_LNG = 0;
export const DEFAULT_GEOFENCE_RAD = 10;
export const RADIUS_LABEL = "Radius: ";
export const RADIUS_UNIT = "Meters";
export const MIN_RADIUS = 10; // 10 meters
export const MAX_RADIUS = 10000; // 10 km
// export const LOCATION_SEARCH_PLACEHOLDER = "Search Location by city, neighbourhood or ZIP code.";
// export const LOCATION_API_URL = `https://us1.locationiq.com/v1/search?key={ACCESS_TOKEN}&q={SEARCH_STRING}&format=json`;
export const INSTRUCTOR_LOCATION_TIMEOUT = 3000;
export const INSTRUCTOR_LOCATION_CONFIG = {
    enableHighAccuracy: true, // high accuracy
    maximumAge: Infinity, // get cached position if any
    timeout: INSTRUCTOR_LOCATION_TIMEOUT // maximum time to wait for a response
};
export const VIEWER_LOCATION_TIMEOUT = 5000;
export const VIEWER_LOCATION_CONFIG = {
    enableHighAccuracy: true, // high accuracy
    maximumAge: 0, // get latest position
    timeout: VIEWER_LOCATION_TIMEOUT // maximum time to wait for a response
};
export const LOCATION = "Location";
export const ACCURACY = "Accuracy";
export const SAVED_LOCATIONS_MODAL_TITLE = "Configure Geofenced Locations";
export const SAVED_LOCATIONS_MODAL_DESCRIPTION = "You can add up to five geofenced locations for attendance tracking.";
export const ADD_LOCATION = "Add Location";
export const MAX_SAVED_LOCATIONS_ERROR = "You have reached the maximum number of saved locations.";
export const MAX_SAVED_LOCATIONS = 5;
export const SAVED_LOCATIONS_SUFFIX = "Locations added";
export const LOCATION_NAME_LABEL = "Location Name:";
export const LOCATION_NAME_LENGTH = 50;
export const NO_SAVED_LOCATIONS_SIDEBAR = "No saved locations found. Please add a location in user settings to use this feature.";
export const SAVED_LOCATIONS_LABEL = "Saved Locations:";
export const SAVED_LOCATIONS_DROPDOWN_PLACEHOLDER = "Manual Location";
export const LOCATION_NAME_PLACEHOLDER = "Location Name";
export const SAVED_LOCATION_INSTRUCTION = "Create saved locations by going to Settings > User > Attendance Details.";

// Poll Ready:
export const POINTS_TEXT = "Points";
export const UNGRADED_TEXT = "Ungraded";
export const SA_PLACE_HOLDER = "Enter your answer here";
export const OE_PLACE_HOLDER = "Enter your answer here";
export const OUTSIDE_GEOFENCE_ATTENTION_TEXT = "You may be outside the geofence. If possible, please try to move closer to the geofenced location.";
export const INSIDE_GEOFENCE_TEXT = "Inside Geofence";
export const OUTSIDE_GEOFENCE_TEXT = "Outside Geofence";
export const CHECK_IN_BLOCKED_TEXT_START = "Your location is not being shared. To enable location sharing, please click on the ";
export const CHECK_IN_BLOCKED_TEXT_HYPERLINK = "given link";
export const ALLOW_LOCATION_ACCESS_SUPPORT_LINK = "https://support.yuja.com/hc/en-us/articles/22108689371671-How-to-Share-Your-Location-for-Attendance-Tracking";
export const CHECK_IN_BLOCKED_TEXT_END = "and follow the steps to allow your location to be included in the attendance.";
export const ATTENDANCE_DESCRIPTION = "Please submit the answer to the attendance question below to be marked as present.";
export const ATTENDANCE_TEXT_INPUT_BOX_PLACEHOLDER = "Enter your response";
export const REFRESH_LOCATION = "Refresh Location";
export const CORRECT_MODAL_HEADER = "Correct Answer";
export const CORRECT_MODAL_BODY = "Well done! Your class results will appear shortly.";
export const TIMEUP_CORRECT_MODAL_BODY = "Your answer is correct! Class results will appear shortly.";
export const TIMEUP_INCORRECT_MODAL_BODY = "Your answer is incorrect. Class results will appear shortly.";
export const INCORRECT_MODAL_HEADER = "Incorrect Answer";
export const INCORRECT_MODAL_BODY = "Don't give up! Your class results will appear shortly.";
export const SUBMITTED_TOAST_TITLE = "Submitted";
export const TIMEUP_MODAL_HEADER = "Time's Up";
export const TIMEUP_MODAL_BODY = "All answers have been submitted. Your class results will appear shortly.";
export const SUBMITTED_MODAL_TEXT = "Your answer has been submitted.";
export const LOCKED_MODAL_TEXT = "Poll submission has been locked";
export const SA_MODAL_HEADER = "Your answer has been submitted.";
export const SA_MODAL_BODY = "Your answer has been submitted and will be graded by the activity creator.";
export const OE_NO_RESPONSE_EXPLAIN = "You can view the responses of other participants as they complete this question."
export const CANCEL_SUBMIT_TEXT = "Cancel Submit";
export const SUBMIT_CONFIRM_TEXT = "Are you sure you want to submit the poll?";
export const PRESENT_ATTENDANCE_MODAL_TEXT = "Your response is correct. You have been marked as present.";
export const UNGRADED_ATTENDANCE_TEXT = "Your attendance result will be posted once it is graded.";
export const ABSENT_ATTENDANCE_MODAL_TEXT = "Your response is incorrect. You have been marked as absent.";
export const ABSENT_ATTENDANCE_LOCATION_MODAL_TEXT = "Your response was correct, but you are outside the geofence location and have been marked as absent. If you think this is incorrect, please contact the creator of the attendance.";
export const IFRAME_PERMISSION_ERROR_MESSAGE = "Geolocation error: Geolocation has been disabled in this document by permissions policy..";
export const LOCATION_ACCURACY_THRESHOLD = 100;
export const LOCATION_MESSAGE = {
    LOCATING: "Finding your location.",
    SUCCESS_IN_GEOFENCE: "You are within the geofenced location.",
    SUCCESS_OUTSIDE_GEOFENCE: "You are outside the geofenced location.",
    BROWSER_NOT_SUPPORTED: "Geolocation is not supported by your browser. We recommend using Google Chrome.",
    PERMISSION_DENIED: "Please enable location access on your device.",
    POSITION_UNAVAILABLE: "Your location could not be determined. You can still take the attendance by selecting the Check-In button.",
    TIMEOUT: "Your location could not be determined. You can still take the attendance by selecting the Check-In button.",
    UNKNOWN_ERROR: "Your location could not be determined. You can still take the attendance by selecting the Check-In button.",
    IFRAME_PERMISSION_ERROR: "Your location could not be shared. Please click the Open in New Tab icon located in the top-right corner of Engage and try again.",
    LOWER_ACCURACY: "Your exact location cannot be determined. Please allow access to location services on this device or use a laptop."
};
export const LOCATION_MESSAGE_GB_DISPLAY = {
    LOCATING: "Could not find location.",
    SUCCESS_IN_GEOFENCE: "Within the geofenced location.",
    SUCCESS_OUTSIDE_GEOFENCE: "Outside of the geofenced area.",
    BROWSER_NOT_SUPPORTED: "Browser not supported.",
    PERMISSION_DENIED: "Location access denied.",
};
// `Within a ${accuracy}-meter radius of the geofenced area`
export const IN_GEOFENCE_PREFIX = `Within a `;
export const IN_GEOFENCE_SUFFIX = `-meter radius of the geofenced area.`;

export const CHECK_IN_TEXT = "Check-In";
export const PRIVACY_POLICY_TEXT = "Privacy Policy";
export const PRIVACY_POLICY_URL = "https://www.yuja.com/documentation/privacy-policy/";
export const THANK_YOU_TEXT = "Thank You for Participating";
export const THANK_YOU_STATUS_PRESENT = "You have been marked present. Thank you for participating.";
export const THANK_YOU_STATUS_ABSENT_BOTH = "You have been marked absent. Your response was incorrect, and you are outside the geofence.";
export const THANK_YOU_STATUS_ABSENT_LOCATION = "You have been marked absent. You are outside the geofence.";
export const THANK_YOU_STATUS_ABSENT_RESPONSE = "You have been marked absent. Your response was incorrect.";
export const ATTENDANCE_CLOSED_TEXT = "The Attendance Has Closed";
export const ATTENDANCE_COUNTDOWN_START = 10;
export const COUNTDOWN_SUFFIX = " secs";
export const USERNAME_EXISTS_ERROR = "The user name already exists in database.";
export const COURSECODE_EXISTS_ERROR = "The course code already exists. Please try another.";
export const QUESTION_INPUT_TEXT = "Question Input";
export const LMS_GEOFENCED_ATTENDANCE = "To take your attendance, we need to open a new tab. Please click Start to begin.";
export const START_TEXT = "Start";

export const JOIN_POLL_SURVEY_ERROR = "You cannot join this activity. Please check activity code and try again later."; // disable join page for Graded Poll and Survey

export const TYPE_CORRECT_ANSWER_TEXT = "Type correct answer";
export const ADD_MEMBERS_TEXT = "Add Members";
export const ALLOW_API_ACCESS_TEXT = "Allow API Access";
export const JOIN_ACTIVITY_TEXT = "How to Join the Activity";

export const VIEW_ACTIVITY_TEXT = "View participants within the activity.";
export const PARTICIPANTS_PLATFORM_TEXT = "Total Participants";
export const PARTICIPANT_SUB_TITLE = "Number of active {Question} participants";
export const PARTICIPANT_SUB_TITLE_EACH_QUESTION = "Number of participants for {Question}";
export const PARTICIPANT_SUB_TITLE_OVER_ALL = "Number of participants for the activity";

export const UPDATE_CLOSE_QUESTION_BANK_TOOLTIP = "Update and close Question Bank";
export const UPDATE_CLOSE_SURVEY_TOOLTIP = "Update & Close Survey";
export const UPDATE_CLOSE_POLL_TOOLTIP = "Update & Close Poll";
export const SAVE_CLOSE_SURVEY_TOOLTIP = "Save & Close Survey";
export const SAVE_CLOSE_POLL_TOLLTIP = "Save & Close Poll";

//ppt insertion completed page
export const PPT_COMPLETE_PAGE_INSERT_SUCCESSFULLY_FORMAT  = "Successfully inserted {count} slide(s).";
export const PPT_COMPLETE_PAGE_INSERT_SUCCESSFULLY_DESCRIPTION  = "The activity is now available in your presentation window.";
export const PPT_COMPLETE_PAGE_GO_BACK_BUTTON_TEXT  = "Go back to Activities";


export const PPT_POLLS_TABLE_TITLE_HEADER = "Title";
export const PPT_POLLS_TABLE_SELECT_HEADER = "Select";
export const PPT_POLLS_TABLE_DATE_HEADER = "Date";
export const PPT_POLLS_TBLE_STATUS_HEADER = "Status"; 
export const PPT_POLLS_TABLE_TYPE_HEADER = "Type";
export const PPT_POLLS_TABLE_QUESTION_HEADER = "Questions";
export const PPT_POLLS_TABLE_ACTION_HEADER = "Action";
export const PPT_POLLS_DATE_ACCESSOR = "updatedDatePK";

export const GRADED_ADD_TIME_TEXT = "Would you like to give participants more time to answer?";
export const GRADED_ADD_TIME_OPTION_TEXT = "Add more time.";
export const ADD_TIME_MODAL_TITLE_TEXT = "Add Time";
export const ADD_TIME_MODAL_SUBMIT_TEXT = "Add";
export const ADD_TIME_MODAL_TEXT = "Choose from the following presets to increase the time.";

export const ADD_TIME_ALL_SUCCESS_MESSAGE = "You have successfully added {Time} to the poll.";
export const ADD_TIME_EACH_SUCCESS_MESSAGE = "You have successfully added {Time} to the question.";
export const ADD_TIME_ALL_STUDENT_MESSAGE = "{Time} been added to the poll."
export const ADD_TIME_EACH_STUDENT_MESSAGE = "{Time} been added to the question."

export const IMPORT_QUESTION_TEXT = "Import Questions";
export const IMPORT_QUESTION_MODAL_TITLE = "Import Questions from CSV File";
export const IMPORT_QUESTION_MODAL_BODY_TEXT = "You can download a template here:";
export const DOWNLOAD_SAMPLE_CSV_TEXT = "Download Sample CSV File";
export const UPLOAD_CSV_FILE_TEXT = "Please upload a CSV file containing the required fields listed below.";
export const LEARN_MORE_TEXT = "Learn more";
export const WATCH_VIDEO_TEXT = "Watch Video.";
export const CREATE_QUESTION_TUTORIAL_DESC = "Watch the video to learn more about how to build this question."
export const HOW_TO_USE_TEXT = "How to Use";
export const VALIDATE_IMPORT_TEXT = "{ImportCount} will be imported.";
export const INVALID_IMPORT_TEXT = "{UnimportCount} cannot be imported.";
export const LEARN_MORE_LINK = "https://support.yuja.com/hc/en-us/articles/24010160873495-How-to-Import-Questions-for-a-Poll-or-Survey-Using-a-CSV-File#h_01HZQVHBT3JT8471YPZRJGS5GN";

export const ACCOUNT_CARD_SETTING_TEXT = "Settings";
export const ACCOUNT_CARD_SUPPORT_TEXT = "Support";
export const ACCOUNT_CARD_LOGOUT_TEXT = "Logout";

export const SSO_LOGIN_POWERPOINT_TEXT = "Please open PowerPoint to continue.";
export const SSO_LOGIN_POWERPOINT_SUCCESS = "You have successfully logged in.";
export const SSO_LOGIN_POWERPOINT_FAILED = "You have not logged in.";


// View Poll Modal
export const VIEW_POLL_MODAL_TITLE = "View Poll";
export const VIEW_POLL_MODAL_QUE_TITLE_HEADER = "Question Title";
export const VIEW_POLL_MODAL_QUE_TYPE_HEADER = "Question Type";

export const RECYCLE_BIN_RESTORE_TEXT = "Restore";

export const DEPRECATED_POLL_TITLE = "No Activity Found";
export const DEPRECATED_POLL_MESSAGE = "The activity you are trying to join does not exist.";
export const RECYCLE_BIN_TITLE = "Recycle Bin";

export const PARTICIPANT_RESPONSE_TEXT = "Responses from Participants";

export const OPEN_ENDED_TIME_SORT = "Time";
export const OPEN_ENDED_LIKE_SORT = "Likes";
export const OPEN_ENDED_DISLIKE_SORT = "Dislikes";

export const SORT_BY_TEXT = "Sort by:";

export const COURSE_CODE_PLACEHOLDER = "Enter course code";
export const COURSE_NAME_PLACEHOLDER = "Enter course name";
export const ACTIVE_PARTICIPANTS_TEXT = "Active participants: ";
export const CLICK_IMAGE_TO_MARK_TEXT = "Click on the image below to mark";



//report page

export const REPORT_USER_TITLE_ARIAL = "My Report";
export const REPORT_USER_TITLE = "Activity Report";
export const REPORT_USER_TITLE_DESC = "A summary of activities you have created.";
export const REPORT_USER_LINE_CHART_TITLE = "Activity Analytics";
export const REPORT_USER_LINE_CHART_DESC = "Compare your overall activities with the number of polls and attendances you have created.";
export const REPORT_USER_STANDALONE_LINE_CHART_DESC = "Compare the overall standalone activities with the number of polls and attendances you have created.";
export const REPORT_USER_STANDALONE_TABLE_DESC = "View a list of standalone polls and attendances you have created.";



export const REPORT_INSTITUTION_TITLE = "Institution Report";
export const REPORT_INSTITUTION_TITLE_DESC = "A summary of all activities on your platform.";
export const REPORT_DATE_FILTER_LABEL = "Filter by Date:";
export const REPORT_POLL_METRIC_TITLE = "Total Polls";
export const REPORT_ATTENDANCE_METRIC_TITLE = "Total Attendance";
export const REPORT_TOTAL_QUE_METRIC_TITLE = "Total Questions";
export const REPORT_RESPONSE_METRIC_TITLE = "Total Responses";
export const REPORT_PARTICIPANT_METRIC_TITLE = "Total Participants";
export const REPORT_FINISHED_METRIC_TITLE = "Total Finished";
export const REPORT_INCOMPLETE_METRIC_TITLE = "Total Incomplete";


export const REPORT_INSTITUTION_LINE_CHART_TITLE = "Institution Analytics";
export const REPORT_INSTITUTION_LINE_CHART_DESC = "Compare your institution’s overall activities with the number of polls and attendances.";
export const REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_OVERALL = "Overall";
export const REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_POLLS = "Polls";
export const REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_ATTENDANCES = "Attendances";

export const REPORT_INSTITUTION_TABLE_TITLE = "Courses and Standalone Analytics";
export const REPORT_INSTITUTION_TABLE_DESC = "Access reports for courses and standalone activities.";
export const REPORT_INSTITUTION_TABLE_SEARCH_BOX_PLACEHOLDER = "Search by course name or course code";
export const REPORT_INSTITUTION_NAN_COURSE_LABEL_POLL_COUNT = "Total Polls";
export const REPORT_INSTITUTION_NAN_COURSE_LABEL_ATTENDANCE_COUNT = "Total Attendances";
export const REPORT_INSTITUTION_NAN_COURSE_LABEL_QUESTION_COUNT = "Total Questions";
export const REPORT_INSTITUTION_NAN_COURSE_LABEL_RESPONSE_COUNT = "Total Responses";
export const REPORT_INSTITUTION_NAN_COURSE_LABEL_PARTICIPANT_COUNT = "Total Participants";
export const REPORT_INSTITUTION_VIEW_NAN_COURSE_TITLE = "Standalone";
export const REPORT_INSTITUTION_VIEW_NAN_COURSE_DESC = "Click Full Report to view analytics for standalone polls and attendances.";
export const REPORT_INSTITUTION_VIEW_NAN_COURSE_METRIC_SUFFIX = "Total Activities";
export const REPORT_INSTITUTION_VIEW_NAN_COURSE_BUTTON = "Full Report";

export const REPORT_STANDALONE_TITLE = "Standalone Report";
export const REPORT_STANDALONE_TITLE_DESC = "A summary of standalone activities on your platform.";
export const REPORT_STANDALONE_LINE_CHART_TITLE = "Standalone Analytics";
export const REPORT_STANDALONE_LINE_CHART_DESC = "Compare your platform's overall standalone activities with the number of polls and attendances.";
export const REPORT_STANDALONE_TABLE_DESC = "View a list of standalone polls and attendances created on your platform.";


export const REPORT_COURSE_TITLE = "Course Report";
export const REPORT_COURSE_TITLE_DESC = "A summary of activities on your course.";
export const REPORT_COURSE_LINE_CHART_TITLE = "Course Analytics";
export const REPORT_COURSE_LINE_CHART_DESC = "Compare your course’s overall activities with the number of polls and attendances.";
export const REPORT_COURSE_TABLE_TITLE = "Activities";
export const REPORT_COURSE_TABLE_DESC = "View the total number of polls and attendances in the course.";
export const REPORT_COURSE_TABLE_SEARCH_BOX_PLACEHOLDER = "Search by title";
export const REPORT_COURSE_TABLE_VIEW_POLL_REPORT_LABEL = "Activity Report";

export const REPORT_ACTIVITY_TITLE = "Activity Report";
export const REPORT_ACTIVITY_TITLE_DESC = "A summary report for a selected activity.";
export const REPORT_ACTIVITY_PIE_CHART_PLATFORM_LABEL = "Platform";
export const REPORT_ACTIVITY_PIE_CHART_BROWSER_LABEL = "Browser";
export const REPORT_ACTIVITY_PIE_CHART_NO_DATA_LABEL = "No data available";
export const REPORT_ACTIVITY_TABLE_TITLE = "List of Question";
export const REPORT_ACTIVITY_TABLE_DESC = "View question types ranked on how often they have appeared in the activity.";
export const REPORT_ACTIVITY_TABLE_SEARCH_BOX_PLACEHOLDER = "Search by question title";



export const STANDALONE_TEXT = "Standalone";
export const REPORT_INSTITUTION_PAGE_TAB_KEY = {
    COURSES: "Courses",
    STANDALONE: "Standalone",
};

export const REPORT_COURSE_PAGE_TAB_KEY = {
    POLLS: "Polls",
    ATTENDANCES: "Attendances",
};


export const REPORT_INSTITUTION_COURSE_TABLE_HEADERS = {
    RANK: {name: "rank", description: "Rank"},
    COURSE_CODE: {name: "courseCode", description: "Course Code"},
    COURSE_NAME: {name: "courseName", description: "Course Name"},
    TOTAL_ACTIVITIES: {name: "activityCount", description: "Total Activities"},
    ACTION: {name: "action", description: ACTION_HEADER},
};


export const REPORT_INSTITUTION_NAN_COURSE_TABLE_HEADERS = {
    METRIC_NAME: {name: "metricName", description: "Standalone Activities"},
    COUNT: {name: "count", description: "Total Count"},
};




export const REPORT_COURSE_ACTIVITY_TABLE_HEADERS = {
    POLL_INDEX: {name: "index", description: "Number"},
    ATTENDANCE_INDEX: {name: "index", description: "Number"},
    TITLE: {name: "pollTitle", description: "Title"},
    PARTICIPANT_COUNT: {name: "participantCount", description: "Participants Count"},
    DATE: {name: "date", description: "Date"},
    ACTION: {name: "action", description: ACTION_HEADER},
};


export const REPORT_ACTIVITY_QUESTION_TABLE_HEADERS = {
    SERIAL_NO: {name: "serialNo", description: "Number"},
    TITLE: {name: "queTitle", description: "Question Title"},
    TYPES: {name: "questionType", description: "Question Types"},
    WEIGHTAGE: {name: "weightage", description: "Points Assigned"},
    FINISHED_COUNT: {name: "finishedCount", description: "Finished"},
    INCOMPLETE_COUNT: {name: "incompleteCount", description: "Incomplete"},
};


export const REPORT_BROWSER_TYPES = {
    CHROME: "Chrome",
    FIREFOX: "Firefox",
    EDGE: "Edge",
    SAFARI: "Safari",
    MOBILE_APP: "Mobile App",
    OTHERS: "Others",
};
export const REPORT_PLATFORM_TYPES = {
    APPLE: "Apple",
    ANDROID: "Android",
    WINDOWS: "Windows",
    OTHERS: "Others"
};


export const SCREEN_TYPE = {
    DESKTOP: "DESKTOP",
    TABLET: "TABLET",
    MOBILE: "MOBILE"
}

export const YUJA_DROPDOWN_SIZE = {SMALL: "small", MEDIUM: "medium", LARGE: "large"};
export const TRY_ANOTHER_SEARCH_TEXT = "Please try another search.";
export const POLL_SCORE_TEXT = "Poll Score";
