import "bootstrap/dist/css/bootstrap.css";
import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useState } from "react";
import { Modal} from "react-bootstrap";
import "./styles.css";
import { useLoading } from "../../utils/LoadingContext";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import {
  CLOSE,
  FEEDBACK_BUTTON_TEXT, HOME_PATH, LOGIN_PATH,
  NO_TEXT,
  REATTEMPT_MODAL_BODY,
  REATTEMPT_MODAL_TITLE,
  RETRY_TEXT, TEXTBOX,
  UNLIMITED_ATTEMPTS, USER_COMPLETE_POLL_REATTEMPT_SUBTITLE,
  USER_COMPLETE_POLL_SUBTITLE,
  YES_TEXT, YOU_ARE_ALL_DONE_TEXT,
} from "../../utils/constants";
import { useCountdown } from "../../hooks/useCountdown";

import YuJaButton from "../../components/standardization/YuJaButton";
import PollTitleContainer from "./PollTitleContainer";
import PollReadyClasses from "./PollReady.module.css";
import userCompleteGif from "../../images/user-complete.gif";
import styles from "./styles.module.css";
import FeedBackModal from "../../components/modals/FeedBackModal";
import {useSessionValidation} from "../../hooks/useSessionValidation";
import {useHistory} from "react-router-dom";

export default function ClassResultAllScheduled({
                                                  pollInfo,
                                                  handlePollComplete,
                                                  pollEndTime,
                                                  handleReattempt,
                                                  allowReattempt,
                                                  attemptsCount,
                                                  institutionId,
                                                  userName,
                                                  maxAttempts, isComplete,
}) {
  const {isLoggedIn, isFromGradeLink} = useSessionValidation();
  const history = useHistory();
  const { pollKey, uniqueCode, pollTitle} = pollInfo;
  const { loading, height } = useLoading();
  const [showReattemptModal, setShowReattemptModal] = useState(false);
  const [canReattempt, setCanReattempt] = useState(false);
  const [feedbackModalShow, setFeedBackModalShow] = useState(false);

  const { getReturnValues, resetCountdown } = useCountdown(pollEndTime, false); 
  const [days, hours, minutes, seconds, ] = getReturnValues;


  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
        handlePollComplete();
    }
  }, [days, hours, minutes, seconds]);

  useEffect(() => {
    resetCountdown(pollEndTime);
  }, [pollEndTime]);

  useEffect(() => {
    setCanReattempt(allowReattempt && (maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts));
  }, [allowReattempt, attemptsCount, maxAttempts]);

  const onClickReattempt = () => {
    setShowReattemptModal(true);
  };

  const handleClose = () => {
    setShowReattemptModal(false);
  };


    return (
        <div className={PollReadyClasses.parentContainer} style={{paddingBottom: 0}}>
          <FeedBackModal
              show={feedbackModalShow}
              setModalShow={setFeedBackModalShow}
              pollKey={pollKey}
              pollUniqueCode={uniqueCode}
              userId={userName}
              institutionId={institutionId}
          />

          <Modal
              id="reattempt-modal"
              show={showReattemptModal}
              onHide={handleClose}
              aria-labelledby="contained-modal-title-vcenter"
              backdrop={loading ? "static" : true}
              dialogClassName="sa-modal"
          >
            <Spin tip="Loading..." size="large" spinning={loading}>
              <ModalClose onClick={handleClose} className="modal-close"/>
              <Modal.Header style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Modal.Title>
                  {REATTEMPT_MODAL_TITLE}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {REATTEMPT_MODAL_BODY}
              </Modal.Body>
              <Modal.Footer>
                <button className="modal-button-cancel" onClick={handleClose}>
                  {NO_TEXT}
                </button>
                <button className="modal-button-submit" onClick={handleReattempt}>
                  {YES_TEXT}
                </button>
              </Modal.Footer>
            </Spin>
          </Modal>
          <div className={PollReadyClasses.progressBarContainer} style={{marginBottom: 15}}>
            <span className={PollReadyClasses.progressBar} style={{width: "100%"}}/>
          </div>

          <PollTitleContainer pollTitle={pollTitle} style={{width: "calc(100% - 20px)", alignSelf: "center"}}/>
          <div style={{flex: 1}}/>

          <img src={userCompleteGif} alt="user complete..." style={{width: "min(50vw, 500px)", alignSelf: "center"}}/>
          <span tabIndex={0} role={TEXTBOX} className={styles.emptyBoxTitle}>{YOU_ARE_ALL_DONE_TEXT}</span>
          <span style={{height: 20}}/>
          <span tabIndex={0} role={TEXTBOX} className={styles.emptyBoxDesc}>
            {canReattempt && USER_COMPLETE_POLL_REATTEMPT_SUBTITLE}
            {!canReattempt && USER_COMPLETE_POLL_SUBTITLE}
          </span>

          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 10,
            marginTop: 40
          }}>
            {!isFromGradeLink() &&
                <YuJaButton
                    onClick={() => history.push({pathname: isLoggedIn() ? HOME_PATH : LOGIN_PATH})}
                    style={{
                      fontSize: 14,
                      width: 130,
                      height: 35,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                >
                  {CLOSE}
                </YuJaButton>
            }

            {canReattempt &&
                <YuJaButton
                    onClick={onClickReattempt}
                    style={{
                      fontSize: 14,
                      width: 130,
                      height: 35,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                >
                  {RETRY_TEXT}
                </YuJaButton>
            }
          </div>


          <div style={{flex: 2}}/>

          <YuJaButton
              style={{
                position: "fixed", right: 0,
                transform: "translate(50%, 50%) rotate(-90deg)",
                transformOrigin: "bottom",
                top: "calc(80% - 90px)"
              }}
              type={"secondary"}
              onClick={() => setFeedBackModalShow(true)}
          >
            {FEEDBACK_BUTTON_TEXT}
          </YuJaButton>
        </div>
    )
}