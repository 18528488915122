import React, {Fragment, useEffect, useRef, useState} from "react";
// import { Tab, Tabs } from "react-bootstrap";
import * as FileSaver from "file-saver";
import { useLocation } from "react-router-dom";
import XLSX from "sheetjs-style";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import GradebookWarningModal from "../../components/modals/GradebookWarningModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as EmptyGradeBook } from "../../images/non-selected-poll-illustration.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import {
  ABSENT_STATUS,
  ABSENT_TEXT,
  ALL_STATUS_LABEL,
  ALL_STATUS_OPTION,
  ATTENDANCE,
  ATTENDANCE_FILTER_LABEL,
  COURSE_FILTER_LABEL,
  MENU_LIST,
  DOWNLOAD_RESULTS_TEXT,
  DRAFT_STATUS,
  EXPORT_GRADEBOOK_TEXT,
  FIXED_ANSWER,
  GET_RESULT_TEXT,
  GRADEBOOK_DEFAULT_MESSAGE,
  GRADEBOOK_SEARCH_BOX_PLACEHOLDER,
  INTEGRATION_TYPES,
  LMS_EXPORT_PREFIX,
  LMS_EXPORT_SUFFIX,
  MERGED_POLL_GRADEBOOK,
  OVERALL_RESULT_HEADER,
  POLL_TITLE_PREFIX,
  POLL_TYPE,
  PRESENT_STATUS,
  PRESENT_TEXT,
  QUESTION_TYPES,
  SEARCH_GRADEBOOK,
  SEARCH_TEXT,
  STATUS_LABEL,
  SYNC_ATTEMPT,
  SYNC_GRADEBOOK_TEXT,
  TEXTBOX,
  USER_ID_HEADER,
  GRADEBOOK,
  CHOOSE_A_ATTENDANCE_GRADEBOOK,
  GRADEBOOK_ATTENDANCE_DEFAULT_MESSAGE,
  GRADEBOOK_SEARCH_BOX_PLACEHOLDER_BY_NAME,
  SEARCH_A_POLL_TEXT,
  SEARCH_BY_POLL_TEXT,
  GRADEBOOK_POLL_TITLE_DESCRIPTION,
  GRADEBOOK_ATTENDANCE_TITLE_DESCRIPTION,
  CHOOSE_A_POLL_GRADEBOOK,
  POLL_TEXT,
  COURSE_PLACEHOLDER,
  SEARCH_BY_ATTENDANCE_TEXT, ATTENDANCE_PLACEHOLDER, SEARCH_BY_COURSE_TEXT, NON_COURSE_OPTION_TEXT
} from "../../utils/constants";
import { getLabelFromIntegrationType, hasWeightage, notifyError, prepareAttemptSubrows, prepareAttendanceSubrows, removePrefix, removePrefixNew } from "../../utils/helpers";
import { LOADING_SEARCH_ERROR, MISSING_ATTENDANCE_FILTER_ERROR, MISSING_OPTION_ERROR } from "../../utils/toast-message-constants";
import styles from "./GradeBook.module.css";
import GradeBookTabs from "./GradeBookTabs";
import 'rc-datepicker/lib/style.css';
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import AttendanceGradeBookTabs from "./AttendanceGradeBookTabs";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

import { ReactComponent as NavbarArrow } from "../../images/navbar_arrow_purple.svg";

export default function GradeBookPage({ setPageIdentifier }) {
  const location = useLocation();
  const { getPollsFromUser, getAttendancesFromCourse } = PollApiCalls();
  const { getResultByGradeBookPerspective, getAttendanceGradebookResult } = ResponseApiCalls();
  const { getSetting } = InstitutionApiCalls();
  const { getCourseByUser } = CourseApiCalls();
  const isNarrow = useMobileAccess(1350);

  const [options, setOptions] = useState([]);
  const [rawOpts, setRawOpts] = useState([]);
  const [updatedResponses, setUpdatedResponses] = useState([]);
  // const [questionBreakDownData, setQuestionBreakDownData] = useState([]);
  // const [studentBreakDownData, setStudentBreakDownData] = useState([]);
  const [gradeBookData, setGradeBookData] = useState({ responses: [], questions: [] });
  const [selectedOption, setSelectedOption] = useState(null);
  const [showTable, setShowTable] = useState(true);
  // const [downloadResultsBtn, setDownloadResultsBut] = useState(false);
  // const [exportGradebookBtn, setExportGradebookBtn] = useState(false);
  const [syncGradebookBtn, setSyncGradebookBtn] = useState(false);
  // const [showSyncOptions, setShowSyncOptions] = useState(false);
  const [autoSyncData, setAutoSyncData] = useState(null);
  const [syncMode, setSyncMode] = useState(0);
  // const [showExportOptions, setShowExportOptions] = useState(false);
  const [exportMode, setExportMode] = useState("");
  // const [fileName, setFileName] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [canvasExcelData, setCanvasExcelData] = useState([]);
  const [blackboardExcelData, setBlackboardExcelData] = useState([]);
  const [, getSession] = useLocalStorageNew("session", {});
  const [integrationType, setIntegrationType] = useState(null);
  const [exportOptions, setExportOptions] = useState([]);
  const [exportOptionsLoading, setExportOptionsLoading] = useState(false);
  const pk = useRef("");
  const pc = useRef("");
  const pollTitle = useRef("");
  const pollType = useRef("");
  const gradeEnabled = useRef(true);
  const lmsAttempt = useRef("");
  const courseId = useRef("");
  // const geofence = useRef("");
  const pollShareMode = useRef("");
  const [pollsLoading, setPollsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [warningModalShow, setWarningModalShow] = useState(false);
  const [filter, setFilter] = useState({});
  // const [pageIndex, setPageIndex] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState(ALL_STATUS_OPTION);
  const [courseOptions, setCourseOptions] = useState([]);
  const [courseFilter, setCourseFilter] = useState(null);
  const [courseReady, setCourseReady] = useState(false);
  const [attendanceFilter, setAttendanceFilter] = useState(null);
  const [loadingCourses, setLoadingCourses] = useState(true);

  const getPlatformSetting = () => {
    return getSetting().then((result) => {
      // console.log(result);
      if (!!result && !!result.LMSQuizAttempts) {
        // console.log(result.LMSQuizAttempts);
        return result.LMSQuizAttempts;
      } else {
        return SYNC_ATTEMPT.FIRST.value; // default
      }
    });
  };

  const getCourses = async () => {
    setLoadingCourses(true);
    const session = getSession();
    let res;
    if (session.idpType && session.intType) {
      res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
      res = await getCourseByUser();
    }
    res.sort(function (a, b) {
      return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
    });

    let opts = res.map(course => ({
      label: `${course.courseCode} - ${course.courseName}`,
      value: course.courseId,
      ...course
    }));

    opts = [{label: NON_COURSE_OPTION_TEXT, value: "0"}].concat(opts);

    setCourseOptions(opts);
    setLoadingCourses(false);
  };

  useEffect(() => {
    // console.log(courseOptions, options, courseReady, selectedOption?.courseId);
    if (courseOptions.length && options.length && !courseReady && selectedOption?.courseId) {
      const course = courseOptions.find(course => course.value === removePrefix(selectedOption.courseId));
      setCourseFilter(course);
      setCourseReady(true);
    }
  }, [loadingCourses, selectedOption]);

  const chooseCourse = async (course) => {
    setCourseFilter(course);
    setAttendanceFilter("");
    if (course.value === "0") {
      setOptions(rawOpts.filter(opt => !opt.courseId));
    } else {
      setOptions(rawOpts.filter(opt => !!opt.courseId && opt.courseId.includes(course.value)));
    }
  };

  const chooseAttendance = (option) => {
    setAttendanceFilter(option);
    setSelectedOption(option);
  };

  useEffect(() => {
    const session = getSession();
    // console.log(session, session?.intType, session?.idpType);
    setIntegrationType(session?.intType);
  }, []);

  useEffect(async () => {
    if (location?.state?.pollType === POLL_TYPE.ATTENDANCE) {
      setPageIdentifier(<>{GRADEBOOK}<NavbarArrow height={15}/>{ATTENDANCE}</>);
    } else {
      setPageIdentifier(<>{GRADEBOOK}<NavbarArrow height={15}/>{MERGED_POLL_GRADEBOOK}</>);
    }

    if (!location || !location.state || !location.state.pollKey || !location.state.uniqueCode) {
      setOptions([]);
      setSelectedOption("");
      setAttendanceFilter("");
      setCourseFilter({label: NON_COURSE_OPTION_TEXT, value: "0"});
      setShowTable(false);
    }

    setPollsLoading(true);
    const res = await getPollsFromUser(true);
    const defaultLmsAttempt = await getPlatformSetting();
    // console.log(defaultLmsAttempt);
    // sort res by last updatedtime
    res.sort((poll1, poll2) => {
      let date1 = new Date(poll1.updatedTimeSK);
      let date2 = new Date(poll2.updatedTimeSK);
      return date2 - date1;
    })

    const newOptions = res.filter(poll => poll.pollSortKey !== DRAFT_STATUS)
      .filter(poll => location?.state?.pollType === POLL_TYPE.ATTENDANCE ? poll.pollType === POLL_TYPE.ATTENDANCE : poll.pollType !== POLL_TYPE.ATTENDANCE)
      .map(poll => ({
        label: poll.pollTitle + " (" + removePrefixNew(poll.pollSortKey) + ")",
        value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
        pollTitle: `${poll.pollTitle.trim()}`,
        pollType: `${poll.pollType}`,
        courseId: poll?.courseId,
        inCourse: !!poll.courseId,
        inLmsCourse: !!poll.lmsHomeUrl,
        pollShareMode: poll.pollShareMode,
        lmsAttempt: poll.lmsAttempt ? poll.lmsAttempt : defaultLmsAttempt,
        geofence: poll?.geofence,
        syncLms: poll?.syncLms,
        LMSColumnId: poll?.LMSColumnId
      }));
    if (location?.state?.pollType === POLL_TYPE.ATTENDANCE) {
      setOptions(newOptions.filter(opt => !opt.courseId));
    } else {
      setOptions(newOptions);
    }

    setRawOpts(newOptions);
    setPollsLoading(false);

    if (location && location.state && location.state.pollKey && location.state.uniqueCode) {
      const key = `${location.state.pollKey}#${location.state.uniqueCode}`;
      const option = newOptions.find(ele => ele.value === key);
      if (location?.state?.pollType === POLL_TYPE.ATTENDANCE) {
        chooseAttendance(option);
        if (!!option.courseId) {
          setOptions(newOptions.filter(opt => !!opt.courseId && opt.courseId.includes(option.courseId)));
        }
      } else {
        setSelectedOption(option);
      }

      await getResult(option);
      // setSyncGradebookBtn(!!location.state.syncGradebookBtn ? location.state.syncGradebookBtn : false);
      if (!!location.state.autoSync) {
        setAutoSyncData(location.state.autoSync);
        location.state.autoSync = null;
      }
    }

    if (location?.state?.pollType === POLL_TYPE.ATTENDANCE) {
      await getCourses();
    }
  }, [location]);

  const getAttendanceResult = async () => {
    setSyncGradebookBtn(false);
    if (attendanceFilter) {
      getResult(attendanceFilter);
      setSyncGradebookBtn(attendanceFilter.inLmsCourse && attendanceFilter.syncLms);

      return;
    }

    if (!courseFilter) {
      notifyError(MISSING_ATTENDANCE_FILTER_ERROR);
      return;
    }

    setKeyword("");
    setStatusFilter(null);
    setFilter({keyword: ""});
    setShowTable(true);
    setDataLoading(true);
    setExportOptionsLoading(true);

    let promises = [];
    options.map(key => {
      const arr = key.value.split("#");
      const [pollKey, uniqueCode] = arr;
      pk.current = removePrefix(pollKey);
      pc.current = uniqueCode;
      pollTitle.current = key.pollTitle;
      pollType.current = key.pollType.toUpperCase();
      lmsAttempt.current = key.lmsAttempt;
      courseId.current = key.courseId;
      // geofence.current = key.geofence;
      
      const res = getAttendanceGradebookResult(pollKey, uniqueCode);
      promises.push(res);
    });

    promises = await Promise.all(promises);

    // let questions = [];
    let responses = [];
    // let gradeBookBreakdownResult = {};
    promises.map(res => {
      // questions = questions.concat(res?.questions);
      responses = responses.concat(prepareAttendanceSubrows(res?.responses));
      // gradeBookBreakdownResult = {questions: questions, responses: responses};
    });
    // console.log(gradeBookBreakdownResult);
    setUpdatedResponses(responses);
    // setGradeBookData(gradeBookBreakdownResult);

    // setSyncGradebookBtn(true);
    setExportOptions([]);
    setExportOptionsLoading(false);
    setDataLoading(false);
    setKeyword("");
  };

  const choosePoll = (option) => {
    setSelectedOption(option);
  };

  const loadPollGradeBook = async (pollKey, uniqueCode, key) => {
    key = !!key ? key : selectedOption;
    const res = await getResultByGradeBookPerspective(pollKey, uniqueCode);
    const {gradeBookBreakdownResult} = res;
    gradeBookBreakdownResult.questions = gradeBookBreakdownResult.questions.sort((a, b) => { // sort questions by serialNo
      return a.serialNo - b.serialNo;
    });

    gradeEnabled.current = hasWeightage(gradeBookBreakdownResult.questions) && pollType.current !== POLL_TYPE.SURVEY;
    const responsesWithSubRows = prepareAttemptSubrows(gradeBookBreakdownResult.responses);
    setUpdatedResponses(responsesWithSubRows);
    let pollTitleColumnName = POLL_TITLE_PREFIX + key.pollTitle;
    let excelDataFormat = [], canvasExcelDataFormat = [], blackboardExcelDataFormat = [];
    let numQuestions = gradeBookBreakdownResult.questions.length;
    for (let element of responsesWithSubRows) {
      if (!element.attempts || !element.attempts[0]) console.log("Missing Attempt #0 in ", element);
      let attempt = !!element.attempts && element.attempts[0] ? element.attempts[0] : element;
      // console.log(element, attempt);
      let excelEntry = {
        "Student": element.firstName + (element.lastName ? " " + element.lastName : ""),
        "IP Address": element.ipAddress,
        "Responded By": element.browserType,
        "Platform": element.platform
      };
      let canvasExcelEntry = {
        "Student": (element.lastName ? element.lastName + ", " : "") + element.firstName,
        "ID": element.tpUId,
        "SIS User ID": "",
        "SIS Login ID": "",
        "Section": "",
      };
      let blackboardExcelEntry = {
        "Last Name": element.lastName,
        "First Name": element.firstName,
        "Username": element.loginUsername,
        "Availability": "Yes",
      };
      for (let i = 0; i < numQuestions; i++) {
        let questionNum = "Q" + (i + 1);
        let answerNum = "A" + (i + 1);
        if (pollType.current !== POLL_TYPE.ATTENDANCE && (!gradeBookBreakdownResult.questions[i].weightage || pollType.current === POLL_TYPE.SURVEY)) {
          if (!element[questionNum]) {
            excelEntry[questionNum] = "Not Posted";
            continue;
          }
          if (element[questionNum] === "No Response") {
            excelEntry[questionNum] = "No Response";
            continue;
          }
          // console.log(gradeBookBreakdownResult.questions[i]);
          let questionType = gradeBookBreakdownResult.questions[i].questionType;
          // console.log(questionType);
          // console.log(element[answerNum]);
          if (questionType === QUESTION_TYPES.MCSS.name) {
            if (element[answerNum][0] === FIXED_ANSWER.UNANSWERED) {
              excelEntry[questionNum] = "No Response";
              continue;
            }
            let resp = '';
            element[answerNum].sort((a, b) => a.localeCompare(b)).map((key) => { resp += key.toUpperCase() });
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.TF.name) {
            excelEntry[questionNum] = element[answerNum];
          } else if (questionType === QUESTION_TYPES.SA.name) {
            excelEntry[questionNum] = element[answerNum][0];
          } else if (questionType === QUESTION_TYPES.FITB.name) {
            let resp = '';
            Object.keys(element[answerNum]).map((key, index) => {
              // console.log(`${index === 0 ? '' : ', '}${key}: ${element[answerNum][key]}`);
              resp += `${index === 0 ? '' : ', '}${key}: ${element[answerNum][key]}`;
            });
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.CI.name) {
            if (element[answerNum].selected === 'other') {
              excelEntry[questionNum] = "other";
              continue;
            }
            excelEntry[questionNum] = String.fromCharCode(65 + parseInt(element[answerNum].selected, 10));
          } else if (questionType === QUESTION_TYPES.WC.name) {
            let resp = '';
            Object.values(element[answerNum]).map((value, index) => {
              resp += `${index === 0 ? '' : ', '}${value}`;
            });
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.RK.name) {
            let resp = '';
            element[answerNum].map((value, index) => {
              resp += `Rank ${index + 1} : ${value} `;
            })
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.OE.name) {
            excelEntry[questionNum] = element[answerNum][0];
          } else {
            excelEntry[questionNum] = "Unknown Q Type" + questionType;
          }
          // console.log(excelEntry[questionNum]);
        } else {
          excelEntry[questionNum] = element[questionNum] ? element[questionNum] : "Not Posted";
        }
      }
      if (gradeEnabled.current) {
        // console.log(element.score);
        const score = attempt.score && attempt.score.includes("%") ? attempt.score.substring(0, attempt.score.length - 1) : attempt.score;
        if (courseId.current === null || courseId.current === undefined) {
          excelEntry[OVERALL_RESULT_HEADER] = score;
        } else {
          blackboardExcelEntry[key.pollTitle] = score;
          excelEntry[USER_ID_HEADER] = element.tpUId;
          excelEntry[pollTitleColumnName] = score;
          canvasExcelEntry[key.pollTitle] = score;
        }
      }
      // console.log(excelEntry);
      excelDataFormat.push(excelEntry);
      canvasExcelDataFormat.push(canvasExcelEntry);
      blackboardExcelDataFormat.push(blackboardExcelEntry);
    }
    setGradeBookData(gradeBookBreakdownResult);
    // setStudentBreakDownData(studentBreakdownResult);
    // setQuestionBreakDownData(questionBreakdownResult);
    setSyncGradebookBtn(gradeEnabled.current && (!!key.inLmsCourse || !!key.LMSColumnId));
    // console.log(excelDataFormat);
    setExcelData(excelDataFormat);
    setCanvasExcelData(canvasExcelDataFormat);
    setBlackboardExcelData(blackboardExcelDataFormat);
    // setFileName(selectedOption.pollTitle + " Class Results");
    let options = [];
    options.push({ value: "", label: DOWNLOAD_RESULTS_TEXT });
    if (!gradeEnabled.current || courseId.current === null || courseId.current === undefined) {
      // setDownloadResultsBut(gradeBookBreakdownResult.responses.length > 0);
      // setExportGradebookBtn(false);
    } else {
      // setDownloadResultsBut(false);
      // setExportGradebookBtn(gradeBookBreakdownResult.responses.length > 0);
      if (!!integrationType) {
        options.push({ value: integrationType, label: LMS_EXPORT_PREFIX + getLabelFromIntegrationType(integrationType) + LMS_EXPORT_SUFFIX });
      }
    }
    setExportOptions(options);
    setExportOptionsLoading(false);

  }

  const getResult = async (key) => {
    setKeyword("");
    setFilter({keyword: ""});

    key = !!key ? key : selectedOption;
    if (!key) {
      notifyError(MISSING_OPTION_ERROR);
      return;
    }

    setSelectedOption(key);
    setShowTable(true);
    setDataLoading(true);
    setExportOptionsLoading(true);
    const arr = key.value.split("#");
    const [pollKey, uniqueCode] = arr;
    pk.current = removePrefix(pollKey);
    pc.current = uniqueCode;
    pollTitle.current = key.pollTitle;
    pollType.current = key.pollType.toUpperCase();
    lmsAttempt.current = key.lmsAttempt;
    courseId.current = key.courseId;
    // geofence.current = key.geofence;
    pollShareMode.current = key.pollShareMode;

    if (pollType.current === POLL_TYPE.ATTENDANCE) {
      gradeEnabled.current = false;
      const res = await getAttendanceGradebookResult(pollKey, uniqueCode);
      setUpdatedResponses(prepareAttendanceSubrows(res?.responses));
      setGradeBookData(res);
      setExportOptions([]);
      setExportOptionsLoading(false);
      setDataLoading(false);
      return;
    }

    await loadPollGradeBook(pollKey, uniqueCode, key);
    setDataLoading(false);
  };

  const exportExcel = async (integration = "") => {
    setExportMode(integration);
    // const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".csv";
    // const wb = { Sheets: {'data':ws }, SheetNames: ['data']};
    // const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array"});
    // const data = new Blob([excelBuffer], { type: fileType});
    // FileSaver.saveAs(data, fileName + fileExtension);
    const key = selectedOption;
    const arr = key.value.split("#");
    const [pollKey, uniqueCode] = arr;
    pk.current = removePrefix(pollKey);
    pc.current = uniqueCode;
    pollTitle.current = key.pollTitle;
    pollType.current = key.pollType.toUpperCase();
    lmsAttempt.current = key.lmsAttempt;
    // console.log(lmsAttempt.current);

    // const pollRecord = await getPollRecordFromUniqueCode(uniqueCode);
    // const courseId = pollRecord && pollRecord.poll ? pollRecord.poll.index2Pk : null;
    if (integration === "" || !gradeEnabled.current || courseId.current === undefined || courseId.current === null) {
      handleExportFromModal();
    } else {
      setWarningModalShow(true);
    }
  };

  const handleExportFromModal = () => {
    const fileType = "application/octet-stream";
    const fileExtension = ".csv";
    const ws = XLSX.utils.json_to_sheet(exportMode === INTEGRATION_TYPES.CANVAS ? canvasExcelData : exportMode === INTEGRATION_TYPES.BLACKBOARD ? blackboardExcelData : excelData);
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const data = new Blob([s2ab(csvOutput)], { type: fileType });
    FileSaver.saveAs(data, pollTitle.current + " Class Results" + fileExtension);
    setWarningModalShow(false);
  };

  const clickSearch = (key)  => {
    if (pollsLoading) {
      notifyError(LOADING_SEARCH_ERROR);
      return;
    }

    setFilter({
      keyword: key === undefined ? keyword : key,
      status: statusFilter.value
      // courseId: course,
      // attendanceKey: attendance
    });
    // setPageIndex(0);
  };

  const enterKeyup = (e) => {
    if (e.key === 'Enter') {
      clickSearch(e.target.value);
    }
  };

  return (
      <>
        {location?.state?.pollType === POLL_TYPE.ATTENDANCE &&
          <div className={styles.mainContainer}>
            <div className={styles.searchContainer}>
              {(loadingCourses || pollsLoading) &&
                  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                    <div>
                      <Skeleton borderRadius={10} height={25}
                                width={150}
                                style={{position: "relative"}}/>
                      <div style={{height: 10}}/>

                      <Skeleton borderRadius={10} height={15}
                                width={"min(30%, 300px)"}
                                style={{position: "relative"}}/>
                    </div>
                    <Skeleton borderRadius={10} height={25} width={"100%"} style={{position: "relative"}}/>
                  </SkeletonTheme>
              }
              {!loadingCourses && !pollsLoading &&
                  <>
                    <div>
                      <div tabIndex={0} className={styles.searchContainerTitle}>{MENU_LIST.GRADEBOOK.name}</div>
                      <div style={{height: 10}}/>
                      <div tabIndex={0} className={styles.searchContainerDesc}>{GRADEBOOK_ATTENDANCE_TITLE_DESCRIPTION}</div>
                    </div>

                    <div className={styles.attendanceSearchRow}>
                      <div tabIndex={0} role={TEXTBOX} className={styles.searchLabel}>{COURSE_FILTER_LABEL}</div>

                      <YuJaDropdown
                          searchAble={true}
                          placeholder={COURSE_PLACEHOLDER}
                          searchPlaceholder={SEARCH_BY_COURSE_TEXT}
                          data={courseOptions}
                          value={courseFilter?.value ? courseFilter.value : "0"}
                          onChange={chooseCourse}
                      />

                      <div tabIndex={0} role={TEXTBOX} className={styles.searchLabel}
                           style={{width: 100}}>{ATTENDANCE_FILTER_LABEL}</div>
                      <YuJaDropdown
                          placeholder={ATTENDANCE_PLACEHOLDER}
                          searchPlaceholder={SEARCH_BY_ATTENDANCE_TEXT}
                          data={options}
                          value={attendanceFilter?.value}
                          searchAble={true}
                          onChange={chooseAttendance}
                          containerStyle={{minWidth: 0}}
                      />

                      <YuJaButton
                          type="primary"
                          onClick={getAttendanceResult}
                          style={{fontSize: 15, width: 120}}
                          disabled={!attendanceFilter}
                      >
                        {GET_RESULT_TEXT}
                      </YuJaButton>

                    </div>

                  </>
              }
            </div>

            {(loadingCourses || pollsLoading) &&
                <div className={styles.body} style={{flex: 1}}>
                  <div style={{flex: 1}}/>
                  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                    <div style={{width: "50%", height: "30vh", alignSelf: "center", justifyContent: "center"}}>
                      <Skeleton borderRadius={10} height={"100%"} width={"100%"}/>
                    </div>
                  </SkeletonTheme>
                  <div style={{flex: 2}}/>
                </div>
            }

            {!loadingCourses && !pollsLoading && !showTable &&
                <div className={styles.body} style={{flex: 1}}>
                  <div style={{flex: 1}}/>
                  <EmptyGradeBook style={{alignSelf: "center"}}/>
                  <div style={{height: 20}}/>
                  <div className={styles.emptyTitle} style={{alignSelf: "center"}}>{CHOOSE_A_ATTENDANCE_GRADEBOOK}</div>
                  <div className={styles.emptyDesc} style={{alignSelf: "center"}}>{GRADEBOOK_ATTENDANCE_DEFAULT_MESSAGE}</div>
                  <div style={{flex: 2}}/>
                </div>
            }


            {!loadingCourses && !pollsLoading && showTable &&
              <div className={styles.body}>
                <div className={styles.searchContainer} style={{flexDirection: "row", padding: "15px 10%"}}>
                  <YuJaTextBox
                      label={SEARCH_GRADEBOOK}
                      containerStyle={{fontSize: 15, fontWeight: 400}}
                      placeholder={attendanceFilter ? GRADEBOOK_SEARCH_BOX_PLACEHOLDER_BY_NAME : GRADEBOOK_SEARCH_BOX_PLACEHOLDER}
                      onChange={e => setKeyword(e.target.value)}
                      onKeyUp={enterKeyup}
                      value={keyword}
                      before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                  />

                  <YuJaButton
                      style={{
                        width: 80,
                        height: 40,
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      onClick={() => clickSearch()}>{SEARCH_TEXT}
                  </YuJaButton>

                  <YuJaDropdown
                      ariaLabel={statusFilter?.value + ALL_STATUS_LABEL}
                      data={[ALL_STATUS_OPTION, {value: PRESENT_STATUS, label: PRESENT_TEXT}, {
                        value: ABSENT_STATUS,
                        label: ABSENT_TEXT
                      }]}
                      onChange={option => setFilter({
                        keyword: keyword,
                        status: option.value
                        // courseId: course,
                        // attendanceKey: attendance
                      })}
                      value={statusFilter?.value}
                      containerStyle={{width: 100, flexShrink: 0}}
                      style={{padding: "8px 15px"}}
                      placeholder={STATUS_LABEL}
                  />
                </div>

                <AttendanceGradeBookTabs
                    responses={updatedResponses}
                    setResponses={setUpdatedResponses}
                    // questions={gradeBookData.questions}
                    pollKey={pk.current}
                    pollCode={pc.current}
                    // pollTitle={pollTitle.current}
                    pollType={POLL_TYPE.ATTENDANCE}
                    // lmsAttempt={lmsAttempt.current}
                    syncLmsEnable={!!attendanceFilter?.syncLms}
                    geofenceEnable={!!attendanceFilter?.geofence && !!Object.keys(attendanceFilter?.geofence).length}
                    syncMode={syncMode}
                    setSyncMode={setSyncMode}
                    showCheckbox={syncGradebookBtn}
                    // showCheckbox={true}
                    dataLoading={dataLoading}
                    // setDataLoading={setDataLoading}
                    autoSyncData={autoSyncData}
                    setAutoSyncData={setAutoSyncData}
                    globalFilterObj={filter}
                    // pageIdx={pageIndex}
                    // geofence={geofence}
                    attendanceFilter={attendanceFilter}
                />
              </div>
            }
          </div>
        }

        {location?.state?.pollType !== POLL_TYPE.ATTENDANCE &&
            <div className={styles.mainContainer}>
              <div className={styles.searchContainer}>
                {pollsLoading &&
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                      <div>
                        <Skeleton borderRadius={10} height={25}
                                  width={150}
                                  style={{position: "relative"}}/>
                        <div style={{height: 10}}/>

                        <Skeleton borderRadius={10} height={15}
                                  width={"min(30%, 300px)"}
                                  style={{position: "relative"}}/>
                      </div>
                      <Skeleton borderRadius={10} height={25} width={"100%"} style={{position: "relative"}}/>
                    </SkeletonTheme>
                }
                {!pollsLoading &&
                    <>
                      <div>
                        <div tabIndex={0} className={styles.searchContainerTitle}>{MENU_LIST.GRADEBOOK.name}</div>
                        <div style={{height: 10}}/>
                        <div tabIndex={0} className={styles.searchContainerDesc}>{GRADEBOOK_POLL_TITLE_DESCRIPTION}</div>
                      </div>

                      <div className={styles.searchRow}>
                        <div tabIndex={0} role={TEXTBOX}
                             className={styles.searchLabel}>{POLL_TEXT}</div>
                        <div/>
                        <YuJaDropdown
                            placeholder={SEARCH_A_POLL_TEXT}
                            searchPlaceholder={SEARCH_BY_POLL_TEXT}
                            data={options}
                            value={selectedOption?.value}
                            searchAble={true}
                            onChange={choosePoll}
                            containerStyle={{flex: 1, minWidth: 250}}
                        />

                        <YuJaButton
                            type="primary"
                            onClick={() => getResult()}
                            style={{fontSize: 15, width: 120}}
                            disabled={!selectedOption}
                        >
                          {GET_RESULT_TEXT}
                        </YuJaButton>

                        {!!selectedOption && gradeBookData.responses.length > 0 &&
                            <YuJaDropdown
                                placeholder={EXPORT_GRADEBOOK_TEXT}
                                data={exportOptions}
                                onChange={option => exportExcel(option.value)}
                                containerStyle={{width: 220}}
                            />
                        }


                        {/*{syncGradebookBtn && gradeBookData.responses.length > 0 &&*/}
                        {/*    <div className={styles.syncGradebookContainer} style={{margin: "0 10px", fontSize: 13}}>*/}
                        {/*      <YuJaButton onClick={() => setSyncMode(1)}>*/}
                        {/*        {SYNC_GRADEBOOK_TEXT}*/}
                        {/*      </YuJaButton>*/}
                        {/*    </div>*/}

                        {/*}*/}
                      </div>
                    </>
                }
              </div>
              {!!pollsLoading &&
                <div className={styles.body} style={{flex: 1}}>
                  <div style={{flex: 1}}/>
                  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                    <div style={{width: "50%", height: "30vh", alignSelf: "center", justifyContent: "center"}}>
                      <Skeleton borderRadius={10} height={"100%"} width={"100%"}/>
                    </div>
                  </SkeletonTheme>
                  <div style={{flex: 2}}/>
                </div>
              }

              {!pollsLoading && !showTable &&
                  <div className={styles.body} style={{flex: 1}}>
                    <div style={{flex: 1}}/>
                    <EmptyGradeBook style={{alignSelf: "center"}}/>
                    <div style={{height: 20}}/>
                    <div className={styles.emptyTitle} style={{alignSelf: "center"}}>{CHOOSE_A_POLL_GRADEBOOK}</div>
                    <div className={styles.emptyDesc} style={{alignSelf: "center"}}>{GRADEBOOK_DEFAULT_MESSAGE}</div>
                    <div style={{flex: 2}}/>
                  </div>
              }

              {!pollsLoading && showTable &&
                  <div className={styles.body}>
                    {!!dataLoading &&
                        <div className={styles.searchContainer} style={{flexDirection: "row", padding: "15px 10%"}}>
                          <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                            <div style={{flex: 1, height: 40}}>
                              <Skeleton borderRadius={6} height={"100%"} width={"100%"}/>
                            </div>
                            <div style={{width: 120, height: 40}}>
                              <Skeleton borderRadius={6} height={"100%"} width={"100%"}/>
                            </div>
                          </SkeletonTheme>
                        </div>
                    }

                    {!dataLoading &&
                        <div className={styles.searchContainer} style={{flexDirection: "row", padding: "15px 10%"}}>
                          <YuJaTextBox
                              containerStyle={{height: 40, fontSize: 15, fontWeight: "400"}}
                              placeholder={GRADEBOOK_SEARCH_BOX_PLACEHOLDER_BY_NAME}
                              before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                              onChange={e => setKeyword(e.target.value)}
                              onKeyUp={enterKeyup}
                          />
                          <YuJaButton style={{fontSize: 15, width: 150}} onClick={() => clickSearch()}>
                            {SEARCH_TEXT}
                          </YuJaButton>
                        </div>
                    }

                    <GradeBookTabs
                        responses={updatedResponses}
                        setResponses={setUpdatedResponses}
                        questions={gradeBookData.questions}
                        pollKey={pk.current}
                        pollCode={pc.current}
                        pollTitle={pollTitle.current}
                        pollType={pollType.current}
                        lmsAttempt={lmsAttempt.current}
                        syncMode={syncMode}
                        pollShareMode={pollShareMode.current}
                        setSyncMode={setSyncMode}
                        showCheckbox={syncGradebookBtn}
                        dataLoading={dataLoading}
                        setDataLoading={setDataLoading}
                        autoSyncData={autoSyncData}
                        setAutoSyncData={setAutoSyncData}
                        globalFilterObj={filter}
                        loadData={getResult}
                    />
                  </div>
              }

              <GradebookWarningModal
                  show={warningModalShow}
                  setModalShow={setWarningModalShow}
                  handleExportFromModal={handleExportFromModal}
              />
            </div>
        }
      </>
  );
}