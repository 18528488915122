import PropTypes from 'prop-types';
import styles from "./YuJaButton.module.css";
import {useEffect, useRef, useState} from "react";
import { BUTTON } from '../../utils/constants';


export default function YuJaButton({ children, onClick, wrapperStyle={}, type="primary", index=0, htmlFor, onlyIcon=false, ...props }) {
    const typeArr = ["primary", "secondary", "delete", "deleteImage"];
    const [btnPadding, setBtnPadding] = useState("8px 10px");
    const actualBtnRef = useRef(null);

    const wrappedOnClick = (e) => {
        const {disabled} = props;
        if (disabled) {
            return;
        }
        if (onClick) {
            onClick(e);
        }
        // Trigger the file input click when the button is clicked
        if (htmlFor) {
            const inputElement = document.getElementById(htmlFor);
            if (inputElement) {
                inputElement.click();
            }
        }
        document.activeElement.blur();
    }

    useEffect(() => {
        const fontSize = parseFloat(getComputedStyle(actualBtnRef.current).fontSize);
        if (props.style?.hasOwnProperty("padding")) {
            setBtnPadding(props.style.padding);
            return;
        }


        if (fontSize > 14) {
            setBtnPadding(`${8 * fontSize / 14}px ${10 * fontSize / 14}px`);
        }
    }, []);

    return (
        <>
            {(type === "primary" || !typeArr.includes(type)) &&
                <div
                    ref={actualBtnRef}
                    className={`${styles.container} ${styles.primary}`}
                    onClick={e => wrappedOnClick(e)}
                    {...props}
                    style={{...props.style, ...{padding: btnPadding}}}
                    tabIndex={index}
                    role={BUTTON}
                >
                    <div
                        className={`${styles.wrapper} ${onlyIcon ? styles.iconWrapper : ""}`}
                        style={{...wrapperStyle}}
                    >{children}</div>
                </div>
            }

            {type === "secondary" &&
                <div
                    ref={actualBtnRef}
                    className={`${styles.container} 
                    ${styles.secondary}`}
                    onClick={e => wrappedOnClick(e)}
                    {...props}
                    style={{...props.style, ...{padding: btnPadding}}}
                    tabIndex={index}
                    role={BUTTON}
                >
                    <div
                        className={`${styles.wrapper} ${onlyIcon ? styles.iconWrapper : ""}`}
                        style={{...wrapperStyle}}
                    >{children}</div>
                </div>
            }

            {type === "delete" &&
                <div
                    ref={actualBtnRef}
                    className={`${styles.container} ${styles.delete}`}
                    onClick={e => wrappedOnClick(e)}
                    {...props}
                    style={{...props.style, ...{padding: btnPadding}}}
                    tabIndex={index}
                    role={BUTTON}
                >
                    <div
                        className={`${styles.wrapper} ${onlyIcon ? styles.iconWrapper : ""}`}
                        style={{...wrapperStyle}}
                    >{children}</div>
                </div>
            }
            {type === "deleteImage" &&
                <div
                    ref={actualBtnRef}
                    className={`${styles.container} ${styles.deleteImage}`}
                    onClick={e => wrappedOnClick(e)}
                    {...props}
                    style={{...props.style, ...{padding: btnPadding}}}
                    tabIndex={index}
                    role={BUTTON}
                >
                    <div
                        className={`${styles.wrapper} ${onlyIcon ? styles.iconWrapper : ""}`}
                        style={{...wrapperStyle}}
                    >{children}</div>
                </div>
            }
        </>

    )
}

YuJaButton.propTypes = {
    type: PropTypes.oneOf(["primary", "secondary", "delete","deleteImage"]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,

}