import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import DragDropIcon from "../../images/drag_drop.svg";
import { DRAG_TITLE, MC_ANSWER_LENGTH, RESPONSE_LABEL } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { MC_CI_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import "../styles.css";
import {convertMHOptionMap} from "../../utils/questionUtils";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";

export default function Response({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  questionBuilder,
  disabled
}) {
  const {isNarrow} = useCheckMainContainerNarrow();
  const [showDragIcon, setShowDragIcon] = useState(false);
  const { serialNo, correctAnswers } = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;


  const handleAnswerChange = (input, index) => {
    let copyOfQuestions = JSON.parse(JSON.stringify(questions));
    let newCorrectAnswers = copyOfQuestions[selectedQuestionIndex].correctAnswers;
    if (newCorrectAnswers[index].length > 1) {
      newCorrectAnswers[index][1] = input;
    } else {
      newCorrectAnswers[index][0] = input;
    }
    copyOfQuestions[selectedQuestionIndex].correctAnswers = newCorrectAnswers;
    if (!!questionBuilder) {
      copyOfQuestions[selectedQuestionIndex].optionsMap = convertMHOptionMap(newCorrectAnswers);
    }
    setQuestions(copyOfQuestions);
  };

  return (
    <div tabIndex={0} style={{display: "flex", width: "100%", justifyContent: "end"}}>
    <Draggable draggableId={"response" + index} index={index} key={"response" + index} isDragDisabled={questionBuilder && disabled}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1} style={{width: "100%", ...provided.draggableProps.style} }>
          <div
            onMouseEnter={() => setShowDragIcon(true)}
            onMouseLeave={() => setShowDragIcon(false)}
            style={{display: "flex", flex: 1, alignItems: "center"}}
          >
            <div
              {...provided.dragHandleProps}
              style={{display: "flex", justifyContent: "center", marginRight: 10}}
              title={(questionBuilder && disabled) ? "" : DRAG_TITLE}
              tabIndex={-1}
            >
              {(!questionBuilder || !disabled)  && <img src={DragDropIcon} alt="drag drop icon" />}
            </div>


            <YuJaTextBox
                disabled={questionBuilder && disabled}
                name={"question-text"}
                label={RESPONSE_LABEL + (index+1)}
                containerStyle={{ width: "100%", fontSize: 16}}
                placeholder={`Response ${index + 1}`}
                value={correctAnswers[index].length > 1 ? optionData[1]: optionData[0]}
                maxLength={MC_ANSWER_LENGTH}
                onChange={(e) => {
                  if(e.target.value.length <= MC_ANSWER_LENGTH) {
                    handleAnswerChange(e.target.value, index);
                  }
                  else {
                    notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                    handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);
                  }
                }}
                showRemaining={true}
            />
          </div>
        </div>
      )}
    </Draggable>
    </div>
  );
}