import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaKebabMenu.module.css";
import {ReactComponent as MenuIcon} from "../../images/cebabe_menu_icon.svg"
import {BUTTON, TEXTBOX} from "../../utils/constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PropTypes from "prop-types";

export const YuJaKebabMenu = ({children, iconVisible=true, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const iconRef = useRef();

    const open = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);
    }


    const findKebabMenuParent = (element) => {

        let thisEl = element;

        if (!thisEl) {
            return false;
        }

        if (thisEl.id === "root") {
            return false;
        }

        if (thisEl.className.includes("YuJaKebabMenu"))  {
            return true;
        }
        return findKebabMenuParent(thisEl.parentElement);
    }



    const onBlur = (event) => {
        const {relatedTarget} = event;
        if (!!relatedTarget) {
            let result = findKebabMenuParent(relatedTarget);
            if (!result) {
                setIsOpen(false);
            }
        }

    };

    return (
        <div className={styles.container}
             onClick={open}
             onBlur={onBlur}
             tabIndex={0}
             // onMouseDown={e => e.preventDefault()}
             // onKeyDown={handleKeyDown}
        >
            <div ref={iconRef} style={{position: "relative"}}>
                <MenuIcon
                    aria-label="Open Menu"
                    role={BUTTON}
                    className={styles.focusIcon}
                    style={{ opacity: iconVisible ? 1: 0, padding: iconVisible ? 0: 20}}
                />
                {children &&
                    <>
                       {React.cloneElement(children,
                           {
                               ...children.props,
                               isOpen: isOpen,
                               setIsOpen: setIsOpen,
                               iconRef: iconRef,
                           })
                       }
                    </>
                }
             </div>

        </div>
    );
}

function Menu({isOpen, setIsOpen, iconRef, children, placement="bottom"}) {
    const menuRef = useRef();
    const windowDimensions = useWindowDimensions();
    const [isTop, setIsTop] = useState(null);


    useEffect(() => {
        const handleKeyDownDropdown = (event) => {
            if (event.key === 'Escape') {
                setIsOpen(false);
                return;
            }


            if (event.key !== "Tab") {
                return;
            }
            const container = menuRef.current;
            if (!container) {
                return;
            }

            const focusableElements = container.querySelectorAll('[tabindex]:not([tabindex="-1"])');

            if (!menuRef.current.contains(event.target)) {
                event.preventDefault();
                focusableElements[0].focus();
                return;
            }

            if (event.shiftKey) {
                for (let i = 0; i < focusableElements.length; i++) {
                    if (focusableElements[i] !== event.target) {
                        continue;
                    }
                    if (i === 0) {
                        event.preventDefault();
                        focusableElements[focusableElements.length - 1].focus();
                        return;
                    } else {
                        event.preventDefault();
                        focusableElements[i - 1].focus();
                        return;
                    }
                }
            } else {
                for (let i = 0; i < focusableElements.length; i++) {
                    if (focusableElements[i] !== event.target) {
                        continue;
                    }
                    if (i === focusableElements.length - 1) {
                        event.preventDefault();
                        focusableElements[0].focus();
                        return;
                    } else {
                        event.preventDefault();
                        focusableElements[i + 1].focus();
                        return;
                    }
                }
            }

        };

        if (!!isOpen) {
            document.addEventListener("keydown", handleKeyDownDropdown);
        }


        return () => {
            document.removeEventListener("keydown", handleKeyDownDropdown);
        };
    }, [isOpen]);


    useEffect(() => {
        if (isOpen) {
            document.addEventListener('scroll', adaptMenuPosition, true);
            adaptMenuPosition();
        }

        return () => {
            document.removeEventListener('scroll', adaptMenuPosition)
        };
    }, [isOpen, windowDimensions]);


    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && menuRef.current && !iconRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            adaptMenuPosition();
            document.addEventListener('click', handleGlobalClick, true);
            menuRef.current.style.visibility = "visible";
        } else {
            resetMenuPosition();
            menuRef.current.style.visibility = "hidden";
        }

        return () => {
            document.removeEventListener('click', handleGlobalClick)
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            //find the first overflow hidden parent ele
            adaptMenuPosition();
        }
    }, [windowDimensions]);


    const resetMenuPosition = () => {
        menuRef.current.style.visibility = "hidden";
        menuRef.current.style.top = `0px`;
        menuRef.current.style.left = `0px`;
    }


    const adaptMenuPosition = () => {
                if (!iconRef.current || !menuRef.current) {
                    return;
                }

        let windowHeight = document.documentElement.scrollHeight;
        const { height, top, left, width} = iconRef.current.getBoundingClientRect();
        const menuHeight = menuRef.current.children[0].offsetHeight;
        const menuWidth = menuRef.current.children[0].offsetWidth;
        /*
        parentTop
                <parent>             iconTop
                    |   <menuIcon/>  iconHeight
                    |                10
        parentHeight|   <menuList>
                    |   </menuList>  menuHeight
                </parent>
        */

        const isBottomOverflow = top + height + menuHeight + 15 > windowHeight;
        const isTopOverflow = top - menuHeight - 15 < 0;
        if (placement === "top" || (isBottomOverflow && !isTopOverflow)) {
            setIsTop(true);
            menuRef.current.style.transform =  `translate(${left + (width / 2) + 24 - menuWidth}px, ${top - menuHeight - 10}px)`;
        } else {
            setIsTop(false);
            menuRef.current.style.transform =  `translate(${left + (width / 2) + 24 - menuWidth}px, ${top + height + 10}px)`;
        }
    }

    return (
        <>
            <div className={`${styles.menuContainer} ${isTop ? styles.top : styles.bottom}`} ref={menuRef}>
                <div className={`${styles.menuList}`} >
                    {
                        children && Array.isArray(children) &&
                        children.map((item, index) => {
                                if (!item) {
                                    return null;
                                }

                                return  React.cloneElement(
                                        item,
                                        {
                                            ...item.props,
                                            id: index,
                                            key: index,
                                            isOpen: isOpen,
                                            setIsOpen: setIsOpen
                                        });
                        })
                    }


                    {children && !Array.isArray(children) &&
                        React.cloneElement(
                            children,
                            {
                                ...children.props,
                                isOpen: isOpen,
                                setIsOpen: setIsOpen
                            }
                        )

                    }
                </div>
            </div>
                {/*<div className={styles.backDrop} onClick={() => setIsOpen(false)}/>*/}
        </>
    );
}


function Item({children, isOpen, label, setIsOpen, ...props}) {
    const nodeRef = useRef();

    useEffect(() => {
        const textNode = nodeRef.current;
        if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
            textNode.title = textNode.innerText;
        }
        // console.log(`isOpen: ${isOpen}`)
    }, []);

    const onClick =(e) => {
        setIsOpen(false);
        if (props.onClick) {
            props.onClick(e);
        }

    }


    return (
        <div className={`${styles.menuItem}`} role={TEXTBOX} onClick={onClick} tabIndex={0} aria-label={label}>
            <span ref={nodeRef} className={styles.menuItemText}>{children}</span>
        </div>
    );
}


YuJaKebabMenu.Menu= Menu;
YuJaKebabMenu.Item= Item;

Menu.propTypes = {
    placement: PropTypes.oneOf(["top", "bottom"]),
}