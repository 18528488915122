import styles from "./YuJaNavbar.module.css";
import { ReactComponent as YuJaLogo } from "../images/yujalogo.svg";
import { ReactComponent as NavbarArrow } from "../images/navbar_arrow_purple.svg";
import { ReactComponent as LogOut } from "../images/LogoutIcon.svg";
import { ReactComponent as Setting } from "../images/SettingNavibarIcon.svg";
import { ReactComponent as Support } from "../images/SupportIcon.svg";
import { ReactComponent as NewWindowIconBlue } from "../images/links/new_window_blue.svg";
import {useHistory, useLocation} from "react-router-dom";
import {useLocalStorageNew} from "../hooks/useLocalStorageNew";
import {useCookie} from "../hooks/useCookie";
import React, {useEffect, useState} from "react";
import {
    ACCOUNT_CARD_LOGOUT_TEXT,
    ACCOUNT_CARD_SETTING_TEXT,
    ACCOUNT_CARD_SUPPORT_TEXT, BUTTON, CREATE_POLL_PATH, GRADED_POLL,
    HOME_ICON,
    MENU_LIST,
    NAVBAR_ENGAGE, OPEN_IN_NEW_TAB, RESPONSIVE_SIDE_BAR_POINT,
    ROLES
} from "../utils/constants";
import {NavDropdown} from "react-bootstrap";
import {roleValuetoDisplay} from "../utils/helpers";
import letterA from "../images/letterA.svg";
import letterB from "../images/letterB.svg";
import letterC from "../images/letterC.svg";
import letterD from "../images/letterD.svg";
import letterE from "../images/letterE.svg";
import letterF from "../images/letterF.svg";
import letterG from "../images/letterG.svg";
import letterH from "../images/letterH.svg";
import letterI from "../images/letterI.svg";
import letterJ from "../images/letterJ.svg";
import letterK from "../images/letterK.svg";
import letterL from "../images/letterL.svg";
import letterM from "../images/letterM.svg";
import letterN from "../images/letterN.svg";
import letterO from "../images/letterO.svg";
import letterP from "../images/letterP.svg";
import letterQ from "../images/letterQ.svg";
import letterR from "../images/letterR.svg";
import letterS from "../images/letterS.svg";
import letterT from "../images/letterT.svg";
import letterU from "../images/letterU.svg";
import letterV from "../images/letterV.svg";
import letterW from "../images/letterW.svg";
import letterX from "../images/letterX.svg";
import letterY from "../images/letterY.svg";
import letterZ from "../images/letterZ.svg";
import {AuthenticationApiCalls} from "../apis/AuthenticationApiCalls";
import {YuJaTooltip} from "./standardization/YuJaTooltip";

import ResponsiveSidebar from "./ResponsiveSidebar";
import layoutStyles from "../layouts/Layout.module.css";
import NewSidebar from "./NewSidebar";
import useWindowDimensions from "../hooks/useWindowDimensions";
export default function YuJaNavbarNew({ isViewer = false, isCreator = false, countdownContent, webSocketState, pollKeyCode, pollType, pageIdentifier }) {
    const history = useHistory();
    const location = useLocation();
    const [, getSession] = useLocalStorageNew("session", {});
    const [, getCookie] = useCookie("name", "Guest");
    const { logout } = AuthenticationApiCalls();
    let { width} = useWindowDimensions();
    const isNarrow = width <= RESPONSIVE_SIDE_BAR_POINT;
    const session = getSession();
    const [showModal, setShowModal] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [showLogoutBtn, setShowLogoutBtn] = useState(!!session.userName);
    const anonymous = !session.userName;
    let userName;
    if (anonymous) {
        userName = getCookie();
    } else {
        if (session.firstName && session.lastName) {
            userName = session.firstName + " " + session.lastName;
        } else if (session.firstName) {
            userName = session.firstName;
        } else {
            userName = session.userName;
        }
    }
    let email = session.email;
    let role = session.role
    let names = userName.split(" ");
    let StringToImage = {
        "A": letterA,
        "B": letterB,
        "C": letterC,
        "D": letterD,
        "E": letterE,
        "F": letterF,
        "G": letterG,
        "H": letterH,
        "I": letterI,
        "J": letterJ,
        "K": letterK,
        "L": letterL,
        "M": letterM,
        "N": letterN,
        "O": letterO,
        "P": letterP,
        "Q": letterQ,
        "R": letterR,
        "S": letterS,
        "T": letterT,
        "U": letterU,
        "V": letterV,
        "W": letterW,
        "X": letterX,
        "Y": letterY,
        "Z": letterZ
    };
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    const sideNavbarStyle = {
        position: "absolute",
        left: "0px",
        top: "0px",
        minWidth: isNarrow ? "240px" : "60px",
        zIndex: 2,
        overflowX: "hidden",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
        height: "100vh",
    };



    const onClickLogo = () => {
        if (!!session.gradedLink) {
            return;
        }
        history.push("/home");
    }

    const NavBarUnit = ({ children, showCourse=false }) => {
        return (
            <div
                tabIndex={0}
                className={showCourse ? "navbar-unit active" : "navbar-unit"}
                style={{position: "relative", display: "flex", justifyContent: "end", width: "100%", height: "100%"}}
            >
                <span className="navDropdownText" style={{display: "flex", alignItems: "center"}}>{children}</span>
            </div>
        );
    }

    const logoutFunction = () => {
        if (window.location.pathname.includes(CREATE_POLL_PATH)) {
            history.push({
                pathname: CREATE_POLL_PATH,
                state: {pollType: GRADED_POLL, fromNavbar: true, previous: CREATE_POLL_PATH, logout: true}
            })
        }
        else {
            logout();
        }
    }

    return (
        <div id="viewer-navbar-container" className={`${styles.navBarContainer} ${isNarrow ? styles.responsiveNavBarContainer : ""}`} >
            {!isNarrow &&
                <>
                    <div
                        tabIndex={0}
                        aria-label={HOME_ICON}
                        className={`${styles.logoContainer} ${!session.gradedLink ? styles.clickable : ""}`}
                        onClick={onClickLogo}
                    >
                        <YuJaLogo width={22} height={22}/>
                    </div>
                    <span tabIndex={0} className={styles.pathContainer}>
                        {NAVBAR_ENGAGE}
                                {location.pathname !== MENU_LIST.HOME.path &&
                                    <>
                                        <NavbarArrow height={15}/>
                                        {pageIdentifier}
                                    </>
                                }
                    </span>
                </>
            }

            {!!isNarrow &&
                <ResponsiveSidebar
                    className={`${layoutStyles.LeftSideBarContainer}`}
                    sidebarStyle={sideNavbarStyle}
                    // setShowSideBar={setShowSideBar}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                >
                    <NewSidebar sideBarCollapseAble={false} isViewer={isViewer} isCreator={isCreator} isMobile={true} collapsed={collapsed} setCollapsed={setCollapsed} />
                </ResponsiveSidebar>
            }
            <div style={{flex: 1, minWidth: 0, height: "100%"}}>
                {countdownContent}
            </div>
            {window.location !== window.parent.location &&
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    {isNarrow &&
                        <YuJaTooltip text={OPEN_IN_NEW_TAB} placement="bottom">
                            <NewWindowIconBlue aria-label={OPEN_IN_NEW_TAB} role={BUTTON}
                                               style={{width: 28, height: 28, cursor: "pointer", marginRight: 5}}
                                               onMouseDown={e => e.preventDefault()} onClick={() => {
                                const engageSession = encodeURIComponent(JSON.stringify(getSession()));
                            const path = window.location.pathname;
                            const dest = encodeURIComponent(path.includes(CREATE_POLL_PATH) && pollKeyCode ? `/edit/${pollKeyCode}` : path);
                            const pollTypeParam = encodeURIComponent(pollType ? pollType : "");
                            window.open(`/portal?engageSession=${engageSession}&dest=${dest}${`&pollType=${pollTypeParam}`}`);
                        }}/>
                    </YuJaTooltip>
                }
                {!isNarrow &&
                    <YuJaTooltip text={OPEN_IN_NEW_TAB} placement="bottom">
                        <NewWindowIconBlue aria-label={OPEN_IN_NEW_TAB} role={BUTTON} style={{width: 28, height: 28, cursor: "pointer", marginRight: 5}} onMouseDown={e => e.preventDefault()} onClick={() => {
                            const engageSession = encodeURIComponent(JSON.stringify(getSession()));
                            const path = window.location.pathname;
                            const dest = encodeURIComponent(path.includes(CREATE_POLL_PATH) && pollKeyCode ? `/edit/${pollKeyCode}` : path);
                            const pollTypeParam = encodeURIComponent(pollType ? pollType : "");
                            window.open(`/portal?engageSession=${engageSession}&dest=${dest}${`&pollType=${pollTypeParam}`}`);
                        }}
                        />
                    </YuJaTooltip>
                }
                </div>
            }


            <NavDropdown
                className = {"navDropdown"}
                title={(
                    <NavBarUnit>
                        <img src={StringToImage[initials[0]] ? StringToImage[initials[0]] : StringToImage["G"]}
                             style={{width: 33, height: 33}}/>
                        {webSocketState === WebSocket.CONNECTING &&
                            <div style={{
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor: "#b0b0b0",
                                position: "absolute",
                                left: "calc(100% - 10px)",
                                top: "calc(100% - 10px)"
                            }}/>
                        }
                        {webSocketState === WebSocket.OPEN &&
                            <div style={{
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor: "#008400",
                                position: "absolute",
                                left: "calc(100% - 10px)",
                                top: "calc(100% - 10px)"
                            }}/>
                        }
                        {webSocketState === WebSocket.CLOSED &&
                            <div style={{
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor: "#B40030",
                                position: "absolute",
                                left: "calc(100% - 10px)",
                                top: "calc(100% - 10px)"
                            }}/>
                        }

                    </NavBarUnit>
                )}
            >
                <div style={{position: "relative", right: 20, width: "100px"}}>
                    <ul role="menu" tabIndex={0} style={{
                        boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 5px 0px",
                        padding: "8px 0px",
                        position: "absolute",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                        display: "flex",  alignItems: "center", flexDirection: "column", width: 180, height: "fit-content"}} >
                        <img src={StringToImage[initials[0]] ? StringToImage[initials[0]] : StringToImage["G"]} style={{width: 45, height:45, margin: "20px 10px"}} />
                        <div title={userName} style={{textAlign: "center", fontWeight: 700, maxWidth: "175px",fontSize: 20, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingLeft: "10px", paddingRight: "10px"}}>{userName} </div>
                        <div style={{color: "rgb(111, 111, 111)", fontSize: "14px"}}> { roleValuetoDisplay(role)} </div>
                        <div title={email} style={{overflow: "hidden", maxWidth: "175px", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", paddingLeft: "10px", paddingRight: "10px"}}> {email} </div>
                        <hr style={{width: "100%"}}/>
                        <NavDropdown.Item tabIndex={0} onClick={() => {setShowModal(true)}} style={{ fontSize: "15px", display: "flex"}}>
                            <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><Support size={24} style={{width: 24 , height: 24}}/>{ACCOUNT_CARD_SUPPORT_TEXT}</span>
                        </NavDropdown.Item>
                        {!anonymous && !session.gradedLink && <>
                            <NavDropdown.Item tabIndex={0} onClick={() => { history.push({pathname: role === ROLES.VIEWER.value?  "/student/settings": "/admin/platformsetting", state: {User: true}});}} style={{ fontSize: "15px", display: "flex", marginTop: 10}}>
                                <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><Setting size={24} style={{width: 24 , height: 24}} />{ACCOUNT_CARD_SETTING_TEXT}</span>
                            </NavDropdown.Item>
                        </>}
                        {!anonymous && showLogoutBtn && <>
                            <hr style={{width: "100%"}}/>
                            <NavDropdown.Item tabIndex={0} onClick={logoutFunction} style={{ fontSize: "15px", display: "flex"}}>
                                <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><LogOut size={24} style={{width: 24 , height: 24}}/>{ACCOUNT_CARD_LOGOUT_TEXT}</span>
                            </NavDropdown.Item>
                        </>}
                    </ul>
                </div>
            </NavDropdown>
        </div>
    );
}