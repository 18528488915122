import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useRef, useState} from "react";
import Countdown from 'react-countdown';
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { ReactComponent as ICIcon } from "../../images/clickable_grey.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_grey.svg";
import { ReactComponent as MHIcon } from "../../images/matching_grey.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_grey.svg";
import { ReactComponent as SAIcon } from "../../images/sa_grey.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEndedGrey.svg";
import { ReactComponent as TFIcon } from "../../images/tf_grey.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_grey.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIconGrey.svg";
import { ReactComponent as ARROW_UP } from "./images/arrow-up.svg";
import { ReactComponent as ARROW_DOWN } from "./images/arrow-down.svg";
import { ReactComponent as EXPAND_ICON } from "./images/expand-icon.svg";
import { YuJaGlobalState, useLoading } from "../../utils/LoadingContext";
import {
    POINTS_TEXT,
    QUESTION,
    QUESTION_TYPES,
    SA_PLACE_HOLDER, SCREEN_TYPE,
    UNANSWERED,
} from "../../utils/constants";
import {
    InitializeQuestionResultCount,
    newPageLog,
} from "../../utils/helpers";
import { questionCodeToName } from "../../utils/questionUtils";
import "./styles.css";
import parse from "html-react-parser";
import classes from "./ClassResult.module.css";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import reactStringReplace from "react-string-replace";
import ImageViewer from "./ImageViewer";
import RenderAllResponse from "./RenderAllResponse";
import RenderYourResponse from "./RenderYourResponse";
import RenderCorrectAnswer from "./RenderCorrectAnswer";
import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";

export default function ClassResult({ state, pollInfo, currentQuestion, handleClassResultComplete, websocketState, sharedQuestionList, questions, setCountdownContent }) { // , handleSAResult, institutionId, quesShareCount
    const {width: windowWidth, type: windowType} = useWindowDimensions();
    // const curWindow = useWindowDimensions();
    const { pollKey, uniqueCode, userName, pollTitle, showAnswerOnViewer } = pollInfo;
    const { question, classResultEndTime } = currentQuestion;
    const { weightage = 0 } = question; // optionsMap, correctAnswers, questionType
    const gradeEnabled = !!weightage;
    const { getAllClassResult, getStudentResultByPoll} = ResponseApiCalls();
    const [questionsData, setQuestionsData] = useState([]);
    const { setLoading } = useLoading();
    const [selectedQuestion, setSelectedQuestion] = useState(question);
    const imageRef = useRef(null);
    const {isOnline} = useContext(YuJaGlobalState);
    const [questionListOpen, setQuestionListOpen] = useState(false);
    const [pollPanelOpen, setPollPanelOpen] = useState(false);
    const pollPanelRef = useRef();
    const questionListContainerRef = useRef();
    // const [yourAnswer, setYourAnswer] = useState(() => {
    //     return getYourAnswer(pollType, questionType, questionResponse.selectedOption);
    // });
    const unanswered = useRef(0);
    // const intervalRef = useRef(0);

    const [dataLoading, setDataLoading] = useState(true);
    // const [participantCount, setParticipantCount] = useState("-");
    // const [responsesCount, setResponsesCount] = useState("-");
    // const [completionRate, setCompletionRate] = useState("-");
    // const [correctCount, setCorrectCount] = useState("-");
    // const [incorrectCount, setIncorrectCount] = useState("-");
    const [questionData, setQuestionData] = useState(() => {
        return {
            ...question,
            questionType: question.questionType,
            queTitle: question.queTitle,
            questionResults: InitializeQuestionResultCount(question),
            rawResults: {},
            serialNo: question.serialNo,
            image: question.image,
            imageURL: question.imageURL,
            suffix: question.suffix,
            directLinkEvp: question.directLinkEvp
        };
    });
    const [questionResponses, setQuestionResponses] = useState({});

    function removeUnanswered(result) {
        let newResult = [];
        result.forEach((r) => {
            if (r.ans !== UNANSWERED) {
                newResult.push({...r});
            } else {
                unanswered.current = r.people;
            }
        });
        return newResult;
    }


    const getUnanswered = (result) => {
        if (result == null) {
          return 0;
        }
    
        let unansweredCount = 0; 
        result.forEach((r) => {
            if (r.ans === UNANSWERED) {
                unansweredCount = r.people;
            }
        });
        return unansweredCount;
    
      }

    async function getQuestionsData() {
        setLoading(true);
        //cur user responses
        const responses = await getStudentResultByPoll(pollKey, questions[0].pollSortKey.split("#")[2], userName);
        setQuestionResponses(responses);
        //all responses
        const results = await getAllClassResult(pollKey, questions[0].pollSortKey.split("#")[2], questions);
        let tempQuestionsData = [];
        for (const result of results) { 
          let serialNo = result.serialNo; 
          const que = questions.find(({serialNo: no}) => parseInt(no) === parseInt(serialNo));
          let questionType = que.questionType;
          let queTitle = que.queTitle;
          let MCSSelements = [];
          if (questionType === "MCSS") {
            for (let key in que.optionsMap) {
              MCSSelements.push(que.optionsMap[key]);
            }
          }
          let MCSSnum = MCSSelements.length;
          let questionImageURL;
          let isVideo;
          if(!!que.directLinkEvp) {
            questionImageURL = que.directLinkEvp;
            isVideo = true;
          } else {
              questionImageURL = que.imageURL;
              isVideo = false;
          }
  
          let questionData = {
              ...que,
              questionType: questionType,
              queTitle: queTitle,
              questionResults: result.questionResults,
              serialNo: serialNo,
              MCSSnum: MCSSnum,
              questionImageURL: questionImageURL,
              isVideo: isVideo,
              rawResponse: result.rawResponse
          };
          tempQuestionsData.push(questionData); 
        }
  
        tempQuestionsData.forEach(item => {
          item.unansweredCount = getUnanswered(item.questionResults);
        });
  
        tempQuestionsData.forEach(item => {
          item.questionResults = removeUnanswered(item.questionResults);
        });

        if (JSON.stringify(questionsData) !== JSON.stringify(tempQuestionsData)) {
            let serialNo = selectedQuestion.serialNo;
            const queData = tempQuestionsData.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
            setQuestionData(queData);
            setQuestionsData(tempQuestionsData);
        }
        setLoading(false);
      }

    useEffect(() => {
        if (!isOnline || websocketState !== WebSocket.OPEN) {
          return;
        }
        let interval = 0;
        if (!questionData.weightage) {
            interval = setInterval(getQuestionsData, 5000);
        }

        return () => interval && clearInterval(interval);
      }, [isOnline, websocketState, questionData]);


    // clean up function
    useEffect(() => {
        newPageLog("ClassResult.js");
    }, []);



    useEffect(() => {
        if (!dataLoading && questionsData && questionsData.length !== 0) {
            // let incorrectCount = 0;
            let serialNo = selectedQuestion.serialNo;
            const queData = questionsData.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
            setQuestionData(queData);
        }
    }, [questionsData, selectedQuestion, dataLoading]);



    useEffect(() => {
        const handleGlobalClick = (event) => {
            if (questionListOpen && !!questionListContainerRef.current && !!questionListContainerRef.current
                && !questionListContainerRef.current.contains(event.target)
            ) {
                setQuestionListOpen(false);
            }
        };

        if (questionListOpen) {
            document.addEventListener('click', handleGlobalClick, true);
        }
        return () => {
            document.removeEventListener('click', handleGlobalClick)
        };
    }, [questionListOpen]);

    useEffect(() => {
        const handleGlobalClick = (event) => {
            if (pollPanelOpen && !!pollPanelRef.current && !!pollPanelRef.current
                && !pollPanelRef.current.contains(event.target)
            ) {
                setPollPanelOpen(false);
            }
        };

        if (pollPanelOpen) {
            document.addEventListener('click', handleGlobalClick, true);
        }
        return () => {
            document.removeEventListener('click', handleGlobalClick)
        };
    }, [pollPanelOpen]);

    const updateTopNavContent = () => {
        if (!setCountdownContent) {
            return;
        }
        if (windowType === SCREEN_TYPE.MOBILE) {
            setCountdownContent(
                <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: 5, padding: "0 20px"}}>
                    <span className={classes.navBarTitle}>{pollTitle}</span>
                    {!pollPanelOpen &&
                        <ARROW_DOWN style={{cursor: "pointer", flexShrink: 0}} onClick={() => setPollPanelOpen(true)}/>
                    }

                    {!!pollPanelOpen &&
                        <ARROW_UP style={{cursor: "pointer", flexShrink: 0}}/>
                    }

                    {!!pollPanelOpen &&
                        <div ref={pollPanelRef} className={classes.pollPanelContainer}>
                            <span className={classes.cardTitle}>{pollTitle}</span>
                        </div>
                    }
                </div>
            );
        } else {
            setCountdownContent(null);
        }
    }


    useEffect(() => {
        updateTopNavContent();
    }, [setCountdownContent, pollTitle, windowWidth, pollPanelOpen, windowType]);


    useEffect(() => {
        setDataLoading(true);
        getQuestionsData().finally(() => {
            setDataLoading(false);
        });

        setTimeout(() => {
            updateTopNavContent();
        }, 500);

        if (!!gradeEnabled) {
            setTimeout(() => {
                getQuestionsData();
            }, 3000);
        }
    }, []);


    const renderTitle = () => {
        if(!!dataLoading) {
            return (
                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                    <Skeleton borderRadius={4} height={30} width={"min(80vw, 500px)"}/>
                </SkeletonTheme>
            );
        }

        if (questionData.questionType !== QUESTION_TYPES.FITB.name) {
            return questionData?.queTitle === undefined ? "" :  parse(questionData?.queTitle);
        }

        let blankNameArr = Object.keys(questionData.correctAnswers).map(str => `\\(${str}\\)`);
        const regex = new RegExp(`(${blankNameArr.join("|")})`, "g");

        return (
            <span style={{marginRight: 5, lineHeight: "32px"}}>
               {reactStringReplace(questionData?.queTitle, regex, (blank, i) => {

                   const match = !!blank ? blank.match(/\(([^)]+)\)/) : null;
                   if (!match) {
                       return "";
                   }
                   const blankName = match[1];

                   return (
                       <input
                           key={i}
                           id={blankName}
                           className={classes.FITBInputBox}
                           disabled={true}
                           placeholder={SA_PLACE_HOLDER}
                           type={"text"}
                           style={{backgroundColor: "#FFF"}}
                       />
                   );
               })}
           </span>
        );

    }

    return (
        <div className={`${classes.parentContainer} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}>
            {windowType !== SCREEN_TYPE.MOBILE &&
                <div className={classes.pollTitleContainer}>
                    <div style={{flex: 1}}/>
                    <span className={classes.pollTitleContainerTitle} tabIndex={0}>
                        {!!pollTitle &&
                            <span>{pollTitle}</span>
                        }
                        {!pollTitle &&
                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                                <Skeleton width={windowWidth * 0.7} height={40} borderRadius={6}/>
                            </SkeletonTheme>
                        }
                    </span>
                    <div style={{flex: 1}}/>
                </div>
            }


            <div className={`${classes.card} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}>
                <div
                    ref={questionListContainerRef}
                    className={`${classes.questionListContainer} 
                    ${windowType === SCREEN_TYPE.TABLET ? classes.tablet : ""} 
                    ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}
                    ${questionListOpen ? classes.opened : ""}`}
                    style={{width: windowType === SCREEN_TYPE.MOBILE ? (questionListOpen ? 88 : 0) : undefined}}
                >
                    {!!sharedQuestionList && (questions.filter(q => sharedQuestionList.includes(q.serialNo)).map((que, index) => (
                        <div
                            key={index}
                            tabIndex={0}
                            className={`${classes.questionListItem} 
                            ${selectedQuestion.serialNo === que.serialNo ? classes.active : ""}
                            ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}
                            onClick={() => {
                                setDataLoading(true)
                                getQuestionsData().finally(() => {
                                    setDataLoading(false);
                                });
                                setSelectedQuestion(que);
                                setQuestionListOpen(false);
                            }}
                        >
                            <YuJaTooltip
                                placement={windowType === SCREEN_TYPE.MOBILE ? "left" : "right"}
                                text={QUESTION_TYPES[que.questionType].description}
                            >
                                <span className={classes.questionListQuestionTypeCircle}>
                                    {que.questionType === QUESTION_TYPES.MCSS.name &&
                                        <MCSSIcon alt="MCSS Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.SA.name &&
                                        <SAIcon alt="SA Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.OE.name &&
                                        <OEIcon alt="QA Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.TF.name &&
                                        <TFIcon alt="TF Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.FITB.name &&
                                        <FITBIcon alt="FITB Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.CI.name &&
                                        <ICIcon alt="CI Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.WC.name &&
                                        <WCIcon alt="WC Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.MH.name &&
                                        <MHIcon alt="MH Icon" style={{width: 18, height: 18}}/>}
                                    {que.questionType === QUESTION_TYPES.RK.name &&
                                        <RKIcon alt="RK Icon" style={{width: 18, height: 18}}/>}
                                </span>
                            </YuJaTooltip>


                            <span className={classes.questionListItemText}>
                                {windowType === SCREEN_TYPE.DESKTOP ? `Question ${que.serialNo}` : `Q${que.serialNo}`}
                            </span>
                        </div>

                    )))}
                </div>

                <div className={classes.mainContainer}>
                    <div className={classes.mainContainerRow}>
                        <span tabIndex={0} style={{fontSize: 18, fontWeight: "500", lineHeight: "22px"}}>
                            {QUESTION} {selectedQuestion?.serialNo}
                        </span>
                        {!!selectedQuestion?.weightage &&
                            <span
                                tabIndex={0}
                                className={classes.badge}
                                style={{width: "fit-content", padding: "0 10px", backgroundColor: "#E4E3F6"}}
                            >
                                {selectedQuestion.weightage} {POINTS_TEXT}
                            </span>
                        }

                        <span style={{flex: 1}}/>

                        {windowType === SCREEN_TYPE.MOBILE &&
                            <span
                                className={classes.expandIcon}
                                style={{marginLeft: 5}}
                                onClick={() => setQuestionListOpen(true)}
                            >
                              <EXPAND_ICON/>
                            </span>
                        }
                    </div>
                    <div className={classes.mainContainerRow}>
                      <span tabIndex={0} style={{color: "#6B6B6B"}}>
                        {questionCodeToName(selectedQuestion?.questionType)}
                      </span>
                    </div>

                    <div
                        tabIndex={0}
                        className={classes.mainContainerRow}
                        style={{
                            flexDirection: windowType === SCREEN_TYPE.MOBILE ? "column" : undefined,
                            alignItems: "start"
                        }}
                    >
                        {renderTitle()}
                        <span style={{flex: 1}}/>

                        {!!selectedQuestion?.imageURL && !selectedQuestion?.directLinkEvp &&
                            <ImageViewer
                                size={150}
                                alt={selectedQuestion?.imageAlt}
                                imageURL={selectedQuestion.imageURL}
                                styles={{alignSelf: windowType === SCREEN_TYPE.MOBILE ? "center" : undefined}}
                            />
                        }
                        {!!selectedQuestion?.imageURL && !!selectedQuestion?.directLinkEvp &&
                            <iframe allowFullScreen style={{width: 270, height: 270, alignSelf: "center"}}
                                    src={selectedQuestion.imageURL}/>
                        }
                    </div>

                    <RenderYourResponse
                        curUserResponses={questionResponses?.questions}
                        showAnswerOnViewer={showAnswerOnViewer}
                        questionData={questionData}
                        dataLoading={dataLoading}
                    />
                    <RenderCorrectAnswer
                        questionData={questionData}
                        curUserResponses={questionResponses?.questions}
                        showAnswerOnViewer={showAnswerOnViewer}
                        dataLoading={dataLoading}
                    />
                    <RenderAllResponse
                        pollKey={pollKey}
                        uniqueCode={uniqueCode}
                        questionData={questionData}
                        curUserResponses={questionResponses?.questions}
                        pollResults={questionsData}
                        dataLoading={dataLoading}
                        showAnswerOnViewer={showAnswerOnViewer}
                    />

                </div>

                {!!classResultEndTime &&
                    <Countdown className="hide" date={classResultEndTime} onComplete={() => handleClassResultComplete()}/>
                }
            </div>
        </div>
    );
}