import {Modal} from "react-bootstrap";
import {
    ABSENT_STATUS,
    ABSENT_TEXT,
    ACCURACY, ATTENDANCE_STATUS_COLUMN_ID,
    BUTTON,
    CANCEL_USER, FIXED_ANSWER, GRADE_ATTENDANCE_MODAL_DESCRIPTION, GRADE_BOOK_RESPONSE_STATUS, LOCATION,
    MODAL,
    MODAL_CANCEL_TEXT,
    MODAL_SAVE_TEXT, NO_RESPONSE, PRESENT_STATUS, PRESENT_TEXT, QUESTION_TYPES,
    SEARCH_BY_PARTICIPANT_TEXT,
    TEXTBOX, UNANSWERED, UNSAVED_CHANGES_CONTENT_TEXT, UNSAVED_CHANGES_TEXT, YES_TEXT,
} from "../../utils/constants";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as SAVE } from "../../images/Save.svg";
import { ReactComponent as ARROW_LEFT_ICON } from "../../images/arrow-left-purple.svg";
import { ReactComponent as ARROW_RIGHT_ICON } from "../../images/arrow-right-purple.svg";
import React, {useEffect, useState} from "react";
import YuJaButton from "../../components/standardization/YuJaButton";
import loadingGif from "../../images/LoadingAnimation.gif";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {getAnswer, getLocationGBDisplay, notifyError, notifySuccess} from "../../utils/helpers";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import {GRADE_QUESTION_ERROR, GRADE_QUESTION_SUCCESS} from "../../utils/toast-message-constants";
import {ResponseApiCalls} from "../../apis/ResponseApiCalls";

export default function GradeAttendanceModal({
                                                 institutionId,
                                                 pollKey,
                                                 pollCode,
                                                 isShow=false,
                                                 setIsShow,
                                                 responses,
                                                 setResponses,
                                                 userId,
                                                 geofenceEnable,
}) {
    const { gradeQuestionStudents} = ResponseApiCalls();

    const students = responses.map(r => ({ label: `${r.firstName}${r.lastName ? " " + r.lastName : ""}`, value: `${r.userId}` }));
    const [curUserId, setCurUserId] = useState(userId);
    const [curResponse, setCurResponse] = useState(responses.find(r => r.userId === userId));
    const [isSaving, setIsSaving] = useState(false);
    const [changes, setChanges] = useState({});
    const [alertGradeShow, setGradeAlertShow] = useState(false);


    useEffect(() => {
        let response = responses.find(r => r.userId === curUserId);
        setCurResponse(response);
    }, [curUserId]);

    const handleClose = () => {
        if (Object.keys(changes).length === 0) {
            setIsShow(false);
            return;
        }

        setGradeAlertShow(true);
    }

    const canPrevious = () => {
        return students[0].value !== curUserId;
    }

    const canNext = () => {
        return students[students.length - 1].value !== curUserId;
    }

    const goPrevious = () => {
        let idx = students.map(stu => stu.value).indexOf(curUserId);
        setCurUserId(students[idx - 1].value);

    }

    const goNext = () => {
        let idx = students.map(stu => stu.value).indexOf(curUserId);
        setCurUserId(students[idx + 1].value);
    }


    const renderResponseData = (response) => {
        if (!geofenceEnable) {
            return <></>;
        }

        let selectedOption = response.selectedOption && response.selectedOption !== FIXED_ANSWER.UNANSWERED ?
            JSON.parse(response.selectedOption)[0] :
            "-";
        let locationData = response.locationData ? JSON.parse(response.locationData) : null;
        let locationDisplay;
        let locationAccuracy;
        if (locationData) {
            locationDisplay = getLocationGBDisplay(locationData.message, locationData.accuracy);
            locationAccuracy = locationData.accuracy ? `${parseFloat(locationData.accuracy).toFixed(2)} m` : null;
        }

        return (
            <>
                <div>
                    <span className="modal-regular-label">Response</span>
                    <div className="modal-divider"/>
                </div>
                <span className="modal-regular-text">{selectedOption}</span>

                <div>
                    <span className="modal-regular-label">Participant’s Location</span>
                    <div className="modal-divider"/>
                </div>
                <span className="modal-regular-text" style={{display: "flex", flexDirection: "column", gap: 15}}>
                    <div style={{display: "flex"}}>
                        <span style={{color: "#595959", fontWeight: "600", width: 80}}>{LOCATION}: </span>
                        <span>{!!locationDisplay ? locationDisplay : "-"}</span>
                    </div>

                    <div style={{display: "flex"}}>
                        <span style={{color: "#595959", fontWeight: "600", width: 80}}>{ACCURACY}:</span>
                        <span>{locationAccuracy ? locationAccuracy : "-"}</span>
                    </div>
                </span>


            </>
        );
    }

    const gradeAlertConfig = {
        title: UNSAVED_CHANGES_TEXT,
        okText: YES_TEXT,
        cancelText: MODAL_CANCEL_TEXT,
        submit: async () => {
            setChanges({});
            setGradeAlertShow(false);
            setIsShow(false);
        },
        close: async () => {
            setGradeAlertShow(false);
        },
        buttonWidth: "77px"
    };

    const isPresent = () => {
        if (!!changes && changes.hasOwnProperty(`${curUserId}`)) {
            return changes[curUserId];
        }

        if (!curResponse.hasOwnProperty(ATTENDANCE_STATUS_COLUMN_ID)) {
            return undefined;
        }

        let status = curResponse[ATTENDANCE_STATUS_COLUMN_ID];

        if (status !== PRESENT_STATUS && status !== ABSENT_STATUS) {
            return undefined;
        }

        return status === PRESENT_STATUS;
    }


    const grade = (isCorrect) => {
        if (isCorrect === changes[curUserId]) {
            return;
        }

        let isPresented =
            (!curResponse.hasOwnProperty(ATTENDANCE_STATUS_COLUMN_ID)
            || (curResponse[ATTENDANCE_STATUS_COLUMN_ID] !== PRESENT_STATUS && curResponse[ATTENDANCE_STATUS_COLUMN_ID] !== ABSENT_STATUS))
            ? undefined
            : curResponse[ATTENDANCE_STATUS_COLUMN_ID] === PRESENT_STATUS;

        let copy = JSON.parse(JSON.stringify(changes));
        if (isCorrect === isPresented && !!copy.hasOwnProperty(curUserId)) {
            delete copy[curUserId];
            setChanges(copy);
        } else {
            setChanges({
                ...copy,
                [curUserId]: isCorrect,
            });
        }
    };

    const onSave = async () => {
        if (!changes || Object.keys(changes).length === 0 ) {
            return;
        }

        setIsSaving(true);
        let isCorrectData = {};
        let selectedOptions = {};
        let copy = JSON.parse(JSON.stringify(responses));

        try {
            for (const user of Object.keys(changes)) {
                isCorrectData[user] = {"1": {"1": changes[user]}};
                selectedOptions[user] = {"1": {"1": {isCorrect: changes[user]}}}
                let item = copy.find(r => r.userId === user);
                item.score = changes[user] ? "100.00" : "0.00";
                item.attendance = changes[user] ? PRESENT_STATUS : ABSENT_STATUS;
            }

            await gradeQuestionStudents(institutionId, pollKey, pollCode,
                isCorrectData, [{serialNo: 1, questionType: QUESTION_TYPES.SA.name}],
                selectedOptions
            );

            setResponses(copy);
            setChanges({});
            notifySuccess(GRADE_QUESTION_SUCCESS);
            setIsShow(false);
        } catch (error) {
            console.log(error);
            notifyError(GRADE_QUESTION_ERROR);
        } finally {
            setIsSaving(false);
        }
    };


    return (
        <>
            <YuJaGeneralAlert
                show={alertGradeShow}
                setModalShow={setGradeAlertShow}
                content={UNSAVED_CHANGES_CONTENT_TEXT}
                config={gradeAlertConfig}
            />

            <Modal
                size={"lg"}
                id="form-modal"
                show={isShow}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={true}
            >
                <ModalClose tabIndex={0} aria-label={CANCEL_USER} role={BUTTON} onClick={handleClose}
                            className="modal-close"/>

                <Modal.Header style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Modal.Title
                        className="modal-title"
                        tabIndex={0}
                        aria-label={"Grade Attendance" + MODAL}
                        role={TEXTBOX}
                    >
                        {"Grade Attendance"}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{display: "flex", flexDirection: "column", gap: 20}}>
                    <span className="modal-description">
                        {GRADE_ATTENDANCE_MODAL_DESCRIPTION}
                    </span>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                        <YuJaButton
                            onlyIcon={true}
                            disabled={!canPrevious()}
                            onClick={goPrevious}
                            style={{
                                width: 32, height: 32, marginRight: 10,
                                display: "flex", alignItems: "center", justifyContent: "center"
                            }}
                        >
                            <ARROW_LEFT_ICON/>
                        </YuJaButton>

                        <YuJaDropdown
                            containerStyle={{width: "min(70%, 400px)"}}
                            size={"small"}
                            data={students}
                            value={curUserId}
                            searchAble={true}
                            searchPlaceholder={SEARCH_BY_PARTICIPANT_TEXT}
                            onChange={option => {
                                setCurUserId(option.value);
                            }}
                        />

                        <YuJaButton
                            onlyIcon={true}
                            disabled={!canNext()}
                            onClick={goNext}
                            style={{
                                width: 32, height: 32, marginLeft: 10,
                                display: "flex", alignItems: "center", justifyContent: "center"
                            }}
                        >
                            <ARROW_RIGHT_ICON/>
                        </YuJaButton>
                    </div>

                    {renderResponseData(curResponse)}

                    <div>
                        <span className="modal-regular-label">Grade</span>
                        <div className="modal-divider"/>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: 15}}>
                        <div className="modal-regular-text">
                            Grade whether the participant was present for the attendance.
                        </div>
                        <div className="modal-regular-text" style={{display: "flex", alignItems: "center", gap: 15}}>
                            <YuJaRadioBox
                                labelStyle={{fontWeight: "400"}}
                                size={18}
                                checked={isPresent() === true}
                                label={PRESENT_TEXT}
                                onClick={() => grade(true)}
                            />
                            <YuJaRadioBox
                                labelStyle={{fontWeight: "400"}}
                                size={18}
                                checked={isPresent() === false}
                                label={ABSENT_TEXT}
                                onClick={() => grade(false)}
                            />
                        </div>
                    </div>
                </Modal.Body>


                <Modal.Footer>
                    <YuJaButton
                        style={{
                            width: 90,
                            height: 35,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        type={"secondary"}
                        onClick={handleClose}
                    >
                        {MODAL_CANCEL_TEXT}
                    </YuJaButton>

                    <YuJaButton
                        onClick={onSave}
                        disabled={isSaving || !Object.keys(changes).length}
                        style={{
                            width: 90,
                            height: 35,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        {isSaving ? <img src={loadingGif} alt={"loading..."} style={{height: 20}}/> : MODAL_SAVE_TEXT}
                    </YuJaButton>

                </Modal.Footer>

            </Modal>
        </>
    );
}