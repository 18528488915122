import React from "react";
import classes from "./styles.module.css";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function PollTitleContainer({pollTitle, ...props}) {
    const {width} = useWindowDimensions();

    return (
        <div className={classes.pollTitleContainer} {...props}>
            <div style={{flex: 1}}/>
            <span className={classes.pollTitleContainerTitle} tabIndex={0}>
                {!!pollTitle &&
                    <span>{pollTitle}</span>
                }
                {!pollTitle &&
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                        <Skeleton width={width * 0.7} height={40} borderRadius={6} />
                    </SkeletonTheme>
                }
            </span>
            <div style={{flex: 1}}/>
        </div>
    );
}