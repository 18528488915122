import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthenticationApiCalls } from "../apis/AuthenticationApiCalls";
import NewSidebar from "../components/NewSidebar";
import YuJaTokenExpiredAlert from "../components/modals/YuJaTokenExpiredAlert";
import { Spin } from "../components/standardization/YuJaLoading";
// import useHostname from "../hooks/useHostname";
import useHostname from "../hooks/useHostname";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import useMobileAccess from "../hooks/useMobileAccess";
import { useSessionValidation } from "../hooks/useSessionValidation";
import { YuJaGlobalState, useLoading } from "../utils/LoadingContext";
import {
    LOGIN_PATH,
    POLL_TYPE, RESPONSIVE_SIDE_BAR_POINT,
    ROLES, SESSION_CHECK_INTERVAL,
} from "../utils/constants";
import { notifyError } from "../utils/helpers";
import useQuery from "../utils/queryparam";
import styles from "./Layout.module.css";
import YuJaNavbarNew from "../components/YuJaNavBarNew";

export default function Layout({
                                   children,
                                   isSidebar = false,
                                   pageRoles = [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value],
                                //    flex = false,
                                   hasPin = false,
                               }) {
    const { loading } = useLoading();
    const [sidebarContent, setSidebarContent] = useState();
    const [countdownContent, setCountdownContent] = useState();
    const [webSocketState, setWebsocketState] = useState();
    const [pollKeyCode, setPollKeyCode] = useState(null); // for create quiz open in new tab
    // const [rightSidebarContent, setRightSidebarContent] = useState();
    const [navBgColor, setNavBgColor] = useState("#E6F2F7");
    useHostname(window.location.hostname); // do not remove this line - needed to set the host resource which has institutionId
    const history = useHistory();
    const location = useLocation();
    const [setSession, getSession] = useLocalStorageNew("session", {});
    const [visibility, setVisibility] = useState(true);
    const intervalIdRef = useRef(0);
    const {checkExpired, checkWillExpired} = useSessionValidation();
    const {reLogin, loginSAML, loginCANVAS, loginBB} = AuthenticationApiCalls();
    let query = useQuery();
    const [, setITManagerHidden] = useState(true);
    // const [isLMS, setIsLMS] = useState(false);
    const [pollType, setPollType] = useState(POLL_TYPE.GRADED_POLL);
    const [pageIdentifier, setPageIdentifier] = useState("");

    const [collapsed, setCollapsed] = useState(true);

    const session = getSession();
    const { role = 'ANONYMOUS', gradedLink = false } = session;


    const [tokenValidateRes, setTokenValidateRes] = useState(false);
    const [roleValidateRes, setRoleValidateRes] = useState(false);
    const [sessionExpiredRes, setSessionExpiredRes] = useState(false);

    const isNarrow = useRef(useMobileAccess(1200));
    const [, setMinWidthState] = useState(isNarrow.current ? "240px" : "60px");
    const {setIsTokenExpiredModalShowUp, setSidebarCollapsed} = useContext(YuJaGlobalState);


    const clearSessionCheckInterval = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = 0;
        }
    };

    useEffect(() => {
        setSidebarCollapsed(collapsed);
    }, [collapsed]);

    useEffect(() => {
        isNarrow.current = window.innerWidth < RESPONSIVE_SIDE_BAR_POINT;
        setMinWidthState(isNarrow.current ? "240px" : "60px");
    }, [window.innerWidth]);

    useEffect(() => {
        if (location.state && location.state.msg) {
            notifyError(location.state.msg);
            const newState = {...location.state};
            newState.msg = null;
            location.state = newState;
        }
        if (location.state && location.state.pollType) {
            setPollType(location.state.pollType);
        }
        if (role === ROLES.IT_MANAGER.value) {
            setITManagerHidden(false);
        }
        // if(location.state && location.state.isLMS) {
        //     setIsLMS(location.state.isLMS);
        // }
    }, [location.state]);

    useEffect(() => {
        const {authorization = '', userName = '', role = 'ANONYMOUS', currentCourseRole = ''} = session;
        const tokenValidateRes = role === ROLES.ANONYMOUS.value || (!!authorization && !!userName);
        const roleValidateRes = pageRoles.includes(role) || pageRoles.includes(currentCourseRole);
        const sessionExpiredRes = role !== ROLES.ANONYMOUS.value && checkExpired();
        if (!query.get("secret") && !tokenValidateRes) {
            setIsTokenExpiredModalShowUp(false);
            history.push(LOGIN_PATH, {from: location.pathname});
            setSession({});
            return
        }
        if (tokenValidateRes && !roleValidateRes) {
            setIsTokenExpiredModalShowUp(false);
            history.push(LOGIN_PATH);
            setSession({});
            return
        }

        if (!query.get("secret") && sessionExpiredRes) {
            setIsTokenExpiredModalShowUp(false);
            history.push(LOGIN_PATH, {from: location.pathname});
            setSession({});
            return
        }
        setTokenValidateRes(tokenValidateRes);
        setRoleValidateRes(roleValidateRes);
        setSessionExpiredRes(sessionExpiredRes);
        setIsTokenExpiredModalShowUp(false);

        if (query.get("secret") && query.get("intType")) {
            if (query.get("intType").toLowerCase() === "adfs") {
                loginSAML(query.get("secret"), query.get("intType"));
            } else if (query.get("intType").toLowerCase() === "canvas") {
                loginCANVAS(query.get("secret"), query.get("intType"));
            } else if (query.get("intType").toLowerCase() === "blackboard") {
                loginBB(query.get("secret"), query.get("intType"));
            }
        }
    }, []);

    useEffect(() => {
        if (document.visibilityState === "hidden") {
            clearSessionCheckInterval();
            return;
        }

        if (document.visibilityState !== "visible") {
            return;
        }


        //session is empty
        if ((!session || !Object.entries(session).length) && !pageRoles.includes(ROLES.ANONYMOUS.value)) {
            setIsTokenExpiredModalShowUp(false);
            history.push(LOGIN_PATH);
            setSession({});
            return;
        }

        //token is expired
        if (role !== ROLES.ANONYMOUS.value && checkExpired()) {
            clearSessionCheckInterval();
            setIsTokenExpiredModalShowUp(true);
            return;
        }

        //token will expire soon
        intervalIdRef.current = setInterval(() => {
            console.log("check session if it will be expired...")
            if (role !== ROLES.ANONYMOUS.value && checkWillExpired()) {
                reLogin();
            }
        }, SESSION_CHECK_INTERVAL);

        return () => {
            clearSessionCheckInterval();
        }
    }, [visibility]);


    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === "hidden") {
            setVisibility(false);
        } else if (document.visibilityState === "visible") {
            setVisibility(true);
        }
    }, []);


    useEffect(() => {
        window.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            window.removeEventListener("visibilitychange", handleVisibilityChange);
        }
    }, [handleVisibilityChange]);


    return (tokenValidateRes && roleValidateRes && !sessionExpiredRes &&
        <>
            <YuJaNavbarNew
                isViewer={[ROLES.VIEWER.value, ROLES.ANONYMOUS.value].includes(role)}
                isCreator={[ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value].includes(role)}
                sidebarCollapsed={collapsed}
                countdownContent={countdownContent}
                webSocketState={webSocketState}
                pollKeyCode={pollKeyCode}
                pollType={pollType}
                pageIdentifier={pageIdentifier}
            />

            <div style={{display: "flex", position: "relative", flex: 1, minHeight: 0}}>
                {(!gradedLink || window.location === window.parent.location) && ([ROLES.VIEWER.value, ROLES.ANONYMOUS.value].includes(role) ?
                    <NewSidebar isViewer={true} collapsed={collapsed} setCollapsed={setCollapsed} /> :
                    ([ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value].includes(role) ?
                        <NewSidebar isCreator={true} collapsed={collapsed} setCollapsed={setCollapsed} /> :
                        <></>)
                )}

                {isSidebar ?
                    (
                        <main className={`${styles.mainSection} ${styles.collapsedSidebar}`}>
                            {pollType !== POLL_TYPE.ATTENDANCE &&
                                <div className={styles.questionSideBar}>
                                    {sidebarContent}
                                </div>
                            }

                            <div className={styles.contentStyle} style={{width: pollType !== POLL_TYPE.ATTENDANCE ? "80%" : "100%", backgroundColor: "#E5E5E5"}}>
                                {hasPin
                                    ?
                                    <Spin size="large" wrapperClassName="globalSpin" spinning={loading}
                                          tip="Loading...">
                                        <div className={styles.contentStyle} >
                                            {React.cloneElement(children, {
                                                setSidebarContent: pollType !== POLL_TYPE.ATTENDANCE ? setSidebarContent : () => {},
                                                // setRightSidebarContent: setRightSidebarContent,
                                                setCountdownContent: setCountdownContent,
                                                setWebsocketState: setWebsocketState,
                                                setNavBgColor: setNavBgColor,
                                                setPageIdentifier: setPageIdentifier
                                            })}
                                        </div>
                                    </Spin>
                                    :
                                    <div className={styles.contentStyle} >
                                        {React.cloneElement(children, {
                                            setSidebarContent: pollType !== POLL_TYPE.ATTENDANCE ? setSidebarContent : () => {},
                                            setCountdownContent: setCountdownContent,
                                            setWebsocketState: setWebsocketState,
                                            // setRightSidebarContent: setRightSidebarContent,
                                            setPollKeyCode: setPollKeyCode,
                                            setNavBgColor: setNavBgColor,
                                            setPageIdentifier: setPageIdentifier
                                        })}
                                    </div>

                                }
                            </div>

                            {/* <ResponsiveSidebar
                                menuClassName="mobile-menu-modified"
                                className={styles.questionDetailBar}
                                style={{backgroundColor: "blue"}}
                                collapsed={rightcollapsed}
                                setCollapsed= {setRightCollapsed}
                            >
                                {rightSidebarContent}
                            </ResponsiveSidebar> */}

                        </main>
                    ) : (
                        <main
                            style={(isNarrow.current && role === ROLES.VIEWER.value ? {marginLeft: "0px"} : {})}
                            className={`${styles.mainSection} ${styles.collapsedSidebar}`}
                        >
                            {hasPin
                                ?
                                <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                    <div className={(gradedLink && (window.location === window.parent.location)) ? styles.contentStyleWoNavbar : styles.contentStyle} >
                                        {React.cloneElement(children, {
                                            setNavBgColor: setNavBgColor,
                                            collapsed: collapsed,
                                            setCountdownContent: setCountdownContent,
                                            setWebsocketState: setWebsocketState,
                                            setPageIdentifier: setPageIdentifier
                                        })}
                                    </div>
                                </Spin>
                                :
                                <div className={(gradedLink && (window.location === window.parent.location)) ? styles.contentStyleWoNavbar : styles.contentStyle} >
                                    {React.cloneElement(children, {
                                        setNavBgColor: setNavBgColor,
                                        setCountdownContent: setCountdownContent,
                                        setWebsocketState: setWebsocketState,
                                        setPageIdentifier: setPageIdentifier
                                    })}
                                </div>
                            }

                        </main>
                    )
                }
            </div>

            <YuJaTokenExpiredAlert/>

        </>
    );
}
