/*global chrome*/
import { browserName, fullBrowserVersion, osName, osVersion } from "react-device-detect";
import toast, {useToaster} from "react-hot-toast";
import {
  ASCII_CODE_A,
  ATTEMPTS,
  ATTEMPT_NO_PREFIX,
  ATTEMPT_REGEX,
  DEFAULT_ATTENDANCE_MANUAL_END,
  DEFAULT_ATTENDANCE_SAVED_LOCATIONS,
  DEFAULT_ATTENDANCE_TIME_LIMIT,
  DEFAULT_GET_READY_ALL_TIME_LIMIT,
  DEFAULT_GET_READY_TIME_LIMIT,
  DEFAULT_POINTS,
  DEFAULT_POLL_LMS_SYNC,
  DEFAULT_POLL_MAX_ATTEMPTS,
  DEFAULT_POLL_RECORD_ATTEMPTS,
  DEFAULT_POLL_REQUIRED_NAME,
  DEFAULT_POLL_SHOW_CORRECT_ANS,
  DEFAULT_POLL_TIME_LIMIT,
  DEFAULT_PPT_AUTO_SHARE,
  DEFAULT_TIME_LIMIT,
  DEFAULT_TOAST_MESSAGE_DURATION,
  FIXED_ANSWER,
  GRADED_POLL,
  INTEGRATION_TYPES,
  INTEGRATION_TYPES_LOWERCASE,
  LOCATION_MESSAGE,
  LOCATION_MESSAGE_GB_DISPLAY,
  LOGOUT_CHROME_EXT_MESSAGE,
  LOG_API_FILES,
  LOG_TYPES,
  MAX_RADIUS,
  MCSSoptLower,
  MCSSoptUpper,
  MENTIMETER_EXTENSION_ID,
  MIN_RADIUS,
  POLL_CODE_LENGTH,
  POLL_EVERYWHERE_EXTENSION_ID,
  POLL_SHARE_MODE,
  POLL_TYPE,
  QUESTION_TYPES,
  READY_TO_SHARE,
  REATTEMPT_OUT_OF,
  REATTEMPT_REMAINING_TEXT,
  ROLES,
  SCHEDULED,
  SESSION,
  SHARED_BY_QUESTION,
  SHARED_ENTIRELY,
  SHARED_ENTIRE_POLL,
  SHARED_ENTIRE_SURVEY,
  STUDENT_COLUMN_INDEX,
  SYNC_ATTEMPT,
  TOAST_TYPE,
  TOPHAT_EXTENSION_ID,
  UNFINISHED,
  UNFINISHED_CHOOSER,
  USER_ID_COLUMN_INDEX,
  USER_SETTINGS,
  WORD_CLOUD_COLORS,
  WORD_CLOUD_ID, MC_ANSWER_LENGTH, IN_GEOFENCE_PREFIX, IN_GEOFENCE_SUFFIX
} from "./constants";
import { AUTHENTICATION_API_URL, COURSE_API_URL, INSTITUTION_API_URL, INTEGRATION_API_URL, POLL_API_URL, PUBLIC_ACCESS_API_URL, RESPONSE_API_URL, USER_API_URL } from "./properties.js";
import { questionError } from "./questionUtils.js";
import { DEFAULT_LOCATION_ERROR } from "./toast-message-constants.js";

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const removePrefix = (text) => {
  // ids are store with prefixes ends with # in the dynamodb
  return text.substring(text.indexOf("#") + 1);
};

export const removePrefixNew = (text) => {
  return text.substring(text.lastIndexOf("#") + 1);
};

export const transferConstantPollType = (pollType) => {
  if (!pollType) {
    return pollType;
  }

  return pollType.trim().toUpperCase().split(' ').join('_');
}


// isBefore: Str Str => Bool checks if date1 comes before date2
// date1 and date2 are strings in the format of "dd-mm-yyyy"
export const isBefore = (date1, date2) => {
  const year1 = date1.slice(-4);
  const year2 = date2.slice(-4);
  const month1 = date1.slice(3, 5);
  const month2 = date2.slice(3, 5);
  const day1 = date1.slice(0, 2);
  const day2 = date2.slice(0, 2);
  if (year1 < year2) {
    return true;
  }
  else if (year1 > year2) {
    return false;
  }
  else {
    if (month1 < month2) {
      return true;
    } else if (month1 > month2) {
      return false;
    } else {
      if (day1 <= day2) {
        return true;
      } else {
        return false;
      }
    }
  }
  // return (year1 <= year2) && (month1 <= month2) && (day1 <= day2);
};

export const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export let logger = {
  institutionId: null,
  userId: null,
  userType: null,
  pollKey: null,
  pollCode: null,
  courseId: null,
  browserType: browserName + " " + fullBrowserVersion,
  platform: osName + " " + osVersion,
  messages: "",
  collectUserLogs: false
};

export const newPageLog = (file) => {
  updateLogger(LOG_TYPES.INFO + "User with userId: " + logger.userId + " has reached page " + file + "\n");
};

export const formatUserSubmit = () => {
  updateLogger(LOG_TYPES.INFO + "User with userId: " + logger.userId + " has submitted the question!\n");
};

export const formatErrorLog = (err) => {
  updateLogger(LOG_TYPES.ERROR + "Message: " + JSON.stringify(err));
}

export const formatAPILog = (url, body) => {
  try {
    updateLogAttributes(body);
    let file = null;
    if (url.includes(RESPONSE_API_URL) || url === "SetUserNameToLocalStorage") {
      file = LOG_API_FILES.RESPONSE;
    } else if (url.includes(PUBLIC_ACCESS_API_URL)) {
      file = LOG_API_FILES.PUBLIC;
    } else if (url.includes(POLL_API_URL)) {
      file = LOG_API_FILES.POLL;
    } else if (url.includes(USER_API_URL)) {
      file = LOG_API_FILES.USER;
    } else if (url.includes(INTEGRATION_API_URL)) {
      file = LOG_API_FILES.LTI;
    } else if (url.includes(INSTITUTION_API_URL)) {
      file = LOG_API_FILES.INST;
    } else if (url.includes(COURSE_API_URL)) {
      file = LOG_API_FILES.COURSE;
    } else if (url.includes(AUTHENTICATION_API_URL)) {
      file = LOG_API_FILES.AUTHENTICATION;
    }
    updateLogger(LOG_TYPES.INFO + "Calling " + url + " from " + file + " with body " + JSON.stringify(body));
  } catch (e) {
    console.log(`format API Log gets error: ${e}`);
  }
};

export const formatAPIResponseLog = (apiName, respBody) => {
  updateLogAttributes(respBody);
  updateLogger(LOG_TYPES.INFO + apiName + " returned with response body " + JSON.stringify(respBody));
};

export const formatAPIErrorLog = (apiName, error) => {
  updateLogger(LOG_TYPES.ERROR + apiName + " returned with error message " + JSON.stringify(error));
};

export const updateLogAttributes = (body) => {
  logger.institutionId = body.institutionIdPK ? body.institutionIdPK.substring(0, 38) : logger.institutionId;
  logger.userId = body.userId ? (body.userId.includes("U#") ? body.userId : "U#" + body.userId) : logger.userId;
  logger.userType = body.userType ? body.userType : logger.userType;
  logger.pollKey = body.pollKey ? (body.pollKey.length === 36 ? "P#" + body.pollKey : body.pollKey) : logger.pollKey;
  logger.pollCode = body.uniqueCodePK ? (body.uniqueCodePK.length === POLL_CODE_LENGTH ? "UC#" + body.uniqueCodePK : body.uniqueCodePK) : logger.pollCode;
  logger.courseId = body.index2Pk ? (body.index2Pk.length === 36 ? "C#" + body.index2Pk : body.index2Pk) : logger.courseId;
};

export const updateLogger = (text) => {
  logger.messages += (text + "\n");
};

export const intToChar = (int) => {
  // 👇️ for Uppercase letters, replace `a` with `A`
  const code = "a".charCodeAt(0);

  return String.fromCharCode(code + int);
};

export const splitArrayIntoChunks = (arr, chunkSize) => {
  return arr
      .map((e, i) => {
        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
};

const buildToastOptObj = () => {
  const optionObj = {};
  const session = window.navigator.cookieEnabled ? JSON.parse(localStorage.session) : localStorageData[SESSION];
  const settings = session?.settings ? session?.settings: {};
  if (!settings.hasOwnProperty(USER_SETTINGS.TOAST_MESSAGE_DURATION)) {
    optionObj.duration = DEFAULT_TOAST_MESSAGE_DURATION * 1000;
  } else if (parseInt(settings[USER_SETTINGS.TOAST_MESSAGE_DURATION]) > 0) {
    optionObj.duration = parseInt(settings[USER_SETTINGS.TOAST_MESSAGE_DURATION]) * 1000;
  }
  return optionObj;
}

export const notifyInfo= (text, manualDismiss=false, title=null) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.INFO;
  if (title != null) {
    optionObj.title= title;
  }
  else {
    optionObj.title='Info';
  }
  if (manualDismiss) {
    optionObj.duration = undefined;
    optionObj.manualDismiss = true;
  }
  toast.loading(text ? text : '', optionObj); // returns the toastId
};

export const notifySuccess = (text, title=null) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.SUCCESS;
  if (title != null) {
    optionObj.title= title;
  }
  else {
    optionObj.title='Success';
  }
  toast.loading(text ? text : '', optionObj);
};

export const notifyWarning= (text) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.WARNING;
  optionObj.title='Warning';
  toast.loading(text ? text : '', optionObj);
};

export const notifyError = (text) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.ERROR;
  optionObj.title='Error';
  toast.loading(text ? text : '', optionObj);
};


// for old design
export const unChooseAllQuestions = (questions) => {
  let newQuestions = [];
  questions.forEach((q) => {
    q = { ...q, checked: false };
    newQuestions.push(q);
  });

  return newQuestions;
};

export const unChooseAll = (content) => {
  let newContent = [];
  content.forEach((poll) => {
    poll = {
      ...poll,
      checked: false,
      showChildren: false,
      questions: unChooseAllQuestions(poll.questions),
    };
    newContent.push(poll);
  });
  return newContent;
};
// for old design


export const validateArray = (arr) => {
  return arr && arr.length > 0;
};

export const validateMap = (map) => {
  return map && Object.entries(map).length > 0;
};

export const validateText = (text, maxLength) => {
  return text && text.trim() !== "" && text.length <= maxLength;
};

export const validateMCSSOptText = (text, optionsImageMap, optIdx) => {
  const {image=""} = !!optionsImageMap && optionsImageMap.hasOwnProperty(optIdx) && !!optionsImageMap[optIdx]
      ? optionsImageMap[optIdx] : {};
  if (!!image) {
    return true;
  }
  return text && text.trim() !== "" && text.length <= MC_ANSWER_LENGTH;
};

export const validateTextEmpty = (text) => {
  return text && text !== "";
};

export const validateTextMaxLength = (text, maxLength) => {
  return text.length <= maxLength;
};

export const validateOptions = (map, maxLength) => {
  return validateMap(map) && Object.values(map).every((v) => validateText(v, maxLength));
};

export const validatePoll = (poll) => {
  if (!poll.questions) return false;
  for (let q of poll.questions) {
    if (q.weightage > 1000) {
      return false;
    }

    let {quesError} = questionError(q, poll.pollType);
    if (quesError) {
      return false;
    }
  }
  return true;
};

export const stringifyNumber = (n) => {
  const special = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
  ];

  const deca = [
    "Twent",
    "Thirt",
    "Fort",
    "Fift",
    "Sixt",
    "Sevent",
    "Right",
    "Ninet",
  ];

  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
};

export const customSlice = (text, length) => { // to slice FITB title without considering blanks in character count
                                               // console.log(text, length);
  if (text.length <= length) return text;
  let i = 0;
  let j = 0;
  let res = "";
  let resLen = 0;
  while (i < text.length && resLen <= length) {
    if (text[i] === '(') {
      j = i;
      while (j < text.length) {
        ++j;
        if (text[j] === '(') {
          --j;
          break;
        } else if (j !== i + 1 && text[j] === ')') {
          break;
        }
      }
      if (text[j] === ')' || (resLen + j - i + 1) <= length) {
        res += text.substring(i, j + 1);
        if (text[j] !== ')') resLen += (j - i + 1);
      }
      i = j + 1;
      // console.log(resLen, res);
    } else {
      if (resLen !== length) res += text[i];
      ++resLen;
      ++i;
      // console.log(resLen, res);
    }
  }
  return res;
};

export const fetchImg = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();
    const url = URL.createObjectURL(imageBlob);
    console.log(url)
    return url;
  } catch (e) {
    return "";
  }
}


export const fetchImgFile = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();
    imageBlob.lastModifiedDate = new Date();
    return imageBlob;
  } catch (e) {
    return null;
  }
}

// Returns the Institution ID from the given GSI5PK
export const getInstitutionId = (gsi5pk) => { // For example: "I#b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615#PUB#UC"
  let startIdx = gsi5pk.indexOf("#") + 1;     // 2
  let endIdx = gsi5pk.indexOf("#", startIdx); // 38
  return gsi5pk.substring(startIdx, endIdx);  // "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615"
}

export const fixFITB = (input) => {
  const regex = /(\(.*?\))/g;
  return input.replaceAll(regex, "______")
}

export const handleUserAns = (liveQuestion, ans) => {
  if (ans === FIXED_ANSWER.UNANSWERED) {
    return false;
  }

  if (liveQuestion.questionType === QUESTION_TYPES.MCSS.name) {
      return checkMCQueCorrect(liveQuestion, ans);
  } else if (liveQuestion.questionType === QUESTION_TYPES.TF.name) {
    return (liveQuestion.correctAnswers === ans);
  } else if (liveQuestion.questionType === QUESTION_TYPES.SA.name) {
    let formattedAns = (ans["0"]).toString().toLowerCase().trim();
    for (let i = 0; i < liveQuestion.correctAnswers.length; i++) {
      if (formattedAns === (liveQuestion.correctAnswers[i]).toString().toLowerCase().trim())
        return true;
    }
    return false;
  } else if (liveQuestion.questionType === QUESTION_TYPES.FITB.name) {
    let correctAns = liveQuestion.correctAnswers;
    let keys = Object.keys(correctAns);
    const FITBnum = keys.length;
    let numCorrect = 0;

    for (let i = 0; i < FITBnum; i++) {
      for (let j = 0; j < correctAns[keys[i]].length; j++) {

        if (ans[keys[i]] === undefined)
          return false;

        if ((correctAns[keys[i]][j]).toString().toLowerCase().trim() === ans[keys[i]].toString().toLowerCase().trim())
          numCorrect++
      }
    }
    return numCorrect === FITBnum;
  } else if (liveQuestion.questionType === QUESTION_TYPES.CI.name) {
    const {selected} = ans;
    if (selected === FIXED_ANSWER.OTHER) {
      return false;
    }

    return liveQuestion.correctAnswers.includes(parseInt(selected));
  } else if (liveQuestion.questionType === QUESTION_TYPES.WC.name) { // Irrelevant for Survey; Same as SA for Graded
    return false;
  } else if (liveQuestion.questionType === QUESTION_TYPES.RK.name) {
    return false;
  } else if (liveQuestion.questionType === QUESTION_TYPES.OE.name) {
    return false;
  } else if (liveQuestion.questionType === QUESTION_TYPES.MH.name) {
    if (ans == null) {
      return false;
    }
    let is_correct = true;
    for (let i = 0; i< liveQuestion.correctAnswers.length; i++) {
      if(liveQuestion.correctAnswers[i].length > 1 && (!ans.hasOwnProperty(i) || liveQuestion.correctAnswers[i][1] !== ans[i]) ) {
        is_correct= false;
      }
    }
    return is_correct;
  }
};



export const isPureNumber = (val) => {
  if (typeof val !== "string" || !(val instanceof String)) {
    return false;
  }

  return /^[0-9]+$/.test(val);
}

/**
 *
 * @returns formatted string yyyy-MM-ddTHH:mm:ss
 */
export const getFormattedCurrentTime = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = ("00" + (now.getUTCMonth() + 1)).slice(-2);
  const date = ("00" + now.getUTCDate()).slice(-2);
  const hour = ("00" + now.getUTCHours()).slice(-2);
  const minute = ("00" + now.getUTCMinutes()).slice(-2);
  const second = ("00" + now.getUTCSeconds()).slice(-2);

  return `${year}-${month}-${date}T${hour}:${minute}:${second}`;
}


/**
 *
 * @param imageName ${originalFileName}-yyyy-MM-ddTHH:mm:ss${suffix}
 * @returns {string}
 */
export const getFileNameFromImageName = (imageName) => {
  const regexp = /(.+)-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\w+/g;
  return regexp.exec(imageName)[1];
}

/**
 *
 * @param imageName ${originalFileName}-yyyy-MM-ddTHH:mm:ss${suffix}
 * @returns {string}
 */
export const getSuffixFromImageName = (imageName) => {
  const regexp = /(.+)-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\w+)/g;
  return regexp.exec(imageName)[2];
}

export const isValidScore = (s) => {
  const score = s;
  // if (score === undefined || score === null) { // invalid variable
  //   return false;
  // }
  const regexp = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/g; // SCORE_REGEX
  const result = regexp.test(score);
  // console.log(score, typeof score, result);
  return result;
}

export const hasSA = (questions) => {
  // console.log(questions);
  for (const q of questions) {
    if (q.questionType === QUESTION_TYPES.SA.name) {
      return true;
    }
  }
  // console.log("no SA");
  return false;
};

export const hasWeightage = (questions) => {
  // console.log(questions);
  for (const q of questions) {
    if (!!q.weightage) {
      return true;
    }
  }
  // console.log("no weightage");
  return false;
};

export const hasWC = (questions) => {
  // console.log(questions);
  for (const q of questions) {
    if (q.questionType === QUESTION_TYPES.WC.name) {
      return true;
    }
  }
  // console.log("no WC");
  return false;
};

// for FITB and WC and MH
export const checkBlankAnswered = (selectedOption) => {
  if (selectedOption === FIXED_ANSWER.UNANSWERED ) {
    return true;
  }
  for (let key in selectedOption) {
    if (selectedOption[key] !== "") {
      return false;
    }
  }
  return true;
}

export const checkUnanswered = (gradeEnabled, curQuestionType, selectedOption) => {
  return !selectedOption
      || (!gradeEnabled && (curQuestionType === QUESTION_TYPES.MCSS.name || curQuestionType === QUESTION_TYPES.RK.name) && selectedOption.length === 0)
      || (!gradeEnabled && curQuestionType === QUESTION_TYPES.MCSS.name && selectedOption[0] === FIXED_ANSWER.UNANSWERED)
      || selectedOption === FIXED_ANSWER.UNANSWERED;
}



export const checkUnansweredNew = (gradeEnabled, curQuestionType, selectedOption) => {
  if (!selectedOption || selectedOption === FIXED_ANSWER.UNANSWERED ) {
    return true;
  }

  if ([QUESTION_TYPES.FITB.name, QUESTION_TYPES.WC.name, QUESTION_TYPES.MH.name].includes(curQuestionType)) {
    for (let key in selectedOption) {
      if (selectedOption[key] !== "") {
        return false;
      }
    }
    return true;
  }

  if (curQuestionType === QUESTION_TYPES.MCSS.name
      && Array.isArray(selectedOption)
      && (!selectedOption.length || selectedOption[0] === FIXED_ANSWER.UNANSWERED)
  ) {
    return true;
  }

  if (!gradeEnabled
      && [QUESTION_TYPES.RK.name, QUESTION_TYPES.MCSS.name].includes(curQuestionType)
      && selectedOption.length === 0
  ) {
    return true;
  }

  return selectedOption === FIXED_ANSWER.UNANSWERED;

}

export const checkIncomplete = (gradeEnabled, curQuestionType, selectedOption) => {
  return checkUnanswered(gradeEnabled, curQuestionType, selectedOption) || (curQuestionType === QUESTION_TYPES.FITB.name && Object.values(selectedOption).includes(""));
}

export const getYourAnswer = (gradeEnabled, questionType, selectedOption) => {
  if (checkUnanswered(gradeEnabled, selectedOption)) {
    return FIXED_ANSWER.UNANSWERED;
  }

  if (questionType === QUESTION_TYPES.MCSS.name && !gradeEnabled) {
    return `${JSON.stringify(selectedOption.map(opt => opt.toUpperCase()))}`
  }

  if (questionType === QUESTION_TYPES.MCSS.name) {
    return `${selectedOption.toUpperCase()}`
  }

  if (questionType === QUESTION_TYPES.TF.name) {
    return selectedOption;
  }

  if (questionType === QUESTION_TYPES.FITB.name) {
    let answerArr = [];
    for (const [key, value] of Object.entries(selectedOption)) {
      answerArr.push(`${key}: ${value}`);
    }
    return answerArr.join(", ");
  }

  if (questionType === QUESTION_TYPES.SA.name) {
    return selectedOption['0'];
  }

  if (questionType === QUESTION_TYPES.CI.name) {
    if (selectedOption.selected === FIXED_ANSWER.OTHER) {
      return FIXED_ANSWER.OTHER;
    }
    return String.fromCharCode(ASCII_CODE_A + parseInt(selectedOption.selected)).toUpperCase();
  }

  if (questionType === QUESTION_TYPES.WC.name) {
    return selectedOption;
  }

  return '';
}

export const checkAllEmpty = (jsonString) => {
  const jsonObject = JSON.parse(jsonString);
  return Object.values(jsonObject).every(value => typeof value !== 'string' || value.trim() === "");
}

export const getReattemptText = (attemptsCount, maxAttempts) => {
  return `${attemptsCount} ${REATTEMPT_OUT_OF} ${maxAttempts} ${REATTEMPT_REMAINING_TEXT}`
};

export const prepareAttemptSubrows = (responses) => {
  console.log("Original Responses", responses);
  let updatedResponses = [];
  responses.map((response, idx) => {
    let updatedResponse = {...response};
    // console.log(response);
    // let userId = response.userId;
    if (updatedResponse.hasOwnProperty(ATTEMPTS)) {
      updatedResponse.subRows = [];
      for (const [attemptNo, attempt] of Object.entries(updatedResponse.attempts)) {
        if (attemptNo === "0") {
          updatedResponse = {...attempt, ...updatedResponse};
        } else {
          updatedResponse.subRows.push({
            firstName: "Attempt",
            lastName: "#" + attemptNo,
            // userId: userId,
            ...attempt
          });
        }
      }
    }
    updatedResponses[idx] = updatedResponse;
    return updatedResponse;
  });
  console.log("Updated Responses", updatedResponses);
  return updatedResponses;
};

export const prepareAttendanceSubrows = (responses) => {
  console.log("Original Responses", responses);
  let updatedResponses = [];
  responses.map((response, idx) => {
    // console.log(response);
    let updatedResponse = JSON.parse(JSON.stringify(response));
    updatedResponse.subRows = [{
      locationData: updatedResponse.locationData,
      selectedOption: updatedResponse.selectedOption
    }];
    updatedResponses[idx] = updatedResponse;
    return updatedResponse;
  });
  console.log("Updated Responses", updatedResponses);
  return updatedResponses;
};

export const prepareFeedbackSubrows = (data) => {
  console.log("Original Data", data);
  let updatedData = [];
  data.map((response, idx) => {
    let updatedResponse = {...response};
    if (updatedResponse.hasOwnProperty("text")) {
      updatedResponse.subRows = [updatedResponse.text];
    }
    updatedData[idx] = updatedResponse;
    return updatedResponse;
  });
  console.log("Updated Data", updatedData);
  return updatedData;
};

export const feedbackAnonymousToGuest = (data) => {
  data.sort(function (a, b) {
    return new Date(Date.parse(a.date)) - new Date(Date.parse(b.date));
  });
  let anonymousCount = 1; 
  let updatedData = [];
  data.map((response, idx) => { 
    let updatedResponse = {...response};
    if(updatedResponse.userName === "anonymous") {
      updatedResponse.userName = "Guest" + anonymousCount;
      anonymousCount +=1; 
    }
    updatedData[idx] = updatedResponse;
    return updatedResponse;
  });
  return updatedData;
}
export const getAllExpanded = (rows) => {
  // console.log(rows);
  const res = rows.every(row => {
    // console.log(row, row.getCanExpand(), row.getIsExpanded());
    return !row.getCanExpand() || row.getIsExpanded();
  });
  return res;
};

export const getUserId = (row) => {
  return row.getAllCells()[USER_ID_COLUMN_INDEX].getValue();
};

export const getAttemptNo = (row) => {
  let str = row.getAllCells()[STUDENT_COLUMN_INDEX].getValue();
  // console.log(str, parseInt(str.substring(str.indexOf(ATTEMPT_NO_PREFIX) + 9)));
  return (!!str && ATTEMPT_REGEX.test(str) ? parseInt(str.substring(str.indexOf(ATTEMPT_NO_PREFIX) + 9)) : 0);
};

// export const getTotalAttempts = (row, customScoresMap) => {
//   const userId = getUserId(row);
//   if (!customScoresMap.hasOwnProperty(userId)) return -1;
//   const keys = Object.keys(customScoresMap[userId]);
//   // console.log(keys, keys.length, keys[keys.length-1]);
//   return parseInt(keys[keys.length-1]);
// };

// export const isLastRow = (row, rows) => {
//   // console.log(row, row.id);
//   // console.log(rows, rows[rows.length - 1].id);
//   // console.log(row.id === rows[rows.length - 1].id);
//   return row.id === rows[rows.length - 1].id;
// }

export const isDisabled = (row, customScoresMap, lmsAttempt) => {
  // console.log(row, customScoresMap, lmsAttempt);
  // if (row.getCanExpand() || getAttemptNo(row) === 0) {
  //   console.log("disable main attempt");
  //   return true;
  // }
  // if (lmsAttempt === SYNC_ATTEMPT.FIRST.value && getAttemptNo(row) !== 1) {
  //   console.log("disable all attempts except first attempt");
  //   return true;
  // }
  // if (lmsAttempt === SYNC_ATTEMPT.RECENT.value && getAttemptNo(row) !== Object.keys(customScoresMap[row.getAllCells()[USER_ID_COLUMN_INDEX].getValue()]).length - 1) {
  //   console.log("disable all attempts except last attempt");
  //   return true;
  // }
  // console.log("enabled");
  // return false;
  return ((row.getCanExpand() || getAttemptNo(row) === 0) || // disable main attempt
      (lmsAttempt === SYNC_ATTEMPT.FIRST.value && // disable all attempts except first attempt
          getAttemptNo(row) !== 1) ||
      (lmsAttempt === SYNC_ATTEMPT.RECENT.value && // disable all attempts except last attempt
          getAttemptNo(row) !== Object.keys(customScoresMap[getUserId(row)]).length - 1));
};

export const isDisabledAlt = (row, customScoresMap, lmsAttempt, pollType=POLL_TYPE.GRADED_POLL) => { // ignores main attempt check
  return (pollType !== POLL_TYPE.SURVEY && getAttemptNo(row) !== 0 && ((lmsAttempt === SYNC_ATTEMPT.FIRST.value && getAttemptNo(row) !== 1) ||
      (lmsAttempt === SYNC_ATTEMPT.RECENT.value && getAttemptNo(row) !== Object.keys(customScoresMap[getUserId(row)]).length - 1)));
};

export const isDisabledRadio = (attemptNo, attempts, lmsAttempt, pollType=POLL_TYPE.GRADED_POLL) => { // ignores main attempt check
  return (pollType !== POLL_TYPE.SURVEY && attemptNo !== 0 && ((lmsAttempt === SYNC_ATTEMPT.FIRST.value && attemptNo !== 1) ||
      (lmsAttempt === SYNC_ATTEMPT.RECENT.value && attemptNo !== Object.keys(attempts).length - 1)));
};

// export const getCustomScore = (r, c) => {
//   const row = r;
//   const customScoresMap = c;
//   console.log(row, customScoresMap);
//   if (!customScoresMap.size) {
//     console.log("custom scores map not initialized yet");
//     return null;
//   }
//   console.log(!!customScoresMap, customScoresMap.size);
//   const userId = getUserId(row);
//   console.log(userId);
//   const userMap = customScoresMap[userId];
//   console.log(userMap);
//   const attemptNo = getAttemptNo(row);
//   console.log(attemptNo);
//   const score = userMap[attemptNo];
//   console.log(score);
//   return score;
// };

export const calculateScore = (earnedPoints, totalPoints) => {
  const score = ((earnedPoints/totalPoints)*100.0).toFixed(2);
  // console.log(earnedPoints, totalPoints, score);
  return score;
};

export const getAnswer = (response, attemptNo, serialNo) => {
  let answer = FIXED_ANSWER.UNANSWERED;
  if (!!response && response.hasOwnProperty("attempts") && response.attempts[attemptNo].hasOwnProperty("A" + serialNo)) {
    if (response.attempts[attemptNo]["A" + serialNo] !== FIXED_ANSWER.UNANSWERED) {
      answer = response.attempts[attemptNo]["A" + serialNo][0];
    }
  }
  return answer;
}

export const parseWCId = (id) => {
  return id.substring(id.lastIndexOf("-") + 1); // "WC-IN-1" => "1"
};

export const organiseData = (data, correctAnswers) => {
  let words = [];
  if (!data) return words;
  data.forEach((name) => {
    if (name === FIXED_ANSWER.UNANSWERED) return;
    const index = words.findIndex((item) => item.name === name);
    if (index === -1) {
      if (correctAnswers.includes(name)) { // change style for correct answers in Graded Poll flow
        words.push({
          name,
          value: 1,
          textStyle: {
            color: "green",
            fontWeight: "bold"
          }
        });
      } else {
        words.push({
          name,
          value: 1,
          textStyle: {
            color: autoFontColor(),
            // size: autoFontSize()
          }
        });
      }
    } else {
      words[index].value++;
    }
  });
  return words;
};

export const getWordCloudWidth = (serialNo=1) => {
  let width = document.getElementById(WORD_CLOUD_ID + serialNo) ? document.getElementById(WORD_CLOUD_ID + serialNo).offsetWidth : 0;
  return width;
};

export const autoFontSize = (serialNo=1) => {
  let width = getWordCloudWidth(serialNo);
  let newFontSize = Math.round(width / 30);
  return newFontSize;
};

export const autoFontColor = () => {
  return WORD_CLOUD_COLORS[Math.floor(Math.random() * WORD_CLOUD_COLORS.length)];
};

export const processResult = (question, result) => {
  const {questionType, optionsMap} = question;
  let newOptionsMap = (questionType === QUESTION_TYPES.MCSS.name ? optionsMap : {});
  const MCSSnum = (questionType === QUESTION_TYPES.MCSS.name ? Object.keys(newOptionsMap).length : 0);
  const data = [];
  if (questionType === QUESTION_TYPES.MCSS.name) {
    for (let i = 0; i < MCSSnum; i++) {
      let opt;
      if (Number.isNaN(Number(result[MCSSoptLower[i]]))) {
        opt = {
          "name": MCSSoptUpper[i] + ". 0",
          "people": 0,
          "ans": MCSSoptUpper[i] + ". " + newOptionsMap[MCSSoptLower[i]],
          "total": result.total,
        }
      } else {
        opt = {
          "name": MCSSoptUpper[i] + ". " + Number(result[MCSSoptLower[i]]),
          "people": Number(result[MCSSoptLower[i]]),
          "ans": MCSSoptUpper[i] + ". " + newOptionsMap[MCSSoptLower[i]],
          "total": result.total,
        }
      }
      data.push(opt);
    }
    let unans;
    if (Number.isNaN(Number(result['unanswered']))) {
      unans = {
        "name": "N/A. 0",
        "people": 0,
        "ans": "Unanswered",
        "total": result.total,
      }
    } else {
      unans = {
        "name": "N/A. " + Number(result['unanswered']),
        "people": Number(result['unanswered']),
        "ans": "Unanswered",
        "total": result.total,
      }
    }
    data.push(unans);

  } else if (questionType === QUESTION_TYPES.RK.name) {
    let opt = {
      "ans": "answered",
      "total": result.total
    }
    let unans = {
      "people": 0,
      "ans": "Unanswered",
      "total": result.total,
    }
    data.push(opt);
    data.push(unans);
    return data; 
  } else if (questionType === QUESTION_TYPES.TF.name) {
    let optT, optF;
    if (Number.isNaN(Number(result['True']))) {
      optT = {
        "name": "True. 0",
        "people": 0,
        "ans": "True",
        "total": result.total
      }
    } else {
      optT = {
        "name": "True. " + Number(result['True']),
        "people": Number(result['True']),
        "ans": "True",
        "total": result.total
      }
    }
    data.push(optT);

    if (Number.isNaN(Number(result['False']))) {
      optF = {
        "name": "False. 0",
        "people": 0,
        "ans": "False",
        "total": result.total
      }
    } else {
      optF = {
        "name": "False. " + Number(result['False']),
        "people": Number(result['False']),
        "ans": "False",
        "total": result.total
      }
    }
    data.push(optF);

    let unans;
    if (Number.isNaN(Number(result['unanswered']))) {
      unans = {
        "name": "N/A. 0",
        "people": 0,
        "ans": "Unanswered",
        "total": result.total
      }
    } else {
      unans = {
        "name": "N/A. " + Number(result['unanswered']),
        "people": Number(result['unanswered']),
        "ans": "Unanswered",
        "total": result.total
      }
    }
    data.push(unans);

  } else if (questionType === QUESTION_TYPES.CI.name) {
    let idx = 0
    for (; idx < optionsMap.length; idx++) {
      let opt = {
        "name": idx,
        "people": result.hasOwnProperty(idx.toString()) ? result[idx.toString()] : 0,
        "ans": `${String.fromCharCode(ASCII_CODE_A + idx)}. ${optionsMap[idx].text}`,
        "total": result.total
      }
      data.push(opt);
    }
    if (result.hasOwnProperty(FIXED_ANSWER.OTHER)) {
      idx++;
      data.push({
        "name": -1,
        "people": result[FIXED_ANSWER.OTHER],
        "ans": `${FIXED_ANSWER.OTHER}`,
        "total": result.total
      });
    }
    data.push({
      "people": result.hasOwnProperty(FIXED_ANSWER.UNANSWERED) ? result[FIXED_ANSWER.UNANSWERED] : 0,
      "ans": "Unanswered",
      "total": result.total
    });

  } else { // SA, FITB or WC or MH
    let optT, optF;
    if (Number.isNaN(Number(result['correct']))) {
      optT = {
        "name": "Correct. 0",
        "people": 0,
        "ans": "Correct",
        "total": result.total
      }
    } else {
      optT = {
        "name": "Correct. " + Number(result['correct']),
        "people": Number(result['correct']),
        "ans": "Correct",
        "total": result.total
      }
    }
    data.push(optT);

    if (Number.isNaN(Number(result['incorrect']))) {
      optF = {
        "name": "Incorrect. 0",
        "people": 0,
        "ans": "Incorrect",
        "total": result.total
      }
    } else {
      optF = {
        "name": "Incorrect. " + Number(result['incorrect']),
        "people": Number(result['incorrect']),
        "ans": "Incorrect",
        "total": result.total
      }
    }
    data.push(optF);

    let unans;
    if (Number.isNaN(Number(result['unanswered']))) {
      unans = {
        "name": "N/A. 0",
        "people": 0,
        "ans": "Unanswered",
        "total": result.total
      }
    } else {
      unans = {
        "name": "N/A. " + Number(result['unanswered']),
        "people": Number(result['unanswered']),
        "ans": "Unanswered",
        "total": result.total
      }
    }
    data.push(unans);

    if (questionType === QUESTION_TYPES.SA.name) {
      let ungraded;
      if (Number.isNaN(Number(result['ungraded']))) {
        ungraded = {
          "name": "Ungraded. 0",
          "people": 0,
          "ans": "Ungraded",
          "total": result.total
        }
      } else {
        ungraded = {
          "name": "Ungraded. " + Number(result['ungraded']),
          "people": Number(result['ungraded']),
          "ans": "Ungraded",
          "total": result.total
        }
      }
      data.push(ungraded);
    }
  }
  // console.log(data);
  return data;
};

export const getPollShareModeFromPollState = (pollState) => {
  switch (pollState) {
    case 'GET_READY_ALL':
    case 'SHARED_ALL':
    case 'TIME_UP_ALL':
    case 'PAUSED_ALL':
    case 'LOCKED_ALL':
      return POLL_SHARE_MODE.SHARE_ALL;
    case 'GET_READY':
    case 'SHARED':
    case 'TIME_UP':
    case 'PAUSED':
    case 'LOCKED':
      return POLL_SHARE_MODE.SHARE_EACH;
    default:
      return POLL_SHARE_MODE.UNKNOWN;
  }
};

export const InitializeQuestionResultCount = (question) => {
  const {questionType, optionsMap} = question;
  let result = [];
  if (questionType === QUESTION_TYPES.CI.name) {
    for (const [index, item] of optionsMap.entries()) {
      result.push({"name": index, "people": 0, "ans":  String.fromCharCode('A'.charCodeAt(0) + index) + ". " + item.text, "total": 0});
    }
  } else if(questionType === QUESTION_TYPES.MCSS.name) {
    Object.entries(optionsMap).forEach(entry => {
      result.push({"name": entry[0].toUpperCase(),"people":0,"ans": entry[0].toUpperCase() + ". " + entry[1],"total": 0})
    })
  } else if (questionType === QUESTION_TYPES.TF.name) {
    result = [{"name":"True. 1","people": 0,"ans": "True","total":0},{"name":"False. 0","people": 0,"ans": "False","total": 0}];
  } else if (questionType === QUESTION_TYPES.MH.name) {
    result = [{"name": "Correct. 0","people": 0, "ans": "Correct","total": 0},{"name":"Incorrect. 2","people": 0,"ans":"Incorrect","total": 0}];
  }

  return result;
}

// engage sidebar state
export let SHOW_CREATE_OPTIONS = false;
export let CREATE_MODE = POLL_TYPE.MERGED_POLL;
export const updateShowCreateOptions = (newState) => {
  SHOW_CREATE_OPTIONS = newState;
}
export const updateCreateMode = (newState) => {
  CREATE_MODE = newState;
}
export let SHOW_GRADEBOOK_OPTIONS = false;
export let GRADEBOOK_MODE = POLL_TYPE.GRADED_POLL;
export const updateShowGradebookOptions = (newState) => {
  SHOW_GRADEBOOK_OPTIONS = newState;
}
export const updateGradebookMode = (newState) => {
  GRADEBOOK_MODE = newState;
}
export let VIEWER_SHOW_GRADEBOOK_OPTIONS = false;
export let VIEWER_GRADEBOOK_MODE = POLL_TYPE.GRADED_POLL;
export const updateShowViewerGradebookOptions = (newState) => {
  SHOW_GRADEBOOK_OPTIONS = newState;
}
export const updateViewerGradebookMode = (newState) => {
  GRADEBOOK_MODE = newState;
}

// store local storage information in global variables
export let localStorageData = {
  authToken: ""
};
export const updateLocalStorageData = (newData) => {
  localStorageData = newData;
}

// mentimeter chrome extension
export const logoutOfChromeExtensions = () => {
  try {
    chrome.runtime.sendMessage(MENTIMETER_EXTENSION_ID, {logout: LOGOUT_CHROME_EXT_MESSAGE}, function(response) {})
    chrome.runtime.sendMessage(TOPHAT_EXTENSION_ID, {logout: LOGOUT_CHROME_EXT_MESSAGE}, function(response) {})
    chrome.runtime.sendMessage(POLL_EVERYWHERE_EXTENSION_ID, {logout: LOGOUT_CHROME_EXT_MESSAGE}, function(response) {})
  }
  catch(e) {
    console.log(e);
  }
}

export const getPriority = (name) => {
  if (!name) return 0; // Titles with no characters
  const firstChar = name[0];
  if (!isNaN(firstChar)) {
    return 1; // Titles starting with a number
  }
  if (firstChar.match(/[a-zA-Z]/)) {
    return 2; // Titles starting with a letter
  }
  return 0; // Titles starting with a symbol
};

export const roleDisplayToValue = (roleDisplay) => {
  switch (roleDisplay) {
    case ROLES.ADMINISTRATOR.display:
      return ROLES.ADMINISTRATOR.value;
    case ROLES.IT_MANAGER.display:
      return ROLES.IT_MANAGER.value;
    case ROLES.CREATOR.display:
      return ROLES.CREATOR.value;
    case ROLES.VIEWER.display:
      return ROLES.VIEWER.value;
    default:
      return 'invalid';
  }
};

export const roleValuetoDisplay = (roleValue) => {
  switch (roleValue) {
    case ROLES.ADMINISTRATOR.value:
      return ROLES.ADMINISTRATOR.display;
    case ROLES.IT_MANAGER.value:
      return ROLES.IT_MANAGER.display;
    case ROLES.CREATOR.value:
      return ROLES.CREATOR.display;
    case ROLES.VIEWER.value:
      return ROLES.VIEWER.display;
    default:
      return '';
  }
};

export const getDefaultUserSettings = () => {
  const res = {};
  res[USER_SETTINGS.TOAST_MESSAGE_DURATION] = DEFAULT_TOAST_MESSAGE_DURATION;
  res[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
  res[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
  res[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
  res[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
  res[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
  res[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
  res[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
  res[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
  res[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
  res[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
  res[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
  res[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
  res[USER_SETTINGS.PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
  res[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] = DEFAULT_ATTENDANCE_SAVED_LOCATIONS;
  // console.log(res)
  return res;
};

export const getPollStatus = (pollShareMode, isValid, pollType=GRADED_POLL, isChooser=false) => {
  switch (pollShareMode) {
    case POLL_SHARE_MODE.SHARE_ALL:
      return SHARED_ENTIRELY;
    case POLL_SHARE_MODE.SHARE_EACH:
      return pollType === POLL_TYPE.ATTENDANCE ? SHARED_ENTIRELY : SHARED_BY_QUESTION;
    case POLL_SHARE_MODE.SCHEDULE:
      return SCHEDULED;
    case POLL_SHARE_MODE.UNKNOWN:
      if (isValid === false) {
        return isChooser ? UNFINISHED_CHOOSER : UNFINISHED;
      }
      return READY_TO_SHARE;
    default:
      if (isValid === true) {
        return READY_TO_SHARE;
      }
      return isChooser ? UNFINISHED_CHOOSER : UNFINISHED;
  }
};

export const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

export const scrollToBottom = (divRef) => {
  const savingTimer = setTimeout(() => {
    const element = divRef.current;
    element.scrollTop = element.scrollHeight;
  }, 1);
  return () => clearTimeout(savingTimer);
};

export const scrollToBottomElement = (element) => {
  const savingTimer = setTimeout(() => {
    element.scrollTop = element.scrollHeight;
  }, 1);
  return () => clearTimeout(savingTimer);
};

export const smoothScrollToBottom = (divRef) => {
  divRef.scrollTo({
    top: divRef.scrollHeight,
    behavior: "smooth"
  });
};

export const formatFileSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) return '0 Byte';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + ' ' + sizes[i];
}

export const getOriginalFileName = (fileNameWithTimestamp) => {
  // console.log(fileNameWithTimestamp);
  if (!fileNameWithTimestamp) return "";
  const regexp = /(.+)-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\w+)/g;
  const match = regexp.exec(fileNameWithTimestamp);
  // console.log(match);
  const fileName = match[1] + match[2];
  // console.log(fileName);
  return fileName;
};

export const getLabelFromIntegrationType = (integrationType) => {
  switch (integrationType) {
    case INTEGRATION_TYPES.CANVAS:
      return INTEGRATION_TYPES_LOWERCASE.CANVAS;
    case INTEGRATION_TYPES.BLACKBOARD:
      return INTEGRATION_TYPES_LOWERCASE.BLACKBOARD;
    case INTEGRATION_TYPES.D2L:
      return INTEGRATION_TYPES_LOWERCASE.D2L;
    case INTEGRATION_TYPES.MOODLE:
      return INTEGRATION_TYPES_LOWERCASE.MOODLE;
    default:
      return "Unknown";
  }
};

const toRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

// point1 = {lat, lng}, point2 = {lat, lng}, returns distance in meters
export const getDistanceBetweenTwoGeoCoordinates = (point1, point2) => {
  const R = 6371e3; // Radius of the Earth in meters
  const lat1 = point1.lat;
  const lon1 = point1.lng;
  const lat2 = point2.lat;
  const lon2 = point2.lng;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
    Math.cos(toRadians(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
};


// uses haversine formula to calculate distance between two points
export const isPointInCircle = (point, circle) => { // point = {lat, lng}, circle = {lat, lng, rad}
  const distance = getDistanceBetweenTwoGeoCoordinates(point, circle);
  // console.log(distance, circle.rad);
  return distance <= circle.rad;
};

export const isCircleOverlap = (circle1, circle2) => { // circle1 = {lat1, lng1, rad1}, circle2 = {lat2, lng2, rad2}
  const { rad: rad1 } = circle1;
  const { rad: rad2 } = circle2;

  // Calculate the distance between the centers of the two circles
  const distanceBetweenCenters = getDistanceBetweenTwoGeoCoordinates(circle1, circle2);
  // Check if the sum of the radii is greater than the distance between centers
  return distanceBetweenCenters < (rad1 + rad2);
  
};

// export const getRadiusFromBoundingBox = (bb) => {
//   const radius = getDistanceBetweenTwoGeoCoordinates({lat: bb[0], lng: bb[2]}, {lat: bb[1], lng: bb[3]}) / 2;
//   // console.log(radius);
//   return Math.max(Math.min(radius, MAX_RADIUS), MIN_RADIUS); // clamp radius between min and max values
// };

export const delay = async (ms) => {
  return new Promise((resolve) =>
      setTimeout(resolve, ms));
};

export const fillFormatText = (str="", replacements={}) => {
  return str.replace(/{(\w+)}/g, (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
      replacements[placeholderWithoutDelimiters] || placeholderWithDelimiters);
}

export const findFirstOverflowParent = (element, initEl) => {

  function notVisible(el) {
    let overflow = getComputedStyle(el).overflow;
    return overflow !== "visible";
  }

  let thisEl = element;

  let origEl = initEl || thisEl;
  if (notVisible(thisEl))  {
    return thisEl;
  }

  if (thisEl.parentElement) {
    return findFirstOverflowParent(thisEl.parentElement, origEl);
  } else {
    return document.getElementById("root");
  }

}

export const getShortName = () => {
  const hostname = window.location.hostname;
  let shortName = '';
  if (hostname.includes("engage.yuja.com") && hostname.includes("www")) {
    shortName = hostname.indexOf('.') >= 0 ?
      hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
      hostname;
  } else {
    shortName = hostname.indexOf('.') >= 0 ?
      hostname.substring(0, hostname.indexOf('.')) :
      hostname;
  }
  return shortName;
}

export const convertTime = (startTime) => {
  if (startTime === null) {
      return "";
  }
  const dateTime = new Date(startTime);
  const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
  ];

  const month = monthNames[dateTime.getMonth()];
  const day = dateTime.getDate();
  const year = dateTime.getFullYear();

  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  const timeZone = dateTime.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

  return `${month} ${day}, ${year} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period} (${timeZone})`;
};

export const getLocationGBDisplay = (message, accuracy) => {
  switch (message) {
    case LOCATION_MESSAGE.SUCCESS_IN_GEOFENCE:
      if (!accuracy) {
        return LOCATION_MESSAGE_GB_DISPLAY.SUCCESS_IN_GEOFENCE;
      } else {
        return IN_GEOFENCE_PREFIX + Math.round(accuracy) + IN_GEOFENCE_SUFFIX;
      }
      return LOCATION_MESSAGE_GB_DISPLAY.SUCCESS_IN_GEOFENCE;
    case LOCATION_MESSAGE.SUCCESS_OUTSIDE_GEOFENCE:
      return LOCATION_MESSAGE_GB_DISPLAY.SUCCESS_OUTSIDE_GEOFENCE;
    case LOCATION_MESSAGE.BROWSER_NOT_SUPPORTED:
      return LOCATION_MESSAGE_GB_DISPLAY.BROWSER_NOT_SUPPORTED;
    case LOCATION_MESSAGE.PERMISSION_DENIED:
      return LOCATION_MESSAGE_GB_DISPLAY.PERMISSION_DENIED;
    default:
      return LOCATION_MESSAGE_GB_DISPLAY.LOCATING;
  }
};

export const fillSlider = (sliderRef) => {
  const fillLeft = "#432A6F";
  const fillRight = "#E1DCE9";
  const min = sliderRef.min;
  const max = sliderRef.max;
  const val = ((sliderRef.value - min) * 100) / (max - min);

  sliderRef.style.background = `linear-gradient(to right, ${fillLeft} 0%, ${fillLeft} ${val}%, ${fillRight} ${val}%, ${fillRight} 100%)`;
};

export const isValidSavedLocation = (savedLocation) => {
  if (!savedLocation) return false; // invalid object
  if (!savedLocation.name) return false; // location name is required
  if (!savedLocation.hasOwnProperty("lat") || !savedLocation.hasOwnProperty("lng") || !savedLocation.hasOwnProperty("rad")) return false; // invalid geofence
  if (savedLocation.rad < MIN_RADIUS || savedLocation.rad > MAX_RADIUS) return false; // invalid radius
  return true;
};

export const isValidSavedLocations = (savedLocations) => {
  if (!savedLocations) return false; // invalid array
  for (let i = 0; i < savedLocations.length; i++) {
    if (!isValidSavedLocation(savedLocations[i])) return false; // invalid location
  }
  return true;
};

export const hasDuplicateLocationNames = (savedLocations) => {
  const locationNames = savedLocations.map(location => location.name);
  return new Set(locationNames).size !== locationNames.length;
};

export const hasDuplicateLocationLatLng = (savedLocations) => {
  const locationLatLng = savedLocations.map(location => `${location.lat},${location.lng}`);
  return new Set(locationLatLng).size !== locationLatLng.length;
};

export const hasDefaultLocation = (savedLocations) => {
  for (let i = 0; i < savedLocations.length; i++) {
    if (savedLocations[i].lat === 0 && savedLocations[i].lng === 0) {
      notifyError(fillFormatText(DEFAULT_LOCATION_ERROR, { locationName: savedLocations[i].name }));
      return true;
    }
  }
  return false;
}

export const stripHTMLTags = (input) => {
  // Parse the input as HTML using DOMParser
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  // Extract and return only the text content (without tags)
  return doc.body.textContent || '';
}


export const escapeHTML = (htmlStr) => {
  // Create a temporary div
  const element = document.createElement('div');
  element.textContent = htmlStr;

  // Extract the escaped HTML
  const escapedHTMLStr = element.innerHTML;

  // Remove the div from the DOM
  element.remove();  // This removes the element from the DOM

  return escapedHTMLStr;
}

export const reorderKeysByDragDrop = (sourceIdx=0, destinationIdx=0, keys=[]) => {
  let keysCopy = JSON.parse(JSON.stringify(keys));
  if (sourceIdx === destinationIdx)  {
    return keysCopy;
  }


  const [removed] = keysCopy.splice(sourceIdx, 1);
  keysCopy.splice(destinationIdx, 0, removed);
  return keysCopy;
}

export const checkMCQueCorrect = (liveQuestion, answer) => {
  let correctAnswers = liveQuestion.correctAnswers;
  if (!Array.isArray(correctAnswers)) {
    correctAnswers = [correctAnswers];
  }


  if (!Array.isArray(answer)) {
    return (correctAnswers.includes(answer));
  }

  let correctAnswersCopy = JSON.parse(JSON.stringify(correctAnswers));
  correctAnswersCopy.sort();
  let answerCopy = JSON.parse(JSON.stringify(answer));
  answerCopy.sort();

  if (!liveQuestion.correctAnyEnable) {
    return JSON.stringify(correctAnswersCopy) === JSON.stringify(answerCopy);
  }

  let isCorrect = true;
  for (let answerEle of answerCopy) {
    if (!correctAnswersCopy.includes(answerEle)) {
      isCorrect = false;
      break;
    }
  }
  return isCorrect;
}

