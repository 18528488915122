import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel, getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {Table} from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LTIApiCalls } from "../../apis/LTIApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import "../../components/modals/modal.css";
import NormalPagination from "../../components/pagination/NormalPagination";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTooltip } from "../../components/standardization/YuJaTooltip";
import useHostname from "../../hooks/useHostname";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
	ATMPT_LABEL_2,
	ATTEMPT_REGEX,
	DEFAULT_ROWS_PER_PAGE,
	GRADE_BOOK_QUESTION_COLUMN_REGEX,
	HIDE_ATMPS,
	NO_RESULTS_FOUND,
	POLL_TYPE,
	QUES_LABEL_1,
	SCORE_COLUMN_ID,
	SCORE_COLUMN_NAME,
	SCORE_LABEL,
	SHOW_ATMPS,
	STD_LABEL,
	STUDENT_COLUMN_ID,
	STUDENT_COLUMN_INDEX,
	STUDENT_COLUMN_NAME,
	TEXTBOX,
	USER_ID_COLUMN_ID,
	ACTION_HEADER,
	ACTION_KEY,
	REAL_SCORE_COLUMN_NAME,
	REAL_SCORE_COLUMN_ID,
	SYNC_GRADEBOOK_TEXT,
	GRADE_BOOK_RESPONSE_STATUS,
	QUESTION_TYPES,
	GRADE_TEXT,
	GRADE_BOOK_POLL_NO_RESULTS_FOUND_EXPLAIN, VIEW_ALL_TEXT,

} from "../../utils/constants";
import { getAttemptNo, hasWeightage, isValidScore, notifyError, notifySuccess } from "../../utils/helpers";
import {
	ENTER_VALID_SCORES,
	GRADEBOOK_SYNC_SUCCESS,
	SELECT_AT_LEAST_ONE_STUDENT,
} from "../../utils/toast-message-constants";
import styles from "./GradeBook.module.css";
import { ReactComponent as DropDown } from "./images/arrow-down-black.svg";
import { ReactComponent as DropUp } from "./images/arrow-right-black.svg";
import {YuJaKebabMenu} from "../../components/standardization/YuJaKebabMenu";
import GradeQuestionNew from "./GradeQuestionNew";
import {getResponseStatus} from "../../utils/questionUtils";


function IndeterminateCheckbox({
	indeterminate,
	className = '',
	...rest
}) {
	const ref = useRef();

	useEffect(() => {
	  if (typeof indeterminate === 'boolean') {
		ref.current.indeterminate = !rest.checked && indeterminate;
	  }
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' indeterminate-checkbox'}
			{...rest}
			aria-label="checkbox"
		/>
	)
}

export default function GradeBookTabs({
	responses = [],
	setResponses=undefined,
	questions = [],
	pollKey=undefined,
	pollCode=undefined,
	// pollTitle=undefined,
	pollType=undefined,
	lmsAttempt="",
	syncMode=0,
	setSyncMode=undefined,
	showCheckbox=true,
	autoSyncData={},
	setAutoSyncData=undefined,
	dataLoading=false,
	pollShareMode, 
	setDataLoading,
	globalFilterObj,
	loadData,
	// globalFilterObj=undefined,
	// pageIdx=0,
	// geofence=undefined,
}) {
	const hostResource = useHostname(window.location.hostname);
	const {institutionId = ""} = hostResource;
	const [customScoresMap, setCustomScoresMap] = useState({});
	const [syncCountMap, setSyncCountMap] = useState(false);
	const {  syncScore } = ResponseApiCalls();
	const { gradebookSync } = LTIApiCalls();
	const { setLoading } = useLoading();
	const [gradeQuestionOpen, setGradeQuestionOpen] = useState(false);

	const [gradeQuestionUserId, setGradeQuestionUserId] = useState("");
	const [gradeQuestionAttemptNo, setGradeQuestionAttemptNo] = useState(0);

	const [totalWeightage, setTotalWeightage] = useState(0);


	const [globalFilter, setGlobalFilter] = useState({});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: DEFAULT_ROWS_PER_PAGE
	});

	const convertResponseStatus = (row, columnId) => {
		let val = row.original[columnId];
		let curQuestion = questions.find(q => `Q${q.serialNo}` === columnId);
		return getResponseStatus(pollType, curQuestion, row.original[columnId.replace('Q', 'A')], val);
	}



	const countUngradedResponse = (serialNo) => {
		let curQuestion = questions.find(q => q.serialNo === serialNo);
		let count = 0;

		if (curQuestion.questionType !== QUESTION_TYPES.SA.name) {
			return count;
		}

		for (let response of responses) {
			if (!response.hasOwnProperty("subRows")) {
				continue;
			}

			for (let attemptObj of response.subRows) {
				let formattedVal = attemptObj[`Q${serialNo}`];
				let actualVal = attemptObj[`A${serialNo}`];
				let status = getResponseStatus(pollType, curQuestion, actualVal, formattedVal);
				if (status.key === GRADE_BOOK_RESPONSE_STATUS.UN_GRADED.key) {
					count++;
				}
			}

		}

		return count;

	}

	const filterCustomize = (r, _, globalFilterValue) => {
		if (!r.getCanExpand()) {
			return true;
		}

		if (Object.entries(globalFilterValue).length === 0) {
			return true;
		}

		const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
		// console.log(filteredKeyword, globalFilterValue);
		if (!filteredKeyword) {
			return true;
		}


		const participantName = r.getValue(STUDENT_COLUMN_ID)
		if (participantName && participantName.toLowerCase().indexOf(filteredKeyword) > -1) {
			return true;
		}

		return false;
	};

	const GRADE_BOOK_COLUMNS = [
		{
			header: STUDENT_COLUMN_NAME,
			accessorFn: row => `${row.firstName}${row.lastName ? ' ' + row.lastName : ''}`,
			enableSorting: true,
			sortingFn: 'alphanumeric',
			// sortingFn: Namesort,
			id: STUDENT_COLUMN_ID,
			size: 230
		},
		{
			header: SCORE_COLUMN_NAME,
			accessorKey: SCORE_COLUMN_ID,
			enableSorting: true,
			sortingFn: (a, b) => {
				const scoreA = a.original.score;
				const scoreB = b.original.score;
				if (scoreA === scoreB) return 0;
				if (!scoreA && !scoreB) {
					return 0;
				} else if (!scoreA) {
					return -1;
				} else if (!scoreB) {
					return 1;
				}
				return parseFloat(scoreA) - parseFloat(scoreB);
			},
			sortUndefined: -1,
			id: SCORE_COLUMN_ID,
			size: 130
		},
		{
			header: REAL_SCORE_COLUMN_NAME,
			accessorKey: REAL_SCORE_COLUMN_ID,
			enableSorting: true,
			sortingFn: (a, b) => {
				const scoreA = a.original.score;
				const scoreB = b.original.score;
				if (scoreA === scoreB) return 0;
				if (!scoreA && !scoreB) {
					return 0;
				} else if (!scoreA) {
					return -1;
				} else if (!scoreB) {
					return 1;
				}
				return parseFloat(scoreA) - parseFloat(scoreB);
			},
			sortUndefined: -1,
			id: REAL_SCORE_COLUMN_ID,
			size: 90
		}
	];

	const columns = useMemo(() => {
		return GRADE_BOOK_COLUMNS.concat(questions.map(q => ({
			header: `${q.questionType}`,
			accessorKey: `Q${q.serialNo}`,
			id: `Q${q.serialNo}`,
			enableSorting: true,
			sortingFn: (rowA, rowB, columnId) => {
				let responseStatusA = convertResponseStatus(rowA, columnId);
				let responseStatusB = convertResponseStatus(rowB, columnId);
				return responseStatusA.order - responseStatusB.order;
			},
			size: 100
		}))).concat([{
			header: ACTION_HEADER,
			accessorKey: ACTION_KEY,
			size: 100,
			id: ACTION_KEY,
		}]);
	}, [questions, GRADE_BOOK_COLUMNS]);

    // const pageOptions = useMemo(() => {
    //     let options = [];
    //     // console.log(responses.length, DEFAULT_ROWS_PER_PAGE);
    //     let nPages = Math.ceil(responses.length / DEFAULT_ROWS_PER_PAGE);
    //     // console.log(nPages);
    //     for (let i = 0; i < nPages; i++) options.push(i);
    //     return options;
    // }, [pagination]);

	const {
		getHeaderGroups,
		getRowModel,
		setPageIndex,
		getPageOptions,
		getCanPreviousPage,
		previousPage,
		getCanNextPage,
		nextPage,
		getPageCount,
		getState,
		getSelectedRowModel,
		getIsAllRowsSelected,
		getIsSomeRowsSelected,
		getToggleAllRowsSelectedHandler,
		// getIsAllRowsExpanded,
		toggleAllRowsExpanded,
		resetRowSelection,
		setPageSize,
		resetSorting,
		resetPagination,
		resetExpanded,
	} = useReactTable({
		columns: columns,
		data: responses,
		paginateExpandedRows: false,
		autoResetPageIndex: true,
		enableSortingRemoval: false,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: (row) => {
			// console.log(row, row.subRows);
			return row.subRows;
		},
		// debugAll: true,
		globalFilterFn: filterCustomize,
		onGlobalFilterChanged: setGlobalFilter,
		onPaginationChange: setPagination,
		state: {
            globalFilter: globalFilter,
			pagination
		},
	});




	useEffect(() => {
		if (dataLoading) {
			resetSorting();
			resetPagination();
			resetExpanded();
			resetRowSelection();

		}
	}, [dataLoading]);

	useEffect(() => {
		setGlobalFilter(globalFilterObj);
		setPageIndex(0);
		// if (selectedRow) {
		// 	selectedRow.getToggleExpandedHandler()();
		// 	setSelectedRow(null);
		// }
	}, [globalFilterObj, responses]);

	useEffect(() => {
		let total = questions.filter(q => !!q.weightage).reduce(function (result, q) {
			return result + q.weightage;
		}, 0);
		setTotalWeightage(total);
	}, [questions]);

	useEffect(() => {
		setPageSize(DEFAULT_ROWS_PER_PAGE);

		// console.log(responses);
		let tempScoresMap = {};
		let tempSyncCountMap = {};
		responses.map(r => {
			if (!r.attempts) return;
			tempSyncCountMap[r.userId] = r.syncCount;
			let tempAttemptsMap = {};
			if (!r.attempts) {
				tempScoresMap[r.userId] = tempAttemptsMap;
				return;
			}
			// tempAttemptsMap[0] = r.score.replace('%', '');
			for (const [attemptNo, attempt] of Object.entries(r.attempts)) {
				tempAttemptsMap[attemptNo] = attempt.score ? attempt.score.replace('%', '') : '';
				// console.log(attempt.score, isValidScore(attempt.score));
			}
			tempScoresMap[r.userId] = tempAttemptsMap;
		});
		// console.log(tempSyncCountMap);
		setCustomScoresMap(tempScoresMap);
		setSyncCountMap(tempSyncCountMap);
	}, [responses]);

	useEffect(() => {
		if (!!autoSyncData && !!autoSyncData.userId) {
			console.log("Auto Syncing...", pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
			autoSync(pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
			setAutoSyncData({});
		} else if (!!autoSyncData && !!autoSyncData.userIds) {
			for (const id of autoSyncData.userIds) {
				autoSync(pollCode, id , responses[responses.findIndex(r => r.userId === id)].attempts[0].score);
			}
			setAutoSyncData({});
		}
	}, [autoSyncData]);

	useEffect(() => {
		const syncWrapper = async () => {
			if (syncMode === 0) { // no need to sync
				return;
			}
			setLoading(true);
			if (syncMode === 1) { // student sync
				const selectedUserIds = getSelectedRowModel().flatRows.map((row) => row.original.userId);
				// console.log(selectedUserIds);
				if (selectedUserIds.length === 0) {
					notifyError(SELECT_AT_LEAST_ONE_STUDENT);
				} else {
					const res = await saveSelectedStudents(selectedUserIds);
					if (res) {
						notifySuccess(GRADEBOOK_SYNC_SUCCESS);
					} else {
						notifyError(ENTER_VALID_SCORES);
					}
				}
			// } else if (syncMode === 2) { // quiz sync
			// 	let students = customScoresMap;
			// 	if (students.length === 0) {
			// 		notifyError(NO_STUDENTS_FOUND);
			// 	} else {
			// 		const res = await saveAllStudents(students);
			// 		if (res) {
			// 			notifySuccess(GRADEBOOK_SYNC_SUCCESS);
			// 		} else {
			// 			notifyError(ENTER_VALID_SCORES);
			// 		}
			// 	}
			}
			setSyncMode(0);
			setLoading(false);
		};

		syncWrapper();
    }, [syncMode]);

	const autoSync = (pollCode, userId, score) => {
		console.log(pollCode, userId, score, syncCountMap[userId]);
		if (syncCountMap[userId] > 0) {
			gradebookSync(
				pollCode,
				userId,
				score,
				// () => syncScore(pollKey, pollCode, userId, true)
			);
		}
	};



	const onClickViewAll = (row, cell) => {
		const userId = row.original.userId;
		const attemptNo = getAttemptNo(row);
		const selectedResponse = responses.find(r => r.userId === userId);


		if (!selectedResponse.hasOwnProperty("attempts")) {
			setGradeQuestionUserId(userId);
			setGradeQuestionAttemptNo(1);
			setGradeQuestionOpen(true);
			return;
		}
		setGradeQuestionUserId(userId);
		setGradeQuestionAttemptNo(attemptNo > 0 ? attemptNo : selectedResponse.attempts[0]?.mainAttemptNo);
		setGradeQuestionOpen(true);
	}

	const syncStudent = async (userId, score) => {
		if (!pollCode) {
			console.log("Invalid pollCode");
			return false;
		}
		if (!userId) {
			console.log("Invalid userId");
			return false;
		}
		if (!isValidScore(score)) {
			console.log("Invalid score");
			return false;
		}
		const res = await gradebookSync(
			pollCode,
			userId,
			score,
			() => {
				syncScore(pollKey, pollCode, userId, true, true);
				setSyncCountMap({...syncCountMap, [userId]: syncCountMap[userId] + 1});
			}
		);
		return res;
	};

	async function saveSelectedStudents (selectedUserIds) {
		if (selectedUserIds.length === 0) { // base case
			return true;
		}
		const selectedUserId = selectedUserIds.pop();
		if (!isValidScore(customScoresMap[selectedUserId][0])) { // invalid score
			console.log("Invalid score", customScoresMap[selectedUserId][0], "for", selectedUserId);
			// return false;
		} else {
			const res = await syncStudent(selectedUserId, customScoresMap[selectedUserId][0]);
			if (res) { 
				console.log("Synced", customScoresMap[selectedUserId][0], "for", selectedUserId);
			} else {
				console.log("Error in Gradebook Sync for", selectedUserId);
			}
		}
		return await saveSelectedStudents(selectedUserIds);
	}

	return (
		<>
			{gradeQuestionOpen &&
				<GradeQuestionNew
					institutionId={institutionId}
					pollKey={pollKey}
					pollCode={pollCode}
					pollType={pollType}
					loadData={loadData}
					open={gradeQuestionOpen}
					setOpen={setGradeQuestionOpen}
					questions={questions}
					responses={responses}
					userId={gradeQuestionUserId}
					attemptNo={gradeQuestionAttemptNo}
				/>
			}

			{!!getSelectedRowModel().flatRows.length &&
				<span style={{width: "100%", display: "flex", justifyContent: "end"}}>
					<YuJaButton
						style={{fontSize: 15, width: 150}}
						onClick={() => {
							if (setSyncMode) {
								setSyncMode(1);
							}
						}}
					>
					  {SYNC_GRADEBOOK_TEXT}
					</YuJaButton>
				</span>
			}
			<div className={`${styles.tableContainer} ${(!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) ?
								styles.surveyTableContainer :
								styles.gradeBookTableContainerWCheckbox}`}
			>
				{dataLoading && (
					<div className={styles.skeletonTableContainer}>
						<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
							<div className={styles.skeletonHeaderContainer}>
								<Skeleton borderRadius={10} height={20} inline={true} width="2vw" containerClassName={styles.skeletonBodyCellWrapper} />
								<Skeleton borderRadius={10} height={20} inline={true} width="6vw" containerClassName={styles.skeletonBodyCellWrapper} />
								<Skeleton borderRadius={10} height={20} inline={true} width="3vw" containerClassName={styles.skeletonBodyCellWrapper} />
								<Skeleton borderRadius={10} height={20} inline={true} width="3vw" containerClassName={styles.skeletonBodyCellWrapper} />
								<Skeleton borderRadius={10} height={20} inline={true} width="4vw" containerClassName={styles.skeletonBodyCellWrapper} />
								<Skeleton borderRadius={10} height={20} inline={true} width="2vw" containerClassName={styles.skeletonBodyCellWrapper} />
								<Skeleton borderRadius={10} height={20} inline={true} width="3vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
							</div>
							<div className={styles.skeletonBodyContainer}>
								{Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
									return (<div className={styles.skeletonBodyRow}>
										<Skeleton borderRadius={10} height={20} inline={true} width={`${6 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
										<Skeleton borderRadius={10} height={20} inline={true} width={`${3 + (Math.random()*7)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
										<Skeleton borderRadius={10} height={20} inline={true} width={`${idx % 2 === 0 ? 3.5 : 3}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
										<Skeleton borderRadius={10} height={20} inline={true} width={`${1 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
										<Skeleton borderRadius={10} height={20} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
										<Skeleton borderRadius={10} height={20} inline={true} width={`${1 + (Math.random())}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
										<Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
									</div>)
								})}
							</div>
						</SkeletonTheme>
					</div>
				)}

				{!dataLoading &&
					<div className={styles.tableWrapper}>
						<Table>
							{/* <table> */}
							<thead>
								{getHeaderGroups().map(headerGroup => (
									<tr key={headerGroup.id}>
										{headerGroup.headers.map(column => {
											if (GRADE_BOOK_QUESTION_COLUMN_REGEX.test(column.id)) {
												let serialNo = parseInt(column.id.replaceAll("Q", ""));
												let ungradedCount = countUngradedResponse(serialNo);
												let q = questions.find(q => q.serialNo === serialNo);

												return (
													<th {...{
														// ...column.getHeaderProps(),
														key: column.id,
														style: {width: column.getSize(), backgroundClip: "padding-box", textAlign: "center"}
													}}>
														<div
															className={styles.questionButton}
															onClick={column.column.getToggleSortingHandler()}
														>

															<YuJaTooltip text={QUESTION_TYPES[q.questionType].description}>
																<div>{column.id}</div>
															</YuJaTooltip>
															{ungradedCount > 0 &&
																<YuJaTooltip text={`You have ${ungradedCount} Ungraded response.`}>
																	<div className={styles.attemptNoContainer} style={{width: 30, cursor: "pointer"}}>
																		{ungradedCount}
																	</div>
																</YuJaTooltip>
															}

															{column.column.getIsSorted()
																?
																(column.column.getNextSortingOrder() === "asc" ?
																		<SortDown
																			tabIndex={0}
																			role={TEXTBOX}
																			aria-label={column.id + "descending order"}
																			style={{ width: "15px", cursor: "pointer"}}
																			title="Descending"

																		/>
																		:
																		<SortUp
																			tabIndex={0}
																			role={TEXTBOX}
																			aria-label={column.id + "ascending order"}
																			style={{ width: "15px", cursor: "pointer" }}
																			title="Ascending"
																		/>
																)
																:
																<SortGrey
																	role={TEXTBOX}
																	tabIndex={0}
																	aria-label={column.id + "descending order"}
																	style={{ width: "15px", cursor: "pointer"}}
																	title="Descending"
																/>
															}

														</div>
													</th>
												);
											} else if (column.id === SCORE_COLUMN_ID) {
												if (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
												return (
													<th tabIndex={0} {...{
														// ...column.getHeaderProps(),
														key: column.id,
														style: {width: column.getSize(), backgroundClip: "padding-box", textAlign: "left"}
													}}>
														Percentage
														{column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
																	<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} /> :
																	<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Ascending" onClick={column.column.getToggleSortingHandler()} />
															) :
															<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} />
														}
													</th>
												);
											} else if (column.id === REAL_SCORE_COLUMN_ID) {
												if (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
												return (
													<th tabIndex={0} {...{
														// ...column.getHeaderProps(),
														key: column.id,
														style: {width: column.getSize(), backgroundClip: "padding-box", textAlign: "left"}
													}}>
														{REAL_SCORE_COLUMN_NAME}
														{column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
																	<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} /> :
																	<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Ascending" onClick={column.column.getToggleSortingHandler()} />
															) :
															<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} />
														}
													</th>
												);
											} else if (column.id === STUDENT_COLUMN_ID) {
												return (
													<th {...{
															// ...column.getHeaderProps(),
															key: column.id,
															style: {width: column.getSize(), textAlign: "center"}
														}}
													>
														<span style={{display: "flex", alignItems: "center"}}>
															<span style={{width: 50, display: "flex", alignItems: "center", marginRight: 5, flexShrink: 0}}>
																{showCheckbox &&
																	<IndeterminateCheckbox
																		className="form-check-input"
																		checked={getIsAllRowsSelected()}
																		indeterminate={getIsSomeRowsSelected()}
																		onChange={getToggleAllRowsSelectedHandler()}
																		style={{width: 16, height: 16, marginTop: 0}}
																	/>
																}
																<span style={{flex: 1}}/>
															</span>

															{flexRender(column.column.columnDef.header, column.getContext())}
															{column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
																		<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} /> :
																		<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Ascending" onClick={column.column.getToggleSortingHandler()} />
																) :
																<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} />
															}
														</span>
													</th>
												);
											} else if (column.id === ACTION_KEY) {
												return (
													<th {...{
														// ...column.getHeaderProps(),
														key: column.id,
														style: {width: column.getSize()}
														}}
													>
														<span style={{width: "100%", display: "flex", justifyContent: "end"}}>
															<span
																style={{
																	width: "80%",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "center"
																}}
															>
															{ACTION_HEADER}
															</span>
														</span>
													</th>
												);
											} else {
												// console.log(column.column.columnDef.header, column.getContext());
												return (
													<th tabIndex={0} {...{
														// ...column.getHeaderProps(),
														key: column.id,
														style: {width: column.getSize(), backgroundClip: "padding-box", textAlign: "left"}
													}}>
														{flexRender(column.column.columnDef.header, column.getContext())}
														{column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
																	<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} /> :
																	<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Ascending" onClick={column.column.getToggleSortingHandler()} />
															) :
															<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="Descending" onClick={column.column.getToggleSortingHandler()} />
														}
													</th>
												);
											}
										})}
									</tr>
								))}
							</thead>
							<tbody>
								{getRowModel().rows?.length === 0
									? <tr>
										<td style={{boxShadow: "none"}}
											colSpan={columns.length - (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY ? 2 : 1)}>
											<div style={{
												display: "flex",
												height: 390,
												flexDirection: "column",
												alignItems: "center",
												gap: "5px",
												justifyContent: "center"
											}}>
												<NoResultIllustration/>
												<div style={{
													fontSize: 20,
													marginTop: 25,
													fontWeight: 600,
													color: "#000"
												}}> {NO_RESULTS_FOUND} </div>
												<div style={{
													fontSize: 15,
													marginTop: 10,
													fontWeight: 400,
													color: "#000"
												}}> {GRADE_BOOK_POLL_NO_RESULTS_FOUND_EXPLAIN} </div>
											</div>
										</td>
									</tr>
									: getRowModel().rows.map((row, i) => {
										// console.log(i);
										// prepareRow(row);
										// console.log(i, row);
										return (
											<tr key={row.id} onClick={row.getToggleExpandedHandler()}>
												{row.getVisibleCells().map(cell => {
													if (GRADE_BOOK_QUESTION_COLUMN_REGEX.test(cell.column.id)) {
														let responseStatus = convertResponseStatus(cell.row, cell.column.id);
														const serialNo = parseInt(cell.column.id.substring(1)); // Remove 'Q'
														return (
															<td
																aria-label={QUES_LABEL_1 + serialNo + " " + responseStatus.description}
																{...{key: cell.id,
																	style: {
																		width: cell.column.getSize(),
																		backgroundClip: "padding-box",
																		textAlign: "center"
																	}
																}}
															>
																<YuJaTooltip
																	text={responseStatus.description}
																	placement="bottom"
																	tooltipStyle={{maxWidth: 400}}
																>
																	{!responseStatus.icon ?
																		<span>N/A</span>
																		:
																		<img
																			src={responseStatus.icon}
																			alt={`${responseStatus.description} icon`}
																		/>
																	}
																</YuJaTooltip>
															</td>
														);
													} else if (cell.column.id === SCORE_COLUMN_ID) {
														if (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) {
															return <></>;
														}
														// console.log(`customScoreInput-${getUserId(row)}-${getAttemptNo(row)}`);
														return (
															<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box", height: "fit-content"}}}>
																<div
																	tabIndex={0}
																	role={TEXTBOX}
																	aria-label={SCORE_LABEL + (cell.row.original.score ? cell.row.original.score : 'score') + " percent"}
																	className={styles.customScoreBox}
																>
																	{cell.row.original.score ? cell.row.original.score : 0}%
																</div>
															</td>
														);
													} else if (cell.column.id === REAL_SCORE_COLUMN_ID) {
														if (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY ) {
															return <></>;
														}
														// console.log(`customScoreInput-${getUserId(row)}-${getAttemptNo(row)}`);
														return (
															<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box", height: "fit-content"}}}>
																<div
																	tabIndex={0}
																	role={TEXTBOX}
																	aria-label={SCORE_LABEL + (cell.row.original.score ? cell.row.original.score : 'score') + " percent"}
																	className={styles.customScoreBox}
																>
																	{cell.row.original.correctCount ? cell.row.original.correctCount : 0}/{totalWeightage}
																</div>
															</td>
														);
													} else if (cell.column.id === STUDENT_COLUMN_ID) {
														return (
															<td
																{...{key: cell.id,
																	style: {width: cell.column.getSize(), backgroundClip: "padding-box", zIndex: getRowModel().rows.length - i}
																}}
															>
																<div
																	aria-label={cell.getValue().includes("Attempt") ? (cell.getValue() + ATMPT_LABEL_2) : (STD_LABEL + cell.getValue())}
																	className={ATTEMPT_REGEX.test(cell.getValue()) ? styles.subRow : styles.mainRow}
																	style={{display: "flex", alignItems: "center", gap: 3, marginRight: 10}}
																>

																	<>
																		{row.getCanExpand() ?
																			<span style={{width: 50, display: "flex", alignItems: "center", marginRight: 5, flexShrink: 0}}>
																				{showCheckbox &&
																					<IndeterminateCheckbox
																						className="form-check-input"
																						checked={row.getIsSelected()}
																						// disabled={!row.getCanSelect()}
																						indeterminate={row.getIsSomeSelected()}
																						onChange={(e) => {
																							row.toggleSelected();
																							e.stopPropagation();
																						}}
																						style={{width: 16, height: 16, marginTop: 0}}
																					/>
																				}
																				<span style={{flex: 1}}/>
																				{row.getIsExpanded() ?
																					<DropDown tabIndex={0} aria-label={HIDE_ATMPS} role={TEXTBOX}
																						// onClick={() => row.toggleExpanded()}
																					/> :
																					<DropUp tabIndex={0} aria-label={SHOW_ATMPS} role={TEXTBOX}
																						// onClick={() => row.toggleExpanded()}
																					/>
																				}
																			</span>
																			:
																			<span style={{width: 50, display: "flex", alignItems: "center", marginRight: 5, flexShrink: 0}}>
																				{(!ATTEMPT_REGEX.test(row.getAllCells()[STUDENT_COLUMN_INDEX].getValue()) && showCheckbox &&
																					<IndeterminateCheckbox
																						className="form-check-input"
																						checked={row.getIsSelected()}
																						// disabled={!row.getCanSelect()}
																						indeterminate={row.getIsSomeSelected()}
																						onChange={row.getToggleSelectedHandler()}
																						style={{width: 16, height: 16, marginTop: 0}}
																					/>
																				)}
																				<span style={{flex: 1}}/>
																			</span>
																		}
																	</>


																	<YuJaTooltip
																		tooltipStyle={{ maxWidth: 180, whiteSpace: "wrap" }}
																		placement={"bottom"}
																		text={`${row.original.firstName}
																		${row.original.lastName ? ' ' + row.original.lastName : ''}`}
																	>
																		<div
																			tabIndex={0}
																			role={TEXTBOX}
																			style={{
																				width: "fit-content",
																				whiteSpace: "nowrap",
																				textOverflow: "ellipsis",
																				overflow: "hidden"
																			}}
																		>
																			{flexRender(cell.column.columnDef.cell, cell.getContext())}
																		</div>
																	</YuJaTooltip>
																	{cell.row.original.hasOwnProperty("subRows") &&
																		<YuJaTooltip placement={"bottom"} text={`${cell.row.original.subRows.length} attempts`}>
																			<div className={styles.attemptNoContainer}>
																				{cell.row.original.subRows.length < 10 ? '0' + cell.row.original.subRows.length : cell.row.original.subRows.length}
																			</div>
																		</YuJaTooltip>
																	}
																</div>
															</td>
														);
													} else if (cell.column.id === ACTION_KEY) {
														return (
															<td
																align={"right"}
																className={styles.actionTd}
																{...{key: cell.id,
																	style: {
																		width: cell.column.getSize(),
																		zIndex: getRowModel().rows.length - i,
																	}
																}}
															>
																<span
																	style={{
																		backgroundColor: "#F8F8F8",
																		width: "80%",
																		height: 60,
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center"
																	}}
																>
																	<YuJaKebabMenu>
																		<YuJaKebabMenu.Menu>
																			<YuJaKebabMenu.Item onClick={() => onClickViewAll(row, cell)}>
																				{!!hasWeightage(questions) && pollType.toUpperCase() !== POLL_TYPE.SURVEY
																					? GRADE_TEXT
																					: VIEW_ALL_TEXT
																				}
																			</YuJaKebabMenu.Item>
																		</YuJaKebabMenu.Menu>
																	</YuJaKebabMenu>
																</span>

															</td>
														);
													} else {
														// console.log(cell);
														return (
															<td tabIndex={0} {...{
																key: cell.id,
																style: {width: cell.column.getSize(), backgroundClip: "padding-box"}
															}}>
																{/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
																{cell.getValue()}
															</td>
														);
													}
												})}
											</tr>
										)
									})
								}
							</tbody>
						</Table>
					</div>
				}


				{!dataLoading && getPageCount() > 0 &&
					<NormalPagination
						pageCount={getPageCount()}
						pageIndex={getState().pagination.pageIndex}
						pageOptions={getPageOptions()}
						canPreviousPage={getCanPreviousPage()}
						canNextPage={getCanNextPage()}
						previousPage={previousPage}
						nextPage={nextPage}
						gotoPage={setPageIndex}
					/>
				}

			</div>
		</>
	)
}