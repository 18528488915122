import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FeedBackModal from "../../components/modals/FeedBackModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { Spin } from "../../components/standardization/YuJaLoading";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    BUTTON, CLOSE,
    CLOSE_MODAL,
    ENDED_POLL_TEXT,
    FEEDBACK_BUTTON_TEXT, HOME_PATH, LOGIN_PATH,
    NO_TEXT,
    REATTEMPT_MODAL_BODY,
    REATTEMPT_MODAL_TITLE,
    RETRY_TEXT,
    SEE_RESPONSES_BUTTON_TEXT,
    TEXTBOX,
    UNLIMITED_ATTEMPTS,
    USER_COMPLETE_MERGED_RESPONSES, USER_COMPLETE_POLL_REATTEMPT_SUBTITLE,
    USER_COMPLETE_POLL_SUBTITLE,
    YES_TEXT, YOU_ARE_ALL_DONE_TEXT
} from "../../utils/constants";
import { newPageLog, removePrefix } from "../../utils/helpers";
import ClassResultAllMerged from "./ClassResultAllMerged";
import thankYou from "./animations/PurpleThankYou.json";
import "./styles.css";
import styles from "./styles.module.css";
import PollTitleContainer from "./PollTitleContainer";
import Lottie from "react-lottie";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import userCompleteGif from "../../images/user-complete.gif"
import {useHistory} from "react-router-dom";
import {useSessionValidation} from "../../hooks/useSessionValidation";
import PollReadyClasses from "./PollReady.module.css";
import {useCountdown} from "../../hooks/useCountdown";

export default function UserCompleteMergedPoll({
    stopped = false,
    websocketState,
    handleReattempt,
    allowReattempt,
    attemptsCount,
    maxAttempts,
    pollTitle,
    currentPoll,
    institutionId,
    userName,
    pollInfo, 
    pollEndTime,
    handlePollComplete,
    setCountdownContent
}) {

    const history = useHistory();
    const {isLoggedIn, isFromGradeLink} = useSessionValidation();
    const { loading } = useLoading();
    const { getReturnValues, resetCountdown } = useCountdown(pollEndTime, false);
    const [days, hours, minutes, seconds, ] = getReturnValues;



    const [showClassResult, setShowClassResult] = useState(false);
    const [canReattempt, setCanReattempt] = useState(false);
    const [feedbackModalShow, setFeedBackModalShow] = useState(false);
    const [showReattemptModal, setShowReattemptModal] = useState(false);

    useEffect(() => {
        newPageLog("UserCompletePoll.js");
    }, []);

    useEffect(() => {
        resetCountdown(pollEndTime);
    }, [pollEndTime]);

    useEffect(() => {
        if (days + hours + minutes + seconds <= 0) {
            handlePollComplete();
        }
    }, [days, hours, minutes, seconds]);

    useEffect(() => {
        const now = Date.now();
        if (now > pollEndTime) {
            handlePollComplete();
        }
    }, [handlePollComplete, pollEndTime]);


    useEffect(() => {
        setCanReattempt(allowReattempt && (maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts));
    }, [allowReattempt, attemptsCount, maxAttempts]);

    const onClickReattempt = () => {
        setShowReattemptModal(true);
    };

    const handleClose = () => {
        setShowReattemptModal(false);
    };

    useEffect(() => {
        if (stopped) {
            setShowReattemptModal(false);
        }
    }, [stopped]);


    return (
        <>
            {showClassResult &&
                <ClassResultAllMerged
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    websocketState={websocketState}
                    pollInfo={pollInfo}
                    pollTitle={pollTitle}
                    userName={userName}
                    setCountdownContent={setCountdownContent}
                    setShowClassResult={setShowClassResult}
                />
            }
            {!showClassResult &&
                <div className={PollReadyClasses.parentContainer} style={{paddingBottom: 0}}>
                    <FeedBackModal
                        show={feedbackModalShow}
                        setModalShow={setFeedBackModalShow}
                        pollKey={removePrefix(currentPoll.questions[0].pollKey)}
                        pollUniqueCode={currentPoll.questions[0].pollSortKey.split("#")[2]}
                        userId={userName}
                        institutionId={institutionId}
                    />
                    <Modal
                        id="reattempt-modal"
                        show={showReattemptModal}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop={loading ? "static" : true}
                        dialogClassName="sa-modal"
                    >
                        <Spin tip="Loading..." size="large" spinning={loading}>
                            <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={handleClose}
                                        className="modal-close"/>
                            <Modal.Header style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <Modal.Title tabIndex={0} role={TEXTBOX}>
                                    {REATTEMPT_MODAL_TITLE}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body tabIndex={0} role={TEXTBOX}>
                                {REATTEMPT_MODAL_BODY}
                            </Modal.Body>
                            <Modal.Footer>
                                <YuJaButton type="secondary" onClick={handleClose}>
                                    {NO_TEXT}
                                </YuJaButton>
                                <YuJaButton onClick={handleReattempt}>
                                    {YES_TEXT}
                                </YuJaButton>
                            </Modal.Footer>
                        </Spin>
                    </Modal>

                    <div className={PollReadyClasses.progressBarContainer} style={{marginBottom: 15}}>
                        <span className={PollReadyClasses.progressBar} style={{width: "100%"}}/>
                    </div>
                    <PollTitleContainer
                        pollTitle={pollTitle}
                        style={{width: "calc(100% - 20px)", alignSelf: "center"}}
                    />

                    <div style={{flex: 1}}/>

                    <img src={userCompleteGif} alt="user complete..."
                         style={{width: "min(50vw, 500px)", alignSelf: "center"}}/>
                    {/*<Lottie*/}
                    {/*    options={{*/}
                    {/*        loop: false,*/}
                    {/*        autoplay: true,*/}
                    {/*        animationData: thankYou,*/}
                    {/*        rendererSettings: {preserveAspectRatio: "xMidYMid slice"}*/}
                    {/*    }}*/}
                    {/*    height={imageSize}*/}
                    {/*    width={imageSize}*/}
                    {/*    isClickToPauseDisabled={true}*/}
                    {/*/>*/}

                    <span tabIndex={0} role={TEXTBOX} className={styles.emptyBoxTitle}>
                        {stopped ? ENDED_POLL_TEXT : YOU_ARE_ALL_DONE_TEXT}
                    </span>
                    <span style={{height: 20}}/>
                    <span tabIndex={0} role={TEXTBOX} className={styles.emptyBoxDesc}>
                        {stopped && USER_COMPLETE_MERGED_RESPONSES}
                        {!stopped && canReattempt && USER_COMPLETE_POLL_REATTEMPT_SUBTITLE}
                        {!stopped && !canReattempt && USER_COMPLETE_POLL_SUBTITLE}
                    </span>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        gap: 10,
                        marginTop: 40
                    }}>
                        {!isFromGradeLink() &&
                            <YuJaButton
                                onClick={() => history.push({pathname: isLoggedIn() ? HOME_PATH : LOGIN_PATH})}
                                style={{
                                    fontSize: 14,
                                    width: 130,
                                    height: 35,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {CLOSE}
                            </YuJaButton>
                        }


                        {stopped &&
                            <YuJaButton
                                style={{
                                    fontSize: 14,
                                    width: 130,
                                    height: 35,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    whiteSpace: "nowrap"
                                }}
                                onClick={() => setShowClassResult(true)}
                            >
                                {SEE_RESPONSES_BUTTON_TEXT}
                            </YuJaButton>
                        }
                        {canReattempt && !stopped &&
                            <YuJaButton
                                onClick={onClickReattempt}
                                style={{
                                    fontSize: 14,
                                    width: 130,
                                    height: 35,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {RETRY_TEXT}
                            </YuJaButton>
                        }
                    </div>


                    <div style={{flex: 2}}/>

                    <YuJaButton
                        style={{
                            position: "fixed", right: 0,
                            transform: "translate(50%, 50%) rotate(-90deg)",
                            transformOrigin: "bottom",
                            top: "calc(80% - 90px)"
                        }}
                        type={"secondary"}
                        onClick={() => setFeedBackModalShow(true)}
                    >
                        {FEEDBACK_BUTTON_TEXT}
                    </YuJaButton>

                </div>
            }

        </>
    );
}