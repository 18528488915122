import React, {useEffect, useState} from "react";
import "./styles.css";
import {
    POLL_STATE,
    SCHEDULE_POLL_OPEN_MESSAGE,
    TEXTBOX,
    DEFAULT_GET_READY_ALL_TIME_LIMIT, GET_READY_PAGE_TITLE, GET_READY_PAGE_EXPLAIN, GET_READY_ALL_PAGE_EXPLAIN
} from "../../utils/constants";
import {newPageLog} from "../../utils/helpers";
import {ReactComponent as ScheduleLoading} from "../../images/schedule_loading.svg";
import {useCountdown} from "../../hooks/useCountdown";
import PollTitleContainer from "./PollTitleContainer";
import styles from "./styles.module.css";
import IllustrationWrapper from "./IllustrationWrapper";


export default function GetReadyAll({poll, handleGetReadyAllComplete, pollState, pollTitle, websocketState}) {
    const {timeReadyDuration, timeLimit, pollStartTime} = poll;
    // const {timeReadyDuration} = current;

    const {getReturnValues, resetCountdown} = useCountdown(new Date(pollStartTime).getTime());
    const [days, hours, minutes, seconds, milliSeconds] = getReturnValues;
    const [showCountDown, setShowCountDown] = useState(false);

    const [countDownSeconds, setCountDownSeconds] = useState(null);

    useEffect(() => {
        let getReadyCountdown = poll.getReadyAllTimeLimit ? poll.getReadyAllTimeLimit : DEFAULT_GET_READY_ALL_TIME_LIMIT;
        if (days === 0 && hours === 0 && minutes === 0 && seconds <= getReadyCountdown && pollState.includes(POLL_STATE.SCHEDULED)) {
            if (countDownSeconds === null) {
                setCountDownSeconds(seconds);
            }
            setShowCountDown(true)
        }
        if (days + hours + minutes + seconds + milliSeconds <= 0) {
            handleGetReadyAllComplete();
        }
    }, [seconds]);
    const [keyNo, setKeyNo] = useState(0);

    const convertTime = () => {
        if (pollStartTime === null) {
            return "";
        }
        const dateTime = new Date(pollStartTime);
        const monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        const month = monthNames[dateTime.getMonth()];
        const day = dateTime.getDate();
        const year = dateTime.getFullYear();

        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        const timeZone = dateTime.toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];

        return `${month} ${day}, ${year} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period} (${timeZone})`;
    }

    useEffect(() => {
        newPageLog("GetReadyAll.js");
    }, []);

    let timeLimitMessage = "";
    let tempTimeLimit = timeLimit;

    if (tempTimeLimit >= 3600) {
        if (tempTimeLimit >= 7200) {
            timeLimitMessage += Math.floor(tempTimeLimit / 3600) + " hours";
        } else {
            timeLimitMessage += "1 hour";
        }
        tempTimeLimit = tempTimeLimit % 3600;
        if (tempTimeLimit !== 0) {
            timeLimitMessage += ", ";
        }
    }

    if (tempTimeLimit >= 60) {
        if (tempTimeLimit >= 120) {
            timeLimitMessage +=
                tempTimeLimit % 60 === 0 && timeLimit >= 3600
                    ? "and " + Math.floor(tempTimeLimit / 60) + " minutes"
                    : Math.floor(tempTimeLimit / 60) + " minutes";
        } else {
            timeLimitMessage +=
                tempTimeLimit % 60 === 0 && timeLimit >= 3600
                    ? "and 1 minute"
                    : "1 minute";
        }

        tempTimeLimit = tempTimeLimit % 60;
        if (tempTimeLimit !== 0) {
            timeLimitMessage += ", ";
        }
    }

    if (tempTimeLimit >= 1) {
        if (tempTimeLimit >= 2) {
            timeLimitMessage +=
                timeLimit >= 60
                    ? "and " + tempTimeLimit + " seconds"
                    : tempTimeLimit + " seconds";
        } else {
            timeLimitMessage += timeLimit >= 60 ? "and 1 second" : "1 second";
        }
    }

    useEffect(() => {
        setKeyNo(keyNo + 1);
    }, [timeReadyDuration]);

    useEffect(() => {
        resetCountdown(pollStartTime);
    }, [pollStartTime]);

    const renderCounter = () => {
        const secondsRemain = days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60 + seconds;
        if (secondsRemain < 1) {
            return (
                <div className={`${styles.getReadyCountDownContainer} ${styles.small}`}>
                    <span style={{fontSize: 15, lineHeight: "18px", fontWeight: "700"}}>Get Ready</span>
                    <span>GO</span>
                </div>
            );
        } else {
            return (
                <div className={`${styles.getReadyCountDownContainer} ${secondsRemain >= 10 ? styles.small : ""}`}>
                    <span className={styles.countDownBlink}>{secondsRemain}</span>
                </div>
            );
        }

    }


    return (
        <div className={styles.generalContainer} style={{height: "unset", flex: 1}}>
          <PollTitleContainer pollTitle={pollTitle} websocketState={websocketState}/>
          <span style={{flex: 1}}/>
          {!pollState.includes(POLL_STATE.SCHEDULED) &&
              <>

                <IllustrationWrapper>
                  {renderCounter()}
                </IllustrationWrapper>

                <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomTitle}>
                  {GET_READY_PAGE_TITLE}
              </span>
                <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomDesc}>
                  {GET_READY_ALL_PAGE_EXPLAIN}
              </span>

                {/*<div className="get-ready-sub-title" id="title" style={{marginTop: "30px"}}>*/}
                {/*  <div tabIndex={0} role={TEXTBOX}>{`${GET_READY_PAGE_TIME_LIMIT_1ST_PART} ${timeLimitMessage} ${GET_READY_PAGE_TIME_LIMIT_2ND_PART}`}<br/></div>*/}
                {/*  <div tabIndex={0} role={TEXTBOX} style={{marginTop: "10px"}}>{GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION}</div>*/}
                {/*</div>*/}
              </>
          }

          {pollState.includes(POLL_STATE.SCHEDULED) &&
              <>
                <ScheduleLoading style={{alignSelf: "center"}}/>
                <div tabIndex={0} role={TEXTBOX} className={styles.waitingRoomTitle}>
                  {SCHEDULE_POLL_OPEN_MESSAGE + convertTime()}
                </div>
                {!!showCountDown &&
                    <>
                      <span style={{height: 10}}/>
                      {renderCounter()}
                      <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomTitle}>
                        {GET_READY_PAGE_TITLE}
                    </span>
                      <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomDesc}>
                        {GET_READY_ALL_PAGE_EXPLAIN}
                    </span>
                    </>
                }
              </>
          }
          <span style={{flex: 2}}/>
        </div>
    );
}
