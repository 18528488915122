import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import DragDropIcon from "../../images/drag_drop.svg";
import { DRAG_TITLE, MC_ANSWER_LENGTH, PREMISE_SELECTED } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { MC_CI_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import "../styles.css";
import { PremiseRadio } from "./PremiseRadio";
import {convertMHOptionMap} from "../../utils/questionUtils";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";

export default function Premise({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  questionBuilder,
  disabled
}) {
  const {isNarrow} = useCheckMainContainerNarrow();
  const [showDragIcon, setShowDragIcon] = useState(false);
  const { serialNo, } = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;

  const handleAnswerChange = (input, index) => {
    let copyOfQuestions = JSON.parse(JSON.stringify(questions));
    let newCorrectAnswers = copyOfQuestions[selectedQuestionIndex].correctAnswers;
    newCorrectAnswers[index][0] = input;
    if (!!questionBuilder) {
      copyOfQuestions[selectedQuestionIndex].optionsMap = convertMHOptionMap(newCorrectAnswers);
    }
    setQuestions(copyOfQuestions);
  };


  return (optionData.length > 1 &&
    <div tabIndex={0} style={{display: "flex", width: "100%"}}>
      <Draggable draggableId={"premise" + index} index={index} key={"premise" + index}
                 isDragDisabled={questionBuilder && disabled}>
        {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}
                 style={{width: "100%", ...provided.draggableProps.style}}>
              <div
                  onMouseEnter={() => setShowDragIcon(true)}
                  onMouseLeave={() => setShowDragIcon(false)}
                  style={{display: "flex", flex: 1, alignItems: "center"}}
              >
                <div
                    {...provided.dragHandleProps}
                    style={{
                      marginRight: 10,
                      display: "flex",
                      justifyContent: "center"
                    }}
                    title={(questionBuilder && disabled) ? "" : DRAG_TITLE}
                    tabIndex={-1}
                >
                  {(!questionBuilder || !disabled) &&
                      <img src={DragDropIcon} alt="drag drop icon"/>}
                </div>
                <PremiseRadio
                    index={index}
                />

                <YuJaTextBox
                    disabled={questionBuilder && disabled}
                    name={"question-text"}
                    label={PREMISE_SELECTED + (index + 1)}
                    containerStyle={{width: "100%", fontSize: 16}}
                    placeholder={`Premise ${index + 1}`}
                    value={optionData[0]}
                    maxLength={MC_ANSWER_LENGTH}
                    onChange={(e) => {
                      if (e.target.value.length <= MC_ANSWER_LENGTH) {
                        handleAnswerChange(e.target.value, index);
                      } else {
                        notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                        handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);
                      }
                    }}
                    showRemaining={true}
                />
              </div>
            </div>
        )}
      </Draggable>
    </div>
  );
}