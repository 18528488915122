import React, { useEffect } from "react";
import useMobileAccess from "../../hooks/useMobileAccess";
import {
    CLOSE,
    CLOSED_GRADED_POLL,
    DO_NEED_ASSISTANCE_TEXT,
    HOME_PATH,
    LOGIN_PATH,
    PLEASE_CONTACT_SUPPORT__PREFIX,
    PLEASE_CONTACT_SUPPORT__SUFFIX,
    REATTEMPT_CLOSED_POLL,
    TEXTBOX,
    YUJA_SUPPORT
} from "../../utils/constants";
import { newPageLog } from "../../utils/helpers";
import { ReactComponent as TELEPHONE } from "./images/telephone-purple.svg";
import PollIsClosedIllustration from "./images/poll-closed-illustration.gif";
import styles from "./styles.module.css";
import PollTitleContainer from "./PollTitleContainer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import classes from "./styles.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import {useHistory} from "react-router-dom";
import {useSessionValidation} from "../../hooks/useSessionValidation";

export default function EndedPollNew({pollTitle, websocketState}) {
    let {width, height} = useWindowDimensions();
    const isNarrow = useMobileAccess(1200);
    const history = useHistory();
    const {isLoggedIn} = useSessionValidation();

    useEffect(() => {
      newPageLog("EndedPollNew.js");
    }, []);

    return (
        <div className={styles.endedPollBackground} style={{justifyContent: "normal"}}>
            <PollTitleContainer pollTitle={pollTitle} websocketState={websocketState}/>
            <div style={{flex: 1}}/>
            <div className={classes.waitingIllustrationWrapper} style={{width: 190, height: 190, marginTop: 30}}>
                <img
                    src={PollIsClosedIllustration}
                    alt="waiting"
                    style={{
                        width: 120,
                        height: 120,
                        flexShrink: 0,
                    }}
                />
            </div>
            <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomTitle}>
                {CLOSED_GRADED_POLL}
            </span>
            <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomDesc} style={{textAlign: "center"}}>
                {REATTEMPT_CLOSED_POLL}
            </span>

            <YuJaButton
                onClick={() => history.push({pathname: isLoggedIn() ? HOME_PATH : LOGIN_PATH})}
                style={{
                    fontSize: 14,
                    width: 130,
                    height: 35,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {CLOSE}
            </YuJaButton>
            <div/>

            <div className={classes.pollCloseSupportContainer} >
                <div className={classes.pollCloseIconProfile}>
                    <TELEPHONE/>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 15,
                        padding: "20px 15px",
                        flex: 1,
                        minWidth: 0,
                        height: "100%"
                    }}
                >
                    <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomTitle} style={{fontSize: 18}}>
                        {DO_NEED_ASSISTANCE_TEXT}?
                    </span>
                    <span tabIndex={0} role={TEXTBOX} className={styles.waitingRoomDesc}>
                        {PLEASE_CONTACT_SUPPORT__PREFIX}
                        <a
                            href="https://support.yuja.com/hc/en-us"
                            target="_blank"
                            style={{textDecoration: "none", fontWeight: "700", color: "#000"}}
                        >{YUJA_SUPPORT}</a>
                        {PLEASE_CONTACT_SUPPORT__SUFFIX}
                    </span>
                </div>
            </div>
            <div style={{flex: 2}}/>
            {/*<span className={styles.endedPollText} style={{fontSize: isNarrow ? 14 : undefined}}>*/}
            {/*    {SUPPORT_PREFIX} <a href="https://support.yuja.com/hc/en-us" target="_blank">{YUJA_ENGAGE_SUPPORT}</a>*/}
            {/*</span>*/}
        </div>
    );
}
